/* eslint-disable array-callback-return */
import React from "react";
import { useAppContext } from "../../../../../Lib/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  divWrapper: {
    display: "flex",
    alignItems: "center",
  },
  divIcon: {
    width: "10px",
    height: "10px",
    borderRadius: "100%",
    marginRight: "10px",
  },
  cell: {
    padding: '0px 5px 0px 5px'
  }
});


export default function ImbTotalsTable(props) {
  const { stateLocation } = useAppContext()
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell colSpan={12} className={classes.cell}>
              <h4 className="table-header">{props.title}</h4>
            </TableCell>
          </TableRow>
          <TableRow>
          <TableCell className={classes.cell}>
              <p className="table-header">Business Name</p>
            </TableCell>
            <TableCell className={classes.cell}>
              <p className="table-header">Address</p>
            </TableCell>
            <TableCell className={classes.cell}>
              <p className="table-header">City</p>
            </TableCell>
            <TableCell className={classes.cell}>
              <p className="table-header">Zip</p>
            </TableCell>
            <TableCell className={classes.cell}>
              <p className="table-header">{stateLocation === 'TX' ? 'County' : 'Parish'}</p>
            </TableCell>
            <TableCell className={classes.cell}>
              <p className="table-header">Region</p>
            </TableCell>
          </TableRow>
          {props.data.map((item, key) => {
              return (
                <TableRow key={key}>
                    <TableCell className={classes.cell}>
                        <p>{item.name}</p>
                    </TableCell>
                    <TableCell className={classes.cell}>
                        <p>{item.address}</p>
                    </TableCell>
                    <TableCell className={classes.cell}>
                    <p>{item.city}</p>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <p>{item.zip}</p>
                    </TableCell>
                  <TableCell className={classes.cell}>
                    <p>{item.county}</p>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <p>{item.region}</p>
                  </TableCell>
                </TableRow>
              );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
