import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import uniqid from "uniqid";
import './PrimaryNavigation.css';
import { Auth, API } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { useAppContext } from "../Lib/UserContext";

import Dashboard from "../Pages/Dashboard/Dashboard"
import Admin from "../Pages/Admin/Admin";
import Reports from "../Pages/Reports/Reports";
import ReactGA from "react-ga4";
import { availableDashboards } from '../Data/availableDashboards'

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import icon from '../Assets/Images/icon-S.png';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Logo from '../Assets/Images/mark.png'
import Prevention from '../Assets/Images/demographics-selected.png'
import PreventionDark from '../Assets/Images/demographics.png'
import Cases from '../Assets/Images/indicators-selected.png'
import CasesDark from '../Assets/Images/indicators.png'
//import Risk from '../Assets/Images/care-selected.png'
//import RiskDark from '../Assets/Images/care.svg'
import Report from '../Assets/Images/reports-selected.png'
import ReportDark from '../Assets/Images/reports-icon.png'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SettingsIcon from '@material-ui/icons/Settings';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import HubspotForm from "react-hubspot-form";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Badge from '@material-ui/core/Badge';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css'


const drawerWidth = 300;

const navIcons = {
  Prevention: {
    active: Prevention, inActive: PreventionDark
  },
  Cases: {
    active: Cases, inActive: CasesDark
  },
  // Temporarily restore the Risk Model dashboard for screenshots
  Risk: {
    active: Cases, inActive: CasesDark
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    position: 'fixed',
    zIndex: 999,
    top: 0,
    bottom: 0,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerIcons: {
    width: '2em'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  activeIcon: {
    position: 'absolute',
    left: 0,
    top: '15px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  drawerDotsActive: {
    width: '15px',
    height: '15px',
    marginLeft: '5px',
    color: '#28ADE3'
  },
  drawerDotsInactive: {
    width: '15px',
    height: '15px',
    marginLeft: '5px',
    color: 'white',
    opacity: '0.5'
  },
  menuExpanded: {

  }
}));

export default function PrimaryNavigation() {
  const classes = useStyles();
  const history = useHistory();
  const { role, email, userHasAuthenticated, stateLocation } = useAppContext();
  const [open, setOpen] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState(false);
  const [primaryNav, setPrimaryNav] = React.useState("Prevention");
  const [secondaryNav, setSecondaryNav] = React.useState(null);
  const [feedback, setFeedback] = React.useState(false);
  const [reports, setReports] = React.useState([]);
  const [openSource, setOpenSource] = React.useState(false);
  const [activePrimaryNav, setActivePrimaryNav] = React.useState("Prevention");
  // eslint-disable-next-line no-unused-vars
  const [activeSecondaryNav, setActiveSecondaryNav] = React.useState("Services");
  const [dashboardData, setDashboardData] = useState({})

  const handleSourceClose = () => {
    setOpenSource(false);
  };

  const handleSourceOpen = () => {
    setOpenSource(true);
    setFeedback(false);
  };

  // Opening and Closing Drawer
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Handling Logout
  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    localStorage.setItem("role", "");
    localStorage.setItem("stateLocation", "");
    history.push("/");
  }

  // Handling Hubspot Modal
  const handleSubmitFeedback = () => {
    setFeedback(true);
    setOpenSource(false);
  };

  const handleCancelHubspot = () => {
    setFeedback(false);
  };

  // Handle Primary Navigation Click
  // const handlePrimaryNav = (e, value) => {
  //   if (value !== 'reports'){
  //       history.push('/dashboard')
  //   }
  //   setPrimaryNav(value)
  //   setHelpOpen(false)
  //   if (value === "Prevention"){
  //     setSecondaryNav("Services");
  //   } else if (value === "Cases") {
  //     setSecondaryNav("PublicSafety");
  //   } else if (value === "Risk") {
  //     setSecondaryNav("Community");
  //   }
  // }

  const handlePrimaryNav = (e, value, primaryNav, secondaryNav) => {
    if (value !== 'reports') {
      history.push('/dashboard')
    }

    setPrimaryNav(value)
    localStorage.setItem("primaryNav", value);
    //localStorage.setItem("primaryNav", JSON.stringify(value));
    setHelpOpen(false)
    if (value === primaryNav) {
      setSecondaryNav(secondaryNav);
    }
  }

  const handleHelpClick = (e, value) => {
    setHelpOpen(!helpOpen)
    //setActivePrimaryNav('help');
  }


  const handleActivePrimaryNav = (e, input) => {
    localStorage.setItem("primaryNav", input);
    setActivePrimaryNav(input);
    setHelpOpen(false)
    if (input === "Prevention") {
      setActiveSecondaryNav("Services");
    } else if (input === "Cases") {
      //setActiveSecondaryNav("PublicSafety");
      setActiveSecondaryNav("Federal");
    }
    // else if (input === "Risk") {
    //   setActiveSecondaryNav("Community");
    // }
  }


  const handleSecondaryNav = (e, primaryNav, secondaryNav) => {
    // console.log(primaryNav, secondaryNav)
    setPrimaryNav(primaryNav);
    setSecondaryNav(secondaryNav);
    setActiveSecondaryNav(secondaryNav)
    if (primaryNav !== 'reports') {
      history.push('/dashboard')
    }
    localStorage.setItem("secondaryNav", secondaryNav);
    localStorage.setItem("primaryNav", primaryNav);
    ReactGA.pageview(`/${stateLocation}/${primaryNav}/${secondaryNav}`);
  }

  // Reports
  const addToReportPage = (data, selectedConfig, type, chartLine, chartLineTwo, activeCategory) => {
    let newId = uniqid();
    let obj = {};
    obj.name = data;
    obj.type = type;
    obj.config = selectedConfig;
    obj.value = null;
    obj.id = newId;
    obj.chartLine = chartLine;
    obj.chartLineTwo = chartLineTwo
    obj.activeCategory = activeCategory
    var newArray = [...reports];
    var concatArray = newArray.concat([JSON.parse(JSON.stringify(obj))])
    // console.log('report obj', obj)
    setReports(concatArray);
  };

  const callBackReports = items => {
    setReports(items)
  };

  useEffect(() => {
    async function getNav() {
      let storedPrimaryNav = localStorage.getItem("primaryNav")
      let storedSecondaryNav = localStorage.getItem("secondaryNav")
      let getDashboardDetails = await availableDashboards?.filter(d => d.stateLocation === stateLocation)[0]
      let dashboardData = getDashboardDetails?.dashboardDataFile
      setDashboardData(dashboardData)

      if (storedPrimaryNav && storedSecondaryNav && dashboardData?.[storedPrimaryNav]?.[storedSecondaryNav]) {
        setPrimaryNav(storedPrimaryNav);
        setSecondaryNav(storedSecondaryNav);
        setActivePrimaryNav(storedPrimaryNav);
        setActiveSecondaryNav(storedSecondaryNav);
        ReactGA.pageview(`/${stateLocation}/${primaryNav}/${secondaryNav}`);
      } else {
        let path = history.location.pathname.split("/");
        if (path[1] === "dashboard") {
          setPrimaryNav(getDashboardDetails.defaultPrimaryNav) // "Prevention"
          setSecondaryNav(getDashboardDetails.defaultSecondaryNav)
          setActivePrimaryNav(getDashboardDetails.defaultPrimaryNav);
          setActiveSecondaryNav(getDashboardDetails.defaultSecondaryNav);
        } else {
          setPrimaryNav(path[1])
          setActivePrimaryNav(path[1]);
        }
      }

      if (email) {
        API.post("lighthouse-data-initiative", "getsavedreports", {
          body: {
            email: email,
            stateLocation: stateLocation
          }
        }).then(response => {
          setReports(response);
        });
      }
    }

    if (stateLocation && stateLocation !== '') {
      getNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateLocation, email]);



  return (
    <div className={classes.root} id="primary-nav">
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })
        }}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <div className={classes.toolbar}>
          <ListItem button className="primvary-nav-header" onClick={handleDrawerOpen}>
            <ListItemIcon style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={Logo}
                style={{ width: '48px', height: '48px' }}
                alt="Lighthouse Data Initiative"
              />
            </ListItemIcon>
            <ListItemText primary={"Lighthouse"} />
          </ListItem>
          <IconButton onClick={handleDrawerClose}>
            {<MenuIcon />}
          </IconButton>
        </div>
        <Divider />

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>

          <List className="primary-nav-list-item">
            {Object?.keys(dashboardData)?.map((primaryNavKey, primaryNavIndex) => (

              <div key={primaryNavIndex}>
                {/* <p key={primaryNavIndex}>{dashboardData[primaryNavKey][Object.keys(dashboardData[primaryNavKey])[0]].primary}</p> */}

                <ListItem button onClick={(e) => handleActivePrimaryNav(e, primaryNavKey)}>
                  {activePrimaryNav === primaryNavKey &&
                    <img src={icon} alt="active tab" className={classes.activeIcon} />
                  }
                  <div style={{ display: 'flex' }}> {/* Need??? This was only on the inactive code */}
                    <ListItemIcon>
                      <img
                        src={activePrimaryNav === primaryNavKey ? navIcons[primaryNavKey].active : navIcons[primaryNavKey].inActive}
                        alt="Lighthouse Data Initiative"
                        className={classes.drawerIcons}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={dashboardData[primaryNavKey][Object.keys(dashboardData[primaryNavKey])[0]].primary}
                      style={activePrimaryNav === primaryNavKey ? null : { color: 'white', opacity: '0.5' }}
                    />
                  </div>
                </ListItem>

                {activePrimaryNav === primaryNavKey && open &&
                  <div style={{ display: 'flex' }}>
                    <SlideDown>
                      <List>

                        {Object.keys(dashboardData[primaryNavKey]).map((secondaryNavKey, secondaryNavIndex) => (

                          <div key={secondaryNavIndex}>
                            {(secondaryNavKey === "Coordination" && role === 'Standard') || (secondaryNavKey === "PublicSafety" && role !== 'Admin' && stateLocation === 'TX') ?
                              null
                              :
                              <ListItem button onClick={(e) => handleSecondaryNav(e, primaryNavKey, secondaryNavKey)}>
                                <ListItemIcon className="nav-icon"><FiberManualRecordIcon className={secondaryNav === secondaryNavKey ? classes.drawerDotsActive : classes.drawerDotsInactive} /></ListItemIcon>
                                <ListItemText
                                  className="nav-text"
                                  primary={dashboardData[primaryNavKey][secondaryNavKey].secondary}
                                  style={{ color: secondaryNav === secondaryNavKey ? '' : 'white', opacity: secondaryNav === secondaryNavKey ? '' : '0.5' }} />
                              </ListItem>
                            }
                          </div>

                        ))}
                      </List>
                    </SlideDown>
                  </div>
                }
              </div>
            )
            )}

            
            <ListItem button component={Link} to={`/reports`} onClick={e => handlePrimaryNav(e, "reports")}>
              <div style={{ display: 'flex' }}>
                {activePrimaryNav === 'reports' &&
                  <img src={icon} alt="active tab" className={classes.activeIcon} />
                }
                <ListItemIcon>
                  <Badge badgeContent={reports.length} color="primary" overlap="rectangular">
                    <img
                      src={activePrimaryNav === 'reports' ? Report : ReportDark}
                      alt="Lighthouse Data Initiative"
                      className={classes.drawerIcons}
                    />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={"Reports"} style={{ color: activePrimaryNav === 'reports' ? '' : 'white', opacity: activePrimaryNav === 'reports' ? '' : '0.5' }} />
              </div>
            </ListItem>
          </List>

          
          {/* Secondary Menu (bottom of drawer) */}
          <List className="primary-nav-list-item-secondary">
            <ListItem button onClick={e => handleHelpClick(e, "help")}>
              {helpOpen &&
                <img src={icon} alt="active tab" className={classes.activeIcon} />
              }
              <div style={{ display: 'flex', color: helpOpen ? '' : 'white' }}>
                <ListItemIcon style={{ color: helpOpen ? '' : 'white', opacity: helpOpen ? '' : '0.5' }}>
                  <HeadsetMicIcon />
                </ListItemIcon>
                <ListItemText primary={"Help"} style={{ color: helpOpen ? '' : 'white', opacity: helpOpen ? '' : '0.5' }} />
              </div>
            </ListItem>

            {helpOpen && open ?
              <div>
                {availableDashboards?.filter(a => a.stateLocation === stateLocation)[0]?.helpPages?.map((page, pageIndex) => (
                  <div key={pageIndex}>
                    {page === 'Submit Feedback' &&
                      <ListItem button onClick={handleSubmitFeedback}>
                        <ListItemIcon className="nav-icon"><FiberManualRecordIcon className={classes.drawerDotsInactive} /></ListItemIcon>
                        <ListItemText className="nav-text" primary={"Submit Feedback"} />
                      </ListItem>
                    }

                    {page === 'Support' &&
                      <a href="https://www.alliesagainstslavery.org/lighthouse-support/" target="_blank"
                        style={{
                          color: "rgb(255, 255, 255)",
                          textDecoration: "none"
                        }}
                        rel="noopener noreferrer">
                        <ListItem button>
                          <ListItemIcon className="nav-icon"><FiberManualRecordIcon className={classes.drawerDotsInactive} /></ListItemIcon>
                          <ListItemText className="nav-text" primary={"Support"} />
                        </ListItem>
                      </a>
                    }

                    {page === 'Data Sources' &&
                      <ListItem button onClick={handleSourceOpen}>
                        <ListItemIcon className="nav-icon"><FiberManualRecordIcon className={classes.drawerDotsInactive} /></ListItemIcon>
                        <ListItemText className="nav-text" primary={"Data Sources"} />
                      </ListItem>
                    }

                    {page === 'CSE-IT Form' &&
                      <a
                        href="https://lighthouse-pdf-images.s3-us-west-2.amazonaws.com/WCC+CSE-IT+2.0.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "rgb(255, 255, 255)",
                          textDecoration: "none"
                        }}
                      >
                        <ListItem button>
                          <ListItemIcon className="nav-icon"><FiberManualRecordIcon className={classes.drawerDotsInactive} /></ListItemIcon>
                          <ListItemText className="nav-text" primary={"CSE-IT Form"} />
                        </ListItem>
                      </a>
                    }
                  </div>
                ))}
              </div>
            : null}

            {role === "Admin" ? (
              <div>
                <ListItem button component={Link} to={`/admin`} onClick={e => handlePrimaryNav(e, "admin")}>
                  <div style={{ display: 'flex' }}>
                    {activePrimaryNav === 'admin' &&
                      <img src={icon} alt="active tab" className={classes.activeIcon} />
                    }
                    <ListItemIcon>
                      <SettingsIcon style={{ color: activePrimaryNav === 'admin' ? '' : 'white', opacity: activePrimaryNav === 'admin' ? '' : '0.5' }} />
                    </ListItemIcon>
                    <ListItemText primary={"Admin"} style={{ color: activePrimaryNav === 'admin' ? '' : 'white', opacity: activePrimaryNav === 'admin' ? '' : '0.5' }} />
                  </div>
                </ListItem>
              </div>
            )
              : null}

            {stateLocation !== 'National' &&
              <a
                href="https://lighthouse.alliesagainstslavery.org/login"
                target="_blank"
                style={{
                  color: "rgb(255, 255, 255)",
                  textDecoration: "none"
                }}
                rel="noopener noreferrer"
              >
                <ListItem button >
                  <div style={{ display: 'flex' }}>
                    <ListItemIcon>
                      <OpenInNewIcon style={{ color: 'white', opacity: '0.5' }} />
                    </ListItemIcon>
                    <ListItemText primary={"Screen with Lighthouse"} style={{ color: 'white', opacity: '0.5' }} />
                  </div>
                </ListItem>
              </a>
            }

            <ListItem button onClick={handleLogout}>
              <div style={{ display: 'flex' }}>
                <ListItemIcon>
                  <PowerSettingsNewIcon style={{ color: 'white', opacity: '0.5' }} />
                </ListItemIcon>
                <ListItemText primary={"Logout"} style={{ color: 'white', opacity: '0.5' }} />
              </div>
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        {primaryNav !== 'reports' && primaryNav !== 'admin' ? (
          <Dashboard
            primaryNav={primaryNav}
            setPrimaryNav={setPrimaryNav}
            setActivePrimaryNav={setActivePrimaryNav}
            secondaryNav={secondaryNav}
            setSecondaryNav={setSecondaryNav}
            setActiveSecondaryNav={setActiveSecondaryNav}
            addToReportPage={addToReportPage}
          //handleDrawerClose={handleDrawerClose}
          //handleDrawerOpen={handleDrawerOpen}
          />
        )
          : null}
        {primaryNav === 'reports' ? (
          <Reports
            data={reports}
            callBackReports={callBackReports}
            addToReportPage={addToReportPage}
          // handleDrawerClose={handleDrawerClose}
          // //open={open}
          // handleDrawerOpen={handleDrawerOpen}
          />
        ) : null}
        {primaryNav === 'admin' ? (
          <Admin
          // data={reports}
          //callBackReports={callBackReports}
          // handleDrawerClose={handleDrawerClose}
          // //open={open}
          // handleDrawerOpen={handleDrawerOpen}
          />
        ) : null}
      </main>
      <Dialog
        maxWidth={"sm"}
        open={feedback}
        onClose={handleCancelHubspot}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <HubspotForm
            portalId="4121080"
            formId="6cbc81ee-f582-46b6-b72e-3554067feaa8"
            onSubmit={handleCancelHubspot}
            onReady={form => console.log("Form ready!")}
            loading={<div>Loading...</div>}
            inlineMessage="Thank you for submitting your feedback! We will be in touch soon."
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSource}
        onClose={handleSourceClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Data Sources
        </DialogTitle>
        <DialogContent>
          {stateLocation === 'TX' ? (
            <div>
              <p>Data in Lighthouse is obtained from multiple sources to provide a comprehensive view of human trafficking in Texas. Data is de-identified and aggregated to show trends and insights, while protecting the identity of individuals or organizations. Select the information icon in each Map view to see the corresponding data sources for that section.</p>
              <p>Census data in Lighthouse, such as total population and youth population, is sourced from the <a href="https://www.census.gov/programs-surveys/acs" target="_blank" rel="noopener noreferrer">American Community Survey (ACS)</a> for 2019. It includes Total Population by County and Breakdown of Age by Gender Category for all 254 Texas counties.</p>
              <p>Certain data sets can be viewed by Region. Regions are defined by the <a href="https://gov.texas.gov/organization/cjd/childsextrafficking" target="_blank" rel="noopener noreferrer">Office of the Texas Governor Child Sex Trafficking Team</a> as follows:</p>
              <ul>
                <li>Central Texas</li>
                <ul>
                  <li>Gregg County</li>
                  <li>Atascosa County</li>
                  <li>Bandera County</li>
                  <li>Bastrop County</li>
                  <li>Bell County</li>
                  <li>Bexar County</li>
                  <li>Blanco County</li>
                  <li>Bosque County</li>
                  <li>Brown County</li>
                  <li>Burnet County</li>
                  <li>Caldwell County</li>
                  <li>Comal County</li>
                  <li>Comanche County</li>
                  <li>Coryell County</li>
                  <li>Falls County</li>
                  <li>Fayette County</li>
                  <li>Freestone County</li>
                  <li>Frio County</li>
                  <li>Gillespie County</li>
                  <li>Gonzales County</li>
                  <li>Guadalupe County</li>
                  <li>Hamilton County</li>
                  <li>Hays County</li>
                  <li>Hill County</li>
                  <li>Karnes County</li>
                  <li>Kendall County</li>
                  <li>Kerr County</li>
                  <li>Kimble County</li>
                  <li>La Salle County</li>
                  <li>Lampasas County</li>
                  <li>Lee County</li>
                  <li>Limestone County</li>
                  <li>Llano County</li>
                  <li>Mason County</li>
                  <li>McCulloch County</li>
                  <li>McLennan County</li>
                  <li>Menard County</li>
                  <li>Milam County</li>
                  <li>Mills County</li>
                  <li>San Saba County</li>
                  <li>Travis County</li>
                  <li>Williamson County</li>
                  <li>Wilson County</li>
                </ul>
                <li>East Texas</li>
                <ul>
                  <li>Anderson County</li>
                  <li>Angelina County</li>
                  <li>Austin County</li>
                  <li>Brazoria County</li>
                  <li>Brazos County</li>
                  <li>Burleson County</li>
                  <li>Chambers County</li>
                  <li>Cherokee County</li>
                  <li>Colorado County</li>
                  <li>Fort Bend County</li>
                  <li>Galveston County</li>
                  <li>Grimes County</li>
                  <li>Hardin County</li>
                  <li>Harris County</li>
                  <li>Houston County</li>
                  <li>Jasper County</li>
                  <li>Jefferson County</li>
                  <li>Leon County</li>
                  <li>Liberty County</li>
                  <li>Madison County</li>
                  <li>Matagorda County</li>
                  <li>Montgomery County</li>
                  <li>Nacogdoches County</li>
                  <li>Newton County</li>
                  <li>Orange County</li>
                  <li>Panola County</li>
                  <li>Polk County</li>
                  <li>Robertson County</li>
                  <li>Rusk County</li>
                  <li>Sabine County</li>
                  <li>San Augustine County</li>
                  <li>San Jacinto County</li>
                  <li>Shelby County</li>
                  <li>Trinity County</li>
                  <li>Tyler County</li>
                  <li>Walker County</li>
                  <li>Waller County</li>
                  <li>Washington County</li>
                  <li>Wharton County</li>
                </ul>
                <li>North Texas</li>
                <ul>
                  <li>Archer County</li>
                  <li>Baylor County</li>
                  <li>Bowie County</li>
                  <li>Camp County</li>
                  <li>Cass County</li>
                  <li>Clay County</li>
                  <li>Collin County</li>
                  <li>Cooke County</li>
                  <li>Dallas County</li>
                  <li>Delta County</li>
                  <li>Denton County</li>
                  <li>Eastland County</li>
                  <li>Ellis County</li>
                  <li>Erath County</li>
                  <li>Fannin County</li>
                  <li>Franklin County</li>
                  <li>Grayson County</li>
                  <li>Gregg County</li>
                  <li>Harrison County</li>
                  <li>Henderson County</li>
                  <li>Hood County</li>
                  <li>Hopkins County</li>
                  <li>Hunt County</li>
                  <li>Jack County</li>
                  <li>Johnson County</li>
                  <li>Kaufman County</li>
                  <li>Lamar County</li>
                  <li>Marion County</li>
                  <li>Montague County</li>
                  <li>Morris County</li>
                  <li>Navarro County</li>
                  <li>Palo Pinto County</li>
                  <li>Parker County</li>
                  <li>Rains County</li>
                  <li>Red River County</li>
                  <li>Rockwall County</li>
                  <li>Smith County</li>
                  <li>Somervell County</li>
                  <li>Stephens County</li>
                  <li>Tarrant County</li>
                  <li>Titus County</li>
                  <li>Upshur County</li>
                  <li>Van Zandt County</li>
                  <li>Wichita County</li>
                  <li>Wilbarger County</li>
                  <li>Wise County</li>
                  <li>Wood County</li>
                  <li>Young County</li>
                </ul>
                <li>South Texas</li>
                <ul>
                  <li>Aransas County</li>
                  <li>Bee County</li>
                  <li>Brooks County</li>
                  <li>Calhoun County</li>
                  <li>Cameron County</li>
                  <li>DeWitt County</li>
                  <li>Dimmit County</li>
                  <li>Duval County</li>
                  <li>Goliad County</li>
                  <li>Hidalgo County</li>
                  <li>Jackson County</li>
                  <li>Jim Hogg County</li>
                  <li>Jim Wells County</li>
                  <li>Kenedy County</li>
                  <li>Kinney County</li>
                  <li>Kleberg County</li>
                  <li>Lavaca County</li>
                  <li>Live Oak County</li>
                  <li>Maverick County</li>
                  <li>McMullen County</li>
                  <li>Medina County</li>
                  <li>Nueces County</li>
                  <li>Real County</li>
                  <li>Refugio County</li>
                  <li>San Patricio County</li>
                  <li>Starr County</li>
                  <li>Uvalde County</li>
                  <li>Val Verde County</li>
                  <li>Victoria County</li>
                  <li>Webb County</li>
                  <li>Willacy County</li>
                  <li>Zapata County</li>
                  <li>Zavala County</li>
                </ul>
                <li>West Texas</li>
                <ul>
                  <li>Andrews County</li>
                  <li>Armstrong County</li>
                  <li>Bailey County</li>
                  <li>Borden County</li>
                  <li>Brewster County</li>
                  <li>Briscoe County</li>
                  <li>Callahan County</li>
                  <li>Carson County</li>
                  <li>Castro County</li>
                  <li>Childress County</li>
                  <li>Cochran County</li>
                  <li>Coke County</li>
                  <li>Coleman County</li>
                  <li>Collingsworth County</li>
                  <li>Concho County</li>
                  <li>Cottle County</li>
                  <li>Crane County</li>
                  <li>Crockett County</li>
                  <li>Crosby County</li>
                  <li>Culberson County</li>
                  <li>Dallam County</li>
                  <li>Dawson County</li>
                  <li>Deaf Smith County</li>
                  <li>Dickens County</li>
                  <li>Donley County</li>
                  <li>Ector County</li>
                  <li>Edwards County</li>
                  <li>El Paso County</li>
                  <li>Fisher County</li>
                  <li>Floyd County</li>
                  <li>Foard County</li>
                  <li>Gaines County</li>
                  <li>Garza County</li>
                  <li>Glasscock County</li>
                  <li>Gray County</li>
                  <li>Hale County</li>
                  <li>Hall County</li>
                  <li>Hansford County</li>
                  <li>Hardeman County</li>
                  <li>Hartley County</li>
                  <li>Haskell County</li>
                  <li>Hemphill County</li>
                  <li>Hockley County</li>
                  <li>Howard County</li>
                  <li>Hudspeth County</li>
                  <li>Hutchinson County</li>
                  <li>Irion County</li>
                  <li>Jeff Davis County</li>
                  <li>Jones County</li>
                  <li>Kent County</li>
                  <li>King County</li>
                  <li>Knox County</li>
                  <li>Lamb County</li>
                  <li>Lipscomb County</li>
                  <li>Loving County</li>
                  <li>Lubbock County</li>
                  <li>Lynn County</li>
                  <li>Martin County</li>
                  <li>Midland County</li>
                  <li>Mitchell County</li>
                  <li>Moore County</li>
                  <li>Motley County</li>
                  <li>Nolan County</li>
                  <li>Ochiltree County</li>
                  <li>Oldham County</li>
                  <li>Parmer County</li>
                  <li>Pecos County</li>
                  <li>Potter County</li>
                  <li>Presidio County</li>
                  <li>Randall County</li>
                  <li>Reagan County</li>
                  <li>Reeves County</li>
                  <li>Roberts County</li>
                  <li>Runnels County</li>
                  <li>Schleicher County</li>
                  <li>Scurry County</li>
                  <li>Shackelford County</li>
                  <li>Sherman County</li>
                  <li>Sterling County</li>
                  <li>Stonewall County</li>
                  <li>Sutton County</li>
                  <li>Swisher County</li>
                  <li>Taylor County</li>
                  <li>Terrell County</li>
                  <li>Terry County</li>
                  <li>Throckmorton County</li>
                  <li>Tom Green County</li>
                  <li>Upton County</li>
                  <li>Ward County</li>
                  <li>Wheeler County</li>
                  <li>Winkler County</li>
                  <li>Yoakum County</li>
                </ul>
              </ul>
            </div>) : (
              <div>
                <p>Data in Lighthouse is obtained from multiple sources to provide a comprehensive view of human trafficking. Data is de-identified and aggregated to show trends and insights, while protecting the identity of individuals or organizations. Select the information icon in each Map view to see the corresponding data sources for that section.</p>
                <p>Census data in Lighthouse, such as total population and youth population, is sourced from the <a href="https://www.census.gov/programs-surveys/acs" target="_blank" rel="noreferrer">American Community Survey (ACS)</a> for 2019.</p>
                <p>Certain data sets can be viewed by Region. Regions are defined by the  <a href="https://www.lacacs.org/lcytc" target="_blank" rel="noreferrer">Louisiana Child and Youth Trafficking Collaborative (LCYTC)</a> as follows, with the corresponding parishes:</p>
                <ol>
                  <li>Orleans Region - Orleans, Jefferson, St. Bernard, Plaquemines</li>
                  <li>Covington Region I 21st Judicial District - Livingston, St. Helena, Tangipahoa, East Feliciana, West Feliciana, Ascension</li>
                  <li>Covington Region II 22nd Judicial District - St. Tammany, Washington</li>
                  <li>Thibodaux Region - Terrebonne, Lafourche, St. Charles, St. John the Baptist, St. James, Assumption</li>
                  <li>Lafayette Region - Lafayette, St. Landry, Evangeline, Acadia, St. Martin, St. Mary, Iberia, Vermillion</li>
                  <li>Baton Rouge Region - West Baton Rouge, East Baton Rouge, Iberville, Pointe Coupee</li>
                  <li>Shreveport Region - Natchitoches, Sabine, Caddo, Red River, De Soto, Bossier, Webster, Bienville, Claiborne, Jackson</li>
                  <li>Alexandria Region - Vernon, Rapides, Grant, Winn, La Salle, Catahoula, Concordia, Avoyelles</li>
                  <li>Lake Charles Region - Beauregard, Allen, Calcasieu, Jefferson Davis, Cameron</li>
                  <li>Monroe Region - Lincoln, Union, Morehouse, West Carroll, East Carroll, Ouachita, Richland, Madison, Caldwell, Franklin, Tensas</li>
                </ol>
              </div>
            )}
          <p>The data and visualizations in Lighthouse are provided as-is with no warranties. The information is intended to inform Lighthouse users about human trafficking; it is not intended to predict human trafficking cases, demonstrate the efficacy of solutions, or identify individual victims or survivors. By using this information, you consent to the permitted use terms of your Lighthouse agreement. Allies Against Slavery is not responsible for your reproduction or representation of the data in Lighthouse.
          </p>
          <p><em>© 2024 ALLIES AGAINST SLAVERY. ALL RIGHTS RESERVED.</em></p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSourceClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
