import React from "react";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function PublicSafetyTable(props) {
  const classes = useStyles();
  //const [customData, setCustomData] = React.useState([]);
  //const [total, setTotal] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [displayValue, setDisplayValue] = React.useState("Total");

  let data = props.data;
  let total = 0;

  if (props.single) {
      data = props.keys.map((item) => {
          let total = 0;
          for (let i = 0; i < props.data.length; i++) {
              total = d3.sum(
                  // eslint-disable-next-line array-callback-return
                  props.data.map((d) => {
                      if (item.variable === d.source) {
                          return d.value;
                      }
                  })
              );
          }
          item.total = total;
          return item;
      })

      total = d3.sum(
          data.map((d) => {
              return d.total;
          })
      );
  } else {
         data = props.keys.map((item) => {
              let total = 0;
              for (let i = 0; i < props.data.length; i++) {
                  total = d3.sum(
                      props.data.map((d) => {
                          return d[item.variable];
                      })
                  );
              }
              item.total = total;
              return item;
          })
          if (props.ordered) {
              data.sort((a, b) => { return b.total - a.total });
          }

          if (props.showTopTenOnly) {
              data = data.slice(0, 10)
          } 

          total = d3.sum(
              data.map((d) => {
                  return d.total;
              })
          );
      }

  return (
    <TableContainer component={Paper} id={`${props.id}`}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="table-header">
                {props.name ?? props.id}  
            </TableCell>
            <TableCell align="right" className="table-header">
                Value  
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {data.map((item, key) => {
             return (
                <TableRow key={key}>
                    <TableCell>
                          {item.name}  
                    </TableCell>
                    <TableCell align="right">{item.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                </TableRow>
             )
         })}
          <TableRow >
            <TableCell className="table-header">
                  Total  
            </TableCell >
            <TableCell align="right" className="table-header">{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
