import { countyData } from "../../../Data/countyJson";
import { parishData } from "../../../Data/parishJson";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

export function PublicSafetyLayer(map, location, data, stateLocation) {
    var publicData = stateLocation === "TX" ? JSON.parse(JSON.stringify(countyData))
    : JSON.parse(JSON.stringify(parishData));

    if (data.counties) {
        var locationSelection = 'agency_oria_county';
        // for (let i = 0; i < location.length; i++){
        //     if (location[i].name === 'Agency Type' && location[i].filter === 'Law Enforcement'){
        //         if (location[i].value[0] === 'Arresting Agency (ORIA)') {
        //             locationSelection = "agency_oria_county";
        //         } else if (location[i].value[0] === 'Prosecuting Agency (ORIP)') {
        //             locationSelection = "agency_orip_county";
        //         } else if (location[i].value[0] === 'Court (ORIC)') {
        //             locationSelection = "agency_oric_county";
        //         }
        //     }
        // }
        // console.log(locationSelection, data)

        // if (Object.keys(data.counties[0])[0] === locationSelection){
            for (let i = 0; i < publicData.features.length; i++) {
                var counter = 0;
                for (let j = 0; j < data.counties.length; j++) {
                    if (
                        publicData.features[i].properties?.COUNTY?.toLowerCase() ===
                    (data.counties[j][locationSelection]?.toLowerCase())
                    ) {
                        publicData.features[i].properties.SCREENINGS = Number(
                        data.counties[j].total
                    );
                    counter += 1;
                    // countyData.features[i].properties.CLEARCONCERN = Number(
                    //     data.counties[j].clearconcern
                    // );
                    // countyData.features[i].properties.POSSIBLECONCERN = Number(
                    //     data.counties[j].possibleconcern
                    // );
                    // countyData.features[i].properties.NOCONCERN = Number(
                    //     data.counties[j].noconcern
                    // );
                    }
                }
                if (counter === 0){
                    publicData.features[i].properties.SCREENINGS = 0;
                }
            }
            let calculatedData = publicData;
            map.on("load", function () {
                map.addSource("county", {
                type: "geojson",
                data: calculatedData,
                });
                map.addLayer({
                id: "county",
                type: "line",
                source: "county",
                layout: {
                    "line-join": "round",
                    "line-cap": "round",
                },
                paint: {
                    "line-color": "#28ADE3",
                    "line-width": 1,
                },
                });
    
                map.addLayer({
                id: "county-fills",
                type: "fill",
                source: "county",
                layout: {
                    visibility: "visible",
                },
                paint: {
                    "fill-color": {
                    property: "SCREENINGS",
                    stops: [
                        [1, "#e9f6fc"],
                        [100, "#93d6f1"],
                        [500, "#28ade3"],
                        [1000, "#186788"],
                        [2000, "#0c3344"],
                        [3000, "#000000"],
                    ],
                    },
                    "fill-outline-color": "#28ADE3",
                    "fill-opacity": 0.9,
                },
                });
            });
    
            let hoveredStateId = null;
            let popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                });
    
                map.on("mousemove", "county-fills", function (e) {
                if (hoveredStateId !== e.features[0].id) {
                    if (e.features.length > 0) {
                    if (hoveredStateId) {
                        map.setFeatureState(
                        { source: "county", id: hoveredStateId },
                        { hover: false }
                        );
                    }
                    hoveredStateId = e.features[0].id;
                    map.setFeatureState(
                        { source: "county", id: hoveredStateId },
                        { hover: true }
                    );
                    }
    
                    // let coordinates;
                    // if (e.features[0].geometry.coordinates.length > 1) {
                    // coordinates = e.features[0].geometry.coordinates[0][0][0];
                    // } else {
                    // coordinates = e.features[0].geometry.coordinates[0][0];
                    // }
    
                    // if (coordinates.length > 2) {
                    // for (let i = 2; i < coordinates.length; i--) {
                    //     coordinates.pop();
                    // }
                    // }
                    let description = `
                    <h3><strong>${e.features[0].properties.COUNTY}</strong></h3> 
                    <p>Cases: <span style="font-weight: 300 !important">${
                       e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                    <p>Percent: <span style="font-weight: 300 !important">${
                        (((e.features[0].properties.SCREENINGS / data.countiesTotal)*100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</span></p>`
                
                    popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
                }
                });
    
                map.on("mouseleave", "county-fills", function () {
                if (hoveredStateId) {
                    map.setFeatureState(
                    { source: "county", id: hoveredStateId },
                    { hover: false }
                    );
                }
                hoveredStateId = null;
                popup.remove();
                });
        //     } else {
        //         return;
        //     }
        }
}