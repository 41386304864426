import React from "react";

import Layout from "./Layout";

export default function Partnerships() {
//contentful id: partnerships
  return (
    <Layout>
      <div>
      </div>
    </Layout>
  );
}
