export var offenses = [
    { 
        name: "20A Trafficking of Persons",
        values: [
            "Trafficking Child - Forcing Engagement in Sexual Conduct",
            "Trafficking Child - Receiving Benefit from Enagagment in Sexual Conduct",
            "Trafficking a Child with Intent Forced Labor Benefit",
            "Trafficking Child with Intent Forced Labor",
            "Trafficking of Person",
            "Trafficking of Persons Causing Death",
            "Trafficking of Persons: Continuous",
            "Trafficking of Persons Under 18",
            "Trafficking Person Forcing Prostitution",
            "Trafficking Person Forcing Prostitution - Receiving Benefit"
        ]
    }, {
        name: "21 Sexual Offenses",
        values: [
            "Continuous Sex Abuse of a Child",
            "Indecency with a Child - Sexual Contact/Exposure of Genitals with Intent to Gratify",
            "Sexual Coercion",
        ]
    },{
        name: "22 Assaultive Offenses",
        values: [
            "Sexual Assault",
            "Sexual Assault of a Child",
            "Sexual Assault of Spouse - Age/Prohibited",
            "Bigamy / Sexual Conduct with Family Member",
            "Aggravated Sexual Assault",
            "Aggravated Sexual Assault Child",
            "Aggravated Sexual Assault Elderly/Disabled Person"
        ]
    },{
        name: "25 Offenses Against the Family",
        values: [
            "Harboring Runaway Child",
        ]
    },{
        name: "33 Computer Crimes",
        values: [
            "Online Solicit of a Minor",
            "Online Solicit of a Minor Sexual Conduct",
            "Online Solicitation of a Minor Under 14"
        ]
    },{
        name: "43 Public Indecency",
        values: [
            "Prostitution (before 2015)",
            "Prostitution 1+ Convictions (before 2015)",
            "Prostitution - Seller",
            "Prostitution 1+ Convictions - Seller",
            "Prostitution - Solicit Person Under 18 - Buyer",
            "Prostitution - Buyer",
            "Prostitution 1+ Convictions - Buyer",
            "Promotion of Prostitution",
            "Promote Prostitution w/ Prev Conv",
            "Promote Prostitution of Person <18",
            "Online Promotion Prostitution",
            "Online Promotion Prostitution Person <18 YOA",
            "Agg Promotion of Prostitution",
            "Agg Promotion of Prostitution Persons <18 YOA",
            "Agg Online Promotion Prostitution",
            "Compelling Prostitution: Force/Threat/Fraud",
            "Compelling Prostitution: Under Age 18",
            "Sale of Harmful Materials to Minors",
            "Sexual Performance by a Child - Induce/Authorize",
            "Sexual Performance by a Child - Under 14",
            "Sexual Performance by a Child - Direct/Promote",
            "Sexual Performance by a Child Under 14 - Direct/Promote",
            "Employment Harmful to Minors",
            "Employment Harmful to Children",
            "Poss/Promotion of Child Pornography",
            "Possession of Child Pornography with 1+ Previous Conviction",
            "Poss w/Int to Promote Child Pornography",
            "Poss w/Int to Promote Child Pornography w/ Previous Conviction",
        ]
    }, {
        name: " Other Chapters and/or Offenses",
        values: [
            "Other"
        ]
    }
];