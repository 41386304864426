import React from "react";

import "./CTABlock.scss";

export default function CTABlock(props) {
    return (
        <div className={`cta-block ${props.className}`}>
            <div className="cta-block-wrapper">
                <div className="cta-group cta-group-description">
                    {props.description}
                </div>
                <div className="cta-group cta-group-content">
                    {props.content}
                </div>
            </div>
        </div>
    );
};