export const nationalCountyList = [ 
    {stateLocation: 'All counties', county: 'All counties'},
    {stateLocation: "AL", county: "Autauga"},
    {stateLocation: "AL", county: "Baldwin"},
    {stateLocation: "AL", county: "Barbour"},
    {stateLocation: "AL", county: "Bibb"},
    {stateLocation: "AL", county: "Blount"},
    {stateLocation: "AL", county: "Bullock"},
    {stateLocation: "AL", county: "Butler"},
    {stateLocation: "AL", county: "Calhoun"},
    {stateLocation: "AL", county: "Chambers"},
    {stateLocation: "AL", county: "Cherokee"},
    {stateLocation: "AL", county: "Chilton"},
    {stateLocation: "AL", county: "Choctaw"},
    {stateLocation: "AL", county: "Clarke"},
    {stateLocation: "AL", county: "Clay"},
    {stateLocation: "AL", county: "Cleburne"},
    {stateLocation: "AL", county: "Coffee"},
    {stateLocation: "AL", county: "Colbert"},
    {stateLocation: "AL", county: "Conecuh"},
    {stateLocation: "AL", county: "Coosa"},
    {stateLocation: "AL", county: "Covington"},
    {stateLocation: "AL", county: "Crenshaw"},
    {stateLocation: "AL", county: "Cullman"},
    {stateLocation: "AL", county: "Dale"},
    {stateLocation: "AL", county: "Dallas"},
    {stateLocation: "AL", county: "DeKalb"},
    {stateLocation: "AL", county: "Elmore"},
    {stateLocation: "AL", county: "Escambia"},
    {stateLocation: "AL", county: "Etowah"},
    {stateLocation: "AL", county: "Fayette"},
    {stateLocation: "AL", county: "Franklin"},
    {stateLocation: "AL", county: "Geneva"},
    {stateLocation: "AL", county: "Greene"},
    {stateLocation: "AL", county: "Hale"},
    {stateLocation: "AL", county: "Henry"},
    {stateLocation: "AL", county: "Houston"},
    {stateLocation: "AL", county: "Jackson"},
    {stateLocation: "AL", county: "Jefferson"},
    {stateLocation: "AL", county: "Lamar"},
    {stateLocation: "AL", county: "Lauderdale"},
    {stateLocation: "AL", county: "Lawrence"},
    {stateLocation: "AL", county: "Lee"},
    {stateLocation: "AL", county: "Limestone"},
    {stateLocation: "AL", county: "Lowndes"},
    {stateLocation: "AL", county: "Macon"},
    {stateLocation: "AL", county: "Madison"},
    {stateLocation: "AL", county: "Marengo"},
    {stateLocation: "AL", county: "Marion"},
    {stateLocation: "AL", county: "Marshall"},
    {stateLocation: "AL", county: "Mobile"},
    {stateLocation: "AL", county: "Monroe"},
    {stateLocation: "AL", county: "Montgomery"},
    {stateLocation: "AL", county: "Morgan"},
    {stateLocation: "AL", county: "Perry"},
    {stateLocation: "AL", county: "Pickens"},
    {stateLocation: "AL", county: "Pike"},
    {stateLocation: "AL", county: "Randolph"},
    {stateLocation: "AL", county: "Russell"},
    {stateLocation: "AL", county: "St. Clair"},
    {stateLocation: "AL", county: "Shelby"},
    {stateLocation: "AL", county: "Sumter"},
    {stateLocation: "AL", county: "Talladega"},
    {stateLocation: "AL", county: "Tallapoosa"},
    {stateLocation: "AL", county: "Tuscaloosa"},
    {stateLocation: "AL", county: "Walker"},
    {stateLocation: "AL", county: "Washington"},
    {stateLocation: "AL", county: "Wilcox"},
    {stateLocation: "AL", county: "Winston"},
    {stateLocation: "AK", county: "Aleutians East Borough"},
    {stateLocation: "AK", county: "Aleutians West Census Area"},
    {stateLocation: "AK", county: "Anchorage Municipality"},
    {stateLocation: "AK", county: "Bethel Census Area"},
    {stateLocation: "AK", county: "Bristol Bay Borough"},
    {stateLocation: "AK", county: "Denali Borough"},
    {stateLocation: "AK", county: "Dillingham Census Area"},
    {stateLocation: "AK", county: "Fairbanks North Star Borough"},
    {stateLocation: "AK", county: "Haines Borough"},
    {stateLocation: "AK", county: "Hoonah-Angoon Census Area"},
    {stateLocation: "AK", county: "Juneau City and Borough"},
    {stateLocation: "AK", county: "Kenai Peninsula Borough"},
    {stateLocation: "AK", county: "Ketchikan Gateway Borough"},
    {stateLocation: "AK", county: "Kodiak Island Borough"},
    {stateLocation: "AK", county: "Lake and Peninsula Borough"},
    {stateLocation: "AK", county: "Matanuska-Susitna Borough"},
    {stateLocation: "AK", county: "Nome Census Area"},
    {stateLocation: "AK", county: "North Slope Borough"},
    {stateLocation: "AK", county: "Northwest Arctic Borough"},
    {stateLocation: "AK", county: "Petersburg Census Area"},
    {stateLocation: "AK", county: "Prince of Wales-Hyder Census Area"},
    {stateLocation: "AK", county: "Sitka City and Borough"},
    {stateLocation: "AK", county: "Skagway Municipality"},
    {stateLocation: "AK", county: "Southeast Fairbanks Census Area"},
    {stateLocation: "AK", county: "Valdez-Cordova Census Area"},
    {stateLocation: "AK", county: "Wade Hampton Census Area"},
    {stateLocation: "AK", county: "Wrangell City and Borough"},
    {stateLocation: "AK", county: "Yakutat City and Borough"},
    {stateLocation: "AK", county: "Yukon-Koyukuk Census Area"},
    {stateLocation: "AZ", county: "Apache"},
    {stateLocation: "AZ", county: "Cochise"},
    {stateLocation: "AZ", county: "Coconino"},
    {stateLocation: "AZ", county: "Gila"},
    {stateLocation: "AZ", county: "Graham"},
    {stateLocation: "AZ", county: "Greenlee"},
    {stateLocation: "AZ", county: "La Paz"},
    {stateLocation: "AZ", county: "Maricopa"},
    {stateLocation: "AZ", county: "Mohave"},
    {stateLocation: "AZ", county: "Navajo"},
    {stateLocation: "AZ", county: "Pima"},
    {stateLocation: "AZ", county: "Pinal"},
    {stateLocation: "AZ", county: "Santa Cruz"},
    {stateLocation: "AZ", county: "Yavapai"},
    {stateLocation: "AZ", county: "Yuma"},
    {stateLocation: "AR", county: "Arkansas"},
    {stateLocation: "AR", county: "Ashley"},
    {stateLocation: "AR", county: "Baxter"},
    {stateLocation: "AR", county: "Benton"},
    {stateLocation: "AR", county: "Boone"},
    {stateLocation: "AR", county: "Bradley"},
    {stateLocation: "AR", county: "Calhoun"},
    {stateLocation: "AR", county: "Carroll"},
    {stateLocation: "AR", county: "Chicot"},
    {stateLocation: "AR", county: "Clark"},
    {stateLocation: "AR", county: "Clay"},
    {stateLocation: "AR", county: "Cleburne"},
    {stateLocation: "AR", county: "Cleveland"},
    {stateLocation: "AR", county: "Columbia"},
    {stateLocation: "AR", county: "Conway"},
    {stateLocation: "AR", county: "Craighead"},
    {stateLocation: "AR", county: "Crawford"},
    {stateLocation: "AR", county: "Crittenden"},
    {stateLocation: "AR", county: "Cross"},
    {stateLocation: "AR", county: "Dallas"},
    {stateLocation: "AR", county: "Desha"},
    {stateLocation: "AR", county: "Drew"},
    {stateLocation: "AR", county: "Faulkner"},
    {stateLocation: "AR", county: "Franklin"},
    {stateLocation: "AR", county: "Fulton"},
    {stateLocation: "AR", county: "Garland"},
    {stateLocation: "AR", county: "Grant"},
    {stateLocation: "AR", county: "Greene"},
    {stateLocation: "AR", county: "Hempstead"},
    {stateLocation: "AR", county: "Hot Spring"},
    {stateLocation: "AR", county: "Howard"},
    {stateLocation: "AR", county: "Independence"},
    {stateLocation: "AR", county: "Izard"},
    {stateLocation: "AR", county: "Jackson"},
    {stateLocation: "AR", county: "Jefferson"},
    {stateLocation: "AR", county: "Johnson"},
    {stateLocation: "AR", county: "Lafayette"},
    {stateLocation: "AR", county: "Lawrence"},
    {stateLocation: "AR", county: "Lee"},
    {stateLocation: "AR", county: "Lincoln"},
    {stateLocation: "AR", county: "Little River"},
    {stateLocation: "AR", county: "Logan"},
    {stateLocation: "AR", county: "Lonoke"},
    {stateLocation: "AR", county: "Madison"},
    {stateLocation: "AR", county: "Marion"},
    {stateLocation: "AR", county: "Miller"},
    {stateLocation: "AR", county: "Mississippi"},
    {stateLocation: "AR", county: "Monroe"},
    {stateLocation: "AR", county: "Montgomery"},
    {stateLocation: "AR", county: "Nevada"},
    {stateLocation: "AR", county: "Newton"},
    {stateLocation: "AR", county: "Ouachita"},
    {stateLocation: "AR", county: "Perry"},
    {stateLocation: "AR", county: "Phillips"},
    {stateLocation: "AR", county: "Pike"},
    {stateLocation: "AR", county: "Poinsett"},
    {stateLocation: "AR", county: "Polk"},
    {stateLocation: "AR", county: "Pope"},
    {stateLocation: "AR", county: "Prairie"},
    {stateLocation: "AR", county: "Pulaski"},
    {stateLocation: "AR", county: "Randolph"},
    {stateLocation: "AR", county: "St. Francis"},
    {stateLocation: "AR", county: "Saline"},
    {stateLocation: "AR", county: "Scott"},
    {stateLocation: "AR", county: "Searcy"},
    {stateLocation: "AR", county: "Sebastian"},
    {stateLocation: "AR", county: "Sevier"},
    {stateLocation: "AR", county: "Sharp"},
    {stateLocation: "AR", county: "Stone"},
    {stateLocation: "AR", county: "Union"},
    {stateLocation: "AR", county: "Van Buren"},
    {stateLocation: "AR", county: "Washington"},
    {stateLocation: "AR", county: "White"},
    {stateLocation: "AR", county: "Woodruff"},
    {stateLocation: "AR", county: "Yell"},
    {stateLocation: "CA", county: "Alameda"},
    {stateLocation: "CA", county: "Alpine"},
    {stateLocation: "CA", county: "Amador"},
    {stateLocation: "CA", county: "Butte"},
    {stateLocation: "CA", county: "Calaveras"},
    {stateLocation: "CA", county: "Colusa"},
    {stateLocation: "CA", county: "Contra Costa"},
    {stateLocation: "CA", county: "Del Norte"},
    {stateLocation: "CA", county: "El Dorado"},
    {stateLocation: "CA", county: "Fresno"},
    {stateLocation: "CA", county: "Glenn"},
    {stateLocation: "CA", county: "Humboldt"},
    {stateLocation: "CA", county: "Imperial"},
    {stateLocation: "CA", county: "Inyo"},
    {stateLocation: "CA", county: "Kern"},
    {stateLocation: "CA", county: "Kings"},
    {stateLocation: "CA", county: "Lake"},
    {stateLocation: "CA", county: "Lassen"},
    {stateLocation: "CA", county: "Los Angeles"},
    {stateLocation: "CA", county: "Madera"},
    {stateLocation: "CA", county: "Marin"},
    {stateLocation: "CA", county: "Mariposa"},
    {stateLocation: "CA", county: "Mendocino"},
    {stateLocation: "CA", county: "Merced"},
    {stateLocation: "CA", county: "Modoc"},
    {stateLocation: "CA", county: "Mono"},
    {stateLocation: "CA", county: "Monterey"},
    {stateLocation: "CA", county: "Napa"},
    {stateLocation: "CA", county: "Nevada"},
    {stateLocation: "CA", county: "Orange"},
    {stateLocation: "CA", county: "Placer"},
    {stateLocation: "CA", county: "Plumas"},
    {stateLocation: "CA", county: "Riverside"},
    {stateLocation: "CA", county: "Sacramento"},
    {stateLocation: "CA", county: "San Benito"},
    {stateLocation: "CA", county: "San Bernardino"},
    {stateLocation: "CA", county: "San Diego"},
    {stateLocation: "CA", county: "San Francisco"},
    {stateLocation: "CA", county: "San Joaquin"},
    {stateLocation: "CA", county: "San Luis Obispo"},
    {stateLocation: "CA", county: "San Mateo"},
    {stateLocation: "CA", county: "Santa Barbara"},
    {stateLocation: "CA", county: "Santa Clara"},
    {stateLocation: "CA", county: "Santa Cruz"},
    {stateLocation: "CA", county: "Shasta"},
    {stateLocation: "CA", county: "Sierra"},
    {stateLocation: "CA", county: "Siskiyou"},
    {stateLocation: "CA", county: "Solano"},
    {stateLocation: "CA", county: "Sonoma"},
    {stateLocation: "CA", county: "Stanislaus"},
    {stateLocation: "CA", county: "Sutter"},
    {stateLocation: "CA", county: "Tehama"},
    {stateLocation: "CA", county: "Trinity"},
    {stateLocation: "CA", county: "Tulare"},
    {stateLocation: "CA", county: "Tuolumne"},
    {stateLocation: "CA", county: "Ventura"},
    {stateLocation: "CA", county: "Yolo"},
    {stateLocation: "CA", county: "Yuba"},
    {stateLocation: "CO", county: "Adams"},
    {stateLocation: "CO", county: "Alamosa"},
    {stateLocation: "CO", county: "Arapahoe"},
    {stateLocation: "CO", county: "Archuleta"},
    {stateLocation: "CO", county: "Baca"},
    {stateLocation: "CO", county: "Bent"},
    {stateLocation: "CO", county: "Boulder"},
    {stateLocation: "CO", county: "Broomfield"},
    {stateLocation: "CO", county: "Chaffee"},
    {stateLocation: "CO", county: "Cheyenne"},
    {stateLocation: "CO", county: "Clear Creek"},
    {stateLocation: "CO", county: "Conejos"},
    {stateLocation: "CO", county: "Costilla"},
    {stateLocation: "CO", county: "Crowley"},
    {stateLocation: "CO", county: "Custer"},
    {stateLocation: "CO", county: "Delta"},
    {stateLocation: "CO", county: "Denver"},
    {stateLocation: "CO", county: "Dolores"},
    {stateLocation: "CO", county: "Douglas"},
    {stateLocation: "CO", county: "Eagle"},
    {stateLocation: "CO", county: "Elbert"},
    {stateLocation: "CO", county: "El Paso"},
    {stateLocation: "CO", county: "Fremont"},
    {stateLocation: "CO", county: "Garfield"},
    {stateLocation: "CO", county: "Gilpin"},
    {stateLocation: "CO", county: "Grand"},
    {stateLocation: "CO", county: "Gunnison"},
    {stateLocation: "CO", county: "Hinsdale"},
    {stateLocation: "CO", county: "Huerfano"},
    {stateLocation: "CO", county: "Jackson"},
    {stateLocation: "CO", county: "Jefferson"},
    {stateLocation: "CO", county: "Kiowa"},
    {stateLocation: "CO", county: "Kit Carson"},
    {stateLocation: "CO", county: "Lake"},
    {stateLocation: "CO", county: "La Plata"},
    {stateLocation: "CO", county: "Larimer"},
    {stateLocation: "CO", county: "Las Animas"},
    {stateLocation: "CO", county: "Lincoln"},
    {stateLocation: "CO", county: "Logan"},
    {stateLocation: "CO", county: "Mesa"},
    {stateLocation: "CO", county: "Mineral"},
    {stateLocation: "CO", county: "Moffat"},
    {stateLocation: "CO", county: "Montezuma"},
    {stateLocation: "CO", county: "Montrose"},
    {stateLocation: "CO", county: "Morgan"},
    {stateLocation: "CO", county: "Otero"},
    {stateLocation: "CO", county: "Ouray"},
    {stateLocation: "CO", county: "Park"},
    {stateLocation: "CO", county: "Phillips"},
    {stateLocation: "CO", county: "Pitkin"},
    {stateLocation: "CO", county: "Prowers"},
    {stateLocation: "CO", county: "Pueblo"},
    {stateLocation: "CO", county: "Rio Blanco"},
    {stateLocation: "CO", county: "Rio Grande"},
    {stateLocation: "CO", county: "Routt"},
    {stateLocation: "CO", county: "Saguache"},
    {stateLocation: "CO", county: "San Juan"},
    {stateLocation: "CO", county: "San Miguel"},
    {stateLocation: "CO", county: "Sedgwick"},
    {stateLocation: "CO", county: "Summit"},
    {stateLocation: "CO", county: "Teller"},
    {stateLocation: "CO", county: "Washington"},
    {stateLocation: "CO", county: "Weld"},
    {stateLocation: "CO", county: "Yuma"},
    {stateLocation: "CT", county: "Fairfield"},
    {stateLocation: "CT", county: "Hartford"},
    {stateLocation: "CT", county: "Litchfield"},
    {stateLocation: "CT", county: "Middlesex"},
    {stateLocation: "CT", county: "New Haven"},
    {stateLocation: "CT", county: "New London"},
    {stateLocation: "CT", county: "Tolland"},
    {stateLocation: "CT", county: "Windham"},
    {stateLocation: "DE", county: "Kent"},
    {stateLocation: "DE", county: "New Castle"},
    {stateLocation: "DE", county: "Sussex"},
    {stateLocation: "DC", county: "District of Columbia"},
    {stateLocation: "FL", county: "Alachua"},
    {stateLocation: "FL", county: "Baker"},
    {stateLocation: "FL", county: "Bay"},
    {stateLocation: "FL", county: "Bradford"},
    {stateLocation: "FL", county: "Brevard"},
    {stateLocation: "FL", county: "Broward"},
    {stateLocation: "FL", county: "Calhoun"},
    {stateLocation: "FL", county: "Charlotte"},
    {stateLocation: "FL", county: "Citrus"},
    {stateLocation: "FL", county: "Clay"},
    {stateLocation: "FL", county: "Collier"},
    {stateLocation: "FL", county: "Columbia"},
    {stateLocation: "FL", county: "DeSoto"},
    {stateLocation: "FL", county: "Dixie"},
    {stateLocation: "FL", county: "Duval"},
    {stateLocation: "FL", county: "Escambia"},
    {stateLocation: "FL", county: "Flagler"},
    {stateLocation: "FL", county: "Franklin"},
    {stateLocation: "FL", county: "Gadsden"},
    {stateLocation: "FL", county: "Gilchrist"},
    {stateLocation: "FL", county: "Glades"},
    {stateLocation: "FL", county: "Gulf"},
    {stateLocation: "FL", county: "Hamilton"},
    {stateLocation: "FL", county: "Hardee"},
    {stateLocation: "FL", county: "Hendry"},
    {stateLocation: "FL", county: "Hernando"},
    {stateLocation: "FL", county: "Highlands"},
    {stateLocation: "FL", county: "Hillsborough"},
    {stateLocation: "FL", county: "Holmes"},
    {stateLocation: "FL", county: "Indian River"},
    {stateLocation: "FL", county: "Jackson"},
    {stateLocation: "FL", county: "Jefferson"},
    {stateLocation: "FL", county: "Lafayette"},
    {stateLocation: "FL", county: "Lake"},
    {stateLocation: "FL", county: "Lee"},
    {stateLocation: "FL", county: "Leon"},
    {stateLocation: "FL", county: "Levy"},
    {stateLocation: "FL", county: "Liberty"},
    {stateLocation: "FL", county: "Madison"},
    {stateLocation: "FL", county: "Manatee"},
    {stateLocation: "FL", county: "Marion"},
    {stateLocation: "FL", county: "Martin"},
    {stateLocation: "FL", county: "Miami-Dade"},
    {stateLocation: "FL", county: "Monroe"},
    {stateLocation: "FL", county: "Nassau"},
    {stateLocation: "FL", county: "Okaloosa"},
    {stateLocation: "FL", county: "Okeechobee"},
    {stateLocation: "FL", county: "Orange"},
    {stateLocation: "FL", county: "Osceola"},
    {stateLocation: "FL", county: "Palm Beach"},
    {stateLocation: "FL", county: "Pasco"},
    {stateLocation: "FL", county: "Pinellas"},
    {stateLocation: "FL", county: "Polk"},
    {stateLocation: "FL", county: "Putnam"},
    {stateLocation: "FL", county: "St. Johns"},
    {stateLocation: "FL", county: "St. Lucie"},
    {stateLocation: "FL", county: "Santa Rosa"},
    {stateLocation: "FL", county: "Sarasota"},
    {stateLocation: "FL", county: "Seminole"},
    {stateLocation: "FL", county: "Sumter"},
    {stateLocation: "FL", county: "Suwannee"},
    {stateLocation: "FL", county: "Taylor"},
    {stateLocation: "FL", county: "Union"},
    {stateLocation: "FL", county: "Volusia"},
    {stateLocation: "FL", county: "Wakulla"},
    {stateLocation: "FL", county: "Walton"},
    {stateLocation: "FL", county: "Washington"},
    {stateLocation: "GA", county: "Appling"},
    {stateLocation: "GA", county: "Atkinson"},
    {stateLocation: "GA", county: "Bacon"},
    {stateLocation: "GA", county: "Baker"},
    {stateLocation: "GA", county: "Baldwin"},
    {stateLocation: "GA", county: "Banks"},
    {stateLocation: "GA", county: "Barrow"},
    {stateLocation: "GA", county: "Bartow"},
    {stateLocation: "GA", county: "Ben Hill"},
    {stateLocation: "GA", county: "Berrien"},
    {stateLocation: "GA", county: "Bibb"},
    {stateLocation: "GA", county: "Bleckley"},
    {stateLocation: "GA", county: "Brantley"},
    {stateLocation: "GA", county: "Brooks"},
    {stateLocation: "GA", county: "Bryan"},
    {stateLocation: "GA", county: "Bulloch"},
    {stateLocation: "GA", county: "Burke"},
    {stateLocation: "GA", county: "Butts"},
    {stateLocation: "GA", county: "Calhoun"},
    {stateLocation: "GA", county: "Camden"},
    {stateLocation: "GA", county: "Candler"},
    {stateLocation: "GA", county: "Carroll"},
    {stateLocation: "GA", county: "Catoosa"},
    {stateLocation: "GA", county: "Charlton"},
    {stateLocation: "GA", county: "Chatham"},
    {stateLocation: "GA", county: "Chattahoochee"},
    {stateLocation: "GA", county: "Chattooga"},
    {stateLocation: "GA", county: "Cherokee"},
    {stateLocation: "GA", county: "Clarke"},
    {stateLocation: "GA", county: "Clay"},
    {stateLocation: "GA", county: "Clayton"},
    {stateLocation: "GA", county: "Clinch"},
    {stateLocation: "GA", county: "Cobb"},
    {stateLocation: "GA", county: "Coffee"},
    {stateLocation: "GA", county: "Colquitt"},
    {stateLocation: "GA", county: "Columbia"},
    {stateLocation: "GA", county: "Cook"},
    {stateLocation: "GA", county: "Coweta"},
    {stateLocation: "GA", county: "Crawford"},
    {stateLocation: "GA", county: "Crisp"},
    {stateLocation: "GA", county: "Dade"},
    {stateLocation: "GA", county: "Dawson"},
    {stateLocation: "GA", county: "Decatur"},
    {stateLocation: "GA", county: "DeKalb"},
    {stateLocation: "GA", county: "Dodge"},
    {stateLocation: "GA", county: "Dooly"},
    {stateLocation: "GA", county: "Dougherty"},
    {stateLocation: "GA", county: "Douglas"},
    {stateLocation: "GA", county: "Early"},
    {stateLocation: "GA", county: "Echols"},
    {stateLocation: "GA", county: "Effingham"},
    {stateLocation: "GA", county: "Elbert"},
    {stateLocation: "GA", county: "Emanuel"},
    {stateLocation: "GA", county: "Evans"},
    {stateLocation: "GA", county: "Fannin"},
    {stateLocation: "GA", county: "Fayette"},
    {stateLocation: "GA", county: "Floyd"},
    {stateLocation: "GA", county: "Forsyth"},
    {stateLocation: "GA", county: "Franklin"},
    {stateLocation: "GA", county: "Fulton"},
    {stateLocation: "GA", county: "Gilmer"},
    {stateLocation: "GA", county: "Glascock"},
    {stateLocation: "GA", county: "Glynn"},
    {stateLocation: "GA", county: "Gordon"},
    {stateLocation: "GA", county: "Grady"},
    {stateLocation: "GA", county: "Greene"},
    {stateLocation: "GA", county: "Gwinnett"},
    {stateLocation: "GA", county: "Habersham"},
    {stateLocation: "GA", county: "Hall"},
    {stateLocation: "GA", county: "Hancock"},
    {stateLocation: "GA", county: "Haralson"},
    {stateLocation: "GA", county: "Harris"},
    {stateLocation: "GA", county: "Hart"},
    {stateLocation: "GA", county: "Heard"},
    {stateLocation: "GA", county: "Henry"},
    {stateLocation: "GA", county: "Houston"},
    {stateLocation: "GA", county: "Irwin"},
    {stateLocation: "GA", county: "Jackson"},
    {stateLocation: "GA", county: "Jasper"},
    {stateLocation: "GA", county: "Jeff Davis"},
    {stateLocation: "GA", county: "Jefferson"},
    {stateLocation: "GA", county: "Jenkins"},
    {stateLocation: "GA", county: "Johnson"},
    {stateLocation: "GA", county: "Jones"},
    {stateLocation: "GA", county: "Lamar"},
    {stateLocation: "GA", county: "Lanier"},
    {stateLocation: "GA", county: "Laurens"},
    {stateLocation: "GA", county: "Lee"},
    {stateLocation: "GA", county: "Liberty"},
    {stateLocation: "GA", county: "Lincoln"},
    {stateLocation: "GA", county: "Long"},
    {stateLocation: "GA", county: "Lowndes"},
    {stateLocation: "GA", county: "Lumpkin"},
    {stateLocation: "GA", county: "McDuffie"},
    {stateLocation: "GA", county: "McIntosh"},
    {stateLocation: "GA", county: "Macon"},
    {stateLocation: "GA", county: "Madison"},
    {stateLocation: "GA", county: "Marion"},
    {stateLocation: "GA", county: "Meriwether"},
    {stateLocation: "GA", county: "Miller"},
    {stateLocation: "GA", county: "Mitchell"},
    {stateLocation: "GA", county: "Monroe"},
    {stateLocation: "GA", county: "Montgomery"},
    {stateLocation: "GA", county: "Morgan"},
    {stateLocation: "GA", county: "Murray"},
    {stateLocation: "GA", county: "Muscogee"},
    {stateLocation: "GA", county: "Newton"},
    {stateLocation: "GA", county: "Oconee"},
    {stateLocation: "GA", county: "Oglethorpe"},
    {stateLocation: "GA", county: "Paulding"},
    {stateLocation: "GA", county: "Peach"},
    {stateLocation: "GA", county: "Pickens"},
    {stateLocation: "GA", county: "Pierce"},
    {stateLocation: "GA", county: "Pike"},
    {stateLocation: "GA", county: "Polk"},
    {stateLocation: "GA", county: "Pulaski"},
    {stateLocation: "GA", county: "Putnam"},
    {stateLocation: "GA", county: "Quitman"},
    {stateLocation: "GA", county: "Rabun"},
    {stateLocation: "GA", county: "Randolph"},
    {stateLocation: "GA", county: "Richmond"},
    {stateLocation: "GA", county: "Rockdale"},
    {stateLocation: "GA", county: "Schley"},
    {stateLocation: "GA", county: "Screven"},
    {stateLocation: "GA", county: "Seminole"},
    {stateLocation: "GA", county: "Spalding"},
    {stateLocation: "GA", county: "Stephens"},
    {stateLocation: "GA", county: "Stewart"},
    {stateLocation: "GA", county: "Sumter"},
    {stateLocation: "GA", county: "Talbot"},
    {stateLocation: "GA", county: "Taliaferro"},
    {stateLocation: "GA", county: "Tattnall"},
    {stateLocation: "GA", county: "Taylor"},
    {stateLocation: "GA", county: "Telfair"},
    {stateLocation: "GA", county: "Terrell"},
    {stateLocation: "GA", county: "Thomas"},
    {stateLocation: "GA", county: "Tift"},
    {stateLocation: "GA", county: "Toombs"},
    {stateLocation: "GA", county: "Towns"},
    {stateLocation: "GA", county: "Treutlen"},
    {stateLocation: "GA", county: "Troup"},
    {stateLocation: "GA", county: "Turner"},
    {stateLocation: "GA", county: "Twiggs"},
    {stateLocation: "GA", county: "Union"},
    {stateLocation: "GA", county: "Upson"},
    {stateLocation: "GA", county: "Walker"},
    {stateLocation: "GA", county: "Walton"},
    {stateLocation: "GA", county: "Ware"},
    {stateLocation: "GA", county: "Warren"},
    {stateLocation: "GA", county: "Washington"},
    {stateLocation: "GA", county: "Wayne"},
    {stateLocation: "GA", county: "Webster"},
    {stateLocation: "GA", county: "Wheeler"},
    {stateLocation: "GA", county: "White"},
    {stateLocation: "GA", county: "Whitfield"},
    {stateLocation: "GA", county: "Wilcox"},
    {stateLocation: "GA", county: "Wilkes"},
    {stateLocation: "GA", county: "Wilkinson"},
    { stateLocation: "GA", county: "Worth" },
    { stateLocation: 'GU', county: 'Guam'},
    {stateLocation: "HI", county: "Hawaii"},
    {stateLocation: "HI", county: "Honolulu"},
    {stateLocation: "HI", county: "Kalawao"},
    {stateLocation: "HI", county: "Kauai"},
    {stateLocation: "HI", county: "Maui"},
    {stateLocation: "ID", county: "Ada"},
    {stateLocation: "ID", county: "Adams"},
    {stateLocation: "ID", county: "Bannock"},
    {stateLocation: "ID", county: "Bear Lake"},
    {stateLocation: "ID", county: "Benewah"},
    {stateLocation: "ID", county: "Bingham"},
    {stateLocation: "ID", county: "Blaine"},
    {stateLocation: "ID", county: "Boise"},
    {stateLocation: "ID", county: "Bonner"},
    {stateLocation: "ID", county: "Bonneville"},
    {stateLocation: "ID", county: "Boundary"},
    {stateLocation: "ID", county: "Butte"},
    {stateLocation: "ID", county: "Camas"},
    {stateLocation: "ID", county: "Canyon"},
    {stateLocation: "ID", county: "Caribou"},
    {stateLocation: "ID", county: "Cassia"},
    {stateLocation: "ID", county: "Clark"},
    {stateLocation: "ID", county: "Clearwater"},
    {stateLocation: "ID", county: "Custer"},
    {stateLocation: "ID", county: "Elmore"},
    {stateLocation: "ID", county: "Franklin"},
    {stateLocation: "ID", county: "Fremont"},
    {stateLocation: "ID", county: "Gem"},
    {stateLocation: "ID", county: "Gooding"},
    {stateLocation: "ID", county: "Idaho"},
    {stateLocation: "ID", county: "Jefferson"},
    {stateLocation: "ID", county: "Jerome"},
    {stateLocation: "ID", county: "Kootenai"},
    {stateLocation: "ID", county: "Latah"},
    {stateLocation: "ID", county: "Lemhi"},
    {stateLocation: "ID", county: "Lewis"},
    {stateLocation: "ID", county: "Lincoln"},
    {stateLocation: "ID", county: "Madison"},
    {stateLocation: "ID", county: "Minidoka"},
    {stateLocation: "ID", county: "Nez Perce"},
    {stateLocation: "ID", county: "Oneida"},
    {stateLocation: "ID", county: "Owyhee"},
    {stateLocation: "ID", county: "Payette"},
    {stateLocation: "ID", county: "Power"},
    {stateLocation: "ID", county: "Shoshone"},
    {stateLocation: "ID", county: "Teton"},
    {stateLocation: "ID", county: "Twin Falls"},
    {stateLocation: "ID", county: "Valley"},
    {stateLocation: "ID", county: "Washington"},
    {stateLocation: "IL", county: "Adams"},
    {stateLocation: "IL", county: "Alexander"},
    {stateLocation: "IL", county: "Bond"},
    {stateLocation: "IL", county: "Boone"},
    {stateLocation: "IL", county: "Brown"},
    {stateLocation: "IL", county: "Bureau"},
    {stateLocation: "IL", county: "Calhoun"},
    {stateLocation: "IL", county: "Carroll"},
    {stateLocation: "IL", county: "Cass"},
    {stateLocation: "IL", county: "Champaign"},
    {stateLocation: "IL", county: "Christian"},
    {stateLocation: "IL", county: "Clark"},
    {stateLocation: "IL", county: "Clay"},
    {stateLocation: "IL", county: "Clinton"},
    {stateLocation: "IL", county: "Coles"},
    {stateLocation: "IL", county: "Cook"},
    {stateLocation: "IL", county: "Crawford"},
    {stateLocation: "IL", county: "Cumberland"},
    {stateLocation: "IL", county: "DeKalb"},
    {stateLocation: "IL", county: "De Witt"},
    {stateLocation: "IL", county: "Douglas"},
    {stateLocation: "IL", county: "DuPage"},
    {stateLocation: "IL", county: "Edgar"},
    {stateLocation: "IL", county: "Edwards"},
    {stateLocation: "IL", county: "Effingham"},
    {stateLocation: "IL", county: "Fayette"},
    {stateLocation: "IL", county: "Ford"},
    {stateLocation: "IL", county: "Franklin"},
    {stateLocation: "IL", county: "Fulton"},
    {stateLocation: "IL", county: "Gallatin"},
    {stateLocation: "IL", county: "Greene"},
    {stateLocation: "IL", county: "Grundy"},
    {stateLocation: "IL", county: "Hamilton"},
    {stateLocation: "IL", county: "Hancock"},
    {stateLocation: "IL", county: "Hardin"},
    {stateLocation: "IL", county: "Henderson"},
    {stateLocation: "IL", county: "Henry"},
    {stateLocation: "IL", county: "Iroquois"},
    {stateLocation: "IL", county: "Jackson"},
    {stateLocation: "IL", county: "Jasper"},
    {stateLocation: "IL", county: "Jefferson"},
    {stateLocation: "IL", county: "Jersey"},
    {stateLocation: "IL", county: "Jo Daviess"},
    {stateLocation: "IL", county: "Johnson"},
    {stateLocation: "IL", county: "Kane"},
    {stateLocation: "IL", county: "Kankakee"},
    {stateLocation: "IL", county: "Kendall"},
    {stateLocation: "IL", county: "Knox"},
    {stateLocation: "IL", county: "Lake"},
    {stateLocation: "IL", county: "LaSalle"},
    {stateLocation: "IL", county: "Lawrence"},
    {stateLocation: "IL", county: "Lee"},
    {stateLocation: "IL", county: "Livingston"},
    {stateLocation: "IL", county: "Logan"},
    {stateLocation: "IL", county: "McDonough"},
    {stateLocation: "IL", county: "McHenry"},
    {stateLocation: "IL", county: "McLean"},
    {stateLocation: "IL", county: "Macon"},
    {stateLocation: "IL", county: "Macoupin"},
    {stateLocation: "IL", county: "Madison"},
    {stateLocation: "IL", county: "Marion"},
    {stateLocation: "IL", county: "Marshall"},
    {stateLocation: "IL", county: "Mason"},
    {stateLocation: "IL", county: "Massac"},
    {stateLocation: "IL", county: "Menard"},
    {stateLocation: "IL", county: "Mercer"},
    {stateLocation: "IL", county: "Monroe"},
    {stateLocation: "IL", county: "Montgomery"},
    {stateLocation: "IL", county: "Morgan"},
    {stateLocation: "IL", county: "Moultrie"},
    {stateLocation: "IL", county: "Ogle"},
    {stateLocation: "IL", county: "Peoria"},
    {stateLocation: "IL", county: "Perry"},
    {stateLocation: "IL", county: "Piatt"},
    {stateLocation: "IL", county: "Pike"},
    {stateLocation: "IL", county: "Pope"},
    {stateLocation: "IL", county: "Pulaski"},
    {stateLocation: "IL", county: "Putnam"},
    {stateLocation: "IL", county: "Randolph"},
    {stateLocation: "IL", county: "Richland"},
    {stateLocation: "IL", county: "Rock Island"},
    {stateLocation: "IL", county: "St. Clair"},
    {stateLocation: "IL", county: "Saline"},
    {stateLocation: "IL", county: "Sangamon"},
    {stateLocation: "IL", county: "Schuyler"},
    {stateLocation: "IL", county: "Scott"},
    {stateLocation: "IL", county: "Shelby"},
    {stateLocation: "IL", county: "Stark"},
    {stateLocation: "IL", county: "Stephenson"},
    {stateLocation: "IL", county: "Tazewell"},
    {stateLocation: "IL", county: "Union"},
    {stateLocation: "IL", county: "Vermilion"},
    {stateLocation: "IL", county: "Wabash"},
    {stateLocation: "IL", county: "Warren"},
    {stateLocation: "IL", county: "Washington"},
    {stateLocation: "IL", county: "Wayne"},
    {stateLocation: "IL", county: "White"},
    {stateLocation: "IL", county: "Whiteside"},
    {stateLocation: "IL", county: "Will"},
    {stateLocation: "IL", county: "Williamson"},
    {stateLocation: "IL", county: "Winnebago"},
    {stateLocation: "IL", county: "Woodford"},
    {stateLocation: "IN", county: "Adams"},
    {stateLocation: "IN", county: "Allen"},
    {stateLocation: "IN", county: "Bartholomew"},
    {stateLocation: "IN", county: "Benton"},
    {stateLocation: "IN", county: "Blackford"},
    {stateLocation: "IN", county: "Boone"},
    {stateLocation: "IN", county: "Brown"},
    {stateLocation: "IN", county: "Carroll"},
    {stateLocation: "IN", county: "Cass"},
    {stateLocation: "IN", county: "Clark"},
    {stateLocation: "IN", county: "Clay"},
    {stateLocation: "IN", county: "Clinton"},
    {stateLocation: "IN", county: "Crawford"},
    {stateLocation: "IN", county: "Daviess"},
    {stateLocation: "IN", county: "Dearborn"},
    {stateLocation: "IN", county: "Decatur"},
    {stateLocation: "IN", county: "DeKalb"},
    {stateLocation: "IN", county: "Delaware"},
    {stateLocation: "IN", county: "Dubois"},
    {stateLocation: "IN", county: "Elkhart"},
    {stateLocation: "IN", county: "Fayette"},
    {stateLocation: "IN", county: "Floyd"},
    {stateLocation: "IN", county: "Fountain"},
    {stateLocation: "IN", county: "Franklin"},
    {stateLocation: "IN", county: "Fulton"},
    {stateLocation: "IN", county: "Gibson"},
    {stateLocation: "IN", county: "Grant"},
    {stateLocation: "IN", county: "Greene"},
    {stateLocation: "IN", county: "Hamilton"},
    {stateLocation: "IN", county: "Hancock"},
    {stateLocation: "IN", county: "Harrison"},
    {stateLocation: "IN", county: "Hendricks"},
    {stateLocation: "IN", county: "Henry"},
    {stateLocation: "IN", county: "Howard"},
    {stateLocation: "IN", county: "Huntington"},
    {stateLocation: "IN", county: "Jackson"},
    {stateLocation: "IN", county: "Jasper"},
    {stateLocation: "IN", county: "Jay"},
    {stateLocation: "IN", county: "Jefferson"},
    {stateLocation: "IN", county: "Jennings"},
    {stateLocation: "IN", county: "Johnson"},
    {stateLocation: "IN", county: "Knox"},
    {stateLocation: "IN", county: "Kosciusko"},
    {stateLocation: "IN", county: "LaGrange"},
    {stateLocation: "IN", county: "Lake"},
    {stateLocation: "IN", county: "LaPorte"},
    {stateLocation: "IN", county: "Lawrence"},
    {stateLocation: "IN", county: "Madison"},
    {stateLocation: "IN", county: "Marion"},
    {stateLocation: "IN", county: "Marshall"},
    {stateLocation: "IN", county: "Martin"},
    {stateLocation: "IN", county: "Miami"},
    {stateLocation: "IN", county: "Monroe"},
    {stateLocation: "IN", county: "Montgomery"},
    {stateLocation: "IN", county: "Morgan"},
    {stateLocation: "IN", county: "Newton"},
    {stateLocation: "IN", county: "Noble"},
    {stateLocation: "IN", county: "Ohio"},
    {stateLocation: "IN", county: "Orange"},
    {stateLocation: "IN", county: "Owen"},
    {stateLocation: "IN", county: "Parke"},
    {stateLocation: "IN", county: "Perry"},
    {stateLocation: "IN", county: "Pike"},
    {stateLocation: "IN", county: "Porter"},
    {stateLocation: "IN", county: "Posey"},
    {stateLocation: "IN", county: "Pulaski"},
    {stateLocation: "IN", county: "Putnam"},
    {stateLocation: "IN", county: "Randolph"},
    {stateLocation: "IN", county: "Ripley"},
    {stateLocation: "IN", county: "Rush"},
    {stateLocation: "IN", county: "St. Joseph"},
    {stateLocation: "IN", county: "Scott"},
    {stateLocation: "IN", county: "Shelby"},
    {stateLocation: "IN", county: "Spencer"},
    {stateLocation: "IN", county: "Starke"},
    {stateLocation: "IN", county: "Steuben"},
    {stateLocation: "IN", county: "Sullivan"},
    {stateLocation: "IN", county: "Switzerland"},
    {stateLocation: "IN", county: "Tippecanoe"},
    {stateLocation: "IN", county: "Tipton"},
    {stateLocation: "IN", county: "Union"},
    {stateLocation: "IN", county: "Vanderburgh"},
    {stateLocation: "IN", county: "Vermillion"},
    {stateLocation: "IN", county: "Vigo"},
    {stateLocation: "IN", county: "Wabash"},
    {stateLocation: "IN", county: "Warren"},
    {stateLocation: "IN", county: "Warrick"},
    {stateLocation: "IN", county: "Washington"},
    {stateLocation: "IN", county: "Wayne"},
    {stateLocation: "IN", county: "Wells"},
    {stateLocation: "IN", county: "White"},
    {stateLocation: "IN", county: "Whitley"},
    {stateLocation: "IA", county: "Adair"},
    {stateLocation: "IA", county: "Adams"},
    {stateLocation: "IA", county: "Allamakee"},
    {stateLocation: "IA", county: "Appanoose"},
    {stateLocation: "IA", county: "Audubon"},
    {stateLocation: "IA", county: "Benton"},
    {stateLocation: "IA", county: "Black Hawk"},
    {stateLocation: "IA", county: "Boone"},
    {stateLocation: "IA", county: "Bremer"},
    {stateLocation: "IA", county: "Buchanan"},
    {stateLocation: "IA", county: "Buena Vista"},
    {stateLocation: "IA", county: "Butler"},
    {stateLocation: "IA", county: "Calhoun"},
    {stateLocation: "IA", county: "Carroll"},
    {stateLocation: "IA", county: "Cass"},
    {stateLocation: "IA", county: "Cedar"},
    {stateLocation: "IA", county: "Cerro Gordo"},
    {stateLocation: "IA", county: "Cherokee"},
    {stateLocation: "IA", county: "Chickasaw"},
    {stateLocation: "IA", county: "Clarke"},
    {stateLocation: "IA", county: "Clay"},
    {stateLocation: "IA", county: "Clayton"},
    {stateLocation: "IA", county: "Clinton"},
    {stateLocation: "IA", county: "Crawford"},
    {stateLocation: "IA", county: "Dallas"},
    {stateLocation: "IA", county: "Davis"},
    {stateLocation: "IA", county: "Decatur"},
    {stateLocation: "IA", county: "Delaware"},
    {stateLocation: "IA", county: "Des Moines"},
    {stateLocation: "IA", county: "Dickinson"},
    {stateLocation: "IA", county: "Dubuque"},
    {stateLocation: "IA", county: "Emmet"},
    {stateLocation: "IA", county: "Fayette"},
    {stateLocation: "IA", county: "Floyd"},
    {stateLocation: "IA", county: "Franklin"},
    {stateLocation: "IA", county: "Fremont"},
    {stateLocation: "IA", county: "Greene"},
    {stateLocation: "IA", county: "Grundy"},
    {stateLocation: "IA", county: "Guthrie"},
    {stateLocation: "IA", county: "Hamilton"},
    {stateLocation: "IA", county: "Hancock"},
    {stateLocation: "IA", county: "Hardin"},
    {stateLocation: "IA", county: "Harrison"},
    {stateLocation: "IA", county: "Henry"},
    {stateLocation: "IA", county: "Howard"},
    {stateLocation: "IA", county: "Humboldt"},
    {stateLocation: "IA", county: "Ida"},
    {stateLocation: "IA", county: "Iowa"},
    {stateLocation: "IA", county: "Jackson"},
    {stateLocation: "IA", county: "Jasper"},
    {stateLocation: "IA", county: "Jefferson"},
    {stateLocation: "IA", county: "Johnson"},
    {stateLocation: "IA", county: "Jones"},
    {stateLocation: "IA", county: "Keokuk"},
    {stateLocation: "IA", county: "Kossuth"},
    {stateLocation: "IA", county: "Lee"},
    {stateLocation: "IA", county: "Linn"},
    {stateLocation: "IA", county: "Louisa"},
    {stateLocation: "IA", county: "Lucas"},
    {stateLocation: "IA", county: "Lyon"},
    {stateLocation: "IA", county: "Madison"},
    {stateLocation: "IA", county: "Mahaska"},
    {stateLocation: "IA", county: "Marion"},
    {stateLocation: "IA", county: "Marshall"},
    {stateLocation: "IA", county: "Mills"},
    {stateLocation: "IA", county: "Mitchell"},
    {stateLocation: "IA", county: "Monona"},
    {stateLocation: "IA", county: "Monroe"},
    {stateLocation: "IA", county: "Montgomery"},
    {stateLocation: "IA", county: "Muscatine"},
    {stateLocation: "IA", county: "O'Brien"},
    {stateLocation: "IA", county: "Osceola"},
    {stateLocation: "IA", county: "Page"},
    {stateLocation: "IA", county: "Palo Alto"},
    {stateLocation: "IA", county: "Plymouth"},
    {stateLocation: "IA", county: "Pocahontas"},
    {stateLocation: "IA", county: "Polk"},
    {stateLocation: "IA", county: "Pottawattamie"},
    {stateLocation: "IA", county: "Poweshiek"},
    {stateLocation: "IA", county: "Ringgold"},
    {stateLocation: "IA", county: "Sac"},
    {stateLocation: "IA", county: "Scott"},
    {stateLocation: "IA", county: "Shelby"},
    {stateLocation: "IA", county: "Sioux"},
    {stateLocation: "IA", county: "Story"},
    {stateLocation: "IA", county: "Tama"},
    {stateLocation: "IA", county: "Taylor"},
    {stateLocation: "IA", county: "Union"},
    {stateLocation: "IA", county: "Van Buren"},
    {stateLocation: "IA", county: "Wapello"},
    {stateLocation: "IA", county: "Warren"},
    {stateLocation: "IA", county: "Washington"},
    {stateLocation: "IA", county: "Wayne"},
    {stateLocation: "IA", county: "Webster"},
    {stateLocation: "IA", county: "Winnebago"},
    {stateLocation: "IA", county: "Winneshiek"},
    {stateLocation: "IA", county: "Woodbury"},
    {stateLocation: "IA", county: "Worth"},
    {stateLocation: "IA", county: "Wright"},
    {stateLocation: "KS", county: "Allen"},
    {stateLocation: "KS", county: "Anderson"},
    {stateLocation: "KS", county: "Atchison"},
    {stateLocation: "KS", county: "Barber"},
    {stateLocation: "KS", county: "Barton"},
    {stateLocation: "KS", county: "Bourbon"},
    {stateLocation: "KS", county: "Brown"},
    {stateLocation: "KS", county: "Butler"},
    {stateLocation: "KS", county: "Chase"},
    {stateLocation: "KS", county: "Chautauqua"},
    {stateLocation: "KS", county: "Cherokee"},
    {stateLocation: "KS", county: "Cheyenne"},
    {stateLocation: "KS", county: "Clark"},
    {stateLocation: "KS", county: "Clay"},
    {stateLocation: "KS", county: "Cloud"},
    {stateLocation: "KS", county: "Coffey"},
    {stateLocation: "KS", county: "Comanche"},
    {stateLocation: "KS", county: "Cowley"},
    {stateLocation: "KS", county: "Crawford"},
    {stateLocation: "KS", county: "Decatur"},
    {stateLocation: "KS", county: "Dickinson"},
    {stateLocation: "KS", county: "Doniphan"},
    {stateLocation: "KS", county: "Douglas"},
    {stateLocation: "KS", county: "Edwards"},
    {stateLocation: "KS", county: "Elk"},
    {stateLocation: "KS", county: "Ellis"},
    {stateLocation: "KS", county: "Ellsworth"},
    {stateLocation: "KS", county: "Finney"},
    {stateLocation: "KS", county: "Ford"},
    {stateLocation: "KS", county: "Franklin"},
    {stateLocation: "KS", county: "Geary"},
    {stateLocation: "KS", county: "Gove"},
    {stateLocation: "KS", county: "Graham"},
    {stateLocation: "KS", county: "Grant"},
    {stateLocation: "KS", county: "Gray"},
    {stateLocation: "KS", county: "Greeley"},
    {stateLocation: "KS", county: "Greenwood"},
    {stateLocation: "KS", county: "Hamilton"},
    {stateLocation: "KS", county: "Harper"},
    {stateLocation: "KS", county: "Harvey"},
    {stateLocation: "KS", county: "Haskell"},
    {stateLocation: "KS", county: "Hodgeman"},
    {stateLocation: "KS", county: "Jackson"},
    {stateLocation: "KS", county: "Jefferson"},
    {stateLocation: "KS", county: "Jewell"},
    {stateLocation: "KS", county: "Johnson"},
    {stateLocation: "KS", county: "Kearny"},
    {stateLocation: "KS", county: "Kingman"},
    {stateLocation: "KS", county: "Kiowa"},
    {stateLocation: "KS", county: "Labette"},
    {stateLocation: "KS", county: "Lane"},
    {stateLocation: "KS", county: "Leavenworth"},
    {stateLocation: "KS", county: "Lincoln"},
    {stateLocation: "KS", county: "Linn"},
    {stateLocation: "KS", county: "Logan"},
    {stateLocation: "KS", county: "Lyon"},
    {stateLocation: "KS", county: "McPherson"},
    {stateLocation: "KS", county: "Marion"},
    {stateLocation: "KS", county: "Marshall"},
    {stateLocation: "KS", county: "Meade"},
    {stateLocation: "KS", county: "Miami"},
    {stateLocation: "KS", county: "Mitchell"},
    {stateLocation: "KS", county: "Montgomery"},
    {stateLocation: "KS", county: "Morris"},
    {stateLocation: "KS", county: "Morton"},
    {stateLocation: "KS", county: "Nemaha"},
    {stateLocation: "KS", county: "Neosho"},
    {stateLocation: "KS", county: "Ness"},
    {stateLocation: "KS", county: "Norton"},
    {stateLocation: "KS", county: "Osage"},
    {stateLocation: "KS", county: "Osborne"},
    {stateLocation: "KS", county: "Ottawa"},
    {stateLocation: "KS", county: "Pawnee"},
    {stateLocation: "KS", county: "Phillips"},
    {stateLocation: "KS", county: "Pottawatomie"},
    {stateLocation: "KS", county: "Pratt"},
    {stateLocation: "KS", county: "Rawlins"},
    {stateLocation: "KS", county: "Reno"},
    {stateLocation: "KS", county: "Republic"},
    {stateLocation: "KS", county: "Rice"},
    {stateLocation: "KS", county: "Riley"},
    {stateLocation: "KS", county: "Rooks"},
    {stateLocation: "KS", county: "Rush"},
    {stateLocation: "KS", county: "Russell"},
    {stateLocation: "KS", county: "Saline"},
    {stateLocation: "KS", county: "Scott"},
    {stateLocation: "KS", county: "Sedgwick"},
    {stateLocation: "KS", county: "Seward"},
    {stateLocation: "KS", county: "Shawnee"},
    {stateLocation: "KS", county: "Sheridan"},
    {stateLocation: "KS", county: "Sherman"},
    {stateLocation: "KS", county: "Smith"},
    {stateLocation: "KS", county: "Stafford"},
    {stateLocation: "KS", county: "Stanton"},
    {stateLocation: "KS", county: "Stevens"},
    {stateLocation: "KS", county: "Sumner"},
    {stateLocation: "KS", county: "Thomas"},
    {stateLocation: "KS", county: "Trego"},
    {stateLocation: "KS", county: "Wabaunsee"},
    {stateLocation: "KS", county: "Wallace"},
    {stateLocation: "KS", county: "Washington"},
    {stateLocation: "KS", county: "Wichita"},
    {stateLocation: "KS", county: "Wilson"},
    {stateLocation: "KS", county: "Woodson"},
    {stateLocation: "KS", county: "Wyandotte"},
    {stateLocation: "KY", county: "Adair"},
    {stateLocation: "KY", county: "Allen"},
    {stateLocation: "KY", county: "Anderson"},
    {stateLocation: "KY", county: "Ballard"},
    {stateLocation: "KY", county: "Barren"},
    {stateLocation: "KY", county: "Bath"},
    {stateLocation: "KY", county: "Bell"},
    {stateLocation: "KY", county: "Boone"},
    {stateLocation: "KY", county: "Bourbon"},
    {stateLocation: "KY", county: "Boyd"},
    {stateLocation: "KY", county: "Boyle"},
    {stateLocation: "KY", county: "Bracken"},
    {stateLocation: "KY", county: "Breathitt"},
    {stateLocation: "KY", county: "Breckinridge"},
    {stateLocation: "KY", county: "Bullitt"},
    {stateLocation: "KY", county: "Butler"},
    {stateLocation: "KY", county: "Caldwell"},
    {stateLocation: "KY", county: "Calloway"},
    {stateLocation: "KY", county: "Campbell"},
    {stateLocation: "KY", county: "Carlisle"},
    {stateLocation: "KY", county: "Carroll"},
    {stateLocation: "KY", county: "Carter"},
    {stateLocation: "KY", county: "Casey"},
    {stateLocation: "KY", county: "Christian"},
    {stateLocation: "KY", county: "Clark"},
    {stateLocation: "KY", county: "Clay"},
    {stateLocation: "KY", county: "Clinton"},
    {stateLocation: "KY", county: "Crittenden"},
    {stateLocation: "KY", county: "Cumberland"},
    {stateLocation: "KY", county: "Daviess"},
    {stateLocation: "KY", county: "Edmonson"},
    {stateLocation: "KY", county: "Elliott"},
    {stateLocation: "KY", county: "Estill"},
    {stateLocation: "KY", county: "Fayette"},
    {stateLocation: "KY", county: "Fleming"},
    {stateLocation: "KY", county: "Floyd"},
    {stateLocation: "KY", county: "Franklin"},
    {stateLocation: "KY", county: "Fulton"},
    {stateLocation: "KY", county: "Gallatin"},
    {stateLocation: "KY", county: "Garrard"},
    {stateLocation: "KY", county: "Grant"},
    {stateLocation: "KY", county: "Graves"},
    {stateLocation: "KY", county: "Grayson"},
    {stateLocation: "KY", county: "Green"},
    {stateLocation: "KY", county: "Greenup"},
    {stateLocation: "KY", county: "Hancock"},
    {stateLocation: "KY", county: "Hardin"},
    {stateLocation: "KY", county: "Harlan"},
    {stateLocation: "KY", county: "Harrison"},
    {stateLocation: "KY", county: "Hart"},
    {stateLocation: "KY", county: "Henderson"},
    {stateLocation: "KY", county: "Henry"},
    {stateLocation: "KY", county: "Hickman"},
    {stateLocation: "KY", county: "Hopkins"},
    {stateLocation: "KY", county: "Jackson"},
    {stateLocation: "KY", county: "Jefferson"},
    {stateLocation: "KY", county: "Jessamine"},
    {stateLocation: "KY", county: "Johnson"},
    {stateLocation: "KY", county: "Kenton"},
    {stateLocation: "KY", county: "Knott"},
    {stateLocation: "KY", county: "Knox"},
    {stateLocation: "KY", county: "Larue"},
    {stateLocation: "KY", county: "Laurel"},
    {stateLocation: "KY", county: "Lawrence"},
    {stateLocation: "KY", county: "Lee"},
    {stateLocation: "KY", county: "Leslie"},
    {stateLocation: "KY", county: "Letcher"},
    {stateLocation: "KY", county: "Lewis"},
    {stateLocation: "KY", county: "Lincoln"},
    {stateLocation: "KY", county: "Livingston"},
    {stateLocation: "KY", county: "Logan"},
    {stateLocation: "KY", county: "Lyon"},
    {stateLocation: "KY", county: "McCracken"},
    {stateLocation: "KY", county: "McCreary"},
    {stateLocation: "KY", county: "McLean"},
    {stateLocation: "KY", county: "Madison"},
    {stateLocation: "KY", county: "Magoffin"},
    {stateLocation: "KY", county: "Marion"},
    {stateLocation: "KY", county: "Marshall"},
    {stateLocation: "KY", county: "Martin"},
    {stateLocation: "KY", county: "Mason"},
    {stateLocation: "KY", county: "Meade"},
    {stateLocation: "KY", county: "Menifee"},
    {stateLocation: "KY", county: "Mercer"},
    {stateLocation: "KY", county: "Metcalfe"},
    {stateLocation: "KY", county: "Monroe"},
    {stateLocation: "KY", county: "Montgomery"},
    {stateLocation: "KY", county: "Morgan"},
    {stateLocation: "KY", county: "Muhlenberg"},
    {stateLocation: "KY", county: "Nelson"},
    {stateLocation: "KY", county: "Nicholas"},
    {stateLocation: "KY", county: "Ohio"},
    {stateLocation: "KY", county: "Oldham"},
    {stateLocation: "KY", county: "Owen"},
    {stateLocation: "KY", county: "Owsley"},
    {stateLocation: "KY", county: "Pendleton"},
    {stateLocation: "KY", county: "Perry"},
    {stateLocation: "KY", county: "Pike"},
    {stateLocation: "KY", county: "Powell"},
    {stateLocation: "KY", county: "Pulaski"},
    {stateLocation: "KY", county: "Robertson"},
    {stateLocation: "KY", county: "Rockcastle"},
    {stateLocation: "KY", county: "Rowan"},
    {stateLocation: "KY", county: "Russell"},
    {stateLocation: "KY", county: "Scott"},
    {stateLocation: "KY", county: "Shelby"},
    {stateLocation: "KY", county: "Simpson"},
    {stateLocation: "KY", county: "Spencer"},
    {stateLocation: "KY", county: "Taylor"},
    {stateLocation: "KY", county: "Todd"},
    {stateLocation: "KY", county: "Trigg"},
    {stateLocation: "KY", county: "Trimble"},
    {stateLocation: "KY", county: "Union"},
    {stateLocation: "KY", county: "Warren"},
    {stateLocation: "KY", county: "Washington"},
    {stateLocation: "KY", county: "Wayne"},
    {stateLocation: "KY", county: "Webster"},
    {stateLocation: "KY", county: "Whitley"},
    {stateLocation: "KY", county: "Wolfe"},
    {stateLocation: "KY", county: "Woodford"},
    {stateLocation: "LA", county: "Acadia"},
    {stateLocation: "LA", county: "Allen"},
    {stateLocation: "LA", county: "Ascension"},
    {stateLocation: "LA", county: "Assumption"},
    {stateLocation: "LA", county: "Avoyelles"},
    {stateLocation: "LA", county: "Beauregard"},
    {stateLocation: "LA", county: "Bienville"},
    {stateLocation: "LA", county: "Bossier"},
    {stateLocation: "LA", county: "Caddo"},
    {stateLocation: "LA", county: "Calcasieu"},
    {stateLocation: "LA", county: "Caldwell"},
    {stateLocation: "LA", county: "Cameron"},
    {stateLocation: "LA", county: "Catahoula"},
    {stateLocation: "LA", county: "Claiborne"},
    {stateLocation: "LA", county: "Concordia"},
    {stateLocation: "LA", county: "De Soto"},
    {stateLocation: "LA", county: "East Baton Rouge"},
    {stateLocation: "LA", county: "East Carroll"},
    {stateLocation: "LA", county: "East Feliciana"},
    {stateLocation: "LA", county: "Evangeline"},
    {stateLocation: "LA", county: "Franklin"},
    {stateLocation: "LA", county: "Grant"},
    {stateLocation: "LA", county: "Iberia"},
    {stateLocation: "LA", county: "Iberville"},
    {stateLocation: "LA", county: "Jackson"},
    {stateLocation: "LA", county: "Jefferson"},
    {stateLocation: "LA", county: "Jefferson Davis"},
    {stateLocation: "LA", county: "Lafayette"},
    {stateLocation: "LA", county: "Lafourche"},
    {stateLocation: "LA", county: "La Salle"},
    {stateLocation: "LA", county: "Lincoln"},
    {stateLocation: "LA", county: "Livingston"},
    {stateLocation: "LA", county: "Madison"},
    {stateLocation: "LA", county: "Morehouse"},
    {stateLocation: "LA", county: "Natchitoches"},
    {stateLocation: "LA", county: "Orleans"},
    {stateLocation: "LA", county: "Ouachita"},
    {stateLocation: "LA", county: "Plaquemines"},
    {stateLocation: "LA", county: "Pointe Coupee"},
    {stateLocation: "LA", county: "Rapides"},
    {stateLocation: "LA", county: "Red River"},
    {stateLocation: "LA", county: "Richland"},
    {stateLocation: "LA", county: "Sabine"},
    {stateLocation: "LA", county: "St. Bernard"},
    {stateLocation: "LA", county: "St. Charles"},
    {stateLocation: "LA", county: "St. Helena"},
    {stateLocation: "LA", county: "St. James"},
    {stateLocation: "LA", county: "St. John the Baptist"},
    {stateLocation: "LA", county: "St. Landry"},
    {stateLocation: "LA", county: "St. Martin"},
    {stateLocation: "LA", county: "St. Mary"},
    {stateLocation: "LA", county: "St. Tammany"},
    {stateLocation: "LA", county: "Tangipahoa"},
    {stateLocation: "LA", county: "Tensas"},
    {stateLocation: "LA", county: "Terrebonne"},
    {stateLocation: "LA", county: "Union"},
    {stateLocation: "LA", county: "Vermilion"},
    {stateLocation: "LA", county: "Vernon"},
    {stateLocation: "LA", county: "Washington"},
    {stateLocation: "LA", county: "Webster"},
    {stateLocation: "LA", county: "West Baton Rouge"},
    {stateLocation: "LA", county: "West Carroll"},
    {stateLocation: "LA", county: "West Feliciana"},
    {stateLocation: "LA", county: "Winn"},
    {stateLocation: "ME", county: "Androscoggin"},
    {stateLocation: "ME", county: "Aroostook"},
    {stateLocation: "ME", county: "Cumberland"},
    {stateLocation: "ME", county: "Franklin"},
    {stateLocation: "ME", county: "Hancock"},
    {stateLocation: "ME", county: "Kennebec"},
    {stateLocation: "ME", county: "Knox"},
    {stateLocation: "ME", county: "Lincoln"},
    {stateLocation: "ME", county: "Oxford"},
    {stateLocation: "ME", county: "Penobscot"},
    {stateLocation: "ME", county: "Piscataquis"},
    {stateLocation: "ME", county: "Sagadahoc"},
    {stateLocation: "ME", county: "Somerset"},
    {stateLocation: "ME", county: "Waldo"},
    {stateLocation: "ME", county: "Washington"},
    {stateLocation: "ME", county: "York"},
    {stateLocation: "MD", county: "Allegany"},
    {stateLocation: "MD", county: "Anne Arundel"},
    {stateLocation: "MD", county: "Baltimore"},
    {stateLocation: "MD", county: "Calvert"},
    {stateLocation: "MD", county: "Caroline"},
    {stateLocation: "MD", county: "Carroll"},
    {stateLocation: "MD", county: "Cecil"},
    {stateLocation: "MD", county: "Charles"},
    {stateLocation: "MD", county: "Dorchester"},
    {stateLocation: "MD", county: "Frederick"},
    {stateLocation: "MD", county: "Garrett"},
    {stateLocation: "MD", county: "Harford"},
    {stateLocation: "MD", county: "Howard"},
    {stateLocation: "MD", county: "Kent"},
    {stateLocation: "MD", county: "Montgomery"},
    {stateLocation: "MD", county: "Prince George's"},
    {stateLocation: "MD", county: "Queen Anne's"},
    {stateLocation: "MD", county: "St. Mary's"},
    {stateLocation: "MD", county: "Somerset"},
    {stateLocation: "MD", county: "Talbot"},
    {stateLocation: "MD", county: "Washington"},
    {stateLocation: "MD", county: "Wicomico"},
    {stateLocation: "MD", county: "Worcester"},
    {stateLocation: "MD", county: "Baltimore city"},
    {stateLocation: "MA", county: "Barnstable"},
    {stateLocation: "MA", county: "Berkshire"},
    {stateLocation: "MA", county: "Bristol"},
    {stateLocation: "MA", county: "Dukes"},
    {stateLocation: "MA", county: "Essex"},
    {stateLocation: "MA", county: "Franklin"},
    {stateLocation: "MA", county: "Hampden"},
    {stateLocation: "MA", county: "Hampshire"},
    {stateLocation: "MA", county: "Middlesex"},
    {stateLocation: "MA", county: "Nantucket"},
    {stateLocation: "MA", county: "Norfolk"},
    {stateLocation: "MA", county: "Plymouth"},
    {stateLocation: "MA", county: "Suffolk"},
    {stateLocation: "MA", county: "Worcester"},
    {stateLocation: "MI", county: "Alcona"},
    {stateLocation: "MI", county: "Alger"},
    {stateLocation: "MI", county: "Allegan"},
    {stateLocation: "MI", county: "Alpena"},
    {stateLocation: "MI", county: "Antrim"},
    {stateLocation: "MI", county: "Arenac"},
    {stateLocation: "MI", county: "Baraga"},
    {stateLocation: "MI", county: "Barry"},
    {stateLocation: "MI", county: "Bay"},
    {stateLocation: "MI", county: "Benzie"},
    {stateLocation: "MI", county: "Berrien"},
    {stateLocation: "MI", county: "Branch"},
    {stateLocation: "MI", county: "Calhoun"},
    {stateLocation: "MI", county: "Cass"},
    {stateLocation: "MI", county: "Charlevoix"},
    {stateLocation: "MI", county: "Cheboygan"},
    {stateLocation: "MI", county: "Chippewa"},
    {stateLocation: "MI", county: "Clare"},
    {stateLocation: "MI", county: "Clinton"},
    {stateLocation: "MI", county: "Crawford"},
    {stateLocation: "MI", county: "Delta"},
    {stateLocation: "MI", county: "Dickinson"},
    {stateLocation: "MI", county: "Eaton"},
    {stateLocation: "MI", county: "Emmet"},
    {stateLocation: "MI", county: "Genesee"},
    {stateLocation: "MI", county: "Gladwin"},
    {stateLocation: "MI", county: "Gogebic"},
    {stateLocation: "MI", county: "Grand Traverse"},
    {stateLocation: "MI", county: "Gratiot"},
    {stateLocation: "MI", county: "Hillsdale"},
    {stateLocation: "MI", county: "Houghton"},
    {stateLocation: "MI", county: "Huron"},
    {stateLocation: "MI", county: "Ingham"},
    {stateLocation: "MI", county: "Ionia"},
    {stateLocation: "MI", county: "Iosco"},
    {stateLocation: "MI", county: "Iron"},
    {stateLocation: "MI", county: "Isabella"},
    {stateLocation: "MI", county: "Jackson"},
    {stateLocation: "MI", county: "Kalamazoo"},
    {stateLocation: "MI", county: "Kalkaska"},
    {stateLocation: "MI", county: "Kent"},
    {stateLocation: "MI", county: "Keweenaw"},
    {stateLocation: "MI", county: "Lake"},
    {stateLocation: "MI", county: "Lapeer"},
    {stateLocation: "MI", county: "Leelanau"},
    {stateLocation: "MI", county: "Lenawee"},
    {stateLocation: "MI", county: "Livingston"},
    {stateLocation: "MI", county: "Luce"},
    {stateLocation: "MI", county: "Mackinac"},
    {stateLocation: "MI", county: "Macomb"},
    {stateLocation: "MI", county: "Manistee"},
    {stateLocation: "MI", county: "Marquette"},
    {stateLocation: "MI", county: "Mason"},
    {stateLocation: "MI", county: "Mecosta"},
    {stateLocation: "MI", county: "Menominee"},
    {stateLocation: "MI", county: "Midland"},
    {stateLocation: "MI", county: "Missaukee"},
    {stateLocation: "MI", county: "Monroe"},
    {stateLocation: "MI", county: "Montcalm"},
    {stateLocation: "MI", county: "Montmorency"},
    {stateLocation: "MI", county: "Muskegon"},
    {stateLocation: "MI", county: "Newaygo"},
    {stateLocation: "MI", county: "Oakland"},
    {stateLocation: "MI", county: "Oceana"},
    {stateLocation: "MI", county: "Ogemaw"},
    {stateLocation: "MI", county: "Ontonagon"},
    {stateLocation: "MI", county: "Osceola"},
    {stateLocation: "MI", county: "Oscoda"},
    {stateLocation: "MI", county: "Otsego"},
    {stateLocation: "MI", county: "Ottawa"},
    {stateLocation: "MI", county: "Presque Isle"},
    {stateLocation: "MI", county: "Roscommon"},
    {stateLocation: "MI", county: "Saginaw"},
    {stateLocation: "MI", county: "St. Clair"},
    {stateLocation: "MI", county: "St. Joseph"},
    {stateLocation: "MI", county: "Sanilac"},
    {stateLocation: "MI", county: "Schoolcraft"},
    {stateLocation: "MI", county: "Shiawassee"},
    {stateLocation: "MI", county: "Tuscola"},
    {stateLocation: "MI", county: "Van Buren"},
    {stateLocation: "MI", county: "Washtenaw"},
    {stateLocation: "MI", county: "Wayne"},
    {stateLocation: "MI", county: "Wexford"},
    {stateLocation: "MN", county: "Aitkin"},
    {stateLocation: "MN", county: "Anoka"},
    {stateLocation: "MN", county: "Becker"},
    {stateLocation: "MN", county: "Beltrami"},
    {stateLocation: "MN", county: "Benton"},
    {stateLocation: "MN", county: "Big Stone"},
    {stateLocation: "MN", county: "Blue Earth"},
    {stateLocation: "MN", county: "Brown"},
    {stateLocation: "MN", county: "Carlton"},
    {stateLocation: "MN", county: "Carver"},
    {stateLocation: "MN", county: "Cass"},
    {stateLocation: "MN", county: "Chippewa"},
    {stateLocation: "MN", county: "Chisago"},
    {stateLocation: "MN", county: "Clay"},
    {stateLocation: "MN", county: "Clearwater"},
    {stateLocation: "MN", county: "Cook"},
    {stateLocation: "MN", county: "Cottonwood"},
    {stateLocation: "MN", county: "Crow Wing"},
    {stateLocation: "MN", county: "Dakota"},
    {stateLocation: "MN", county: "Dodge"},
    {stateLocation: "MN", county: "Douglas"},
    {stateLocation: "MN", county: "Faribault"},
    {stateLocation: "MN", county: "Fillmore"},
    {stateLocation: "MN", county: "Freeborn"},
    {stateLocation: "MN", county: "Goodhue"},
    {stateLocation: "MN", county: "Grant"},
    {stateLocation: "MN", county: "Hennepin"},
    {stateLocation: "MN", county: "Houston"},
    {stateLocation: "MN", county: "Hubbard"},
    {stateLocation: "MN", county: "Isanti"},
    {stateLocation: "MN", county: "Itasca"},
    {stateLocation: "MN", county: "Jackson"},
    {stateLocation: "MN", county: "Kanabec"},
    {stateLocation: "MN", county: "Kandiyohi"},
    {stateLocation: "MN", county: "Kittson"},
    {stateLocation: "MN", county: "Koochiching"},
    {stateLocation: "MN", county: "Lac qui Parle"},
    {stateLocation: "MN", county: "Lake"},
    {stateLocation: "MN", county: "Lake of the Woods"},
    {stateLocation: "MN", county: "Le Sueur"},
    {stateLocation: "MN", county: "Lincoln"},
    {stateLocation: "MN", county: "Lyon"},
    {stateLocation: "MN", county: "McLeod"},
    {stateLocation: "MN", county: "Mahnomen"},
    {stateLocation: "MN", county: "Marshall"},
    {stateLocation: "MN", county: "Martin"},
    {stateLocation: "MN", county: "Meeker"},
    {stateLocation: "MN", county: "Mille Lacs"},
    {stateLocation: "MN", county: "Morrison"},
    {stateLocation: "MN", county: "Mower"},
    {stateLocation: "MN", county: "Murray"},
    {stateLocation: "MN", county: "Nicollet"},
    {stateLocation: "MN", county: "Nobles"},
    {stateLocation: "MN", county: "Norman"},
    {stateLocation: "MN", county: "Olmsted"},
    {stateLocation: "MN", county: "Otter Tail"},
    {stateLocation: "MN", county: "Pennington"},
    {stateLocation: "MN", county: "Pine"},
    {stateLocation: "MN", county: "Pipestone"},
    {stateLocation: "MN", county: "Polk"},
    {stateLocation: "MN", county: "Pope"},
    {stateLocation: "MN", county: "Ramsey"},
    {stateLocation: "MN", county: "Red Lake"},
    {stateLocation: "MN", county: "Redwood"},
    {stateLocation: "MN", county: "Renville"},
    {stateLocation: "MN", county: "Rice"},
    {stateLocation: "MN", county: "Rock"},
    {stateLocation: "MN", county: "Roseau"},
    {stateLocation: "MN", county: "St. Louis"},
    {stateLocation: "MN", county: "Scott"},
    {stateLocation: "MN", county: "Sherburne"},
    {stateLocation: "MN", county: "Sibley"},
    {stateLocation: "MN", county: "Stearns"},
    {stateLocation: "MN", county: "Steele"},
    {stateLocation: "MN", county: "Stevens"},
    {stateLocation: "MN", county: "Swift"},
    {stateLocation: "MN", county: "Todd"},
    {stateLocation: "MN", county: "Traverse"},
    {stateLocation: "MN", county: "Wabasha"},
    {stateLocation: "MN", county: "Wadena"},
    {stateLocation: "MN", county: "Waseca"},
    {stateLocation: "MN", county: "Washington"},
    {stateLocation: "MN", county: "Watonwan"},
    {stateLocation: "MN", county: "Wilkin"},
    {stateLocation: "MN", county: "Winona"},
    {stateLocation: "MN", county: "Wright"},
    {stateLocation: "MN", county: "Yellow Medicine"},
    {stateLocation: "MS", county: "Adams"},
    {stateLocation: "MS", county: "Alcorn"},
    {stateLocation: "MS", county: "Amite"},
    {stateLocation: "MS", county: "Attala"},
    {stateLocation: "MS", county: "Benton"},
    {stateLocation: "MS", county: "Bolivar"},
    {stateLocation: "MS", county: "Calhoun"},
    {stateLocation: "MS", county: "Carroll"},
    {stateLocation: "MS", county: "Chickasaw"},
    {stateLocation: "MS", county: "Choctaw"},
    {stateLocation: "MS", county: "Claiborne"},
    {stateLocation: "MS", county: "Clarke"},
    {stateLocation: "MS", county: "Clay"},
    {stateLocation: "MS", county: "Coahoma"},
    {stateLocation: "MS", county: "Copiah"},
    {stateLocation: "MS", county: "Covington"},
    {stateLocation: "MS", county: "DeSoto"},
    {stateLocation: "MS", county: "Forrest"},
    {stateLocation: "MS", county: "Franklin"},
    {stateLocation: "MS", county: "George"},
    {stateLocation: "MS", county: "Greene"},
    {stateLocation: "MS", county: "Grenada"},
    {stateLocation: "MS", county: "Hancock"},
    {stateLocation: "MS", county: "Harrison"},
    {stateLocation: "MS", county: "Hinds"},
    {stateLocation: "MS", county: "Holmes"},
    {stateLocation: "MS", county: "Humphreys"},
    {stateLocation: "MS", county: "Issaquena"},
    {stateLocation: "MS", county: "Itawamba"},
    {stateLocation: "MS", county: "Jackson"},
    {stateLocation: "MS", county: "Jasper"},
    {stateLocation: "MS", county: "Jefferson"},
    {stateLocation: "MS", county: "Jefferson Davis"},
    {stateLocation: "MS", county: "Jones"},
    {stateLocation: "MS", county: "Kemper"},
    {stateLocation: "MS", county: "Lafayette"},
    {stateLocation: "MS", county: "Lamar"},
    {stateLocation: "MS", county: "Lauderdale"},
    {stateLocation: "MS", county: "Lawrence"},
    {stateLocation: "MS", county: "Leake"},
    {stateLocation: "MS", county: "Lee"},
    {stateLocation: "MS", county: "Leflore"},
    {stateLocation: "MS", county: "Lincoln"},
    {stateLocation: "MS", county: "Lowndes"},
    {stateLocation: "MS", county: "Madison"},
    {stateLocation: "MS", county: "Marion"},
    {stateLocation: "MS", county: "Marshall"},
    {stateLocation: "MS", county: "Monroe"},
    {stateLocation: "MS", county: "Montgomery"},
    {stateLocation: "MS", county: "Neshoba"},
    {stateLocation: "MS", county: "Newton"},
    {stateLocation: "MS", county: "Noxubee"},
    {stateLocation: "MS", county: "Oktibbeha"},
    {stateLocation: "MS", county: "Panola"},
    {stateLocation: "MS", county: "Pearl River"},
    {stateLocation: "MS", county: "Perry"},
    {stateLocation: "MS", county: "Pike"},
    {stateLocation: "MS", county: "Pontotoc"},
    {stateLocation: "MS", county: "Prentiss"},
    {stateLocation: "MS", county: "Quitman"},
    {stateLocation: "MS", county: "Rankin"},
    {stateLocation: "MS", county: "Scott"},
    {stateLocation: "MS", county: "Sharkey"},
    {stateLocation: "MS", county: "Simpson"},
    {stateLocation: "MS", county: "Smith"},
    {stateLocation: "MS", county: "Stone"},
    {stateLocation: "MS", county: "Sunflower"},
    {stateLocation: "MS", county: "Tallahatchie"},
    {stateLocation: "MS", county: "Tate"},
    {stateLocation: "MS", county: "Tippah"},
    {stateLocation: "MS", county: "Tishomingo"},
    {stateLocation: "MS", county: "Tunica"},
    {stateLocation: "MS", county: "Union"},
    {stateLocation: "MS", county: "Walthall"},
    {stateLocation: "MS", county: "Warren"},
    {stateLocation: "MS", county: "Washington"},
    {stateLocation: "MS", county: "Wayne"},
    {stateLocation: "MS", county: "Webster"},
    {stateLocation: "MS", county: "Wilkinson"},
    {stateLocation: "MS", county: "Winston"},
    {stateLocation: "MS", county: "Yalobusha"},
    {stateLocation: "MS", county: "Yazoo"},
    {stateLocation: "MO", county: "Adair"},
    {stateLocation: "MO", county: "Andrew"},
    {stateLocation: "MO", county: "Atchison"},
    {stateLocation: "MO", county: "Audrain"},
    {stateLocation: "MO", county: "Barry"},
    {stateLocation: "MO", county: "Barton"},
    {stateLocation: "MO", county: "Bates"},
    {stateLocation: "MO", county: "Benton"},
    {stateLocation: "MO", county: "Bollinger"},
    {stateLocation: "MO", county: "Boone"},
    {stateLocation: "MO", county: "Buchanan"},
    {stateLocation: "MO", county: "Butler"},
    {stateLocation: "MO", county: "Caldwell"},
    {stateLocation: "MO", county: "Callaway"},
    {stateLocation: "MO", county: "Camden"},
    {stateLocation: "MO", county: "Cape Girardeau"},
    {stateLocation: "MO", county: "Carroll"},
    {stateLocation: "MO", county: "Carter"},
    {stateLocation: "MO", county: "Cass"},
    {stateLocation: "MO", county: "Cedar"},
    {stateLocation: "MO", county: "Chariton"},
    {stateLocation: "MO", county: "Christian"},
    {stateLocation: "MO", county: "Clark"},
    {stateLocation: "MO", county: "Clay"},
    {stateLocation: "MO", county: "Clinton"},
    {stateLocation: "MO", county: "Cole"},
    {stateLocation: "MO", county: "Cooper"},
    {stateLocation: "MO", county: "Crawford"},
    {stateLocation: "MO", county: "Dade"},
    {stateLocation: "MO", county: "Dallas"},
    {stateLocation: "MO", county: "Daviess"},
    {stateLocation: "MO", county: "DeKalb"},
    {stateLocation: "MO", county: "Dent"},
    {stateLocation: "MO", county: "Douglas"},
    {stateLocation: "MO", county: "Dunklin"},
    {stateLocation: "MO", county: "Franklin"},
    {stateLocation: "MO", county: "Gasconade"},
    {stateLocation: "MO", county: "Gentry"},
    {stateLocation: "MO", county: "Greene"},
    {stateLocation: "MO", county: "Grundy"},
    {stateLocation: "MO", county: "Harrison"},
    {stateLocation: "MO", county: "Henry"},
    {stateLocation: "MO", county: "Hickory"},
    {stateLocation: "MO", county: "Holt"},
    {stateLocation: "MO", county: "Howard"},
    {stateLocation: "MO", county: "Howell"},
    {stateLocation: "MO", county: "Iron"},
    {stateLocation: "MO", county: "Jackson"},
    {stateLocation: "MO", county: "Jasper"},
    {stateLocation: "MO", county: "Jefferson"},
    {stateLocation: "MO", county: "Johnson"},
    {stateLocation: "MO", county: "Knox"},
    {stateLocation: "MO", county: "Laclede"},
    {stateLocation: "MO", county: "Lafayette"},
    {stateLocation: "MO", county: "Lawrence"},
    {stateLocation: "MO", county: "Lewis"},
    {stateLocation: "MO", county: "Lincoln"},
    {stateLocation: "MO", county: "Linn"},
    {stateLocation: "MO", county: "Livingston"},
    {stateLocation: "MO", county: "McDonald"},
    {stateLocation: "MO", county: "Macon"},
    {stateLocation: "MO", county: "Madison"},
    {stateLocation: "MO", county: "Maries"},
    {stateLocation: "MO", county: "Marion"},
    {stateLocation: "MO", county: "Mercer"},
    {stateLocation: "MO", county: "Miller"},
    {stateLocation: "MO", county: "Mississippi"},
    {stateLocation: "MO", county: "Moniteau"},
    {stateLocation: "MO", county: "Monroe"},
    {stateLocation: "MO", county: "Montgomery"},
    {stateLocation: "MO", county: "Morgan"},
    {stateLocation: "MO", county: "New Madrid"},
    {stateLocation: "MO", county: "Newton"},
    {stateLocation: "MO", county: "Nodaway"},
    {stateLocation: "MO", county: "Oregon"},
    {stateLocation: "MO", county: "Osage"},
    {stateLocation: "MO", county: "Ozark"},
    {stateLocation: "MO", county: "Pemiscot"},
    {stateLocation: "MO", county: "Perry"},
    {stateLocation: "MO", county: "Pettis"},
    {stateLocation: "MO", county: "Phelps"},
    {stateLocation: "MO", county: "Pike"},
    {stateLocation: "MO", county: "Platte"},
    {stateLocation: "MO", county: "Polk"},
    {stateLocation: "MO", county: "Pulaski"},
    {stateLocation: "MO", county: "Putnam"},
    {stateLocation: "MO", county: "Ralls"},
    {stateLocation: "MO", county: "Randolph"},
    {stateLocation: "MO", county: "Ray"},
    {stateLocation: "MO", county: "Reynolds"},
    {stateLocation: "MO", county: "Ripley"},
    {stateLocation: "MO", county: "St. Charles"},
    {stateLocation: "MO", county: "St. Clair"},
    {stateLocation: "MO", county: "Ste. Genevieve"},
    {stateLocation: "MO", county: "St. Francois"},
    {stateLocation: "MO", county: "St. Louis"},
    {stateLocation: "MO", county: "Saline"},
    {stateLocation: "MO", county: "Schuyler"},
    {stateLocation: "MO", county: "Scotland"},
    {stateLocation: "MO", county: "Scott"},
    {stateLocation: "MO", county: "Shannon"},
    {stateLocation: "MO", county: "Shelby"},
    {stateLocation: "MO", county: "Stoddard"},
    {stateLocation: "MO", county: "Stone"},
    {stateLocation: "MO", county: "Sullivan"},
    {stateLocation: "MO", county: "Taney"},
    {stateLocation: "MO", county: "Texas"},
    {stateLocation: "MO", county: "Vernon"},
    {stateLocation: "MO", county: "Warren"},
    {stateLocation: "MO", county: "Washington"},
    {stateLocation: "MO", county: "Wayne"},
    {stateLocation: "MO", county: "Webster"},
    {stateLocation: "MO", county: "Worth"},
    {stateLocation: "MO", county: "Wright"},
    {stateLocation: "MO", county: "St. Louis city"},
    {stateLocation: "MT", county: "Beaverhead"},
    {stateLocation: "MT", county: "Big Horn"},
    {stateLocation: "MT", county: "Blaine"},
    {stateLocation: "MT", county: "Broadwater"},
    {stateLocation: "MT", county: "Carbon"},
    {stateLocation: "MT", county: "Carter"},
    {stateLocation: "MT", county: "Cascade"},
    {stateLocation: "MT", county: "Chouteau"},
    {stateLocation: "MT", county: "Custer"},
    {stateLocation: "MT", county: "Daniels"},
    {stateLocation: "MT", county: "Dawson"},
    {stateLocation: "MT", county: "Deer Lodge"},
    {stateLocation: "MT", county: "Fallon"},
    {stateLocation: "MT", county: "Fergus"},
    {stateLocation: "MT", county: "Flathead"},
    {stateLocation: "MT", county: "Gallatin"},
    {stateLocation: "MT", county: "Garfield"},
    {stateLocation: "MT", county: "Glacier"},
    {stateLocation: "MT", county: "Golden Valley"},
    {stateLocation: "MT", county: "Granite"},
    {stateLocation: "MT", county: "Hill"},
    {stateLocation: "MT", county: "Jefferson"},
    {stateLocation: "MT", county: "Judith Basin"},
    {stateLocation: "MT", county: "Lake"},
    {stateLocation: "MT", county: "Lewis and Clark"},
    {stateLocation: "MT", county: "Liberty"},
    {stateLocation: "MT", county: "Lincoln"},
    {stateLocation: "MT", county: "McCone"},
    {stateLocation: "MT", county: "Madison"},
    {stateLocation: "MT", county: "Meagher"},
    {stateLocation: "MT", county: "Mineral"},
    {stateLocation: "MT", county: "Missoula"},
    {stateLocation: "MT", county: "Musselshell"},
    {stateLocation: "MT", county: "Park"},
    {stateLocation: "MT", county: "Petroleum"},
    {stateLocation: "MT", county: "Phillips"},
    {stateLocation: "MT", county: "Pondera"},
    {stateLocation: "MT", county: "Powder River"},
    {stateLocation: "MT", county: "Powell"},
    {stateLocation: "MT", county: "Prairie"},
    {stateLocation: "MT", county: "Ravalli"},
    {stateLocation: "MT", county: "Richland"},
    {stateLocation: "MT", county: "Roosevelt"},
    {stateLocation: "MT", county: "Rosebud"},
    {stateLocation: "MT", county: "Sanders"},
    {stateLocation: "MT", county: "Sheridan"},
    {stateLocation: "MT", county: "Silver Bow"},
    {stateLocation: "MT", county: "Stillwater"},
    {stateLocation: "MT", county: "Sweet Grass"},
    {stateLocation: "MT", county: "Teton"},
    {stateLocation: "MT", county: "Toole"},
    {stateLocation: "MT", county: "Treasure"},
    {stateLocation: "MT", county: "Valley"},
    {stateLocation: "MT", county: "Wheatland"},
    {stateLocation: "MT", county: "Wibaux"},
    {stateLocation: "MT", county: "Yellowstone"},
    {stateLocation: "NE", county: "Adams"},
    {stateLocation: "NE", county: "Antelope"},
    {stateLocation: "NE", county: "Arthur"},
    {stateLocation: "NE", county: "Banner"},
    {stateLocation: "NE", county: "Blaine"},
    {stateLocation: "NE", county: "Boone"},
    {stateLocation: "NE", county: "Box Butte"},
    {stateLocation: "NE", county: "Boyd"},
    {stateLocation: "NE", county: "Brown"},
    {stateLocation: "NE", county: "Buffalo"},
    {stateLocation: "NE", county: "Burt"},
    {stateLocation: "NE", county: "Butler"},
    {stateLocation: "NE", county: "Cass"},
    {stateLocation: "NE", county: "Cedar"},
    {stateLocation: "NE", county: "Chase"},
    {stateLocation: "NE", county: "Cherry"},
    {stateLocation: "NE", county: "Cheyenne"},
    {stateLocation: "NE", county: "Clay"},
    {stateLocation: "NE", county: "Colfax"},
    {stateLocation: "NE", county: "Cuming"},
    {stateLocation: "NE", county: "Custer"},
    {stateLocation: "NE", county: "Dakota"},
    {stateLocation: "NE", county: "Dawes"},
    {stateLocation: "NE", county: "Dawson"},
    {stateLocation: "NE", county: "Deuel"},
    {stateLocation: "NE", county: "Dixon"},
    {stateLocation: "NE", county: "Dodge"},
    {stateLocation: "NE", county: "Douglas"},
    {stateLocation: "NE", county: "Dundy"},
    {stateLocation: "NE", county: "Fillmore"},
    {stateLocation: "NE", county: "Franklin"},
    {stateLocation: "NE", county: "Frontier"},
    {stateLocation: "NE", county: "Furnas"},
    {stateLocation: "NE", county: "Gage"},
    {stateLocation: "NE", county: "Garden"},
    {stateLocation: "NE", county: "Garfield"},
    {stateLocation: "NE", county: "Gosper"},
    {stateLocation: "NE", county: "Grant"},
    {stateLocation: "NE", county: "Greeley"},
    {stateLocation: "NE", county: "Hall"},
    {stateLocation: "NE", county: "Hamilton"},
    {stateLocation: "NE", county: "Harlan"},
    {stateLocation: "NE", county: "Hayes"},
    {stateLocation: "NE", county: "Hitchcock"},
    {stateLocation: "NE", county: "Holt"},
    {stateLocation: "NE", county: "Hooker"},
    {stateLocation: "NE", county: "Howard"},
    {stateLocation: "NE", county: "Jefferson"},
    {stateLocation: "NE", county: "Johnson"},
    {stateLocation: "NE", county: "Kearney"},
    {stateLocation: "NE", county: "Keith"},
    {stateLocation: "NE", county: "Keya Paha"},
    {stateLocation: "NE", county: "Kimball"},
    {stateLocation: "NE", county: "Knox"},
    {stateLocation: "NE", county: "Lancaster"},
    {stateLocation: "NE", county: "Lincoln"},
    {stateLocation: "NE", county: "Logan"},
    {stateLocation: "NE", county: "Loup"},
    {stateLocation: "NE", county: "McPherson"},
    {stateLocation: "NE", county: "Madison"},
    {stateLocation: "NE", county: "Merrick"},
    {stateLocation: "NE", county: "Morrill"},
    {stateLocation: "NE", county: "Nance"},
    {stateLocation: "NE", county: "Nemaha"},
    {stateLocation: "NE", county: "Nuckolls"},
    {stateLocation: "NE", county: "Otoe"},
    {stateLocation: "NE", county: "Pawnee"},
    {stateLocation: "NE", county: "Perkins"},
    {stateLocation: "NE", county: "Phelps"},
    {stateLocation: "NE", county: "Pierce"},
    {stateLocation: "NE", county: "Platte"},
    {stateLocation: "NE", county: "Polk"},
    {stateLocation: "NE", county: "Red Willow"},
    {stateLocation: "NE", county: "Richardson"},
    {stateLocation: "NE", county: "Rock"},
    {stateLocation: "NE", county: "Saline"},
    {stateLocation: "NE", county: "Sarpy"},
    {stateLocation: "NE", county: "Saunders"},
    {stateLocation: "NE", county: "Scotts Bluff"},
    {stateLocation: "NE", county: "Seward"},
    {stateLocation: "NE", county: "Sheridan"},
    {stateLocation: "NE", county: "Sherman"},
    {stateLocation: "NE", county: "Sioux"},
    {stateLocation: "NE", county: "Stanton"},
    {stateLocation: "NE", county: "Thayer"},
    {stateLocation: "NE", county: "Thomas"},
    {stateLocation: "NE", county: "Thurston"},
    {stateLocation: "NE", county: "Valley"},
    {stateLocation: "NE", county: "Washington"},
    {stateLocation: "NE", county: "Wayne"},
    {stateLocation: "NE", county: "Webster"},
    {stateLocation: "NE", county: "Wheeler"},
    {stateLocation: "NE", county: "York"},
    {stateLocation: "NV", county: "Churchill"},
    {stateLocation: "NV", county: "Clark"},
    {stateLocation: "NV", county: "Douglas"},
    {stateLocation: "NV", county: "Elko"},
    {stateLocation: "NV", county: "Esmeralda"},
    {stateLocation: "NV", county: "Eureka"},
    {stateLocation: "NV", county: "Humboldt"},
    {stateLocation: "NV", county: "Lander"},
    {stateLocation: "NV", county: "Lincoln"},
    {stateLocation: "NV", county: "Lyon"},
    {stateLocation: "NV", county: "Mineral"},
    {stateLocation: "NV", county: "Nye"},
    {stateLocation: "NV", county: "Pershing"},
    {stateLocation: "NV", county: "Storey"},
    {stateLocation: "NV", county: "Washoe"},
    {stateLocation: "NV", county: "White Pine"},
    {stateLocation: "NV", county: "Carson City"},
    {stateLocation: "NH", county: "Belknap"},
    {stateLocation: "NH", county: "Carroll"},
    {stateLocation: "NH", county: "Cheshire"},
    {stateLocation: "NH", county: "Coos"},
    {stateLocation: "NH", county: "Grafton"},
    {stateLocation: "NH", county: "Hillsborough"},
    {stateLocation: "NH", county: "Merrimack"},
    {stateLocation: "NH", county: "Rockingham"},
    {stateLocation: "NH", county: "Strafford"},
    {stateLocation: "NH", county: "Sullivan"},
    {stateLocation: "NJ", county: "Atlantic"},
    {stateLocation: "NJ", county: "Bergen"},
    {stateLocation: "NJ", county: "Burlington"},
    {stateLocation: "NJ", county: "Camden"},
    {stateLocation: "NJ", county: "Cape May"},
    {stateLocation: "NJ", county: "Cumberland"},
    {stateLocation: "NJ", county: "Essex"},
    {stateLocation: "NJ", county: "Gloucester"},
    {stateLocation: "NJ", county: "Hudson"},
    {stateLocation: "NJ", county: "Hunterdon"},
    {stateLocation: "NJ", county: "Mercer"},
    {stateLocation: "NJ", county: "Middlesex"},
    {stateLocation: "NJ", county: "Monmouth"},
    {stateLocation: "NJ", county: "Morris"},
    {stateLocation: "NJ", county: "Ocean"},
    {stateLocation: "NJ", county: "Passaic"},
    {stateLocation: "NJ", county: "Salem"},
    {stateLocation: "NJ", county: "Somerset"},
    {stateLocation: "NJ", county: "Sussex"},
    {stateLocation: "NJ", county: "Union"},
    {stateLocation: "NJ", county: "Warren"},
    {stateLocation: "NM", county: "Bernalillo"},
    {stateLocation: "NM", county: "Catron"},
    {stateLocation: "NM", county: "Chaves"},
    {stateLocation: "NM", county: "Cibola"},
    {stateLocation: "NM", county: "Colfax"},
    {stateLocation: "NM", county: "Curry"},
    {stateLocation: "NM", county: "De Baca"},
    {stateLocation: "NM", county: "Dona Ana"},
    {stateLocation: "NM", county: "Eddy"},
    {stateLocation: "NM", county: "Grant"},
    {stateLocation: "NM", county: "Guadalupe"},
    {stateLocation: "NM", county: "Harding"},
    {stateLocation: "NM", county: "Hidalgo"},
    {stateLocation: "NM", county: "Lea"},
    {stateLocation: "NM", county: "Lincoln"},
    {stateLocation: "NM", county: "Los Alamos"},
    {stateLocation: "NM", county: "Luna"},
    {stateLocation: "NM", county: "McKinley"},
    {stateLocation: "NM", county: "Mora"},
    {stateLocation: "NM", county: "Otero"},
    {stateLocation: "NM", county: "Quay"},
    {stateLocation: "NM", county: "Rio Arriba"},
    {stateLocation: "NM", county: "Roosevelt"},
    {stateLocation: "NM", county: "Sandoval"},
    {stateLocation: "NM", county: "San Juan"},
    {stateLocation: "NM", county: "San Miguel"},
    {stateLocation: "NM", county: "Santa Fe"},
    {stateLocation: "NM", county: "Sierra"},
    {stateLocation: "NM", county: "Socorro"},
    {stateLocation: "NM", county: "Taos"},
    {stateLocation: "NM", county: "Torrance"},
    {stateLocation: "NM", county: "Union"},
    {stateLocation: "NM", county: "Valencia"},
    {stateLocation: "NY", county: "Albany"},
    {stateLocation: "NY", county: "Allegany"},
    {stateLocation: "NY", county: "Bronx"},
    {stateLocation: "NY", county: "Broome"},
    {stateLocation: "NY", county: "Cattaraugus"},
    {stateLocation: "NY", county: "Cayuga"},
    {stateLocation: "NY", county: "Chautauqua"},
    {stateLocation: "NY", county: "Chemung"},
    {stateLocation: "NY", county: "Chenango"},
    {stateLocation: "NY", county: "Clinton"},
    {stateLocation: "NY", county: "Columbia"},
    {stateLocation: "NY", county: "Cortland"},
    {stateLocation: "NY", county: "Delaware"},
    {stateLocation: "NY", county: "Dutchess"},
    {stateLocation: "NY", county: "Erie"},
    {stateLocation: "NY", county: "Essex"},
    {stateLocation: "NY", county: "Franklin"},
    {stateLocation: "NY", county: "Fulton"},
    {stateLocation: "NY", county: "Genesee"},
    {stateLocation: "NY", county: "Greene"},
    {stateLocation: "NY", county: "Hamilton"},
    {stateLocation: "NY", county: "Herkimer"},
    {stateLocation: "NY", county: "Jefferson"},
    {stateLocation: "NY", county: "Kings"},
    {stateLocation: "NY", county: "Lewis"},
    {stateLocation: "NY", county: "Livingston"},
    {stateLocation: "NY", county: "Madison"},
    {stateLocation: "NY", county: "Monroe"},
    {stateLocation: "NY", county: "Montgomery"},
    {stateLocation: "NY", county: "Nassau"},
    {stateLocation: "NY", county: "New York"},
    {stateLocation: "NY", county: "Niagara"},
    {stateLocation: "NY", county: "Oneida"},
    {stateLocation: "NY", county: "Onondaga"},
    {stateLocation: "NY", county: "Ontario"},
    {stateLocation: "NY", county: "Orange"},
    {stateLocation: "NY", county: "Orleans"},
    {stateLocation: "NY", county: "Oswego"},
    {stateLocation: "NY", county: "Otsego"},
    {stateLocation: "NY", county: "Putnam"},
    {stateLocation: "NY", county: "Queens"},
    {stateLocation: "NY", county: "Rensselaer"},
    {stateLocation: "NY", county: "Richmond"},
    {stateLocation: "NY", county: "Rockland"},
    {stateLocation: "NY", county: "St. Lawrence"},
    {stateLocation: "NY", county: "Saratoga"},
    {stateLocation: "NY", county: "Schenectady"},
    {stateLocation: "NY", county: "Schoharie"},
    {stateLocation: "NY", county: "Schuyler"},
    {stateLocation: "NY", county: "Seneca"},
    {stateLocation: "NY", county: "Steuben"},
    {stateLocation: "NY", county: "Suffolk"},
    {stateLocation: "NY", county: "Sullivan"},
    {stateLocation: "NY", county: "Tioga"},
    {stateLocation: "NY", county: "Tompkins"},
    {stateLocation: "NY", county: "Ulster"},
    {stateLocation: "NY", county: "Warren"},
    {stateLocation: "NY", county: "Washington"},
    {stateLocation: "NY", county: "Wayne"},
    {stateLocation: "NY", county: "Westchester"},
    {stateLocation: "NY", county: "Wyoming"},
    {stateLocation: "NY", county: "Yates"},
    {stateLocation: "NC", county: "Alamance"},
    {stateLocation: "NC", county: "Alexander"},
    {stateLocation: "NC", county: "Alleghany"},
    {stateLocation: "NC", county: "Anson"},
    {stateLocation: "NC", county: "Ashe"},
    {stateLocation: "NC", county: "Avery"},
    {stateLocation: "NC", county: "Beaufort"},
    {stateLocation: "NC", county: "Bertie"},
    {stateLocation: "NC", county: "Bladen"},
    {stateLocation: "NC", county: "Brunswick"},
    {stateLocation: "NC", county: "Buncombe"},
    {stateLocation: "NC", county: "Burke"},
    {stateLocation: "NC", county: "Cabarrus"},
    {stateLocation: "NC", county: "Caldwell"},
    {stateLocation: "NC", county: "Camden"},
    {stateLocation: "NC", county: "Carteret"},
    {stateLocation: "NC", county: "Caswell"},
    {stateLocation: "NC", county: "Catawba"},
    {stateLocation: "NC", county: "Chatham"},
    {stateLocation: "NC", county: "Cherokee"},
    {stateLocation: "NC", county: "Chowan"},
    {stateLocation: "NC", county: "Clay"},
    {stateLocation: "NC", county: "Cleveland"},
    {stateLocation: "NC", county: "Columbus"},
    {stateLocation: "NC", county: "Craven"},
    {stateLocation: "NC", county: "Cumberland"},
    {stateLocation: "NC", county: "Currituck"},
    {stateLocation: "NC", county: "Dare"},
    {stateLocation: "NC", county: "Davidson"},
    {stateLocation: "NC", county: "Davie"},
    {stateLocation: "NC", county: "Duplin"},
    {stateLocation: "NC", county: "Durham"},
    {stateLocation: "NC", county: "Edgecombe"},
    {stateLocation: "NC", county: "Forsyth"},
    {stateLocation: "NC", county: "Franklin"},
    {stateLocation: "NC", county: "Gaston"},
    {stateLocation: "NC", county: "Gates"},
    {stateLocation: "NC", county: "Graham"},
    {stateLocation: "NC", county: "Granville"},
    {stateLocation: "NC", county: "Greene"},
    {stateLocation: "NC", county: "Guilford"},
    {stateLocation: "NC", county: "Halifax"},
    {stateLocation: "NC", county: "Harnett"},
    {stateLocation: "NC", county: "Haywood"},
    {stateLocation: "NC", county: "Henderson"},
    {stateLocation: "NC", county: "Hertford"},
    {stateLocation: "NC", county: "Hoke"},
    {stateLocation: "NC", county: "Hyde"},
    {stateLocation: "NC", county: "Iredell"},
    {stateLocation: "NC", county: "Jackson"},
    {stateLocation: "NC", county: "Johnston"},
    {stateLocation: "NC", county: "Jones"},
    {stateLocation: "NC", county: "Lee"},
    {stateLocation: "NC", county: "Lenoir"},
    {stateLocation: "NC", county: "Lincoln"},
    {stateLocation: "NC", county: "McDowell"},
    {stateLocation: "NC", county: "Macon"},
    {stateLocation: "NC", county: "Madison"},
    {stateLocation: "NC", county: "Martin"},
    {stateLocation: "NC", county: "Mecklenburg"},
    {stateLocation: "NC", county: "Mitchell"},
    {stateLocation: "NC", county: "Montgomery"},
    {stateLocation: "NC", county: "Moore"},
    {stateLocation: "NC", county: "Nash"},
    {stateLocation: "NC", county: "New Hanover"},
    {stateLocation: "NC", county: "Northampton"},
    {stateLocation: "NC", county: "Onslow"},
    {stateLocation: "NC", county: "Orange"},
    {stateLocation: "NC", county: "Pamlico"},
    {stateLocation: "NC", county: "Pasquotank"},
    {stateLocation: "NC", county: "Pender"},
    {stateLocation: "NC", county: "Perquimans"},
    {stateLocation: "NC", county: "Person"},
    {stateLocation: "NC", county: "Pitt"},
    {stateLocation: "NC", county: "Polk"},
    {stateLocation: "NC", county: "Randolph"},
    {stateLocation: "NC", county: "Richmond"},
    {stateLocation: "NC", county: "Robeson"},
    {stateLocation: "NC", county: "Rockingham"},
    {stateLocation: "NC", county: "Rowan"},
    {stateLocation: "NC", county: "Rutherford"},
    {stateLocation: "NC", county: "Sampson"},
    {stateLocation: "NC", county: "Scotland"},
    {stateLocation: "NC", county: "Stanly"},
    {stateLocation: "NC", county: "Stokes"},
    {stateLocation: "NC", county: "Surry"},
    {stateLocation: "NC", county: "Swain"},
    {stateLocation: "NC", county: "Transylvania"},
    {stateLocation: "NC", county: "Tyrrell"},
    {stateLocation: "NC", county: "Union"},
    {stateLocation: "NC", county: "Vance"},
    {stateLocation: "NC", county: "Wake"},
    {stateLocation: "NC", county: "Warren"},
    {stateLocation: "NC", county: "Washington"},
    {stateLocation: "NC", county: "Watauga"},
    {stateLocation: "NC", county: "Wayne"},
    {stateLocation: "NC", county: "Wilkes"},
    {stateLocation: "NC", county: "Wilson"},
    {stateLocation: "NC", county: "Yadkin"},
    {stateLocation: "NC", county: "Yancey"},
    {stateLocation: "ND", county: "Adams"},
    {stateLocation: "ND", county: "Barnes"},
    {stateLocation: "ND", county: "Benson"},
    {stateLocation: "ND", county: "Billings"},
    {stateLocation: "ND", county: "Bottineau"},
    {stateLocation: "ND", county: "Bowman"},
    {stateLocation: "ND", county: "Burke"},
    {stateLocation: "ND", county: "Burleigh"},
    {stateLocation: "ND", county: "Cass"},
    {stateLocation: "ND", county: "Cavalier"},
    {stateLocation: "ND", county: "Dickey"},
    {stateLocation: "ND", county: "Divide"},
    {stateLocation: "ND", county: "Dunn"},
    {stateLocation: "ND", county: "Eddy"},
    {stateLocation: "ND", county: "Emmons"},
    {stateLocation: "ND", county: "Foster"},
    {stateLocation: "ND", county: "Golden Valley"},
    {stateLocation: "ND", county: "Grand Forks"},
    {stateLocation: "ND", county: "Grant"},
    {stateLocation: "ND", county: "Griggs"},
    {stateLocation: "ND", county: "Hettinger"},
    {stateLocation: "ND", county: "Kidder"},
    {stateLocation: "ND", county: "LaMoure"},
    {stateLocation: "ND", county: "Logan"},
    {stateLocation: "ND", county: "McHenry"},
    {stateLocation: "ND", county: "McIntosh"},
    {stateLocation: "ND", county: "McKenzie"},
    {stateLocation: "ND", county: "McLean"},
    {stateLocation: "ND", county: "Mercer"},
    {stateLocation: "ND", county: "Morton"},
    {stateLocation: "ND", county: "Mountrail"},
    {stateLocation: "ND", county: "Nelson"},
    {stateLocation: "ND", county: "Oliver"},
    {stateLocation: "ND", county: "Pembina"},
    {stateLocation: "ND", county: "Pierce"},
    {stateLocation: "ND", county: "Ramsey"},
    {stateLocation: "ND", county: "Ransom"},
    {stateLocation: "ND", county: "Renville"},
    {stateLocation: "ND", county: "Richland"},
    {stateLocation: "ND", county: "Rolette"},
    {stateLocation: "ND", county: "Sargent"},
    {stateLocation: "ND", county: "Sheridan"},
    {stateLocation: "ND", county: "Sioux"},
    {stateLocation: "ND", county: "Slope"},
    {stateLocation: "ND", county: "Stark"},
    {stateLocation: "ND", county: "Steele"},
    {stateLocation: "ND", county: "Stutsman"},
    {stateLocation: "ND", county: "Towner"},
    {stateLocation: "ND", county: "Traill"},
    {stateLocation: "ND", county: "Walsh"},
    {stateLocation: "ND", county: "Ward"},
    {stateLocation: "ND", county: "Wells"},
    {stateLocation: "ND", county: "Williams"},
    {stateLocation: 'MP', county: 'Northern Mariana Islands' },
    {stateLocation: "OH", county: "Adams"},
    {stateLocation: "OH", county: "Allen"},
    {stateLocation: "OH", county: "Ashland"},
    {stateLocation: "OH", county: "Ashtabula"},
    {stateLocation: "OH", county: "Athens"},
    {stateLocation: "OH", county: "Auglaize"},
    {stateLocation: "OH", county: "Belmont"},
    {stateLocation: "OH", county: "Brown"},
    {stateLocation: "OH", county: "Butler"},
    {stateLocation: "OH", county: "Carroll"},
    {stateLocation: "OH", county: "Champaign"},
    {stateLocation: "OH", county: "Clark"},
    {stateLocation: "OH", county: "Clermont"},
    {stateLocation: "OH", county: "Clinton"},
    {stateLocation: "OH", county: "Columbiana"},
    {stateLocation: "OH", county: "Coshocton"},
    {stateLocation: "OH", county: "Crawford"},
    {stateLocation: "OH", county: "Cuyahoga"},
    {stateLocation: "OH", county: "Darke"},
    {stateLocation: "OH", county: "Defiance"},
    {stateLocation: "OH", county: "Delaware"},
    {stateLocation: "OH", county: "Erie"},
    {stateLocation: "OH", county: "Fairfield"},
    {stateLocation: "OH", county: "Fayette"},
    {stateLocation: "OH", county: "Franklin"},
    {stateLocation: "OH", county: "Fulton"},
    {stateLocation: "OH", county: "Gallia"},
    {stateLocation: "OH", county: "Geauga"},
    {stateLocation: "OH", county: "Greene"},
    {stateLocation: "OH", county: "Guernsey"},
    {stateLocation: "OH", county: "Hamilton"},
    {stateLocation: "OH", county: "Hancock"},
    {stateLocation: "OH", county: "Hardin"},
    {stateLocation: "OH", county: "Harrison"},
    {stateLocation: "OH", county: "Henry"},
    {stateLocation: "OH", county: "Highland"},
    {stateLocation: "OH", county: "Hocking"},
    {stateLocation: "OH", county: "Holmes"},
    {stateLocation: "OH", county: "Huron"},
    {stateLocation: "OH", county: "Jackson"},
    {stateLocation: "OH", county: "Jefferson"},
    {stateLocation: "OH", county: "Knox"},
    {stateLocation: "OH", county: "Lake"},
    {stateLocation: "OH", county: "Lawrence"},
    {stateLocation: "OH", county: "Licking"},
    {stateLocation: "OH", county: "Logan"},
    {stateLocation: "OH", county: "Lorain"},
    {stateLocation: "OH", county: "Lucas"},
    {stateLocation: "OH", county: "Madison"},
    {stateLocation: "OH", county: "Mahoning"},
    {stateLocation: "OH", county: "Marion"},
    {stateLocation: "OH", county: "Medina"},
    {stateLocation: "OH", county: "Meigs"},
    {stateLocation: "OH", county: "Mercer"},
    {stateLocation: "OH", county: "Miami"},
    {stateLocation: "OH", county: "Monroe"},
    {stateLocation: "OH", county: "Montgomery"},
    {stateLocation: "OH", county: "Morgan"},
    {stateLocation: "OH", county: "Morrow"},
    {stateLocation: "OH", county: "Muskingum"},
    {stateLocation: "OH", county: "Noble"},
    {stateLocation: "OH", county: "Ottawa"},
    {stateLocation: "OH", county: "Paulding"},
    {stateLocation: "OH", county: "Perry"},
    {stateLocation: "OH", county: "Pickaway"},
    {stateLocation: "OH", county: "Pike"},
    {stateLocation: "OH", county: "Portage"},
    {stateLocation: "OH", county: "Preble"},
    {stateLocation: "OH", county: "Putnam"},
    {stateLocation: "OH", county: "Richland"},
    {stateLocation: "OH", county: "Ross"},
    {stateLocation: "OH", county: "Sandusky"},
    {stateLocation: "OH", county: "Scioto"},
    {stateLocation: "OH", county: "Seneca"},
    {stateLocation: "OH", county: "Shelby"},
    {stateLocation: "OH", county: "Stark"},
    {stateLocation: "OH", county: "Summit"},
    {stateLocation: "OH", county: "Trumbull"},
    {stateLocation: "OH", county: "Tuscarawas"},
    {stateLocation: "OH", county: "Union"},
    {stateLocation: "OH", county: "Van Wert"},
    {stateLocation: "OH", county: "Vinton"},
    {stateLocation: "OH", county: "Warren"},
    {stateLocation: "OH", county: "Washington"},
    {stateLocation: "OH", county: "Wayne"},
    {stateLocation: "OH", county: "Williams"},
    {stateLocation: "OH", county: "Wood"},
    {stateLocation: "OH", county: "Wyandot"},
    {stateLocation: "OK", county: "Adair"},
    {stateLocation: "OK", county: "Alfalfa"},
    {stateLocation: "OK", county: "Atoka"},
    {stateLocation: "OK", county: "Beaver"},
    {stateLocation: "OK", county: "Beckham"},
    {stateLocation: "OK", county: "Blaine"},
    {stateLocation: "OK", county: "Bryan"},
    {stateLocation: "OK", county: "Caddo"},
    {stateLocation: "OK", county: "Canadian"},
    {stateLocation: "OK", county: "Carter"},
    {stateLocation: "OK", county: "Cherokee"},
    {stateLocation: "OK", county: "Choctaw"},
    {stateLocation: "OK", county: "Cimarron"},
    {stateLocation: "OK", county: "Cleveland"},
    {stateLocation: "OK", county: "Coal"},
    {stateLocation: "OK", county: "Comanche"},
    {stateLocation: "OK", county: "Cotton"},
    {stateLocation: "OK", county: "Craig"},
    {stateLocation: "OK", county: "Creek"},
    {stateLocation: "OK", county: "Custer"},
    {stateLocation: "OK", county: "Delaware"},
    {stateLocation: "OK", county: "Dewey"},
    {stateLocation: "OK", county: "Ellis"},
    {stateLocation: "OK", county: "Garfield"},
    {stateLocation: "OK", county: "Garvin"},
    {stateLocation: "OK", county: "Grady"},
    {stateLocation: "OK", county: "Grant"},
    {stateLocation: "OK", county: "Greer"},
    {stateLocation: "OK", county: "Harmon"},
    {stateLocation: "OK", county: "Harper"},
    {stateLocation: "OK", county: "Haskell"},
    {stateLocation: "OK", county: "Hughes"},
    {stateLocation: "OK", county: "Jackson"},
    {stateLocation: "OK", county: "Jefferson"},
    {stateLocation: "OK", county: "Johnston"},
    {stateLocation: "OK", county: "Kay"},
    {stateLocation: "OK", county: "Kingfisher"},
    {stateLocation: "OK", county: "Kiowa"},
    {stateLocation: "OK", county: "Latimer"},
    {stateLocation: "OK", county: "Le Flore"},
    {stateLocation: "OK", county: "Lincoln"},
    {stateLocation: "OK", county: "Logan"},
    {stateLocation: "OK", county: "Love"},
    {stateLocation: "OK", county: "McClain"},
    {stateLocation: "OK", county: "McCurtain"},
    {stateLocation: "OK", county: "McIntosh"},
    {stateLocation: "OK", county: "Major"},
    {stateLocation: "OK", county: "Marshall"},
    {stateLocation: "OK", county: "Mayes"},
    {stateLocation: "OK", county: "Murray"},
    {stateLocation: "OK", county: "Muskogee"},
    {stateLocation: "OK", county: "Noble"},
    {stateLocation: "OK", county: "Nowata"},
    {stateLocation: "OK", county: "Okfuskee"},
    {stateLocation: "OK", county: "Oklahoma"},
    {stateLocation: "OK", county: "Okmulgee"},
    {stateLocation: "OK", county: "Osage"},
    {stateLocation: "OK", county: "Ottawa"},
    {stateLocation: "OK", county: "Pawnee"},
    {stateLocation: "OK", county: "Payne"},
    {stateLocation: "OK", county: "Pittsburg"},
    {stateLocation: "OK", county: "Pontotoc"},
    {stateLocation: "OK", county: "Pottawatomie"},
    {stateLocation: "OK", county: "Pushmataha"},
    {stateLocation: "OK", county: "Roger Mills"},
    {stateLocation: "OK", county: "Rogers"},
    {stateLocation: "OK", county: "Seminole"},
    {stateLocation: "OK", county: "Sequoyah"},
    {stateLocation: "OK", county: "Stephens"},
    {stateLocation: "OK", county: "Texas"},
    {stateLocation: "OK", county: "Tillman"},
    {stateLocation: "OK", county: "Tulsa"},
    {stateLocation: "OK", county: "Wagoner"},
    {stateLocation: "OK", county: "Washington"},
    {stateLocation: "OK", county: "Washita"},
    {stateLocation: "OK", county: "Woods"},
    {stateLocation: "OK", county: "Woodward"},
    {stateLocation: "OR", county: "Baker"},
    {stateLocation: "OR", county: "Benton"},
    {stateLocation: "OR", county: "Clackamas"},
    {stateLocation: "OR", county: "Clatsop"},
    {stateLocation: "OR", county: "Columbia"},
    {stateLocation: "OR", county: "Coos"},
    {stateLocation: "OR", county: "Crook"},
    {stateLocation: "OR", county: "Curry"},
    {stateLocation: "OR", county: "Deschutes"},
    {stateLocation: "OR", county: "Douglas"},
    {stateLocation: "OR", county: "Gilliam"},
    {stateLocation: "OR", county: "Grant"},
    {stateLocation: "OR", county: "Harney"},
    {stateLocation: "OR", county: "Hood River"},
    {stateLocation: "OR", county: "Jackson"},
    {stateLocation: "OR", county: "Jefferson"},
    {stateLocation: "OR", county: "Josephine"},
    {stateLocation: "OR", county: "Klamath"},
    {stateLocation: "OR", county: "Lake"},
    {stateLocation: "OR", county: "Lane"},
    {stateLocation: "OR", county: "Lincoln"},
    {stateLocation: "OR", county: "Linn"},
    {stateLocation: "OR", county: "Malheur"},
    {stateLocation: "OR", county: "Marion"},
    {stateLocation: "OR", county: "Morrow"},
    {stateLocation: "OR", county: "Multnomah"},
    {stateLocation: "OR", county: "Polk"},
    {stateLocation: "OR", county: "Sherman"},
    {stateLocation: "OR", county: "Tillamook"},
    {stateLocation: "OR", county: "Umatilla"},
    {stateLocation: "OR", county: "Union"},
    {stateLocation: "OR", county: "Wallowa"},
    {stateLocation: "OR", county: "Wasco"},
    {stateLocation: "OR", county: "Washington"},
    {stateLocation: "OR", county: "Wheeler"},
    {stateLocation: "OR", county: "Yamhill"},
    {stateLocation: "PA", county: "Adams"},
    {stateLocation: "PA", county: "Allegheny"},
    {stateLocation: "PA", county: "Armstrong"},
    {stateLocation: "PA", county: "Beaver"},
    {stateLocation: "PA", county: "Bedford"},
    {stateLocation: "PA", county: "Berks"},
    {stateLocation: "PA", county: "Blair"},
    {stateLocation: "PA", county: "Bradford"},
    {stateLocation: "PA", county: "Bucks"},
    {stateLocation: "PA", county: "Butler"},
    {stateLocation: "PA", county: "Cambria"},
    {stateLocation: "PA", county: "Cameron"},
    {stateLocation: "PA", county: "Carbon"},
    {stateLocation: "PA", county: "Centre"},
    {stateLocation: "PA", county: "Chester"},
    {stateLocation: "PA", county: "Clarion"},
    {stateLocation: "PA", county: "Clearfield"},
    {stateLocation: "PA", county: "Clinton"},
    {stateLocation: "PA", county: "Columbia"},
    {stateLocation: "PA", county: "Crawford"},
    {stateLocation: "PA", county: "Cumberland"},
    {stateLocation: "PA", county: "Dauphin"},
    {stateLocation: "PA", county: "Delaware"},
    {stateLocation: "PA", county: "Elk"},
    {stateLocation: "PA", county: "Erie"},
    {stateLocation: "PA", county: "Fayette"},
    {stateLocation: "PA", county: "Forest"},
    {stateLocation: "PA", county: "Franklin"},
    {stateLocation: "PA", county: "Fulton"},
    {stateLocation: "PA", county: "Greene"},
    {stateLocation: "PA", county: "Huntingdon"},
    {stateLocation: "PA", county: "Indiana"},
    {stateLocation: "PA", county: "Jefferson"},
    {stateLocation: "PA", county: "Juniata"},
    {stateLocation: "PA", county: "Lackawanna"},
    {stateLocation: "PA", county: "Lancaster"},
    {stateLocation: "PA", county: "Lawrence"},
    {stateLocation: "PA", county: "Lebanon"},
    {stateLocation: "PA", county: "Lehigh"},
    {stateLocation: "PA", county: "Luzerne"},
    {stateLocation: "PA", county: "Lycoming"},
    {stateLocation: "PA", county: "McKean"},
    {stateLocation: "PA", county: "Mercer"},
    {stateLocation: "PA", county: "Mifflin"},
    {stateLocation: "PA", county: "Monroe"},
    {stateLocation: "PA", county: "Montgomery"},
    {stateLocation: "PA", county: "Montour"},
    {stateLocation: "PA", county: "Northampton"},
    {stateLocation: "PA", county: "Northumberland"},
    {stateLocation: "PA", county: "Perry"},
    {stateLocation: "PA", county: "Philadelphia"},
    {stateLocation: "PA", county: "Pike"},
    {stateLocation: "PA", county: "Potter"},
    {stateLocation: "PA", county: "Schuylkill"},
    {stateLocation: "PA", county: "Snyder"},
    {stateLocation: "PA", county: "Somerset"},
    {stateLocation: "PA", county: "Sullivan"},
    {stateLocation: "PA", county: "Susquehanna"},
    {stateLocation: "PA", county: "Tioga"},
    {stateLocation: "PA", county: "Union"},
    {stateLocation: "PA", county: "Venango"},
    {stateLocation: "PA", county: "Warren"},
    {stateLocation: "PA", county: "Washington"},
    {stateLocation: "PA", county: "Wayne"},
    {stateLocation: "PA", county: "Westmoreland"},
    {stateLocation: "PA", county: "Wyoming"},
    { stateLocation: "PA", county: "York" },
    {stateLocation: "PR", county: 'Adjuntas'},
    { stateLocation: "PR", county: 'Aguada' },
    {
        stateLocation: "PR", county: 'Aguadilla'
    },
    {
        stateLocation: "PR", county: 'Aguas Buenas'
    },
    {
        stateLocation: "PR", county: 'Aibonito'
    },
    {
        stateLocation: "PR", county: 'Arecibo'
    },
    {
        stateLocation: "PR", county: 'Arroyo'
    },
    { stateLocation: "PR", county: 'Añasco' },
    {
        stateLocation: "PR", county: 'Barceloneta'
    },
    {
        stateLocation: "PR", county: 'Barranquitas'
    },
    {
        stateLocation: "PR", county: 'Bayamón'
    },
    {
        stateLocation: "PR", county: 'Cabo Rojo'
    },
    {
        stateLocation: "PR", county: 'Caguas'
    },
    {
        stateLocation: "PR", county: 'Camuy'
    },
    {
        stateLocation: "PR", county: 'Canóvanas'
    },
    { stateLocation: "PR", county: 'Carolina' },
    {
        stateLocation: "PR", county: 'Cataño'
    },
    {
        stateLocation: "PR", county: 'Cayey'
    },
    {
        stateLocation: "PR", county: 'Ceiba'
    },
    {
        stateLocation: "PR", county: 'Ciales'
    },
    {
        stateLocation: "PR", county: 'Cidra'
    },
    {
        stateLocation: "PR", county: 'Coamo'
    },
    {
        stateLocation: "PR", county: 'Comerío'
    },
    {
        stateLocation: "PR", county: 'Corozal'
    },
    {
        stateLocation: "PR", county: 'Culebra'
    },
    {
        stateLocation: "PR", county: 'Dorado'
    },
    {
        stateLocation: "PR", county: 'Fajardo'
    },
    {
        stateLocation: "PR", county: 'Florida'
    },
    {
        stateLocation: "PR", county: 'Guayama'
    },
    {
        stateLocation: "PR", county: 'Guayanilla'
    },
    {
        stateLocation: "PR", county: 'Guaynabo'
    },
    {
        stateLocation: "PR", county: 'Gurabo'
    },
    {
        stateLocation: "PR", county: 'Guánica'
    },
    {
        stateLocation: "PR", county: 'Hatillo'
    },
    {
        stateLocation: "PR", county: 'Hormigueros'
    },
    {
        stateLocation: "PR", county: 'Humacao'
    },
    {
        stateLocation: "PR", county: 'Isabela'
    },
    {
        stateLocation: "PR", county: 'Jayuya'
    },
    {
        stateLocation: "PR", county: 'Juana Díaz'
    },
    {
        stateLocation: "PR", county: 'Juncos'
    },
    {
        stateLocation: "PR", county: 'Lajas'
    },
    {
        stateLocation: "PR", county: 'Lares'
    },
    {
        stateLocation: "PR", county: 'Las Marías'
    },
    {
        stateLocation: "PR", county: 'Las Piedras'
    },
    {
        stateLocation: "PR", county: 'Loíza'
    },
    {
        stateLocation: "PR", county: 'Luquillo'
    },
    {
        stateLocation: "PR", county: 'Manatí'
    },
    {
        stateLocation: "PR", county: 'Maricao'
    },
    {
        stateLocation: "PR", county: 'Maunabo'
    },
    {
        stateLocation: "PR", county: 'Mayagüez'
    },
    {
        stateLocation: "PR", county: 'Moca'
    },
    {
        stateLocation: "PR", county: 'Morovis'
    },
    {
        stateLocation: "PR", county: 'Naguabo'
    },
    {
        stateLocation: "PR", county: 'Naranjito'
    },
    {
        stateLocation: "PR", county: 'Orocovis'
    },
    {
        stateLocation: "PR", county: 'Patillas'
    },
{stateLocation: "PR", county: 'Peñuelas'},
    {
        stateLocation: "PR", county: 'Ponce'
    },
    {
        stateLocation: "PR", county: 'Quebradillas'
    },
    {
        stateLocation: "PR", county: 'Rincón'
    },
    {
        stateLocation: "PR", county: 'Río Grande'
    },
    {
        stateLocation: "PR", county: 'Sabana Grande'
    },
    {
        stateLocation: "PR", county: 'Salinas'
    },
    {
        stateLocation: "PR", county: 'San Germán'
    },
    {
        stateLocation: "PR", county: 'San Juan'
    },
    {
        stateLocation: "PR", county: 'San Lorenzo'
    },
    {
        stateLocation: "PR", county: 'San Sebastián'
    },
    {
        stateLocation: "PR", county: 'Santa Isabel'
    },
{stateLocation: "PR", county: 'Toa Alta'},
    {
        stateLocation: "PR", county: 'Toa Baja'
    },
    {
        stateLocation: "PR", county: 'Trujillo Alto'
    },
    {
        stateLocation: "PR", county: 'Utuado'
    },
    {
        stateLocation: "PR", county: 'Vega Alta'
    },
    {
        stateLocation: "PR", county: 'Vega Baja'
    },
    {
        stateLocation: "PR", county: 'Vieques'
    },
    {
        stateLocation: "PR", county: 'Villalba'
    },
    {
        stateLocation: "PR", county: 'Yabucoa'
    },
    { stateLocation: "PR", county: 'Yauco' },
    {stateLocation: "RI", county: "Bristol"},
    {stateLocation: "RI", county: "Kent"},
    {stateLocation: "RI", county: "Newport"},
    {stateLocation: "RI", county: "Providence"},
    {stateLocation: "RI", county: "Washington"},
    {stateLocation: "SC", county: "Abbeville"},
    {stateLocation: "SC", county: "Aiken"},
    {stateLocation: "SC", county: "Allendale"},
    {stateLocation: "SC", county: "Anderson"},
    {stateLocation: "SC", county: "Bamberg"},
    {stateLocation: "SC", county: "Barnwell"},
    {stateLocation: "SC", county: "Beaufort"},
    {stateLocation: "SC", county: "Berkeley"},
    {stateLocation: "SC", county: "Calhoun"},
    {stateLocation: "SC", county: "Charleston"},
    {stateLocation: "SC", county: "Cherokee"},
    {stateLocation: "SC", county: "Chester"},
    {stateLocation: "SC", county: "Chesterfield"},
    {stateLocation: "SC", county: "Clarendon"},
    {stateLocation: "SC", county: "Colleton"},
    {stateLocation: "SC", county: "Darlington"},
    {stateLocation: "SC", county: "Dillon"},
    {stateLocation: "SC", county: "Dorchester"},
    {stateLocation: "SC", county: "Edgefield"},
    {stateLocation: "SC", county: "Fairfield"},
    {stateLocation: "SC", county: "Florence"},
    {stateLocation: "SC", county: "Georgetown"},
    {stateLocation: "SC", county: "Greenville"},
    {stateLocation: "SC", county: "Greenwood"},
    {stateLocation: "SC", county: "Hampton"},
    {stateLocation: "SC", county: "Horry"},
    {stateLocation: "SC", county: "Jasper"},
    {stateLocation: "SC", county: "Kershaw"},
    {stateLocation: "SC", county: "Lancaster"},
    {stateLocation: "SC", county: "Laurens"},
    {stateLocation: "SC", county: "Lee"},
    {stateLocation: "SC", county: "Lexington"},
    {stateLocation: "SC", county: "McCormick"},
    {stateLocation: "SC", county: "Marion"},
    {stateLocation: "SC", county: "Marlboro"},
    {stateLocation: "SC", county: "Newberry"},
    {stateLocation: "SC", county: "Oconee"},
    {stateLocation: "SC", county: "Orangeburg"},
    {stateLocation: "SC", county: "Pickens"},
    {stateLocation: "SC", county: "Richland"},
    {stateLocation: "SC", county: "Saluda"},
    {stateLocation: "SC", county: "Spartanburg"},
    {stateLocation: "SC", county: "Sumter"},
    {stateLocation: "SC", county: "Union"},
    {stateLocation: "SC", county: "Williamsburg"},
    {stateLocation: "SC", county: "York"},
    {stateLocation: "SD", county: "Aurora"},
    {stateLocation: "SD", county: "Beadle"},
    {stateLocation: "SD", county: "Bennett"},
    {stateLocation: "SD", county: "Bon Homme"},
    {stateLocation: "SD", county: "Brookings"},
    {stateLocation: "SD", county: "Brown"},
    {stateLocation: "SD", county: "Brule"},
    {stateLocation: "SD", county: "Buffalo"},
    {stateLocation: "SD", county: "Butte"},
    {stateLocation: "SD", county: "Campbell"},
    {stateLocation: "SD", county: "Charles Mix"},
    {stateLocation: "SD", county: "Clark"},
    {stateLocation: "SD", county: "Clay"},
    {stateLocation: "SD", county: "Codington"},
    {stateLocation: "SD", county: "Corson"},
    {stateLocation: "SD", county: "Custer"},
    {stateLocation: "SD", county: "Davison"},
    {stateLocation: "SD", county: "Day"},
    {stateLocation: "SD", county: "Deuel"},
    {stateLocation: "SD", county: "Dewey"},
    {stateLocation: "SD", county: "Douglas"},
    {stateLocation: "SD", county: "Edmunds"},
    {stateLocation: "SD", county: "Fall River"},
    {stateLocation: "SD", county: "Faulk"},
    {stateLocation: "SD", county: "Grant"},
    {stateLocation: "SD", county: "Gregory"},
    {stateLocation: "SD", county: "Haakon"},
    {stateLocation: "SD", county: "Hamlin"},
    {stateLocation: "SD", county: "Hand"},
    {stateLocation: "SD", county: "Hanson"},
    {stateLocation: "SD", county: "Harding"},
    {stateLocation: "SD", county: "Hughes"},
    {stateLocation: "SD", county: "Hutchinson"},
    {stateLocation: "SD", county: "Hyde"},
    {stateLocation: "SD", county: "Jackson"},
    {stateLocation: "SD", county: "Jerauld"},
    {stateLocation: "SD", county: "Jones"},
    {stateLocation: "SD", county: "Kingsbury"},
    {stateLocation: "SD", county: "Lake"},
    {stateLocation: "SD", county: "Lawrence"},
    {stateLocation: "SD", county: "Lincoln"},
    {stateLocation: "SD", county: "Lyman"},
    {stateLocation: "SD", county: "McCook"},
    {stateLocation: "SD", county: "McPherson"},
    {stateLocation: "SD", county: "Marshall"},
    {stateLocation: "SD", county: "Meade"},
    {stateLocation: "SD", county: "Mellette"},
    {stateLocation: "SD", county: "Miner"},
    {stateLocation: "SD", county: "Minnehaha"},
    {stateLocation: "SD", county: "Moody"},
    {stateLocation: "SD", county: "Pennington"},
    {stateLocation: "SD", county: "Perkins"},
    {stateLocation: "SD", county: "Potter"},
    {stateLocation: "SD", county: "Roberts"},
    {stateLocation: "SD", county: "Sanborn"},
    {stateLocation: "SD", county: "Shannon"},
    {stateLocation: "SD", county: "Spink"},
    {stateLocation: "SD", county: "Stanley"},
    {stateLocation: "SD", county: "Sully"},
    {stateLocation: "SD", county: "Todd"},
    {stateLocation: "SD", county: "Tripp"},
    {stateLocation: "SD", county: "Turner"},
    {stateLocation: "SD", county: "Union"},
    {stateLocation: "SD", county: "Walworth"},
    {stateLocation: "SD", county: "Yankton"},
    {stateLocation: "SD", county: "Ziebach"},
    {stateLocation: "TN", county: "Anderson"},
    {stateLocation: "TN", county: "Bedford"},
    {stateLocation: "TN", county: "Benton"},
    {stateLocation: "TN", county: "Bledsoe"},
    {stateLocation: "TN", county: "Blount"},
    {stateLocation: "TN", county: "Bradley"},
    {stateLocation: "TN", county: "Campbell"},
    {stateLocation: "TN", county: "Cannon"},
    {stateLocation: "TN", county: "Carroll"},
    {stateLocation: "TN", county: "Carter"},
    {stateLocation: "TN", county: "Cheatham"},
    {stateLocation: "TN", county: "Chester"},
    {stateLocation: "TN", county: "Claiborne"},
    {stateLocation: "TN", county: "Clay"},
    {stateLocation: "TN", county: "Cocke"},
    {stateLocation: "TN", county: "Coffee"},
    {stateLocation: "TN", county: "Crockett"},
    {stateLocation: "TN", county: "Cumberland"},
    {stateLocation: "TN", county: "Davidson"},
    {stateLocation: "TN", county: "Decatur"},
    {stateLocation: "TN", county: "DeKalb"},
    {stateLocation: "TN", county: "Dickson"},
    {stateLocation: "TN", county: "Dyer"},
    {stateLocation: "TN", county: "Fayette"},
    {stateLocation: "TN", county: "Fentress"},
    {stateLocation: "TN", county: "Franklin"},
    {stateLocation: "TN", county: "Gibson"},
    {stateLocation: "TN", county: "Giles"},
    {stateLocation: "TN", county: "Grainger"},
    {stateLocation: "TN", county: "Greene"},
    {stateLocation: "TN", county: "Grundy"},
    {stateLocation: "TN", county: "Hamblen"},
    {stateLocation: "TN", county: "Hamilton"},
    {stateLocation: "TN", county: "Hancock"},
    {stateLocation: "TN", county: "Hardeman"},
    {stateLocation: "TN", county: "Hardin"},
    {stateLocation: "TN", county: "Hawkins"},
    {stateLocation: "TN", county: "Haywood"},
    {stateLocation: "TN", county: "Henderson"},
    {stateLocation: "TN", county: "Henry"},
    {stateLocation: "TN", county: "Hickman"},
    {stateLocation: "TN", county: "Houston"},
    {stateLocation: "TN", county: "Humphreys"},
    {stateLocation: "TN", county: "Jackson"},
    {stateLocation: "TN", county: "Jefferson"},
    {stateLocation: "TN", county: "Johnson"},
    {stateLocation: "TN", county: "Knox"},
    {stateLocation: "TN", county: "Lake"},
    {stateLocation: "TN", county: "Lauderdale"},
    {stateLocation: "TN", county: "Lawrence"},
    {stateLocation: "TN", county: "Lewis"},
    {stateLocation: "TN", county: "Lincoln"},
    {stateLocation: "TN", county: "Loudon"},
    {stateLocation: "TN", county: "McMinn"},
    {stateLocation: "TN", county: "McNairy"},
    {stateLocation: "TN", county: "Macon"},
    {stateLocation: "TN", county: "Madison"},
    {stateLocation: "TN", county: "Marion"},
    {stateLocation: "TN", county: "Marshall"},
    {stateLocation: "TN", county: "Maury"},
    {stateLocation: "TN", county: "Meigs"},
    {stateLocation: "TN", county: "Monroe"},
    {stateLocation: "TN", county: "Montgomery"},
    {stateLocation: "TN", county: "Moore"},
    {stateLocation: "TN", county: "Morgan"},
    {stateLocation: "TN", county: "Obion"},
    {stateLocation: "TN", county: "Overton"},
    {stateLocation: "TN", county: "Perry"},
    {stateLocation: "TN", county: "Pickett"},
    {stateLocation: "TN", county: "Polk"},
    {stateLocation: "TN", county: "Putnam"},
    {stateLocation: "TN", county: "Rhea"},
    {stateLocation: "TN", county: "Roane"},
    {stateLocation: "TN", county: "Robertson"},
    {stateLocation: "TN", county: "Rutherford"},
    {stateLocation: "TN", county: "Scott"},
    {stateLocation: "TN", county: "Sequatchie"},
    {stateLocation: "TN", county: "Sevier"},
    {stateLocation: "TN", county: "Shelby"},
    {stateLocation: "TN", county: "Smith"},
    {stateLocation: "TN", county: "Stewart"},
    {stateLocation: "TN", county: "Sullivan"},
    {stateLocation: "TN", county: "Sumner"},
    {stateLocation: "TN", county: "Tipton"},
    {stateLocation: "TN", county: "Trousdale"},
    {stateLocation: "TN", county: "Unicoi"},
    {stateLocation: "TN", county: "Union"},
    {stateLocation: "TN", county: "Van Buren"},
    {stateLocation: "TN", county: "Warren"},
    {stateLocation: "TN", county: "Washington"},
    {stateLocation: "TN", county: "Wayne"},
    {stateLocation: "TN", county: "Weakley"},
    {stateLocation: "TN", county: "White"},
    {stateLocation: "TN", county: "Williamson"},
    {stateLocation: "TN", county: "Wilson"},
    {stateLocation: "TX", county: "Anderson"},
    {stateLocation: "TX", county: "Andrews"},
    {stateLocation: "TX", county: "Angelina"},
    {stateLocation: "TX", county: "Aransas"},
    {stateLocation: "TX", county: "Archer"},
    {stateLocation: "TX", county: "Armstrong"},
    {stateLocation: "TX", county: "Atascosa"},
    {stateLocation: "TX", county: "Austin"},
    {stateLocation: "TX", county: "Bailey"},
    {stateLocation: "TX", county: "Bandera"},
    {stateLocation: "TX", county: "Bastrop"},
    {stateLocation: "TX", county: "Baylor"},
    {stateLocation: "TX", county: "Bee"},
    {stateLocation: "TX", county: "Bell"},
    {stateLocation: "TX", county: "Bexar"},
    {stateLocation: "TX", county: "Blanco"},
    {stateLocation: "TX", county: "Borden"},
    {stateLocation: "TX", county: "Bosque"},
    {stateLocation: "TX", county: "Bowie"},
    {stateLocation: "TX", county: "Brazoria"},
    {stateLocation: "TX", county: "Brazos"},
    {stateLocation: "TX", county: "Brewster"},
    {stateLocation: "TX", county: "Briscoe"},
    {stateLocation: "TX", county: "Brooks"},
    {stateLocation: "TX", county: "Brown"},
    {stateLocation: "TX", county: "Burleson"},
    {stateLocation: "TX", county: "Burnet"},
    {stateLocation: "TX", county: "Caldwell"},
    {stateLocation: "TX", county: "Calhoun"},
    {stateLocation: "TX", county: "Callahan"},
    {stateLocation: "TX", county: "Cameron"},
    {stateLocation: "TX", county: "Camp"},
    {stateLocation: "TX", county: "Carson"},
    {stateLocation: "TX", county: "Cass"},
    {stateLocation: "TX", county: "Castro"},
    {stateLocation: "TX", county: "Chambers"},
    {stateLocation: "TX", county: "Cherokee"},
    {stateLocation: "TX", county: "Childress"},
    {stateLocation: "TX", county: "Clay"},
    {stateLocation: "TX", county: "Cochran"},
    {stateLocation: "TX", county: "Coke"},
    {stateLocation: "TX", county: "Coleman"},
    {stateLocation: "TX", county: "Collin"},
    {stateLocation: "TX", county: "Collingsworth"},
    {stateLocation: "TX", county: "Colorado"},
    {stateLocation: "TX", county: "Comal"},
    {stateLocation: "TX", county: "Comanche"},
    {stateLocation: "TX", county: "Concho"},
    {stateLocation: "TX", county: "Cooke"},
    {stateLocation: "TX", county: "Coryell"},
    {stateLocation: "TX", county: "Cottle"},
    {stateLocation: "TX", county: "Crane"},
    {stateLocation: "TX", county: "Crockett"},
    {stateLocation: "TX", county: "Crosby"},
    {stateLocation: "TX", county: "Culberson"},
    {stateLocation: "TX", county: "Dallam"},
    {stateLocation: "TX", county: "Dallas"},
    {stateLocation: "TX", county: "Dawson"},
    {stateLocation: "TX", county: "Deaf Smith"},
    {stateLocation: "TX", county: "Delta"},
    {stateLocation: "TX", county: "Denton"},
    {stateLocation: "TX", county: "DeWitt"},
    {stateLocation: "TX", county: "Dickens"},
    {stateLocation: "TX", county: "Dimmit"},
    {stateLocation: "TX", county: "Donley"},
    {stateLocation: "TX", county: "Duval"},
    {stateLocation: "TX", county: "Eastland"},
    {stateLocation: "TX", county: "Ector"},
    {stateLocation: "TX", county: "Edwards"},
    {stateLocation: "TX", county: "Ellis"},
    {stateLocation: "TX", county: "El Paso"},
    {stateLocation: "TX", county: "Erath"},
    {stateLocation: "TX", county: "Falls"},
    {stateLocation: "TX", county: "Fannin"},
    {stateLocation: "TX", county: "Fayette"},
    {stateLocation: "TX", county: "Fisher"},
    {stateLocation: "TX", county: "Floyd"},
    {stateLocation: "TX", county: "Foard"},
    {stateLocation: "TX", county: "Fort Bend"},
    {stateLocation: "TX", county: "Franklin"},
    {stateLocation: "TX", county: "Freestone"},
    {stateLocation: "TX", county: "Frio"},
    {stateLocation: "TX", county: "Gaines"},
    {stateLocation: "TX", county: "Galveston"},
    {stateLocation: "TX", county: "Garza"},
    {stateLocation: "TX", county: "Gillespie"},
    {stateLocation: "TX", county: "Glasscock"},
    {stateLocation: "TX", county: "Goliad"},
    {stateLocation: "TX", county: "Gonzales"},
    {stateLocation: "TX", county: "Gray"},
    {stateLocation: "TX", county: "Grayson"},
    {stateLocation: "TX", county: "Gregg"},
    {stateLocation: "TX", county: "Grimes"},
    {stateLocation: "TX", county: "Guadalupe"},
    {stateLocation: "TX", county: "Hale"},
    {stateLocation: "TX", county: "Hall"},
    {stateLocation: "TX", county: "Hamilton"},
    {stateLocation: "TX", county: "Hansford"},
    {stateLocation: "TX", county: "Hardeman"},
    {stateLocation: "TX", county: "Hardin"},
    {stateLocation: "TX", county: "Harris"},
    {stateLocation: "TX", county: "Harrison"},
    {stateLocation: "TX", county: "Hartley"},
    {stateLocation: "TX", county: "Haskell"},
    {stateLocation: "TX", county: "Hays"},
    {stateLocation: "TX", county: "Hemphill"},
    {stateLocation: "TX", county: "Henderson"},
    {stateLocation: "TX", county: "Hidalgo"},
    {stateLocation: "TX", county: "Hill"},
    {stateLocation: "TX", county: "Hockley"},
    {stateLocation: "TX", county: "Hood"},
    {stateLocation: "TX", county: "Hopkins"},
    {stateLocation: "TX", county: "Houston"},
    {stateLocation: "TX", county: "Howard"},
    {stateLocation: "TX", county: "Hudspeth"},
    {stateLocation: "TX", county: "Hunt"},
    {stateLocation: "TX", county: "Hutchinson"},
    {stateLocation: "TX", county: "Irion"},
    {stateLocation: "TX", county: "Jack"},
    {stateLocation: "TX", county: "Jackson"},
    {stateLocation: "TX", county: "Jasper"},
    {stateLocation: "TX", county: "Jeff Davis"},
    {stateLocation: "TX", county: "Jefferson"},
    {stateLocation: "TX", county: "Jim Hogg"},
    {stateLocation: "TX", county: "Jim Wells"},
    {stateLocation: "TX", county: "Johnson"},
    {stateLocation: "TX", county: "Jones"},
    {stateLocation: "TX", county: "Karnes"},
    {stateLocation: "TX", county: "Kaufman"},
    {stateLocation: "TX", county: "Kendall"},
    {stateLocation: "TX", county: "Kenedy"},
    {stateLocation: "TX", county: "Kent"},
    {stateLocation: "TX", county: "Kerr"},
    {stateLocation: "TX", county: "Kimble"},
    {stateLocation: "TX", county: "King"},
    {stateLocation: "TX", county: "Kinney"},
    {stateLocation: "TX", county: "Kleberg"},
    {stateLocation: "TX", county: "Knox"},
    {stateLocation: "TX", county: "Lamar"},
    {stateLocation: "TX", county: "Lamb"},
    {stateLocation: "TX", county: "Lampasas"},
    {stateLocation: "TX", county: "La Salle"},
    {stateLocation: "TX", county: "Lavaca"},
    {stateLocation: "TX", county: "Lee"},
    {stateLocation: "TX", county: "Leon"},
    {stateLocation: "TX", county: "Liberty"},
    {stateLocation: "TX", county: "Limestone"},
    {stateLocation: "TX", county: "Lipscomb"},
    {stateLocation: "TX", county: "Live Oak"},
    {stateLocation: "TX", county: "Llano"},
    {stateLocation: "TX", county: "Loving"},
    {stateLocation: "TX", county: "Lubbock"},
    {stateLocation: "TX", county: "Lynn"},
    {stateLocation: "TX", county: "McCulloch"},
    {stateLocation: "TX", county: "McLennan"},
    {stateLocation: "TX", county: "McMullen"},
    {stateLocation: "TX", county: "Madison"},
    {stateLocation: "TX", county: "Marion"},
    {stateLocation: "TX", county: "Martin"},
    {stateLocation: "TX", county: "Mason"},
    {stateLocation: "TX", county: "Matagorda"},
    {stateLocation: "TX", county: "Maverick"},
    {stateLocation: "TX", county: "Medina"},
    {stateLocation: "TX", county: "Menard"},
    {stateLocation: "TX", county: "Midland"},
    {stateLocation: "TX", county: "Milam"},
    {stateLocation: "TX", county: "Mills"},
    {stateLocation: "TX", county: "Mitchell"},
    {stateLocation: "TX", county: "Montague"},
    {stateLocation: "TX", county: "Montgomery"},
    {stateLocation: "TX", county: "Moore"},
    {stateLocation: "TX", county: "Morris"},
    {stateLocation: "TX", county: "Motley"},
    {stateLocation: "TX", county: "Nacogdoches"},
    {stateLocation: "TX", county: "Navarro"},
    {stateLocation: "TX", county: "Newton"},
    {stateLocation: "TX", county: "Nolan"},
    {stateLocation: "TX", county: "Nueces"},
    {stateLocation: "TX", county: "Ochiltree"},
    {stateLocation: "TX", county: "Oldham"},
    {stateLocation: "TX", county: "Orange"},
    {stateLocation: "TX", county: "Palo Pinto"},
    {stateLocation: "TX", county: "Panola"},
    {stateLocation: "TX", county: "Parker"},
    {stateLocation: "TX", county: "Parmer"},
    {stateLocation: "TX", county: "Pecos"},
    {stateLocation: "TX", county: "Polk"},
    {stateLocation: "TX", county: "Potter"},
    {stateLocation: "TX", county: "Presidio"},
    {stateLocation: "TX", county: "Rains"},
    {stateLocation: "TX", county: "Randall"},
    {stateLocation: "TX", county: "Reagan"},
    {stateLocation: "TX", county: "Real"},
    {stateLocation: "TX", county: "Red River"},
    {stateLocation: "TX", county: "Reeves"},
    {stateLocation: "TX", county: "Refugio"},
    {stateLocation: "TX", county: "Roberts"},
    {stateLocation: "TX", county: "Robertson"},
    {stateLocation: "TX", county: "Rockwall"},
    {stateLocation: "TX", county: "Runnels"},
    {stateLocation: "TX", county: "Rusk"},
    {stateLocation: "TX", county: "Sabine"},
    {stateLocation: "TX", county: "San Augustine"},
    {stateLocation: "TX", county: "San Jacinto"},
    {stateLocation: "TX", county: "San Patricio"},
    {stateLocation: "TX", county: "San Saba"},
    {stateLocation: "TX", county: "Schleicher"},
    {stateLocation: "TX", county: "Scurry"},
    {stateLocation: "TX", county: "Shackelford"},
    {stateLocation: "TX", county: "Shelby"},
    {stateLocation: "TX", county: "Sherman"},
    {stateLocation: "TX", county: "Smith"},
    {stateLocation: "TX", county: "Somervell"},
    {stateLocation: "TX", county: "Starr"},
    {stateLocation: "TX", county: "Stephens"},
    {stateLocation: "TX", county: "Sterling"},
    {stateLocation: "TX", county: "Stonewall"},
    {stateLocation: "TX", county: "Sutton"},
    {stateLocation: "TX", county: "Swisher"},
    {stateLocation: "TX", county: "Tarrant"},
    {stateLocation: "TX", county: "Taylor"},
    {stateLocation: "TX", county: "Terrell"},
    {stateLocation: "TX", county: "Terry"},
    {stateLocation: "TX", county: "Throckmorton"},
    {stateLocation: "TX", county: "Titus"},
    {stateLocation: "TX", county: "Tom Green"},
    {stateLocation: "TX", county: "Travis"},
    {stateLocation: "TX", county: "Trinity"},
    {stateLocation: "TX", county: "Tyler"},
    {stateLocation: "TX", county: "Upshur"},
    {stateLocation: "TX", county: "Upton"},
    {stateLocation: "TX", county: "Uvalde"},
    {stateLocation: "TX", county: "Val Verde"},
    {stateLocation: "TX", county: "Van Zandt"},
    {stateLocation: "TX", county: "Victoria"},
    {stateLocation: "TX", county: "Walker"},
    {stateLocation: "TX", county: "Waller"},
    {stateLocation: "TX", county: "Ward"},
    {stateLocation: "TX", county: "Washington"},
    {stateLocation: "TX", county: "Webb"},
    {stateLocation: "TX", county: "Wharton"},
    {stateLocation: "TX", county: "Wheeler"},
    {stateLocation: "TX", county: "Wichita"},
    {stateLocation: "TX", county: "Wilbarger"},
    {stateLocation: "TX", county: "Willacy"},
    {stateLocation: "TX", county: "Williamson"},
    {stateLocation: "TX", county: "Wilson"},
    {stateLocation: "TX", county: "Winkler"},
    {stateLocation: "TX", county: "Wise"},
    {stateLocation: "TX", county: "Wood"},
    {stateLocation: "TX", county: "Yoakum"},
    {stateLocation: "TX", county: "Young"},
    {stateLocation: "TX", county: "Zapata"},
    {stateLocation: "TX", county: "Zavala"},
    {stateLocation: "UT", county: "Beaver"},
    {stateLocation: "UT", county: "Box Elder"},
    {stateLocation: "UT", county: "Cache"},
    {stateLocation: "UT", county: "Carbon"},
    {stateLocation: "UT", county: "Daggett"},
    {stateLocation: "UT", county: "Davis"},
    {stateLocation: "UT", county: "Duchesne"},
    {stateLocation: "UT", county: "Emery"},
    {stateLocation: "UT", county: "Garfield"},
    {stateLocation: "UT", county: "Grand"},
    {stateLocation: "UT", county: "Iron"},
    {stateLocation: "UT", county: "Juab"},
    {stateLocation: "UT", county: "Kane"},
    {stateLocation: "UT", county: "Millard"},
    {stateLocation: "UT", county: "Morgan"},
    {stateLocation: "UT", county: "Piute"},
    {stateLocation: "UT", county: "Rich"},
    {stateLocation: "UT", county: "Salt Lake"},
    {stateLocation: "UT", county: "San Juan"},
    {stateLocation: "UT", county: "Sanpete"},
    {stateLocation: "UT", county: "Sevier"},
    {stateLocation: "UT", county: "Summit"},
    {stateLocation: "UT", county: "Tooele"},
    {stateLocation: "UT", county: "Uintah"},
    {stateLocation: "UT", county: "Utah"},
    {stateLocation: "UT", county: "Wasatch"},
    {stateLocation: "UT", county: "Washington"},
    {stateLocation: "UT", county: "Wayne"},
    {stateLocation: "UT", county: "Weber"},
    {stateLocation: "VT", county: "Addison"},
    {stateLocation: "VT", county: "Bennington"},
    {stateLocation: "VT", county: "Caledonia"},
    {stateLocation: "VT", county: "Chittenden"},
    {stateLocation: "VT", county: "Essex"},
    {stateLocation: "VT", county: "Franklin"},
    {stateLocation: "VT", county: "Grand Isle"},
    {stateLocation: "VT", county: "Lamoille"},
    {stateLocation: "VT", county: "Orange"},
    {stateLocation: "VT", county: "Orleans"},
    {stateLocation: "VT", county: "Rutland"},
    {stateLocation: "VT", county: "Washington"},
    {stateLocation: "VT", county: "Windham"},
    { stateLocation: "VT", county: "Windsor" },
    { stateLocation: 'VI', county: 'Saint Croix' },
    {stateLocation: 'VI', county: 'Saint John'},
    {stateLocation: 'VI', county: 'Saint Thomas'},
    {stateLocation: "VA", county: "Accomack"},
    {stateLocation: "VA", county: "Albemarle"},
    {stateLocation: "VA", county: "Alleghany"},
    {stateLocation: "VA", county: "Amelia"},
    {stateLocation: "VA", county: "Amherst"},
    {stateLocation: "VA", county: "Appomattox"},
    {stateLocation: "VA", county: "Arlington"},
    {stateLocation: "VA", county: "Augusta"},
    {stateLocation: "VA", county: "Bath"},
    {stateLocation: "VA", county: "Bedford"},
    {stateLocation: "VA", county: "Bland"},
    {stateLocation: "VA", county: "Botetourt"},
    {stateLocation: "VA", county: "Brunswick"},
    {stateLocation: "VA", county: "Buchanan"},
    {stateLocation: "VA", county: "Buckingham"},
    {stateLocation: "VA", county: "Campbell"},
    {stateLocation: "VA", county: "Caroline"},
    {stateLocation: "VA", county: "Carroll"},
    {stateLocation: "VA", county: "Charles City"},
    {stateLocation: "VA", county: "Charlotte"},
    {stateLocation: "VA", county: "Chesterfield"},
    {stateLocation: "VA", county: "Clarke"},
    {stateLocation: "VA", county: "Craig"},
    {stateLocation: "VA", county: "Culpeper"},
    {stateLocation: "VA", county: "Cumberland"},
    {stateLocation: "VA", county: "Dickenson"},
    {stateLocation: "VA", county: "Dinwiddie"},
    {stateLocation: "VA", county: "Essex"},
    {stateLocation: "VA", county: "Fairfax"},
    {stateLocation: "VA", county: "Fauquier"},
    {stateLocation: "VA", county: "Floyd"},
    {stateLocation: "VA", county: "Fluvanna"},
    {stateLocation: "VA", county: "Franklin"},
    {stateLocation: "VA", county: "Frederick"},
    {stateLocation: "VA", county: "Giles"},
    {stateLocation: "VA", county: "Gloucester"},
    {stateLocation: "VA", county: "Goochland"},
    {stateLocation: "VA", county: "Grayson"},
    {stateLocation: "VA", county: "Greene"},
    {stateLocation: "VA", county: "Greensville"},
    {stateLocation: "VA", county: "Halifax"},
    {stateLocation: "VA", county: "Hanover"},
    {stateLocation: "VA", county: "Henrico"},
    {stateLocation: "VA", county: "Henry"},
    {stateLocation: "VA", county: "Highland"},
    {stateLocation: "VA", county: "Isle of Wight"},
    {stateLocation: "VA", county: "James City"},
    {stateLocation: "VA", county: "King and Queen"},
    {stateLocation: "VA", county: "King George"},
    {stateLocation: "VA", county: "King William"},
    {stateLocation: "VA", county: "Lancaster"},
    {stateLocation: "VA", county: "Lee"},
    {stateLocation: "VA", county: "Loudoun"},
    {stateLocation: "VA", county: "Louisa"},
    {stateLocation: "VA", county: "Lunenburg"},
    {stateLocation: "VA", county: "Madison"},
    {stateLocation: "VA", county: "Mathews"},
    {stateLocation: "VA", county: "Mecklenburg"},
    {stateLocation: "VA", county: "Middlesex"},
    {stateLocation: "VA", county: "Montgomery"},
    {stateLocation: "VA", county: "Nelson"},
    {stateLocation: "VA", county: "New Kent"},
    {stateLocation: "VA", county: "Northampton"},
    {stateLocation: "VA", county: "Northumberland"},
    {stateLocation: "VA", county: "Nottoway"},
    {stateLocation: "VA", county: "Orange"},
    {stateLocation: "VA", county: "Page"},
    {stateLocation: "VA", county: "Patrick"},
    {stateLocation: "VA", county: "Pittsylvania"},
    {stateLocation: "VA", county: "Powhatan"},
    {stateLocation: "VA", county: "Prince Edward"},
    {stateLocation: "VA", county: "Prince George"},
    {stateLocation: "VA", county: "Prince William"},
    {stateLocation: "VA", county: "Pulaski"},
    {stateLocation: "VA", county: "Rappahannock"},
    {stateLocation: "VA", county: "Richmond"},
    {stateLocation: "VA", county: "Roanoke"},
    {stateLocation: "VA", county: "Rockbridge"},
    {stateLocation: "VA", county: "Rockingham"},
    {stateLocation: "VA", county: "Russell"},
    {stateLocation: "VA", county: "Scott"},
    {stateLocation: "VA", county: "Shenandoah"},
    {stateLocation: "VA", county: "Smyth"},
    {stateLocation: "VA", county: "Southampton"},
    {stateLocation: "VA", county: "Spotsylvania"},
    {stateLocation: "VA", county: "Stafford"},
    {stateLocation: "VA", county: "Surry"},
    {stateLocation: "VA", county: "Sussex"},
    {stateLocation: "VA", county: "Tazewell"},
    {stateLocation: "VA", county: "Warren"},
    {stateLocation: "VA", county: "Washington"},
    {stateLocation: "VA", county: "Westmoreland"},
    {stateLocation: "VA", county: "Wise"},
    {stateLocation: "VA", county: "Wythe"},
    {stateLocation: "VA", county: "York"},
    {stateLocation: "VA", county: "Alexandria city"},
    {stateLocation: "VA", county: "Bedford city"},
    {stateLocation: "VA", county: "Bristol city"},
    {stateLocation: "VA", county: "Buena Vista city"},
    {stateLocation: "VA", county: "Charlottesville city"},
    {stateLocation: "VA", county: "Chesapeake city"},
    {stateLocation: "VA", county: "Colonial Heights city"},
    {stateLocation: "VA", county: "Covington city"},
    {stateLocation: "VA", county: "Danville city"},
    {stateLocation: "VA", county: "Emporia city"},
    {stateLocation: "VA", county: "Fairfax city"},
    {stateLocation: "VA", county: "Falls Church city"},
    {stateLocation: "VA", county: "Franklin city"},
    {stateLocation: "VA", county: "Fredericksburg city"},
    {stateLocation: "VA", county: "Galax city"},
    {stateLocation: "VA", county: "Hampton city"},
    {stateLocation: "VA", county: "Harrisonburg city"},
    {stateLocation: "VA", county: "Hopewell city"},
    {stateLocation: "VA", county: "Lexington city"},
    {stateLocation: "VA", county: "Lynchburg city"},
    {stateLocation: "VA", county: "Manassas city"},
    {stateLocation: "VA", county: "Manassas Park city"},
    {stateLocation: "VA", county: "Martinsville city"},
    {stateLocation: "VA", county: "Newport News city"},
    {stateLocation: "VA", county: "Norfolk city"},
    {stateLocation: "VA", county: "Norton city"},
    {stateLocation: "VA", county: "Petersburg city"},
    {stateLocation: "VA", county: "Poquoson city"},
    {stateLocation: "VA", county: "Portsmouth city"},
    {stateLocation: "VA", county: "Radford city"},
    {stateLocation: "VA", county: "Richmond city"},
    {stateLocation: "VA", county: "Roanoke city"},
    {stateLocation: "VA", county: "Salem city"},
    {stateLocation: "VA", county: "Staunton city"},
    {stateLocation: "VA", county: "Suffolk city"},
    {stateLocation: "VA", county: "Virginia Beach city"},
    {stateLocation: "VA", county: "Waynesboro city"},
    {stateLocation: "VA", county: "Williamsburg city"},
    {stateLocation: "VA", county: "Winchester city"},
    {stateLocation: "WA", county: "Adams"},
    {stateLocation: "WA", county: "Asotin"},
    {stateLocation: "WA", county: "Benton"},
    {stateLocation: "WA", county: "Chelan"},
    {stateLocation: "WA", county: "Clallam"},
    {stateLocation: "WA", county: "Clark"},
    {stateLocation: "WA", county: "Columbia"},
    {stateLocation: "WA", county: "Cowlitz"},
    {stateLocation: "WA", county: "Douglas"},
    {stateLocation: "WA", county: "Ferry"},
    {stateLocation: "WA", county: "Franklin"},
    {stateLocation: "WA", county: "Garfield"},
    {stateLocation: "WA", county: "Grant"},
    {stateLocation: "WA", county: "Grays Harbor"},
    {stateLocation: "WA", county: "Island"},
    {stateLocation: "WA", county: "Jefferson"},
    {stateLocation: "WA", county: "King"},
    {stateLocation: "WA", county: "Kitsap"},
    {stateLocation: "WA", county: "Kittitas"},
    {stateLocation: "WA", county: "Klickitat"},
    {stateLocation: "WA", county: "Lewis"},
    {stateLocation: "WA", county: "Lincoln"},
    {stateLocation: "WA", county: "Mason"},
    {stateLocation: "WA", county: "Okanogan"},
    {stateLocation: "WA", county: "Pacific"},
    {stateLocation: "WA", county: "Pend Oreille"},
    {stateLocation: "WA", county: "Pierce"},
    {stateLocation: "WA", county: "San Juan"},
    {stateLocation: "WA", county: "Skagit"},
    {stateLocation: "WA", county: "Skamania"},
    {stateLocation: "WA", county: "Snohomish"},
    {stateLocation: "WA", county: "Spokane"},
    {stateLocation: "WA", county: "Stevens"},
    {stateLocation: "WA", county: "Thurston"},
    {stateLocation: "WA", county: "Wahkiakum"},
    {stateLocation: "WA", county: "Walla Walla"},
    {stateLocation: "WA", county: "Whatcom"},
    {stateLocation: "WA", county: "Whitman"},
    {stateLocation: "WA", county: "Yakima"},
    {stateLocation: "WV", county: "Barbour"},
    {stateLocation: "WV", county: "Berkeley"},
    {stateLocation: "WV", county: "Boone"},
    {stateLocation: "WV", county: "Braxton"},
    {stateLocation: "WV", county: "Brooke"},
    {stateLocation: "WV", county: "Cabell"},
    {stateLocation: "WV", county: "Calhoun"},
    {stateLocation: "WV", county: "Clay"},
    {stateLocation: "WV", county: "Doddridge"},
    {stateLocation: "WV", county: "Fayette"},
    {stateLocation: "WV", county: "Gilmer"},
    {stateLocation: "WV", county: "Grant"},
    {stateLocation: "WV", county: "Greenbrier"},
    {stateLocation: "WV", county: "Hampshire"},
    {stateLocation: "WV", county: "Hancock"},
    {stateLocation: "WV", county: "Hardy"},
    {stateLocation: "WV", county: "Harrison"},
    {stateLocation: "WV", county: "Jackson"},
    {stateLocation: "WV", county: "Jefferson"},
    {stateLocation: "WV", county: "Kanawha"},
    {stateLocation: "WV", county: "Lewis"},
    {stateLocation: "WV", county: "Lincoln"},
    {stateLocation: "WV", county: "Logan"},
    {stateLocation: "WV", county: "McDowell"},
    {stateLocation: "WV", county: "Marion"},
    {stateLocation: "WV", county: "Marshall"},
    {stateLocation: "WV", county: "Mason"},
    {stateLocation: "WV", county: "Mercer"},
    {stateLocation: "WV", county: "Mineral"},
    {stateLocation: "WV", county: "Mingo"},
    {stateLocation: "WV", county: "Monongalia"},
    {stateLocation: "WV", county: "Monroe"},
    {stateLocation: "WV", county: "Morgan"},
    {stateLocation: "WV", county: "Nicholas"},
    {stateLocation: "WV", county: "Ohio"},
    {stateLocation: "WV", county: "Pendleton"},
    {stateLocation: "WV", county: "Pleasants"},
    {stateLocation: "WV", county: "Pocahontas"},
    {stateLocation: "WV", county: "Preston"},
    {stateLocation: "WV", county: "Putnam"},
    {stateLocation: "WV", county: "Raleigh"},
    {stateLocation: "WV", county: "Randolph"},
    {stateLocation: "WV", county: "Ritchie"},
    {stateLocation: "WV", county: "Roane"},
    {stateLocation: "WV", county: "Summers"},
    {stateLocation: "WV", county: "Taylor"},
    {stateLocation: "WV", county: "Tucker"},
    {stateLocation: "WV", county: "Tyler"},
    {stateLocation: "WV", county: "Upshur"},
    {stateLocation: "WV", county: "Wayne"},
    {stateLocation: "WV", county: "Webster"},
    {stateLocation: "WV", county: "Wetzel"},
    {stateLocation: "WV", county: "Wirt"},
    {stateLocation: "WV", county: "Wood"},
    {stateLocation: "WV", county: "Wyoming"},
    {stateLocation: "WI", county: "Adams"},
    {stateLocation: "WI", county: "Ashland"},
    {stateLocation: "WI", county: "Barron"},
    {stateLocation: "WI", county: "Bayfield"},
    {stateLocation: "WI", county: "Brown"},
    {stateLocation: "WI", county: "Buffalo"},
    {stateLocation: "WI", county: "Burnett"},
    {stateLocation: "WI", county: "Calumet"},
    {stateLocation: "WI", county: "Chippewa"},
    {stateLocation: "WI", county: "Clark"},
    {stateLocation: "WI", county: "Columbia"},
    {stateLocation: "WI", county: "Crawford"},
    {stateLocation: "WI", county: "Dane"},
    {stateLocation: "WI", county: "Dodge"},
    {stateLocation: "WI", county: "Door"},
    {stateLocation: "WI", county: "Douglas"},
    {stateLocation: "WI", county: "Dunn"},
    {stateLocation: "WI", county: "Eau Claire"},
    {stateLocation: "WI", county: "Florence"},
    {stateLocation: "WI", county: "Fond du Lac"},
    {stateLocation: "WI", county: "Forest"},
    {stateLocation: "WI", county: "Grant"},
    {stateLocation: "WI", county: "Green"},
    {stateLocation: "WI", county: "Green Lake"},
    {stateLocation: "WI", county: "Iowa"},
    {stateLocation: "WI", county: "Iron"},
    {stateLocation: "WI", county: "Jackson"},
    {stateLocation: "WI", county: "Jefferson"},
    {stateLocation: "WI", county: "Juneau"},
    {stateLocation: "WI", county: "Kenosha"},
    {stateLocation: "WI", county: "Kewaunee"},
    {stateLocation: "WI", county: "La Crosse"},
    {stateLocation: "WI", county: "Lafayette"},
    {stateLocation: "WI", county: "Langlade"},
    {stateLocation: "WI", county: "Lincoln"},
    {stateLocation: "WI", county: "Manitowoc"},
    {stateLocation: "WI", county: "Marathon"},
    {stateLocation: "WI", county: "Marinette"},
    {stateLocation: "WI", county: "Marquette"},
    {stateLocation: "WI", county: "Menominee"},
    {stateLocation: "WI", county: "Milwaukee"},
    {stateLocation: "WI", county: "Monroe"},
    {stateLocation: "WI", county: "Oconto"},
    {stateLocation: "WI", county: "Oneida"},
    {stateLocation: "WI", county: "Outagamie"},
    {stateLocation: "WI", county: "Ozaukee"},
    {stateLocation: "WI", county: "Pepin"},
    {stateLocation: "WI", county: "Pierce"},
    {stateLocation: "WI", county: "Polk"},
    {stateLocation: "WI", county: "Portage"},
    {stateLocation: "WI", county: "Price"},
    {stateLocation: "WI", county: "Racine"},
    {stateLocation: "WI", county: "Richland"},
    {stateLocation: "WI", county: "Rock"},
    {stateLocation: "WI", county: "Rusk"},
    {stateLocation: "WI", county: "St. Croix"},
    {stateLocation: "WI", county: "Sauk"},
    {stateLocation: "WI", county: "Sawyer"},
    {stateLocation: "WI", county: "Shawano"},
    {stateLocation: "WI", county: "Sheboygan"},
    {stateLocation: "WI", county: "Taylor"},
    {stateLocation: "WI", county: "Trempealeau"},
    {stateLocation: "WI", county: "Vernon"},
    {stateLocation: "WI", county: "Vilas"},
    {stateLocation: "WI", county: "Walworth"},
    {stateLocation: "WI", county: "Washburn"},
    {stateLocation: "WI", county: "Washington"},
    {stateLocation: "WI", county: "Waukesha"},
    {stateLocation: "WI", county: "Waupaca"},
    {stateLocation: "WI", county: "Waushara"},
    {stateLocation: "WI", county: "Winnebago"},
    {stateLocation: "WI", county: "Wood"},
    {stateLocation: "WY", county: "Albany"},
    {stateLocation: "WY", county: "Big Horn"},
    {stateLocation: "WY", county: "Campbell"},
    {stateLocation: "WY", county: "Carbon"},
    {stateLocation: "WY", county: "Converse"},
    {stateLocation: "WY", county: "Crook"},
    {stateLocation: "WY", county: "Fremont"},
    {stateLocation: "WY", county: "Goshen"},
    {stateLocation: "WY", county: "Hot Springs"},
    {stateLocation: "WY", county: "Johnson"},
    {stateLocation: "WY", county: "Laramie"},
    {stateLocation: "WY", county: "Lincoln"},
    {stateLocation: "WY", county: "Natrona"},
    {stateLocation: "WY", county: "Niobrara"},
    {stateLocation: "WY", county: "Park"},
    {stateLocation: "WY", county: "Platte"},
    {stateLocation: "WY", county: "Sheridan"},
    {stateLocation: "WY", county: "Sublette"},
    {stateLocation: "WY", county: "Sweetwater"},
    {stateLocation: "WY", county: "Teton"},
    {stateLocation: "WY", county: "Uinta"},
    {stateLocation: "WY", county: "Washakie"},
    { stateLocation: "WY", county: "Weston" }
]