import React, { useState, useRef, useEffect } from "react";
import {
  select,
  scaleBand,
  event,
  axisBottom,
  axisLeft,
  axisRight,
  scaleLinear,
  max,
  nest,
  line,
  format
} from "d3";
// import { withStyles } from '@material-ui/core/styles';
// import Checkbox from '@material-ui/core/Checkbox';


// const ColorCheckbox = withStyles({
//   root: {
//     color: props => props.color,
//     padding: '0px',
//     '&$checked': {
//       color: props => props.color,
//       padding: '0px'
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);


export const GroupedBarGraph = props => {
  const allKeys = props.keys.map((item) => {
    return item.variable;
  })

  var data;
  if (props.data) {
    data = props.data;
  } else {
    data = [];
  }

  // eslint-disable-next-line no-unused-vars
  const [keys, setKeys] = useState(allKeys);
  //If a trendline, adding values to state
  const [trendLine, setTrendLine] = useState(true);
  const [trendLineLegend, setTrendLineLegend] = useState(true);
  const [percentLine, setPercentLine] = useState(true);
  const [percentLineLegend, setPercentLineLegend] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colors = {};
  for (let i = 0; i < props.keys.length; i++) {
    colors[props.keys[i].variable] = props.keys[i].color;
  }
  const svgRef = useRef();
  const wrapperRef = useRef();

  const handleLegendClick = (e, key) => {
    if (keys.includes(key)) {
      setKeys(keys.filter((_key) => _key !== key));
    } else {
      var newArray = Array.from(new Set([...keys, key]));
      var sortedArray = [];
      for (let i = 0; i < allKeys.length; i++) {
        for (let j = 0; j < newArray.length; j++) {
          if (allKeys[i] === newArray[j]) {
            sortedArray.push(newArray[j]);
          }
        }
      }
      setKeys(sortedArray);
    }
  };

  // Click handler for trendline in legend 
  const handleLegendLineClick = (e) => {
    // If chartLine is set to false in this component, update props
    props.updateChartLineShowing(!trendLine)

    setTrendLineLegend(!trendLineLegend);
    setTrendLine(!trendLine);
  }

  // Click handler for perventline in legend 
  const handlePercentLegendLineClick = (e) => {
    // If chartLine is set to false in this component, update props
    props.updateChartLineShowing(!percentLine)
    setPercentLineLegend(!percentLineLegend)
    setPercentLine(!percentLine)
  }

  useEffect(() => {
    function endTooltip() {
      const boxes = document.querySelectorAll('.tooltip');

      boxes.forEach(box => {
        box.style.opacity = 0;
      });
    }
    window.addEventListener('scroll', endTooltip)
    //Selecting the container that will hold the D3 graph  
    const svg = select(svgRef.current);

    //Removing the previous lines and data, in order to re-draw them when the filters are changed
    svg.selectAll(".rect").remove();
    svg.selectAll(".group").remove();
    svg.selectAll(".group-label").remove();
    svg.selectAll(".axis").remove();
    svg.selectAll(".category-label").remove();
    svg.selectAll(".line").remove();
    svg.selectAll(".dots").remove();
    svg.selectAll(".myCircle").remove();
    svg.selectAll(".axislabel").remove();

    // Setting the height and width of the graph (responsive to viewport size)
    var margin = { top: 30, right: 0, bottom: 90, left: 80 };
    var width;
    var height;
    if (window.innerWidth > 1200 && window.innerWidth < 1440) {
      width = 900;
      height = 500;
    } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
      width = 900;
      height = 500;
    } else {
      width = 1200;
      height = 500;
    }


    if (props.percentLine) {
      width = 850;
    }

    if (props.report && props.hotlineCases) {
      width = 800;
    } else if (props.report) {
      width = 550;
    }

    //Creating the hover tooltip
    var tooltip = select(wrapperRef.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("color", "black")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("position", "fixed")
      .style("z-index", "-1")
      .style("padding", "5px");
    tooltip.append("div").attr("class", "title");
    tooltip.append("div").attr("class", "label");
    tooltip.append("div").attr("class", "percent");

    // Creating all of the axis calculations
    var x_groups = scaleBand()
      .range([margin.left, width - margin.right])

    var x_categories = scaleBand()
      .range([0, x_groups.bandwidth()])
      .padding(0.12);

    var x_values = scaleBand();

    var y = scaleLinear().range([height - margin.bottom, margin.top]);

    var groups_axis = axisBottom(x_groups);

    // eslint-disable-next-line no-unused-vars
    var categories_axis = axisBottom(x_categories);

    // Creating the data structure for grouped bars
    var nested = nest()
      .key(function (d) {
        return d.name;
      })
      .key(function (d) {
        return d.type;
      })
      .rollup(function (leaves) {
        var nestedData = []
        for (let i = 0; i < keys.length; i++) {
          nestedData.push({
            key: keys[i],
            value: leaves[0][keys[i]],
          })
        }
        return nestedData;
      })
      .entries(data);


    x_groups.domain(
      nested.map(function (d) {
        return d.key;
      })
    );

    var categories = nested[0].values.map(function (d, i) {
      return d.key;
    });
    x_categories.domain(categories).rangeRound([0, x_groups.bandwidth()]);

    x_values.domain(allKeys).rangeRound([0, x_categories.bandwidth()]);

    var yMaxValue;
    if (trendLine && props.line) {
      yMaxValue = max(props.line, (d) => d.irp_youthactivecare);
    } else if (props.yaxisMaxValue) {
      yMaxValue = max(nested, function (d) {
        return max(d.values, function (d) {
          return max(d.value, function (d) {
            return d.value;
          });
        });
      });
    } else {
      yMaxValue = max(data, (d) => d.total);
    }

    y.domain([0, yMaxValue]);

    var ticks = y.ticks(),
      lastTick = ticks[ticks.length - 1],
      newLastTick = lastTick + (ticks[1] - ticks[0]);
    ticks.push(newLastTick);

    y.domain([0, newLastTick]);

    var formatxAxis = format('.0f');
    var yAxis;

    if (yMaxValue < 20) {
      yAxis = axisLeft(y).ticks(yMaxValue).tickFormat(formatxAxis);
    } else {
      yAxis = axisLeft(y).tickValues(ticks);
    }

    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + (height - 75) + ")")
      .call(groups_axis);

    svg
      .append("g")
      .attr("class", "y axis")
      .attr("transform", `translate(${80}, 0 )`)
      .call(yAxis);

    // Add line dividers between each year
    for (let i = 1; i < nested.length; i++) {
      const yearLine = line()([[(x_groups.bandwidth() * i) + 80, height - margin.bottom - 10], [(x_groups.bandwidth() * i) + 80, height - 30]])

      svg
        .append("path")
        // .datum(data)
        .attr("fill", "none")
        .attr("stroke", "#E5E5E5 !important")
        .attr("stroke-width", 4)
        .attr("class", "line divider")
        .attr("d", yearLine);
    }

    if (nested.length > 4) {
      const yearLine4 = line()([[(x_groups.bandwidth() * 4) + 80, height - margin.bottom - 10], [(x_groups.bandwidth() * 4) + 80, height - 30]])

      svg
        .append("path")
        // .datum(data)
        .attr("fill", "none")
        .attr("stroke", "#E5E5E5 !important")
        .attr("stroke-width", 4)
        .attr("class", "line divider")
        .attr("d", yearLine4);
    }

    var groups_g = svg
      .selectAll(".group")
      .data(nested)
      .enter()
      .append("g")
      .attr("class", function (d) {
        return "group group-" + d.key;
      })
      .attr("transform", function (d) {
        return "translate(" + x_groups(d.key) + ",0)";
      });

    var categories_g = groups_g
      .selectAll(".category")
      .data(function (d) {
        return d.values;
      })
      .enter()
      .append("g")
      .attr("class", function (d) {
        return "category category-" + d.key;
      })
      .attr("transform", function (d) {
        // original - x_categories(d.key)
        return "translate(" + x_categories(d.key) + ",0)";
      })

    // eslint-disable-next-line no-unused-vars
    var categories_labels = categories_g
      .selectAll(".category-label")
      .data(function (d) {
        return [d.key];
      })
      .enter()
      .append("text")
      .attr("class", function (d) {
        return "category-label category-label-" + d;
      })
      .attr("x", function (d) {
        // orginal x_categories.bandwidth() / 2
        return x_categories.bandwidth() / 2;
      })
      .attr("y", function (d) {
        return height - 80;
      })
      .style("font-size", ".8em")
      .attr("text-anchor", "middle")
      .text(function (d) {
        var quarterCheck = d.split("");
        if (quarterCheck[0] === "Q") {
          return d;
        }
      });

    var values_g = categories_g
      .selectAll(".value")
      .data(function (d) {
        return d.value;
      })
      .enter()
      .append("g")
      .attr("class", function (d) {
        return "value value-" + d.key;
      })
      .attr("transform", function (d) {
        let placement = x_values(d.key)
        return "translate(" + placement + ",0)";
        // }
      });


    //Hover for bar graph
    function onMouseOver(d) {
      tooltip.style("opacity", 1)
      tooltip.style("z-index", "9999")

      // tooltip
      //   .style("top", (event.clientY - 100) + "px")
      //   .style("left", (event.clientX - 150) + "px");

      var subgroupName = select(this.parentNode).datum().key;
      var label;
      for (let i = 0; i < props.keys.length; i++) {
        if (subgroupName === props.keys[i].variable) {
          label = props.keys[i].name
        }
      }
      var tooltipData = select(this).data()[0];
      var total = tooltipData.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      tooltip.select(".label").text("Total: " + total);
      tooltip.select(".title").text(label);
    }

    function onMouseOut() {
      tooltip.style("opacity", 0)
      tooltip.style("z-index", "-1")

    }

    function onMouseMove(d) {
      // tooltip.style("opacity", 1);
      // tooltip.style("z-index", "9999")

      tooltip
        .style("top", (event.clientY - 100) + "px")
        .style("left", (event.clientX - 150) + "px");
    }


    // eslint-disable-next-line no-unused-vars
    var rects = values_g
      .selectAll(".rect")
      .data(function (d) {
        return [d];
      })
      .enter()
      .append("rect")
      .attr("class", "rect")

      // .attr("width", x_values.bandwidth())
      .attr("x", function (d) {
        return ((x_values.bandwidth() / 2) - (x_values.bandwidth() >= 60 ? 30 : x_values.bandwidth() / 2))
      })
      .attr("width", x_values.bandwidth() >= 60 ? 60 : x_values.bandwidth())
      .attr("y", function (d) {
        return y(d.value);
      })
      .attr("height", function (d) {
        if (d.value === 0) {
          return 0
        } else {
          return height - y(d.value) - margin.bottom;
        }
      })
      .style("fill", function (d) {
        return colors[d.key];
      })
      .on("mouseover", onMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove);


    // **************** TrendLine **************

    if (props.trendLine) {
      var lineData;
      if (props.line) {
        // eslint-disable-next-line
        lineData = props.line.filter((item, key) => {
          if (item.total !== 0) {
            return item;
          }
        })
      } else {
        // eslint-disable-next-line
        lineData = data.filter((item, key) => {
          if (item.total !== 0) {
            return item;
          }
        })
      }

      if (trendLine && lineData.length > 1) {
        //Adding line and circle hover
        var totalLine;
        if (props.line) {
          totalLine = line()
            .x((d) => x_groups(d.name) + x_categories(d.type) + (x_values.bandwidth() * 1.5))
            .y((d) => y(d.irp_youthactivecare));
        } else {
          totalLine = line()
            .x((d) => x_groups(d.name) + x_categories(d.type) + (x_values.bandwidth() * 1.5))
            .y((d) => y(d.total));
        }


        svg
          .append("path")
          .datum(lineData)
          .attr("fill", "none")
          .attr("stroke", "#212121")
          .attr("stroke-width", 2)
          .attr("class", "line")
          .attr("d", totalLine);

        let Tooltip = select(wrapperRef.current)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          .style("z-index", "-1")
          .style("padding", "5px");

        let mouseover = function (d) {
          Tooltip.style("opacity", 1);
          Tooltip.style("z-index", "9999")
        };
        let mousemove = function (d) {
          if (props.lineTitle) {
            Tooltip.html(props.lineTitle + ": " + d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
              .style("top", (event.clientY - 75) + "px")
              .style("left", (event.clientX - 150) + "px");
          } else {
            Tooltip.html(props.trendLineLegend + ": " + d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
              .style("top", (event.clientY - 75) + "px")
              .style("left", (event.clientX - 150) + "px");
          }
        };
        let mouseleave = function (d) {
          Tooltip.style("opacity", 0);
          Tooltip.style("z-index", "-1")

        };

        svg
          .append("g")
          .selectAll("dot")
          .data(lineData)
          .enter()
          .append("circle")
          .attr("class", "myCircle")
          .attr("cx", function (d) {
            return x_groups(d.name) + x_categories(d.type) + (x_values.bandwidth() * 1.5);
          })
          .attr("cy", function (d) {
            return y(props.line ? d.irp_youthactivecare : d.total);
          })
          .attr("r", 3)
          .attr("stroke", "#000000")
          .attr("stroke-width", 3)
          .attr("fill", "#000000")
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave);

      } else {
        svg.selectAll(".trendLine").remove();
        svg.selectAll(".dot").remove();
      }
    }

    // Percent Line
    if (props.percentLine && percentLine) {
      // eslint-disable-next-line
      var percentLineExtraData = data.filter((item) => {
        if (item.total !== 0 && item[allKeys[0]] !== 0 && item[allKeys[1]] !== 0) {
          return item;
        }
      })

      var linePercentData = percentLineExtraData.map((item) => {
        item.percent = ((Number(item[allKeys[0]]) / Number(item[allKeys[1]])) * 100).toFixed(2);
        return item
      })

      var y2 = scaleLinear().range([height - margin.bottom, margin.top]);

      y2.domain([0, 100]);

      var y2Axis = axisRight(y2).tickFormat(d => d + "%")

      svg
        .append("g")
        .attr("class", "y axis")
        .attr("transform", `translate(${width}, 0 )`)
        .call(y2Axis);

      if (percentLine && linePercentData.length > 1) {
        //Adding line and circle hover
        const totalLine = line()
          .x((d) => x_groups(d.name) + x_categories(d.type) + (x_values.bandwidth()))
          .y((d) => y2(d.percent));

        svg
          .append("path")
          .datum(linePercentData)
          .attr("fill", "none")
          .attr("stroke", "#212121")
          .attr("stroke-width", 2)
          .attr("class", "line")
          .attr("d", totalLine);

        let Tooltip = select(wrapperRef.current)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          .style("z-index", "-1")
          .style("padding", "5px");

        let mouseover = function (d) {
          Tooltip.style("opacity", 1);
          Tooltip.style("z-index", "9999")

        };
        let mousemove = function (d) {
          Tooltip.html(`${props.percentLegend}: ` + Math.floor(d.percent) + `%`)
            .style("top", (event.clientY - 75) + "px")
            .style("left", (event.clientX - 150) + "px");
        };
        let mouseleave = function (d) {
          Tooltip.style("opacity", 0);
          Tooltip.style("z-index", "-1")

        };

        svg
          .append("g")
          .selectAll("dot")
          .data(linePercentData)
          .enter()
          .append("circle")
          .attr("class", "myCircle")
          .attr("cx", function (d) {
            return x_groups(d.name) + x_categories(d.type) + (x_values.bandwidth());
          })
          .attr("cy", function (d) {
            return y2(d.percent);
          })
          .attr("r", 3)
          .attr("stroke", "#000000")
          .attr("stroke-width", 3)
          .attr("fill", "#000000")
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave);

      } else {
        svg.selectAll(".trendLine").remove();
        svg.selectAll(".dot").remove();
        svg.selectAll(".myCircle").remove();
      }
    }

    // text label for the x axis
    svg
      .append("text")
      .attr("class", "axislabel")
      .attr("transform", "translate(" + ((width + 60) / 2) + " ," + (height - 20) + ")")
      .style("text-anchor", "middle")
      .text(props.xaxis);

    // text label for the y axis
    svg
      .append("text")
      .attr("class", "axislabel")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(props.yaxis);

  }, [keys, props.yaxisMaxValue, props.trendLineLegend, props.report, trendLine, props.trendLine, allKeys, colors, props.keys, props.xaxis, props.yaxis, data, props.percentLine, percentLine, props.percentLegend, props.line, props.lineTitle, props.hotlineCases]);

  return (
    <div
      className="graph"
      id={props.id}
      style={{ position: "relative", height: 550 }}
    >
      <div
        ref={wrapperRef}
        style={{ position: "relative", height: 500 }}
      >
        <svg ref={svgRef} style={{ width: "100%", height: 500 }}></svg>
      </div>
      <div className="fields" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {allKeys.map((key, item) => {
          var labelId;
          for (let i = 0; i < props.keys.length; i++) {
            if (key === props.keys[i].variable) {
              labelId = props.keys[i].name
            }
          }
          return (
            <div
              key={item}
              className="field"
              style={{ display: "flex", marginLeft: '20px', alignItems: 'center' }}
            >
              {!props.report ?
                <>
                  {props.selectable ?
                    <label
                      htmlFor={key}
                      className='chartCheckboxContainer'
                  >
                      {labelId}
                      <input
                        type="checkbox"
                        className='chartCheckboxes'
                        // checked={keys.includes(key)}
                        defaultChecked={keys.includes(key)}
                      />
                      <span
                        className="chartCheckmark"
                        onClick={(e) => handleLegendClick(e, key)}
                        style={{
                          backgroundColor: keys.includes(key) ? colors[key] : 'transparent',
                          borderColor: keys.includes(key) ? colors[key] : '#0000008a'
                        }}
                      />
                    </label>
                    // <div>
                    //   {keys.includes(key) ? (
                    //     <ColorCheckbox
                    //       checked={true}
                    //       color={colors[key]}
                    //       onClick={(e) => handleLegendClick(e, key)}
                    //     />
                    //   ) : (
                    //       <ColorCheckbox
                    //         color={colors[key]}
                    //         checked={false}
                    //         style={{ padding: "0px" }}
                    //         onClick={(e) => handleLegendClick(e, key)}
                    //       />
                    //     )}
                    //   <label
                    //     htmlFor={key}
                    //     style={{ fontSize: "12px", marginLeft: "5px" }}
                    //   >
                    //     {labelId}
                    //   </label>
                    // </div>
                    :
                    <>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          background: colors[key],
                          marginRight: "10px"
                        }}
                      ></div>
                      <label
                        htmlFor={key}
                        style={{ fontSize: "12px" }} >
                        {labelId}
                      </label>
                    </>
                  }
                </>
                :
                <>
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      background: colors[key],
                      marginRight: "10px"
                    }}
                  ></div>
                  <label
                    htmlFor={key}
                    style={{ fontSize: "12px" }} >
                    {labelId}
                  </label>
                </>
              }
            </div>
          );
        })}
        {data.length > 1 && props.trendLine ?
          <div className="field" style={{ display: "flex", marginLeft: '20px', alignItems: 'center' }}>
            {props.report ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  style={{ display: "flex", width: "15px", height: "15px", backgroundColor: "black", border: "none" }}
                ></button>
                <label
                  style={{ fontSize: "12px", marginLeft: '5px' }}>
                  {props.line ? props.lineTitle : props.trendLineLegend}
                </label>
              </div>
            ) : (
                <label
                      className='chartCheckboxContainer'
                  >
                      {props.line ? props.lineTitle : props.trendLineLegend}
                      <input
                        type="checkbox"
                        className='chartCheckboxes'
                    // checked={trendLineLegend}
                    defaultChecked={trendLineLegend}
                      />
                      <span
                        className="chartCheckmark"
                        onClick={(e) => handleLegendLineClick(e)}
                    style={{
                      backgroundColor: trendLineLegend ? 'black' : 'transparent',
                      borderColor: trendLineLegend ? 'black' : '#0000008a'
                    }}
                      />
                  </label>
                // <div>
                //   { trendLineLegend ? <ColorCheckbox checked={true} color="black" onClick={(e) => handleLegendLineClick(e)} />
                //     : <ColorCheckbox color="black" checked={false} style={{ padding: '0px' }} onClick={(e) => handleLegendLineClick(e)} />}
                //   <label
                //     style={{ fontSize: "12px", marginLeft: '5px' }}>
                //     {props.line ? props.lineTitle : props.trendLineLegend}
                //   </label>
                // </div>
              )}
          </div>
          : null}
        {data.length > 1 && props.percentLine ?
          <div className="field" style={{ display: "flex", marginLeft: '20px', alignItems: 'center' }}>
            {props.report ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  style={{ display: "flex", width: "15px", height: "15px", backgroundColor: "black", border: "none" }}
                ></button>
                <label
                  style={{ fontSize: "12px", marginLeft: '5px' }}>
                  {props.percentLegend}
                </label>
              </div>
            ) : (
                <label
                      className='chartCheckboxContainer'
                  >
                      {props.percentLegend}
                      <input
                        type="checkbox"
                        className='chartCheckboxes'
                    // checked={percentLineLegend}
                    defaultChecked={percentLineLegend}
                      />
                      <span
                        className="chartCheckmark"
                        onClick={(e) => handlePercentLegendLineClick(e)}
                    style={{
                      backgroundColor: percentLineLegend ? 'black' : 'transparent',
                      borderColor: percentLineLegend ? 'black' : '#0000008a'
                    }}
                      />
                  </label>
                // <div>
                //   { percentLineLegend ? <ColorCheckbox checked={true} color="black" onClick={(e) => handlePercentLegendLineClick(e)} />
                //     : <ColorCheckbox color="black" checked={false} style={{ padding: '0px' }} onClick={(e) => handlePercentLegendLineClick(e)} />}

                //   <label
                //     style={{ fontSize: "12px", marginLeft: '5px' }}>
                //     {props.percentLegend}
                //   </label>
                // </div>
              )}
          </div>
          : null}
      </div>
    </div>
  );
};
