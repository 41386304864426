export const LACCKeys = {
    totalServedRegion: [
        {
            variable: 'Alexandria',
            name: 'Alexandria',
            color: '#4E79A7'
        },
        {
            variable: 'Baton Rouge',
            name: 'Baton Rouge',
            color: '#A0CBE8'
        },
        {
            variable: 'Covington I',
            name: 'Covington I',
            color: '#F28E2B'
        },
        {
            variable: 'Covington II',
            name: 'Covington II',
            color: '#FFBE7D'
        },
        {
            variable: 'Lafayette',
            name: 'Lafayette',
            color: '#59A14E'
        },
        {
            variable: 'Lake Charles',
            name: 'Lake Charles',
            color: '#8CD17D'
        },
        {
            variable: 'Monroe',
            name: 'Monroe',
            color: '#B6992D'
        },
        {
            variable: 'Orleans',
            name: 'Orleans',
            color: '#499894'
        },
        {
            variable: 'Shreveport',
            name: 'Shreveport',
            color: '#86BCB6'
        },
        {
            variable: 'Thibodaux',
            name: 'Thibodaux',
            color: '#E15759'
        },
    ],

    totalAndNew: [ // Dark blue, light blue
        {
            variable: 'totalNew',
            name: 'New individuals served',
            color: '#4E79A7',
        },
        {
            variable: 'totalServed',
            name: 'Total individuals served',
            color: '#A0CBE8',
        },
    ],
    totalVictimTypeServed: [
        {
            variable: 'tp_total_confirmed',
            name: 'Confirmed',
            color: '#4E79A7',
        },
        {
            variable: 'tp_total_suspected',
            name: 'Suspected',
            color: '#A0CBE8',
        },
        {
            variable: 'tp_total_high_risk',
            name: 'At risk',
            color: '#F28E2B',
        },
    ],
    totalNewVictimTypeServed: [
        {
            variable: 'totalConfirmed',
            name: 'Confirmed',
            color: '#4E79A7',
        },
        {
            variable: 'totalSuspected',
            name: 'Suspected',
            color: '#A0CBE8',
        },
        {
            variable: 'totalRisk',
            name: 'At risk',
            color: '#F28E2B',
        },
    ],
    referralsAndContacts: [ // Dark blue, light blue
        {
            variable: 'tp_total_cc_referrals',
            name: 'Care Coordination Referrals',
            color: '#4E79A7'
        },
        {
            variable: 'tp_total_anon_contacts',
            name: 'Anonymous Contacts',
            color: '#A0CBE8'
        },
    ],
    locationOfTraff: [

    ],
    screenings: [ // Dark blue, light blue
        {
            variable: 'tp_cse_it_screenings',
            name: 'CSE-IT',
            color: '#4E79A7'
        },
        {
            variable: 'tp_labor_screenings',
            name: 'Labor Trafficking',
            color: '#A0CBE8'
        },
    ],
    demographicsRace: [
        {
            variable: "black_total",
            name: "African American or Black",
            color: "#4E79A7",

        },
        {
            variable: "indian_alaska_total",
            name: "American Indian or Alaska Native",
            color: "#A0CBE8",

        },
        {
            variable: "asian_total",
            name: "Asian",
            color: "#F28E2B",

        },
        {
            variable: "multiracial_total",
            name: "Multiracial",
            color: "#E15759"
        },
        {
            variable: "islander_total",
            name: "Native Hawaiian or Other Pacific Islander",
            color: "#76B7B2"
        },
        {
            variable: "other_race_total",
            name: "Other",
            color: "#59A14E"
        },
        {
            variable: "unknown_race_total",
            name: "Unknown",
            color: "#EDC949",
        },
        {
            variable: "white_total",
            name: "White",
            color: "#B07AA2",
        },
    ],
    demographicsEthnicity: [
        {
            variable: "latino_total",
            name: "Hispanic or Latino",
            color: "#4E79A7",

        },
        {
            variable: "not_latino_total",
            name: "Not Hispanic or Latino",
            color: "#A0CBE8",

        },
        {
            variable: "unknown_ethnicity_total",
            name: "Unknown",
            color: "#F28E2B",

        },
    ],
    demographicsGender: [
        {
            variable: 'female_total',
            name: 'Female',
            color: '#4E79A7'
        },
        {
            variable: 'male_total',
            name: 'Male',
            color: '#A0CBE8'
        },
        {
            variable: 'non_binary_total',
            name: 'Non-binary',
            color: '#F28E2B'
        },
        {
            variable: 'transgender_total',
            name: 'Transgender',
            color: '#FFBE7D'
        },
        {
            variable: 'other_gender_total',
            name: 'Other',
            color: '#59A14E'
        },
        {
            variable: 'unknown_gender_total',
            name: 'Unknown',
            color: '#8CD17D'
        },
    ],
    demographicsAge: [
        {
            variable: 'age_0_12_total',
            name: '0-12',
            color: '#4E79A7'
        },
        {
            variable: 'age_13_17_total',
            name: '13-17',
            color: '#A0CBE8'
        },
        {
            variable: 'age_18_24_total',
            name: '18-24',
            color: '#F28E2B'
        },
        {
            variable: 'age_25_59_total',
            name: '25-59',
            color: '#FFBE7D'
        },
        {
            variable: 'age_60_total',
            name: '60 and older',
            color: '#59A14E'
        },
        {
            variable: 'unknown_age_total',
            name: 'Unknown',
            color: '#8CD17D'
        },
    ],
    demographicsCitizen: [

    ],
    traffickingType: [
        {
            variable: 'victimization_sex_traff', // 'tp_new_confirmed_sex_traff',
            name: 'Sex Trafficking',
            color: '#4E79A7'
        },
        {
            variable: 'victimization_labor_traff', // 'tp_new_confirmed_labor',
            name: 'Labor Trafficking',
            color: '#A0CBE8'
        },
        {
            variable: 'victimization_sex_labor_traff', // 'tp_new_confirmed_sex_labor',
            name: 'Both Sex and Labor Trafficking',
            color: '#F28E2B'
        },
        {
            variable: 'victimization_unknown_traff',
            name: 'Unknown',
            color: '#FFBE7D'
        },
    ],
    coOccurringVictimType: [
        {
            variable: 'adult_physical_assault',
            name: 'Adult Physical Assault',
            color: '#4E79A7'
        },
        {
            variable: 'adult_sexual_assault',
            name: 'Adult Sexual Assault',
            color: '#A0CBE8'
        },
        {
            variable: 'adult_sexually_abused_as_child',
            name: 'Adults sexually abused as children',
            color: '#F28E2B'
        },
        {
            variable: 'arson',
            name: 'Arson',
            color: '#FFBE7D'
        },
        {
            variable: 'bullying',
            name: 'Bullying',
            color: '#59A14E'
        },
        {
            variable: 'burglary',
            name: 'Burglary',
            color: '#8CD17D'
        },
        {
            variable: 'child_physical_abuse',
            name: 'Child physical abuse or neglect',
            color: '#B6992D'
        },
        {
            variable: 'child_pornography',
            name: 'Child pornography',
            color: '#499894'
        },
        {
            variable: 'child_sexual_abuse',
            name: 'Child sexual abuse/assault',
            color: '#86BCB6'
        },
        {
            variable: 'cyber_crimes',
            name: 'Cyber crimes',
            color: '#E15759'
        },
        {
            variable: 'domestic_violence',
            name: 'Domestic and/or family violence',
            color: '#FF9D9A'
        },
        {
            variable: 'dui_incidents',
            name: 'DUI/DWI incidents',
            color: '#79706E'
        },
        {
            variable: 'elder_abuse',
            name: 'Elder abuse or neglect',
            color: '#BAB0AC'
        },
        {
            variable: 'gang_violence',
            name: 'Gang violence',
            color: '#D37295'
        },
        {
            variable: 'hate_crime',
            name: 'Hate crime',
            color: '#FABFD2'
        },
        {
            variable: 'identity_theft',
            name: 'Identity theft/fraud/financial crime',
            color: '#B07AA1'
        },
        {
            variable: 'kidnapping_non_custodial',
            name: 'Kidnapping (noncustodial)',
            color: '#D4A6C8'
        },
        {
            variable: 'kidnapping_custodial',
            name: 'Kidnapping (custodial)',
            color: '#4E79A7'
        },
        {
            variable: 'mass_violence',
            name: 'Mass violence',
            color: '#A0CBE8'
        },
        {
            variable: 'other_vehicular_victimization',
            name: 'Other vehicular victimization',
            color: '#F28E2B'
        },
        {
            variable: 'robbery',
            name: 'Robbery',
            color: '#FFBE7D'
        },
        {
            variable: 'stalking',
            name: 'Stalking/harassment',
            color: '#59A14E'
        },
        {
            variable: 'survivors_of_homicide_victims',
            name: 'Survivors of homicide victims',
            color: '#8CD17D'
        },
        {
            variable: 'teen_dating',
            name: 'Teen dating victimization',
            color: '#B6992D'
        },
        {
            variable: 'terrorism',
            name: 'Terrorism',
            color: '#499894'
        },
        {
            variable: 'other_victimization',
            name: 'Other victimization',
            color: '#86BCB6'
        },
        {
            variable: 'more_than_one_victimization',
            name: 'Multiple types',
            color: '#E15759'
        },
    ],
    specialClassification: [
        {
            variable: 'hard_of_hearing',
            name: 'Deaf/hard of hearing',
            color: '#4E79A7'
        },
        {
            variable: 'homeless',
            name: 'Homeless',
            color: '#A0CBE8'
        },
        {
            variable: 'immigrants',
            name: 'Immigrants/refugees/asylum seekers',
            color: '#F28E2B'
        },
        {
            variable: 'lgbtq',
            name: 'LGBTQ/two-spirit persons',
            color: '#FFBE7D'
        },
        {
            variable: 'veterans',
            name: 'Veterans',
            color: '#59A14E'
        },
        {
            variable: 'disabilities',
            name: 'Victims with disabilities: cognitive/physical/mental',
            color: '#8CD17D'
        },
        {
            variable: 'limited_english',
            name: 'Victims with limited English proficiency',
            color: '#B6992D'
        },
        {
            variable: 'other_special_classification',
            name: 'Other special classification',
            color: '#499894'
        },
    ],
    referralServicesByType: [
        {
            variable: 'referral_services_suspected',
            name: 'Information and Referrals',
            color: '#4E79A7'
        },
        {
            variable: 'personal_advocacy_suspected_victims',
            name: 'Personal Advocacy and Accompaniment',
            color: '#A0CBE8'
        },
        {
            variable: 'emotional_support_suspected_victims',
            name: 'Emotional Support, Safety, and Health',
            color: '#F28E2B'
        },
        {
            variable: 'housing_services_suspected_victims',
            name: 'Shelter and Housing',
            color: '#FFBE7D'
        },
        {
            variable: 'criminal_justice_assist_suspected',
            name: 'Criminal or Civil Justice System Assistance',
            color: '#59A14E'
        },
        {
            variable: 'life_skills_suspected_victims',
            name: 'Education, Employment, and Life Skills',
            color: '#8CD17D'
        },
    ],
    servicesByTypeRelatives: [
        {
            variable: 'referral_services_relatives',
            name: 'Information and Referrals',
            color: '#4E79A7'
        },
        {
            variable: 'personal_advocacy_relatives',
            name: 'Personal Advocacy and Accompaniment',
            color: '#A0CBE8'
        },
        {
            variable: 'emotional_support_relatives',
            name: 'Emotional Support, Safety, and Health',
            color: '#F28E2B'
        },
        {
            variable: 'housing_services_relatives',
            name: 'Shelter and Housing',
            color: '#FFBE7D'
        },
        {
            variable: 'criminal_justice_assist_relatives',
            name: 'Criminal or Civil Justice System Assistance',
            color: '#59A14E'
        },
        {
            variable: 'life_skills_relatives',
            name: 'Education, Employment, and Life Skills',
            color: '#8CD17D'
        },
    ],
    informationReferrals: [
        {
            variable: 'criminal_justice_victim_information',
            name: 'Criminal justice process information',
            color: '#4E79A7'
        },
        {
            variable: 'rights_victim_information',
            name: 'Victim rights information',
            color: '#A0CBE8'
        },
        {
            variable: 'other_program_victim_referrals',
            name: 'Victim service program referrals',
            color: '#F28E2B'
        },
        {
            variable: 'other_resource_victim_referrals',
            name: 'Other referrals',
            color: '#FFBE7D'
        },
        {
            variable: 'substance_victim_information',
            name: 'Substance use treatment information',
            color: '#59A14E'
        },
        {
            variable: 'substance_victim_referral',
            name: 'Substance use treatment referrals',
            color: '#8CD17D'
        },
    ],
    informationReferralsRelatives: [
        {
            variable: 'criminal_justice_relative_information',
            name: 'Criminal justice process information',
            color: '#4E79A7'
        },
        {
            variable: 'rights_relative_information',
            name: 'Victim rights information',
            color: '#A0CBE8'
        },
        {
            variable: 'other_program_relative_referrals',
            name: 'Victim service program referrals',
            color: '#F28E2B'
        },
        {
            variable: 'other_resource_relative_referrals',
            name: 'Other referrals',
            color: '#FFBE7D'
        },
        {
            variable: 'substance_relative_information',
            name: 'Substance use treatment information',
            color: '#59A14E'
        },
        {
            variable: 'substance_relative_referral',
            name: 'Substance use treatment referrals',
            color: '#8CD17D'
        },
    ],
    personalAdvocacy: [
        {
            variable: 'advocacy_medical_care_victims',
            name: 'Medical care advocacy',
            color: '#4E79A7'
        },
        {
            variable: 'advocacy_forensic_exam_victims',
            name: 'Forensic exam advocacy',
            color: '#A0CBE8'
        },
        {
            variable: 'law_interview_advocacy_victims',
            name: 'Law enforcement advocacy',
            color: '#F28E2B'
        },
        {
            variable: 'individual_advocacy_victims',
            name: 'Individual advocacy',
            color: '#FFBE7D'
        },
        {
            variable: 'family_reunification_victims',
            name: 'Family reunification',
            color: '#59A14E'
        },
        {
            variable: 'immigration_advocacy_victims',
            name: 'Immigration assistance advocacy',
            color: '#8CD17D'
        },
        {
            variable: 'intervention_advocacy_victims',
            name: 'Intervention with employer, etc.',
            color: '#B6992D'
        },
        {
            variable: 'dependent_care_victims',
            name: 'Child or dependent care assistance',
            color: '#499894'
        },
        {
            variable: 'transportation_victims',
            name: 'Transportation assistance',
            color: '#86BCB6'
        },
        {
            variable: 'interpreter_advocacy_victims',
            name: 'Interpreter services',
            color: '#E15759'
        },
        {
            variable: 'perform_collection_victims',
            name: 'Forensic Exam',
            color: '#FF9D9A'
        },
    ],
    personalAdvocacyRelatives: [
        {
            variable: 'advocacy_medical_care_relatives',
            name: 'Medical care advocacy',
            color: '#4E79A7'
        },
        {
            variable: 'advocacy_forensic_exam_relatives',
            name: 'Forensic exam advocacy',
            color: '#A0CBE8'
        },
        {
            variable: 'law_interview_advocacy_relatives',
            name: 'Law enforcement advocacy',
            color: '#F28E2B'
        },
        {
            variable: 'individual_advocacy_relatives',
            name: 'Individual advocacy',
            color: '#FFBE7D'
        },
        {
            variable: 'family_reunification_relatives',
            name: 'Family Reunification',
            color: '#59A14E'
        },
        {
            variable: 'immigration_advocacy_relatives',
            name: 'Immigration assistance advocacy',
            color: '#8CD17D'
        },
        {
            variable: 'intervention_advocacy_relatives',
            name: 'Intervention with employer, etc.',
            color: '#B6992D'
        },
        {
            variable: 'dependent_care_relatives',
            name: 'Child or dependent care assistance',
            color: '#499894'
        },
        {
            variable: 'transportation_relatives',
            name: 'Transportation assistance',
            color: '#86BCB6'
        },
        {
            variable: 'interpreter_advocacy_relatives',
            name: 'Interpreter services',
            color: '#E15759'
        },
        {
            variable: 'perform_collection_relatives',
            name: 'Forensic Exam',
            color: '#FF9D9A'
        },
    ],
    emotionalSupport: [
        {
            variable: 'crisis_intervention_victims',
            name: 'Crisis intervention',
            color: '#4E79A7'
        },
        {
            variable: 'hotline_service_victims',
            name: 'Hotline counseling',
            color: '#A0CBE8'
        },
        {
            variable: 'crisis_response_victims',
            name: 'On-scene crisis response',
            color: '#F28E2B'
        },
        {
            variable: 'therapy_services_victims',
            name: 'Individual mental health',
            color: '#FFBE7D'
        },
        {
            variable: 'support_group_victims',
            name: 'Support groups',
            color: '#59A14E'
        },
        {
            variable: 'other_therapy_victims',
            name: 'Other therapy',
            color: '#8CD17D'
        },
        {
            variable: 'financial_assistance_victims',
            name: 'Emergency financial assistance',
            color: '#B6992D'
        },
        {
            variable: 'emergency_medical_care_victims',
            name: 'Emergency medical care',
            color: '#499894'
        },
        {
            variable: 'long_term_medical_care_victims',
            name: 'Long-term medical care',
            color: '#86BCB6'
        },
        {
            variable: 'substance_abuse_victims',
            name: 'Substance abuse services',
            color: '#E15759'
        },
        {
            variable: 'safety_planning_victims',
            name: 'Safety planning',
            color: '#FF9D9A'
        },
        {
            variable: 'case_management_victims',
            name: 'Case management',
            color: '#79706E'
        },
    ],
    emotionalSupportRelatives: [
        {
            variable: 'crisis_intervention_relatives',
            name: 'Crisis intervention',
            color: '#4E79A7'
        },
        {
            variable: 'hotline_service_relatives',
            name: 'Hotline counseling',
            color: '#A0CBE8'
        },
        {
            variable: 'crisis_response_relatives',
            name: 'On-scene crisis response',
            color: '#F28E2B'
        },
        {
            variable: 'therapy_services_relatives',
            name: 'Individual mental health',
            color: '#FFBE7D'
        },
        {
            variable: 'support_group_relatives',
            name: 'Support groups',
            color: '#59A14E'
        },
        {
            variable: 'other_therapy_relatives',
            name: 'Other therapy',
            color: '#8CD17D'
        },
        {
            variable: 'financial_assistance_relatives',
            name: 'Emergency financial assistance',
            color: '#B6992D'
        },
        {
            variable: 'emergency_medical_care_relatives',
            name: 'Emergency medical care',
            color: '#499894'
        },
        {
            variable: 'long_term_medical_care_relatives',
            name: 'Long-term medical care',
            color: '#86BCB6'
        },
        {
            variable: 'substance_abuse_relatives',
            name: 'Substance abuse services',
            color: '#E15759'
        },
        {
            variable: 'safety_planning_relatives',
            name: 'Safety planning',
            color: '#FF9D9A'
        },
        {
            variable: 'case_management_relatives',
            name: 'Case management',
            color: '#79706E'
        },
    ],
    shelterHousing: [
        {
            variable: 'emergency_shelter_victims',
            name: 'Emergency shelter or placement',
            color: '#4E79A7'
        },
        {
            variable: 'transitional_housing_victims',
            name: 'Transitional housing',
            color: '#A0CBE8'
        },
        {
            variable: 'relocation_assistance_victims',
            name: 'Relocation assistance',
            color: '#F28E2B'
        },
        {
            variable: 'rapid_rehousing_victims',
            name: 'Rapid rehousing',
            color: '#FFBE7D'
        },
        {
            variable: 'rental_assistance_victims',
            name: 'Rental assistance',
            color: '#59A14E'
        },
        {
            variable: 'host_homes_victims',
            name: 'Host homes',
            color: '#8CD17D'
        },
        {
            variable: 'other_housing_victims',
            name: 'Other',
            color: '#B6992D'
        },
    ],
    shelterHousingRelatives: [
        {
            variable: 'emergency_shelter_relatives',
            name: 'Emergency shelter or placement',
            color: '#4E79A7'
        },
        {
            variable: 'transitional_housing_relatives',
            name: 'Transitional housing',
            color: '#A0CBE8'
        },
        {
            variable: 'relocation_assistance_relatives',
            name: 'Relocation assistance',
            color: '#F28E2B'
        },
        {
            variable: 'rapid_rehousing_relatives',
            name: 'Rapid rehousing',
            color: '#FFBE7D'
        },
        {
            variable: 'rental_assistance_relatives',
            name: 'Rental assistance',
            color: '#59A14E'
        },
        {
            variable: 'host_homes_relatives',
            name: 'Host homes',
            color: '#8CD17D'
        },
        {
            variable: 'other_housing_relatives',
            name: 'Other',
            color: '#B6992D'
        },
    ],
    criminalCivilJustice: [
        {
            variable: 'criminal_justice_notification_victims',
            name: 'Event notification',
            color: '#4E79A7'
        },
        {
            variable: 'impact_statement_assistance_victims',
            name: 'Impact statement',
            color: '#A0CBE8'
        },
        {
            variable: 'restitution_assistance_victims',
            name: 'Restitution assistance',
            color: '#F28E2B'
        },
        {
            variable: 'civil_legal_assistance_victims',
            name: 'Protection or restraining order',
            color: '#FFBE7D'
        },
        {
            variable: 'family_law_assistance_victims',
            name: 'Family law issues',
            color: '#59A14E'
        },
        {
            variable: 'other_emergency_justice_victims',
            name: 'Other emergency assistance',
            color: '#8CD17D'
        },
        {
            variable: 'immigration_assistance_victims',
            name: 'Immigration assistance',
            color: '#B6992D'
        },
        {
            variable: 'prosecution_interview_victims',
            name: 'Prosecution advocacy',
            color: '#499894'
        },
        {
            variable: 'law_interview_services_victims',
            name: 'Law enforcement advocacy',
            color: '#86BCB6'
        },
        {
            variable: 'criminal_justice_services_victims',
            name: 'Criminal justice advocacy',
            color: '#E15759'
        },
        {
            variable: 'other_legal_advice_victims',
            name: 'Other legal advice',
            color: '#FF9D9A'
        },
        {
            variable: 'repatriation_services_victims',
            name: 'Repatriation',
            color: '#79706E'
        },
        {
            variable: 'public_benefits_law_victims',
            name: 'Public benefits law',
            color: '#BAB0AC'
        },
        {
            variable: 'criminal_record_relief_victims',
            name: 'Criminal record relief',
            color: '#D37295'
        },
        {
            variable: 'victim_rights_services_victims',
            name: "Victim's rights representation",
            color: '#FABFD2'
        },
    ],
    criminalCivilJusticeRelatives: [
        {
            variable: 'criminal_justice_notification_relatives',
            name: 'Event notification',
            color: '#4E79A7'
        },
        {
            variable: 'impact_statement_assistance_relatives',
            name: 'Impact statement',
            color: '#A0CBE8'
        },
        {
            variable: 'restitution_assistance_relatives',
            name: 'Restitution assistance',
            color: '#F28E2B'
        },
        {
            variable: 'civil_legal_assistance_relatives',
            name: 'Protection or restraining order',
            color: '#FFBE7D'
        },
        {
            variable: 'family_law_assistance_relatives',
            name: 'Family law issues',
            color: '#59A14E'
        },
        {
            variable: 'other_emergency_justice_relatives',
            name: 'Other emergency assistance',
            color: '#8CD17D'
        },
        {
            variable: 'immigration_assistance_relatives',
            name: 'Immigration assistance',
            color: '#B6992D'
        },
        {
            variable: 'prosecution_interview_relatives',
            name: 'Prosecution advocacy',
            color: '#499894'
        },
        {
            variable: 'law_interview_services_relatives',
            name: 'Law enforcement advocacy',
            color: '#86BCB6'
        },
        {
            variable: 'criminal_justice_services_relatives',
            name: 'Criminal justice advocacy',
            color: '#E15759'
        },
        {
            variable: 'other_legal_advice_relatives',
            name: 'Other legal advice',
            color: '#FF9D9A'
        },
        {
            variable: 'repatriation_services_relatives',
            name: 'Repatriation',
            color: '#79706E'
        },
        {
            variable: 'public_benefits_law_relatives',
            name: 'Public benefits law',
            color: '#BAB0AC'
        },
        {
            variable: 'criminal_record_relief_relatives',
            name: 'Criminal record relief',
            color: '#D37295'
        },
        {
            variable: 'victim_rights_services_relatives',
            name: "Victim's rights representation",
            color: '#FABFD2'
        },
    ],
    educationEmployment: [
        {
            variable: 'education_services_victims',
            name: 'Education',
            color: '#4E79A7'
        },
        {
            variable: 'job_training_services_victims',
            name: 'Job/vocational training',
            color: '#A0CBE8'
        },
        {
            variable: 'job_readiness_services_victims',
            name: 'Job readiness/employment',
            color: '#F28E2B'
        },
        {
            variable: 'life_skill_services_victims',
            name: 'Life skills',
            color: '#FFBE7D'
        },
        {
            variable: 'employment_services_victims',
            name: 'Supported employment',
            color: '#59A14E'
        },
    ],
    educationEmploymentRelatives: [
        {
            variable: 'education_services_relatives',
            name: 'Education',
            color: '#4E79A7'
        },
        {
            variable: 'job_training_services_relatives',
            name: 'Job/vocational training',
            color: '#A0CBE8'
        },
        {
            variable: 'job_readiness_services_relatives',
            name: 'Job readiness/employment',
            color: '#F28E2B'
        },
        {
            variable: 'life_skill_services_relatives',
            name: 'Life skills',
            color: '#FFBE7D'
        },
        {
            variable: 'employment_services_relatives',
            name: 'Supported employment',
            color: '#59A14E'
        },
    ],
    partnerships: [ // 26
        {
            variable: 'state_law_total_partners',
            name: 'State law enforcement agency',
            color: '#4E79A7'
        },
        {
            variable: 'local_law_total_partners',
            name: 'Local law enforcement agency',
            color: '#A0CBE8'
        },
        {
            variable: 'tribal_law_total_partners',
            name: 'Tribal law enforcement agency',
            color: '#F28E2B'
        },
        {
            variable: 'fbi_total_partners',
            name: 'Federal Bureau of Investigation (FBI)',
            color: '#FFBE7D'
        },
        {
            variable: 'hsi_total_partners',
            name: 'Homeland Security Investigations (HSI)',
            color: '#59A14E'
        },
        {
            variable: 'victim_services_total_partners',
            name: 'Victim services provider/agency',
            color: '#8CD17D'
        },
        {
            variable: 'usao_total_partners',
            name: 'United States Attorney’s Office (USAO)',
            color: '#B6992D'
        },
        {
            variable: 'prosecutor_office_total_partners',
            name: 'State/Local prosecutor/Prosecutors office',
            color: '#499894'
        },
        {
            variable: 'state_agency_total_partners',
            name: 'State agency',
            color: '#86BCB6'
        },
        {
            variable: 'tribal_agency_total_partners',
            name: 'Tribal agency',
            color: '#E15759'
        },
        {
            variable: 'local_agency_total_partners',
            name: 'Local agency',
            color: '#FF9D9A'
        },
        {
            variable: 'immigrant_agency_total_partners',
            name: 'Immigrant/Refugee services agency',
            color: '#79706E'
        },
        {
            variable: 'interpreter_services_total_partners',
            name: 'Interpreters/Language assistance services',
            color: '#BAB0AC'
        },
        {
            variable: 'legal_provider_total_partners',
            name: 'Legal services provider',
            color: '#D37295'
        },
        {
            variable: 'courts_total_partnerts',
            name: 'Courts',
            color: '#FABFD2'
        },
        {
            variable: 'corrections_total_partners',
            name: 'Corrections/Community corrections',
            color: '#B07AA1'
        },
        {
            variable: 'healthcare_total_partners',
            name: 'Medical professionals/healthcare providers',
            color: '#D4A6C8'
        },
        {
            variable: 'mental_health_total_partners',
            name: 'Mental health care providers',
            color: '#4E79A7'
        },
        {
            variable: 'substance_abuse_total_providers',
            name: 'Substance abuse treatment providers',
            color: '#A0CBE8'
        },
        {
            variable: 'child_advocacy_total_partners',
            name: 'Child advocacy centers',
            color: '#F28E2B'
        },
        {
            variable: 'community_total_partners',
            name: 'Community-based service providers',
            color: '#FFBE7D'
        },
        {
            variable: 'faith_based_total_partners',
            name: 'Faith-based organizations',
            color: '#59A14E'
        },
        {
            variable: 'foundations_total_partners',
            name: 'Foundations/Philanthropic organizations',
            color: '#8CD17D'
        },
        {
            variable: 'businesses_total_partners',
            name: 'Businesses',
            color: '#B6992D'
        },
        {
            variable: 'local_community_total_partners',
            name: 'Local community group or community member',
            color: '#499894'
        },
        {
            variable: 'other_partner_total',
            name: 'Other partners',
            color: '#86BCB6'
        },
    ],
    partnershipBuilding: [ // 26
        {
            variable: 'grantee_capacity_increase_activities',
            name: "Increase grantee's capacity to identity/serve victims",
            color: '#4E79A7'
        },
        {
            variable: 'service_access_increase_activities',
            name: "Increase client's access to a range of services",
            color: '#A0CBE8'
        },
        {
            variable: 'traff_awareness_increase_activities',
            name: 'Increase awareness of the issue of trafficking',
            color: '#F28E2B'
        },
        {
            variable: 'expertise_sharing_increase_activities',
            name: 'Increase sharing of professional expertise',
            color: '#FFBE7D'
        }
    ],

    careCoordinationMeetings: [
        {
            variable: 'emergency_staffings',
            name: 'Emergency Response Staffings',
            color: '#4E79A7'
        },
        {
            variable: 'cc_meetings',
            name: 'Care Coordination Meetings',
            color: '#A0CBE8'
        },
        {
            variable: 'new_cases_reviewed',
            name: 'New Cases Reviewed',
            color: '#F28E2B'
        },
        {
            variable: 'advisory_meetings',
            name: 'Advisory Team Meetings',
            color: '#FFBE7D'
        },
    ],
    trainingsAndMeetings: [
        {
            variable: 'professional_dev_training_attended',
            name: 'Professional Development (attended)',
            color: '#4E79A7'
        },
        {
            variable: 'Professional Development (hosted)',
            name: 'Professional Development (hosted)',
            color: '#A0CBE8'
        },
        {
            variable: 'sex_traff_training_attended',
            name: 'Sex trafficking (attended)',
            color: '#F28E2B'
        },
        {
            variable: 'sex_traff_training_hosted',
            name: 'Sex trafficking (hosted)',
            color: '#FFBE7D'
        },
        {
            variable: 'labor_traff_training_attended',
            name: 'Labor trafficking (attended)',
            color: '#59A14E'
        },
        {
            variable: 'labor_traff_training_hosted',
            name: 'Labor trafficking (hosted)',
            color: '#8CD17D'
        },
        {
            variable: 'sex_labor_traff_training_attended',
            name: 'Sex and labor trafficking (attended)',
            color: '#B6992D'
        },
        {
            variable: 'sex_labor_traff_training_hosted',
            name: 'Sex and labor trafficking (hosted)',
            color: '#499894'
        },
        {
            variable: 'other_training_attended',
            name: 'Other (attended)',
            color: '#86BCB6'
        },
        {
            variable: 'other_training_hosted',
            name: 'Other (hosted)',
            color: '#E15759'
        }
    ],
    participantTypes: [
        {
            variable: 'internal',
            name: 'Internal Trainings',
            color: '#4E79A7',
        },
        {
            variable: 'external',
            name: 'External Trainings',
            color: '#A0CBE8',
        },
    ],
    funding: [ // Dark blue, light blue
        {
            variable: 'totalServed',
            name: 'Grant-Funded Participants',
            color: '#4E79A7',
        },
        {
            variable: 'totalNew',
            name: 'Grant-Funded Trainings',
            color: '#A0CBE8',
        },
    ],

    outreachTypes: [
        {
            variable: 'attended_community_meetings_time',
            name: 'Attended Community Meetings',
            color: '#4E79A7',
        },
        {
            variable: 'distributed_newsletters_time',
            name: 'Distributed Newsletters',
            color: '#A0CBE8'
        },
        {
            variable: 'attended_communtiy_events_time',
            name: 'Attended Community Events',
            color: '#F28E2B'
        },
        {
            variable: 'social_media_activities_time',
            name: 'Social Media',
            color: '#FFBE7D'
        },
        {
            variable: 'vulnerable_outreach_time',
            name: 'Vulnerable Outreach',
            color: '#59A14E'
        },
        {
            variable: 'business_outreach_time',
            name: 'Business Outreach',
            color: '#8CD17D'
        },
        {
            variable: 'taskforce_outreach_time',
            name: 'Taskforce Outreach',
            color: '#B6992D'
        },
        {
            variable: 'other_community_activities_time',
            name: 'Other',
            color: '#499894'
        },
    ],

    lawEnforcement: [
        {
            variable: 'new_arrests',
            name: 'New Arrests',
            color: '#4E79A7'
        },
        {
            variable: 'new_investigations',
            name: 'New Investigations',
            color: '#A0CBE8'
        },
        {
            variable: 'ht_prosecutions',
            name: 'HT Prosecutions',
            color: '#F28E2B'
        },
        {
            variable: 'other_new_prosecutions',
            name: 'Other prosecutions',
            color: '#FFBE7D'
        },
        {
            variable: 'familial_relationships',
            name: 'Familial relationships',
            color: '#59A14E'
        },
    ],
}