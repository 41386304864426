import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const RaceGenderComp = (props) => {
  const ref = useRef(null);
  const cache = useRef(props.data);

  var margin = { top: 20, right: 100, bottom: 60, left: 0 };
  var rectHeight = 25;
  var catNum = 10;

  var width = 900 - margin.left - margin.right,
    height = rectHeight * catNum + 30; //changed + 50 to + 30 to move x-axis closer to the y-axis

  useEffect(() => {
    const svg = d3.select(ref.current);
    const data = [...props.data.reverse()];
    data.forEach(function (d) {
      d.female = +d.female;
      d.male = +d.male;
    });

    svg.selectAll("svg").remove();
    const xaxisLines = svg.selectAll(".axis");
    xaxisLines.remove();

    var yScale = d3.scaleBand().range([height, 0]).domain(d3.range(0, catNum));

    var xMax = d3.max(data, (d) => {
      if (Number(d.male) > Number(d.female)) return Number(d.male);
      return Number(d.female + 50);
    });

    var femaleScale = d3
      .scaleLinear()
      .range([0, width / 2])
      .domain([0, xMax]);

    var femaleAxis = d3.axisBottom().scale(femaleScale).ticks(5);
    
    var maleScale = d3
      .scaleLinear()
      .range([0, -width / 2])
      .domain([0, xMax]);
    var maleAxis = d3.axisBottom().scale(maleScale).ticks(5);
    console.log(maleAxis)
    const groupWithData = svg.selectAll(".rectangle-group");
    groupWithData.remove();

    svg.enter();
    //.append("g")
    // .merge(svg)
    svg.attr("transform", "translate(100, 100)");

    var labels = svg.append("g").attr("class", "labels");

    labels
      .append("text")
      .attr("transform", "translate(" + (width / 2 + 5) + "," + -25 + ")")
      .text("Male | Female")
      .attr("text-anchor", "middle");

    femaleAxis = svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(" + width / 2 + "," + height + ")")
      .call(femaleAxis);

    maleAxis = svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(" + width / 2 + "," + height + ")")
      .call(maleAxis);
      console.log(femaleAxis)
      console.log(maleAxis)
    var rectangleGroup = svg
      .selectAll(".rectangle-group")
      .data(data)
      .enter()
      // and group
      .append("g")
      .attr("class", "rectangle-group")
      .attr("transform", (d, idx) => {
        return "translate(0," + yScale(idx + 1) + ")";
      });

    var tooltip = d3
      .select(".racegender")
      .append("div")
      .attr("class", "tooltip")
        .style("opacity", 0)
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        .style("z-index", "-1")
        .style("padding", "5px")
      .style("width", "200px");
    tooltip.append("div").attr("class", "count");
    tooltip.append("div").attr("class", "label");
    tooltip.append("div").attr("class", "percent");

    //
    rectangleGroup
      .append("rect")
      .attr("class", "female")
      .on("mouseover", onFemaleMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove)
      .attr("x", width / 2)
      .attr("height", yScale.bandwidth())
      .attr("width", (d) => {
        return femaleScale(d.female);
      })
      .attr("y", 0)
      .attr("fill", "#4E79A7");

    rectangleGroup
      .append("rect")
      .attr("class", "male")
      .on("mouseover", onMaleMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove)
      .attr("x", (d) => {
        return width / 2 - femaleScale(d.male);
      })
      .attr("height", yScale.bandwidth())
      .attr("width", (d) => {
        return femaleScale(d.male);
      })
      .attr("y", 0)
      .attr("fill", "#76B7B2");

    //
    rectangleGroup
      .append("text")
      .text((d) => {
        return d.b_race;
      })
      .attr("dx", femaleScale(femaleScale.domain()[1]))
      .attr("dy", +20);

    // text label for the x axis
    svg
      .append("text")
      .attr(
        "transform",
        "translate(" + width / 2 + " ," + (height + margin.top + 20) + ")"
      )
      .style("text-anchor", "middle")
      .text("← Number of Screenings →");

    function onFemaleMouseOver(d) {
      tooltip.style("opacity", 1);
      tooltip.select(".count").text("Race: " + d.b_race);
      tooltip.select(".label").text("Number of Females: " + d.female.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      var total = d3.sum(
        data.map((d) => {
          return d.female;
        })
      );
      var percent = Math.round((1000 * d.female) / total) / 10;
      tooltip.select(".percent").text("Percent: " + percent + "%");
    }

    function onMaleMouseOver(d) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", "9999")

      tooltip.select(".count").text("Race: " + d.b_race);
      tooltip.select(".label").text("Number of Males: " + d.male.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      var total = d3.sum(
        data.map((d) => {
          return d.male;
        })
      );
      var percent = Math.round((1000 * d.male) / total) / 10;
      tooltip.select(".percent").text("Percent: " + percent + "%");
    }

    function onMouseOut() {
      tooltip.style("opacity", 0);
      tooltip.style("z-index", "-1")

    }

    function onMouseMove(d) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", "9999")

      tooltip
      .style("top", (d3.event.clientY - 150) + "px")
      .style("left", (d3.event.clientX - 200) + "px");
    }

    // text label for the y axis
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Race");

    cache.current = props.data;
  }, [props.data, catNum, height, width, rectHeight, margin.left, margin.top]);

  return (
    <div id={props.id} className="racegender">
      <svg width={900} height={500}>
        <g ref={ref} transform={`translate(0,50)`} />
      </svg>
    </div>
  );
};
export default RaceGenderComp;
