import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import { withStyles } from '@material-ui/core/styles';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        fontWeight: '300',
        //height: '400px',
        overflowY: 'scroll',
        //width: '500px'
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer'
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function ImbYearFilter(props) {
  const classes = useStyles();
  const [filterSelections, setFilterSelections] = React.useState([]);
  const yearsToUse = ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016',]

  const handleCheckboxChange = (e, item) => {
      setFilterSelections([item]);
  }
  console.log(filterSelections)

  const handleSubmit = () => {
    props.filterCallback({
      name: 'Year',
      filter: 'Businesses of Interest',
      value: filterSelections
    })
    props.handleClose()
  }

  useEffect(() => {
    console.log(props.selectedFilters)
    for (let i = 0; i < props.selectedFilters.length; i++){
      if (props.selectedFilters[i].name === 'Year' && props.selectedFilters[i].filter === 'Businesses of Interest'){
        setFilterSelections(props.selectedFilters[i].value)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
        <div className={classes.container}>
        <div className={classes.checkboxes}>
          {yearsToUse.map((year) => {
            return (
              <FormControlLabel key={year} className="checkboxLabel"
                control={
                  <BlackCheckbox
                    checked={filterSelections.includes(year)}
                    onChange={e => handleCheckboxChange(e, year)} name="checkedA" />
                }
                label={year}
              />
            )
          })}     
        </div> 
       
        <div className={classes.saveButton}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    )
}