import React from "react";
import FacebookRounded from "@mui/icons-material/FacebookRounded";
import XIcon from "@mui/icons-material/X";
import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Button from "@material-ui/core/Button";

import "./Footer.scss";

import { ENUMS } from "../../../Lib/Enums";

export default function Footer(props) {
    const handleSocialMediaBtnClick = (platformName) => {
        let url = "";
        switch (platformName) {
            case "facebook":
                url = ENUMS.URLS.FACEBOOK;
                break;
            case "twitter":
                url = ENUMS.URLS.TWITTER;
                break;
            case "instagram":
                url = ENUMS.URLS.INSTAGRAM;
                break;
            case "linkedin":
                url = ENUMS.URLS.LINKED_IN;
                break;
            default:
                break;
        }

        if (!url) {
            return;
        }

        window.open(url, "_blank");
    };

    return (
        <div className="footer">
            <div className="footer-group">
                <div>
                    Notices
                </div>
                <div className="socials-wrapper">
                    <Button onClick={() => handleSocialMediaBtnClick("facebook")}>
                        <FacebookRounded fontSize="large" />
                    </Button>
                    <Button onClick={() => handleSocialMediaBtnClick("twitter")}>
                        <div className="social-icon">
                            <XIcon fontSize="small" />
                        </div>
                    </Button>
                    <Button onClick={() => handleSocialMediaBtnClick("instagram")}>
                        <div className="social-icon">
                            <Instagram fontSize="medium" />
                        </div>
                    </Button>
                    <Button onClick={() => handleSocialMediaBtnClick("linkedin")}>
                        <div className="social-icon">
                            <div className="social-icon-linkedin">
                                <LinkedIn fontSize="medium" />
                            </div>
                        </div>
                    </Button>
                </div>
            </div>
            <div className="divider"></div>
            <div className="footer-group">
                <div>
                    {props.footerText}
                </div>
                <div className="terms-and-policy-wrapper">
                    <a
                        href={ENUMS.URLS.PRIVACY_POLICY}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Privacy Policy
                    </a>
                    <a
                        href={ENUMS.URLS.TERMS}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Terms of Service
                    </a>
                    <a
                        href={ENUMS.URLS.COOKIES_SETTINGS}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Cookies Settings
                    </a>
                </div>
            </div>
        </div>
    );
}