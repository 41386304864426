import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import '../Filter.css';
import { traffickingTypes } from "../../../../Data/traffickingTypes"
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        fontWeight: '300',
        height: '100%',
        overflowY: 'scroll',
        width: '500px'
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer'
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function TraffickingTypeFilter(props) {
  const classes = useStyles();
  const [filterSelections, setFilterSelections] = React.useState([]);

  const handleCheckboxChange = (e, item) => {
    if (filterSelections.includes(item)){
      let newArray = filterSelections.filter(x => x !== item); 
      setFilterSelections(newArray);
    } else {
      let newArray = [...filterSelections]
      let filteredArray = newArray.filter(x => x !== "All types");
      filteredArray.push(item);
      setFilterSelections(filteredArray);
    }
  }


  const handleAllCheckboxChange = (e) => {
    if (e.target.checked){
      setFilterSelections(["All types"]);
    } else {
      setFilterSelections([]);
    }
  }

  const handleSubmit = () => {
    props.filterCallback({
      name: 'Trafficking Type',
      filter: 'Federal Cases',
      selection: filterSelections,
      value: filterSelections
    })
    props.handleClose()
  }

  useEffect(() => {
    // console.log(props.selectedFilters)
    for (let i = 0; i < props.selectedFilters.length; i++){
      if (props.selectedFilters[i].name === 'Trafficking Type' && props.selectedFilters[i].filter === 'Federal Cases'){
        setFilterSelections(props.selectedFilters[i].value)
      }
    }
  }, [props.selectedFilters])

    return (
        <div className={classes.container}>
          <div className={classes.checkboxes}>
          {/* <FormControl>
            <InputLabel htmlFor="grouped-native-select">Trafficking Types</InputLabel>
              <Select native defaultValue="" label="Trafficking Types" onChange={handleCheckboxChange} value={filterSelections}>
                <option value="All types" >All types</option>
                {traffickingTypes.map((type, key) => {
                  return (
                  <optgroup label={type.name} key={key}>
                    {type.values.map((item, counter) => {
                      return (
                      <option key={counter} value={item}>{item}</option>
                      )
                    })}
                  </optgroup>
                  )
                })}
              </Select>
          </FormControl> */}
                <FormControlLabel className="checkboxLabel"
                  control={ 
                    <BlackCheckbox 
                      checked={filterSelections.includes("All types")} 
                      onChange={e => handleAllCheckboxChange(e, "All types")} name="checkedA" />
                    }
                  label={"All types"}
                />
                {traffickingTypes.map((type, key) => {
                  return (
                      <FormControlLabel key={key} className="checkboxLabel"
                      control={ 
                        <BlackCheckbox 
                          checked={filterSelections.includes(type)} 
                          onChange={e => handleCheckboxChange(e, type)} name="checkedA" />
                        }
                      label={type}
                    />
                  
                  )
                })}
        </div> 
       
        <div className={classes.saveButton}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    )
}