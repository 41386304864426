import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import "./Dendrogram.css";

const DendrogramV2 = (props) => {
  const ref = useRef(null);
  const cache = useRef(d3.hierarchy(props.data));
  var treeLayout = d3
    .cluster()
    .size([props.height, props.width - 1000])
    .separation(function separate(a, b) {
      return a.parent === b.parent ||
        a.parent.parent === b.parent ||
        a.parent === b.parent.parent
        ? 0.4
        : 0.8;
    });
  useEffect(() => {
    console.log('props', props)
    const svg = d3.select(ref.current);
    const myRoot = d3.hierarchy(props.data);
    const data = treeLayout(myRoot);

    const xaxisLines = svg.selectAll(".node--leaf").data(data);
    const grid = svg.selectAll(".grid").data(data);
    xaxisLines.exit().remove();
    grid.exit().remove();

    var max = 0;
    props.data.children.forEach((item) => {
      item.children.forEach((value) => {
        if (Number(value.value) > max) {
          max = Number(value.value);
        }
      });
    });
    if (max === 0) {
      max = 100;
    } else {
      max = max + max * 0.1;
    }

    var xScale = d3.scaleLinear().domain([0, max]).range([0, 900]);
    var xAxis = d3.axisTop().scale(xScale).ticks(10);

    //const prevData = treeLayout(cache.current);

    const groupWithData = svg.selectAll(".node--leaf-g").data(data);
    const groupWithBall = svg.selectAll(".ballG").data(data);
    const groupWithText = svg.selectAll(".node").data(data);
    groupWithText.exit().remove();
    groupWithBall.exit().remove();
    groupWithData.exit().remove();

    var g = svg
      .enter()
      .append("g")
      .merge(svg)
      .attr("transform", "translate(40,0)"); // 40

    if (!props.noLines) {
      // Draw every datum a line connecting to its parent.
      const link = g.selectAll(".link").data(data.descendants().slice(1));
      // eslint-disable-next-line no-unused-vars
      var lines = link
        .enter()
        .append("path")
        .merge(link)
        .attr("class", "link")
        .attr("d", function (d) {
          return (
            "M" +
            d.y +
            "," +
            d.x +
            "C" +
            (d.parent.y + 100) +
            "," +
            d.x +
            " " +
            (d.parent.y + 100) +
            "," +
            d.parent.x +
            " " +
            d.parent.y +
            "," +
            d.parent.x
          );
        });
    }
    // Setup position for every datum; Applying different css classes to parents and leafs.
    const node = g.selectAll(".node").data(data.descendants());
    // eslint-disable-next-line no-unused-vars
    var positions;
    if (!props.noLines) {
      positions = node
        .enter()
        .append("g")
        .merge(node)
        .attr("class", function (d) {
          return "node" + (d.children ? " node--internal" : " node--leaf");
        })
        .attr("transform", function (d) {
          return "translate(" + d.y + "," + d.x + ")";
        })
        .append("circle")
        .attr("r", 4); // // Draw every datum a small circle.
    } else {
      // eslint-disable-next-line no-unused-vars
      positions = node
        .enter()
        .append("g")
        .merge(node)
        .attr("class", function (d) {
          return "node" + (d.children ? " node--internal" : " node--leaf");
        })
        .attr("transform", function (d) {
          return "translate(" + d.y + "," + d.x + ")";
        });
    }

    // // Setup G for every leaf datum.
    const leafNodeG = g
      .selectAll(".node--leaf")
      .append("g")
      .attr("class", "node--leaf-g")
      .attr("transform", "translate(" + 8 + "," + -13 + ")");

    // eslint-disable-next-line no-unused-vars
    let updateRect = leafNodeG
      .append("rect")
      .attr("class", "shadow")
      .style("fill", function (d) {
        return d.data.color;
      })
      .attr("width", 2)
      .attr("height", 30)
      .attr("rx", 2)
      .attr("ry", 2)
      .transition()
      .duration(800)
      .attr("width", function (d) {
        return xScale(d.data.value);
      });
    // Write down text on the colored bars
    // eslint-disable-next-line no-unused-vars
    let updateText = leafNodeG
      .append("text")
      .attr("dy", 19.5)
      .attr("x", 8)
      .style("text-anchor", "start")
      .style("fill", "black")
      .attr("class", "barText")
      .text(function (d) {
        return d.data.id;
      });

    // Write down text for every parent datum(Dots)
    // eslint-disable-next-line no-unused-vars
    var internalNode = g
      .selectAll(".node--internal")
      .append("text")
      .attr("x", 0)
      .attr("y", -10)
      .style("text-anchor", "middle")
      .text(function (d) {
        return d.data.id;
      });
    // eslint-disable-next-line no-unused-vars
    var wrapText = g.selectAll(".node--internal text").call(wrap, 120); //150

    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          // eslint-disable-next-line no-unused-vars
          x = text.attr("x"),
          y = text.attr("y"),
          dy = -2, // parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width && line.length > 1) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }

    // Attach axis on top of the first leaf datum.
    var firstEndNode = g.select(".node--leaf");
    firstEndNode
      .insert("g")
      .attr("class", "xAxis")
      .attr("transform", "translate(" + 7 + "," + -14 + ")")
      .call(xAxis);

    // tick marks down for x-axis
    firstEndNode
      .insert("g")
      .attr("class", "grid")
      .attr("transform", "translate(7," + (props.height - 15) + ")")
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .ticks(10)
          .tickSize(-props.height, 0, 0)
          .tickFormat("")
      );

    // Emphasize the y-axis baseline.(The line up against the last set of vertical dots)
    svg
      .selectAll(".grid")
      .select("line")
      .style("stroke-dasharray", "20,1")
      .style("stroke", "black");

    // // The moving ball
    var ballG = svg
      .insert("g")
      .attr("class", "ballG")
      .attr(
        "transform",
        "translate(" + props.height + "," + Math.ceil(props.height / 2) + ")"
      );
    ballG
      .insert("circle")
      .attr("class", "shadow")
      .style("fill", "steelblue")
      .attr("r", 5);

    ballG
      .insert("text")
      .style("text-anchor", "middle")
      .attr("dy", 5)
      .text("0.0");

    const handleMouseOver = (d) => {
      var leafG = d3.select(this);

      leafG.select("rect").attr("stroke", "#4D4D4D").attr("stroke-width", "2");

      var ballGMovement = ballG
        .transition()
        .duration(400)
        .attr(
          "transform",
          "translate(" +
            (d.y + xScale(d.data.value) + 90) +
            "," +
            (d.x + 1.5) +
            ")"
        );

      ballGMovement.select("circle").style("fill", d.data.color).attr("r", 25);

      ballGMovement.select("text").delay(300).text(Number(d.data.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    };

    const handleMouseOut = () => {
      var leafG = d3.select(this);

      leafG.select("rect").attr("stroke-width", "0");
    };

    d3.selectAll(".node--leaf-g")
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut);

    cache.current = myRoot;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.height]); // , treeLayout, 

  return (
    <div id={props.id}>
      <svg width={props.width} height={props.height}>
        <g
          ref={ref}
          //transform={`translate(${props.outerRadius} ${props.outerRadius})`}
        />
      </svg>
    </div>
  );
};

export default DendrogramV2;
