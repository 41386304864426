// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { laRegionData } from "../../../Data/laRegionJson";

export function CareCoordinationLayer(map, location, data, report, stateLocation) {
  map.on("load", function () {
    if (stateLocation === 'LA') {
      let hoveredStateId = null;
      
      map.addSource("region", {
        type: "geojson",
        data: laRegionData,
      });
      map.addLayer({
        id: "region",
        type: "line",
        source: "region",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#28ADE3",
          "line-width": 1,
        },
      });

      map.addLayer({
        id: "region-fills",
        type: "fill",
        source: "region",
        layout: {},
        paint: {
          "fill-color": "#28ADE3",
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.5,
            0,
          ],
        },
      });

      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map.on("mousemove", "region-fills", function (e) {
        if (hoveredStateId !== e.features[0].id) {
          if (e.features.length > 0) {
            if (hoveredStateId) {
              map.setFeatureState(
                { source: "region", id: hoveredStateId },
                { hover: false }
              );
            }
            hoveredStateId = e.features[0].id;
            map.setFeatureState(
              { source: "region", id: hoveredStateId },
              { hover: true }
            );
          }

          // var coordinates = e.features[0].geometry.coordinates[0][0];
          // if (coordinates.length > 2) {
          //   for (var i = 2; i < coordinates.length; i--) {
          //     coordinates.pop();
          //   }
          // }
          var description = e.features[0].properties.region;
          popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
        }
      });

      map.on("mouseleave", "region-fills", function () {
        if (hoveredStateId) {
          map.setFeatureState(
            { source: "region", id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = null;
        popup.remove();
      });
    }


    if (data.allOrgData) {
      data.allOrgData.map((item) => {
        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
          `<p>${item.organization}</p>
                  ${stateLocation === 'TX' ?
            `<p>County: <span style="font-weight: 300 !important">${item.county} </span></p>`
            :
            `<p>Region: <span style="font-weight: 300 !important">${item.region} </span></p>`
          }
                  <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
        );
        return new mapboxgl.Marker({ color: "#4E79A7", scale: report ? 0.2 : 0.5 })
          .setLngLat([item.long, item.lat])
          .setPopup(popup)
          .addTo(map);
      });
    }
  });
}