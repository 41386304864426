import { zipData } from "../../../Data/texas_zip_codes";
import { zipLAData } from "../../../Data/la_zip_codes";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
var imbData = JSON.parse(JSON.stringify(zipData));
let imbParishData = JSON.parse(JSON.stringify(zipLAData));

export function ImbZipLayer(map, location, data, stateLocations) {
    if (data.counties) {
        var stateLocation = stateLocations === 'TX' ? imbData : imbParishData;
        if (data.zipcodes.length !== 0){
            if (Object.keys(data.zipcodes[0])[0] === "zip"){
                for (let i = 0; i < stateLocation.features.length; i++) {
                    var counter = 0;
                    for (let j = 0; j < data.zipcodes.length; j++) {
                        if (
                            stateLocation.features[i].properties.ZCTA5CE10 ===
                (data.zipcodes[j].zip)
                ) {
                    stateLocation.features[i].properties.SCREENINGS = Number(
                    data.zipcodes[j].total
                );
                counter += 1;
                }
            }
            if (counter === 0){
                stateLocation.features[i].properties.SCREENINGS = 0;
                }
            }
        } 
        } else {
            for (let i = 0; i < stateLocation.features.length; i++) {
                stateLocation.features[i].properties.SCREENINGS = 0;
            }
        }
    let calculatedData = stateLocation;
    map.on("load", function () {
        map.addSource("county", {
        type: "geojson",
        data: calculatedData,
        });
        map.addLayer({
        id: "county",
        type: "line",
        source: "county",
        layout: {
            "line-join": "round",
            "line-cap": "round",
        },
        paint: {
            "line-color": "#28ADE3",
            "line-width": 1,
        },
        });

        map.addLayer({
        id: "county-fills",
        type: "fill",
        source: "county",
        layout: {
            visibility: "visible",
        },
        paint: {
            "fill-color": {
            property: "SCREENINGS",
            stops: [
                [1, "#e9f6fc"],
                [5, "#93d6f1"],
                [10, "#28ade3"],
                [15, "#186788"],
                [20, "#0c3344"],
                [25, "#000000"],
            ],
            },
            "fill-outline-color": "#28ADE3",
            "fill-opacity": 0.9,
        },
        });
    });

    let hoveredStateId = null;
    let popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        });

        map.on("mousemove", "county-fills", function (e) {
        if (hoveredStateId !== e.features[0].properties.ZCTA5CE10) {
            if (e.features.length > 0) {
            if (hoveredStateId) {
                map.setFeatureState(
                { source: "county", id: hoveredStateId },
                { hover: false }
                );
            }
            hoveredStateId = e.features[0].properties.ZCTA5CE10;
            map.setFeatureState(
                { source: "county", id: hoveredStateId },
                { hover: true }
            );
            }
            let description = `
            <h3><strong>Zip Code: ${e.features[0].properties.ZCTA5CE10}</strong></h3> 
            <p>Businesses: <span style="font-weight: 300 !important">${
                e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }</span></p>
               
                    `
        
            popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
        }
        });

        map.on("mouseleave", "county-fills", function () {
        if (hoveredStateId) {
            map.setFeatureState(
            { source: "county", id: hoveredStateId },
            { hover: false }
            );
        }
        hoveredStateId = null;
        popup.remove();
        });
    }

}