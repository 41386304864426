import React, { useState, useEffect, useRef, useCallback } from "react";

import "./PublicSite.scss";

import Banner from "../../Components/PublicSite/Banner/Banner";
import NavigationHeader from "../../Components/PublicSite/NavigationHeader/NavigationHeader";
import Footer from "../../Components/PublicSite/Footer/Footer";
import NewsletterCTABlock from "../../Components/PublicSite/CTABlock/NewsletterCTABlock";
import MobileDisclaimerModal from "../../Components/PublicSite/MobileDisclaimerModal/MobileDisclaimerModal";

import useContentful from "../../Lib/Hooks/useContentful";
import { useAppContext } from "../../Lib/UserContext";
import { isMobile } from "../../Lib/Helpers/Utils";
import { ENUMS } from "../../Lib/Enums";

export default function Layout({ children }) {
  const { setStateLocation } = useAppContext();
  const { getFooterContent } = useContentful();
  const header = useRef(null);
  const body = useRef(null);
  const footer = useRef(null);
  const banner = useRef(null);
  const headerMenuOverlay = useRef(null);

  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const [footerText, setFooterText] = useState("");
  const [shouldShowMobileDisclaimer, setShouldShowMobileDisclaimer] = useState(false);
  const bannerContent = "We're rolling out a fresh new look and feel! You may notice some changes as we update our site.";
  const tabOptions = [
    {
        name: "Policies & Legislation",
        route: ENUMS.ROUTES.POLICY.URL
    },
    {
        name: "Geographic Trends",
        route: ENUMS.ROUTES.GEOGRAPHIC_TRENDS.URL
    },
    {
        name: "Data Explorer",
        route: ENUMS.ROUTES.DATA_EXPLORER.URL
    },
    {
        name: "Partnerships",
        route: ENUMS.ROUTES.PARTNERSHIPS.URL
    }
  ];

  const resizeOverlay = useCallback(() => {
    if (!headerMenuOverlay.current || !header.current) {
      return;
    }

    if (shouldShowBanner && !banner.current) {
      return;
    }

    const bannerHeight = shouldShowBanner
      ? banner.current.clientHeight
      : 0;

    const headerHeight = header.current.clientHeight;

    header.current.style.top = `${bannerHeight}px`;
    body.current.style.marginTop = `${headerHeight}px`;
    headerMenuOverlay.current.style.top = `${bannerHeight + headerHeight}px`;
  }, [shouldShowBanner]);

  const repositionHeader = useCallback(() => {
      if (!header.current) {
        return;
      }

      if (shouldShowBanner && !banner.current) {
        return;
      }

      const scrolled = document.scrollingElement.scrollTop;
      const headerHeight = header.current.clientHeight;
      const bannerHeight = shouldShowBanner
          ? banner.current.clientHeight
          : 0;
      if (scrolled < bannerHeight) {
        header.current.style.top = `${bannerHeight - scrolled}px`;
        headerMenuOverlay.current.style.top = `${bannerHeight + headerHeight}px`;
      } else {
        header.current.style.top = "0px";
        headerMenuOverlay.current.style.top = `${headerHeight}px`;
      }
  }, [header, banner, shouldShowBanner]);

  const handleMobileDisclaimerMobileClose = () => {
    setShouldShowMobileDisclaimer(false);
  };

  useEffect(() => {
    if (isMobile()) {
      setShouldShowMobileDisclaimer(true);
    }

    (async () => {
      const { footerText } = await getFooterContent();
      setFooterText(footerText);
    })();
  }, [getFooterContent]);

  useEffect(() => {
    setTimeout(() => {
      resizeOverlay();
      repositionHeader();
    }, 300);
    window.addEventListener("resize", resizeOverlay);

    return () => {
      window.removeEventListener("resize", resizeOverlay);
    }
  });

  useEffect(() => {
    if (shouldShowBanner) {
      window.addEventListener("scroll", repositionHeader);
    } else {
      window.removeEventListener("scroll", repositionHeader);
    }

    return () => {
      window.removeEventListener("scroll", repositionHeader);
    }
  }, [shouldShowBanner, repositionHeader]);

  const closeBanner = () => {
    window.localStorage.setItem("isBannerClosed", true);
    setShouldShowBanner(false);
  };

  useEffect(() => {
    const isBannerClosed = window.localStorage.getItem("isBannerClosed");
    setShouldShowBanner(!isBannerClosed);
    setStateLocation("National");
  }, [setStateLocation]);

  return (
    <div className='publicSiteLayout'>
      <Banner
        innerRef={banner}
        className={shouldShowBanner ? "" : "banner-hidden"}
        content={bannerContent}
        closeBanner={closeBanner}
      />
      <div className="layout-wrapper">
        <NavigationHeader
          innerRef={header}
          innerOverlayRef={headerMenuOverlay}
          isBannerVisible={shouldShowBanner}
          tabOptions={tabOptions}
        />
        <div
          ref={body}
          className="layout-body"
        >
          {children}
        </div>
        <div
          ref={footer}
          className="layout-footer"
        >
          <NewsletterCTABlock />
          <Footer footerText={footerText} />
        </div>
      </div>
      <MobileDisclaimerModal
        open={shouldShowMobileDisclaimer}
        handleClose={handleMobileDisclaimerMobileClose}
      />
    </div>
  );
}
