import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import './Filter.css';
import { withStyles } from '@material-ui/core/styles';

const BlackCheckbox = withStyles({
    root: {
        color: 'black',
        '&$checked': {
            color: 'black',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        fontWeight: '300',
        height: '400px',
        overflowY: 'scroll',
        // width: '500px'
    },
    filterWrapper: {
        display: 'flex'
    },
    active: {
        cursor: 'pointer'
    },
    inactive: {
        fontWeight: '400 !important',
        cursor: 'pointer'
    },
    checkboxLabel: {
        padding: '0px'
    },
    checkboxes: {
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column'
    },
    saveButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px'
    }
}));

export default function TimeYearFilter(props) {
    const classes = useStyles();
    const [filterSelections, setFilterSelections] = React.useState(['All Years']);

    const handleCheckboxChange = (e, item) => {
        if (item === 'All Years') {
            if (e.target.checked) {
                setFilterSelections(["All Years"]);
            } else {
                setFilterSelections([]);
            }
        } else {
            if (filterSelections.includes(item)) {
                let newArray = filterSelections.filter(x => x !== item);
                setFilterSelections(newArray);
            } else {
                let newArray = [...filterSelections]
                let filteredArray = newArray.filter(x => x !== "All Years");
                filteredArray.push(item);
                setFilterSelections(filteredArray);
            }
        }
    }

    const handleSubmit = () => {
        props.filterCallback({
            name: props.filterName,
            filter: props.page,
            selection: filterSelections,
            value: filterSelections
        })
        props.handleClose()
    }

    useEffect(() => {
        props.selectedFilters.forEach(sf => {
            if (sf.name === props.filterName && sf.filter === props.page) {
                setFilterSelections(sf.value)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`${classes.container} time-year-filter`}>
            <div className={classes.checkboxes}>
                {props.options?.map(option => (
                    <FormControlLabel key={option} className="checkboxLabel"
                        control={
                            <BlackCheckbox
                                checked={filterSelections.includes(option)}
                                onChange={e => handleCheckboxChange(e, option)} name="checkedA" />
                        }
                        label={option}
                    />
                ))}
            </div>

            <div className={classes.saveButton}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
          </Button>
            </div>
        </div>
    )
}