import React from "react";
import './form.css'

import { regions } from "./Data/ccFormData";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    "&.Mui-focused": {
      border: "2px solid #28ADE3",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }
});

export default function OverviewInformation(props) {
  //  const [multiSelect, setmultiSelect] = useState([]);
  const classes = useStyles();

  // const handleMultiSelect = (event) => {
  //   const { options } = event.target;
  //   console.log("options", options);
  //   setmultiSelect(event.target.value);
  //   props.addData(event.target.name, multiSelect);
  // };

  const handleChange = (event) => {
    props.addData(event.target.name, event.target.value, event.target.type);
  };

  return (
    <div className={`${props.review ? '' : 'formWrapper'}`}>
      <div className={`${props.review ? 'reviewEditBtnWrap' : ''}`}>
        <h1 className={`${props.review ? 'h2' : 'h1'}`}>Overview information</h1>

        {props.review &&
          <Button
            className='reviewEditBtns'
            name="Edit Section Button"
            onClick={(e) => props.handleSideBarClick(0)}
          >Edit</Button>
        }
      </div>

      <div className={`${props.review ? 'marginB60' : ''}`}>
        <FormControl fullWidth className='marginB10'>
          <Typography>Region</Typography>
          <Select
            required
            name="region"
            value={props.data?.region ?? 'Name'}
            defaultValue='Name'
            onChange={handleChange}
            className={`inputPadding14 ${props.data?.region?.length > 0 ? '' : 'placeholder' }`}
            variant="outlined"
            inputProps={{ className: classes.root, readOnly: props.review }}
          >
            <MenuItem value='Name' disabled>Name</MenuItem>
            {regions.map((region) => {
              return (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth className='marginB10'>
          <Typography >Your email address</Typography>
          <TextField
            required
            // fullWidth
            name="email"
            value={props.data?.email ?? ''}
            placeholder="Email address"
            onChange={handleChange}
            className='inputPadding14'
            variant="outlined"
            inputProps={{ className: classes.root, readOnly: props.review }}
          />
        </FormControl>

        <FormControl fullWidth className='marginB10'>
          <Typography>Quarter</Typography>
          <Select
            required
            name="quarter"
            value={props.data?.quarter ?? 'Select'}
            defaultValue='Select'
            onChange={handleChange}
            className={`inputPadding14 ${props.data?.quarter?.length > 0 ? '' : 'placeholder'}`}
            variant="outlined"
            inputProps={{ className: classes.root, readOnly: props.review }}
          >
            <MenuItem value='Select' disabled>Select</MenuItem>
            {['Q1: Jan 1 - Mar 31', 'Q2: Apr 1 - Jun 30', 'Q3: Jul 1 - Sep 30', 'Q4: Oct 1 - Dec 31'].map((quarter) => {
              return (
                <MenuItem key={quarter} value={quarter}>
                  {quarter}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl required fullWidth>
          <Typography>Calendar Year</Typography>
          <Select
            name="year"
            value={props.data?.year ?? 'Select'}
            onChange={handleChange}
            defaultValue='Select'
            className={`inputPadding14 ${props.data?.year?.length > 0 ? '' : 'placeholder'}`}
            variant="outlined"
            inputProps={{ className: classes.root, readOnly: props.review }}
          >
            <MenuItem value='Select' disabled>Select</MenuItem>
            {['2023', '2024'].map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
