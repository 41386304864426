import React, { useEffect } from 'react';
import { useAppContext } from '../../../../Lib/UserContext'
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
//import { orgList } from "../../../Data/orgList";
import { withStyles } from '@material-ui/core/styles';
import { availableDashboards } from '../../../../Data/availableDashboards'

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        fontWeight: '300',
        overflowY: 'scroll'
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer'
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function LocationTypeFilter(props) {
  const classes = useStyles();
  const { stateLocation } = useAppContext()
  const [filterSelections, setFilterSelections] = React.useState([]);

  let options = []
  Object.keys(availableDashboards.filter(d => d.stateLocation === stateLocation)[0]?.mapsIndex['Federal Cases']['Location ']).forEach(f => options.push(f))

  const handleCheckboxChange = (e, item) => {
    setFilterSelections(item);
  }

  const handleSubmit = () => {
    props.filterCallback({
      name: 'Location ',
      filter: 'Federal Cases',
      selection: filterSelections,
      value: [filterSelections]
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      if (props.selectedFilters[i].name === 'Location ' && props.selectedFilters[i].filter === 'Federal Cases'){
        setFilterSelections(props.selectedFilters[i].value)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
        <div className={classes.container}>
        <div className={classes.checkboxes}>
          {options?.map(option => (
            <FormControlLabel key={option} className="checkboxLabel"
              control={
                <BlackCheckbox
                  checked={filterSelections.includes(option)}
                  onChange={e => handleCheckboxChange(e, option)} name="checkedA" />
              }
              label={option}
            />
          ))}
        </div>

        <div className={classes.saveButton}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    )
}