export const laservicesdata = {
    individualsServed: [
        {
            variable: "newindividuals",
            name: "New Individuals",
            color: "#4E79A7"
        },
        {
            variable: "total",
            name: "Total Individuals",
            color: "#a0cbe8"
        },
    ],
    victimStatus: [
        {
            variable: "confirmed",
            name: "Confirmed",
            color: "#4E79A7"
        },
        {
            variable: "suspected",
            name: "Suspected",
            color: "#F28E2B"
        },
        {
            variable: "unknown",
            name: "Unknown",
            color: "#E15759"
        },
        
    ],
    typeOfTrafficking: [
        {
            variable: "sextrafficking",
            name: "Sex Trafficking",
            color: "#4E79A7"
        },
        {
            variable: "labortrafficking",
            name: "Labor Trafficking",
            color: "#A0CBE8"
        },
        {
            variable: "both",
            name: "Both Sex and Labor Trafficking",
            color: "#F28E2B"
        }, {
            variable: "other",
            name: "Other",
            color: "#FFBE7D"
        },
        {
            variable: "unknown",
            name: "Unknown",
            color: "#59A14E"
        },

    ],
    gender: [
        {
            variable: "Female",
            name: "Female",
            color: "#4E79A7"
        }, {
            variable: "Male",
            name: "Male",
            color: "#A0CBE8"
        },
        {
            variable: "Non-binary",
            name: "Non-binary",
            color: "#F28E2B"
        },
        {
            variable: "Transgender",
            name: "Transgender",
            color: "#FFBE7D"
        },
        {
            variable: "Other",
            name: "Other",
            color: "#E15759"
        },
        {
            variable: "Unknown",
            name: "Unknown",
            color: "#76B7B2"
        },
    ],
    race: [
        {
            variable: "African American or Black",
            name: "African American or Black",
            color: "#4E79A7",
            
        }, {
            variable: "American Indian or Alaska Native",
            name: "American Indian or Alaska Native",
            color: "#A0CBE8"
        },
        {
            variable: "Asian",
            color: "#F28E2B",
            name: "Asian"
        },
        {
            variable: "Middle Eastern or North African",
            color: "#E15759",
            name: "Middle Eastern or North African"
        },
        {
            variable: "Multiracial",
            name: "Multiracial",
            color: "#76B7B2"
        },
        {
            variable: "Native Hawaiian or Other Pacific Islander",
            name: "Native Hawaiian or Other Pacific Islander",
            color: "#59A14E"
        },
        {
            variable: "Other",
            name: "Other",
            color: "#EDC949"
        },
        {
            variable: "Unknown",
            name: "Unknown",
            color: "#B07AA2"
        },
        {
            variable: "White",
            name: "White",
            color: "#FF9DA7"
        },
    ],
    ethnicity: [
        {
            variable: "Hispanic or Latino",
            name: "Hispanic or Latino",
            color: "#4E79A7"
        },
        {
            variable: "Not Hispanic or Latino",
            name: "Not Hispanic or Latino",
            color: "#F28E2B"
        },
        {
            variable: "Unknown",
            name: "Unknown",
            color: "#E15759"
        },
    ],
    age: [
        {
            variable: "0-4",
            name: "0-4",
            xaxis: "0-4",
            color: "#4E79A7",

        },
        {
            variable: "5-9",
            name: "5-9",
            xaxis: "5-9",
            color: "#A0CBE8"
        },
        {
            variable: "10-14",
            name: "10-14",
            xaxis: "10-14",
            color: "#F28E2B"
        },
        {
            variable: "15-17",
            name: "15-17",
            xaxis: "15-17",
            color: "#FFBE7D",
        },
        {
            variable: "18-20",
            name: "18-20",
            xaxis: "18-20",
            color: "#59A14E"
        },
        {
            variable: "21-24",
            name: "21-24",
            xaxis: "21-24",
            color: "#8CD17D"
        },
        {
            variable: "25-29",
            name: "25-29",
            xaxis: "25-29",
            color: "#B6992D"
        },
        {
            variable: "30-39",
            name: "30-39",
            xaxis: "30-39",
            color: "#499894"
        },
        {
            variable: "40-49",
            name: "40-49",
            xaxis: "40-49",
            color: "#86BCB6"
        },
        {
            variable: "50-59",
            name: "50-59",
            xaxis: "50-59",
            color: "#E15759",

        },
        {
            variable: "60-69",
            name: "60-69",
            xaxis: "60-69",
            color: "#FF9D9A"
        },
        {
            variable: "70+",
            name: "70+",
            xaxis: "70+",
            color: "#FF9DA7"
        },
        {
            variable: "Unknown",
            name: "Unknown",
            xaxis: "Unknown",
            color: "#79706E",
        },
    ],
    typeTraffAge: [
        {
            variable: "sextrafficking",
            name: "Sex Trafficking",
            color: "#4E79A7"
        },
        {
            variable: "labortrafficking",
            name: "Labor Trafficking",
            color: "#A0CBE8"
        },
        {
            variable: "both",
            name: "Both Sex and Labor Trafficking",
            color: "#F28E2B"
        },
        {
            variable: "unknown",
            name: "Unknown",
            color: "#FFBE7D"
        },
        {
            variable: "other",
            name: "Other",
            color: "#59A14E"
        },
    ],
    sexOrientation: [
        {
            variable: "Bisexual",
            name: "Bisexual",
            color: "#4E79A7"
        }, {
            variable: "Gay",
            name: "Gay",
            color: "#A0CBE8"
        },
        {
            variable: "Heterosexual",
            name: "Heterosexual",
            color: "#F28E2B"
        },
        {
            variable: "Lesbian",
            name: "Lesbian",
            color: "#E15759"
        },
        {
            variable: "Other",
            name: "Other",
            color: "#76B7B2"
        },
        {
            variable: "Asked but Unknown",
            name: "Asked but Unknown",
            color: "#59A14E"
        },
        {
            variable: "Unknown",
            name: "Unknown",
            color: "#EDC949"
        },
    ],
    educationLevel: [
        {
            variable: "Pre-K",
            name: "Pre-K",
            xaxis: "Pre-K",
            color: "#4E79A7",
            
        },
        {
            variable: "K",
            name: "K",
            xaxis: "K",
            color: "#A0CBE8"
        },
        {
            variable: "1",
            name: "1",
            xaxis: "1",
            color: "#F28E2B"
        },
        {
            variable: "2",
            name: "2",
            xaxis: "2",
            color: "#FFBE7D",
        },
        {
            variable: "3",
            name: "3",
            xaxis: "3",
            color: "#59A14E"
        },
        {
            variable: "4",
            name: "4",
            xaxis: "4",
            color: "#8CD17D"
        },
        {
            variable: "5",
            name: "5",
            xaxis: "5",
            color: "#B6992D"
        },
        {
            variable: "6",
            name: "6",
            xaxis: "6",
            color: "#499894"
        },
        {
            variable: "7",
            name: "7",
            xaxis: "7",
            color: "#86BCB6"
        },
        {
            variable: "8",
            name: "8",
            xaxis: "8",
            color: "#E15759",

        },
        {
            variable: "9",
            name: "9",
            xaxis: "9",
            color: "#FF9D9A"
        },
        {
            variable: "10",
            name: "10",
            xaxis: "10",
            color: "#FF9DA7"
        },
        {
            variable: "11",
            name: "11",
            xaxis: "11",
            color: "#79706E",
        },
        {
            variable: "12",
            name: "12",
            xaxis: "12",
            color: "#BAB0AC"
        },
        {
            variable: "Some high school/no diploma",
            name: "Some high school/no diploma",
            xaxis: "Some HS",
            color: "#D37295"
        },
        {
            variable: "High school diploma/GED",
            name: "High school diploma/GED",
            xaxis: "Diploma/GED",
            color: "#4E79A7"
        },
        {
            variable: "Some college credit/no degree",
            name: "Some college credit/no degree",
            xaxis: "Some college",
            color: "#A0CBE8"
        },
        {
            variable: "Trade/technical/vocational training",
            name: "Trade/technical/vocational training",
            xaxis: "Vocation training",
            color: "#F28E2B"
        },
        {
            variable: "Associate's degree",
            name: "Associate's degree",
            xaxis: "Assoc degree",
            color: "#FFBE7D",
        },
        {
            variable: "College/advanced degree",
            name: "College/advanced degree",
            xaxis: "College degree",
            color: "#59A14E"
        },
    ],
    disability: [
        {
            variable: "None",
            name: "None",
            color: "#4E79A7",

        },
        {
            variable: "Physical Disability",
            name: "Physical Disability",
            color: "#A0CBE8"
        },
        {
            variable: "Intellectual Disability",
            name: "Intellectual Disability",
            color: "#F28E2B"
        },
        {
            variable: "Both",
            name: "Both",
            color: "#FFBE7D",
        },
        {
            variable: "Other",
            name: "Other",
            color: "#59A14E"
        },
        {
            variable: "Unknown",
            name: "Unknown",
            color: "#8CD17D"
        }
    ],
    immigrationStatus: [
        {
            variable: "U.S. Citizen",
            name: "U.S. Citizen",
            color: "#4E79A7",

        },
        {
            variable: "Lawful Permanent Resident",
            name: "Lawful Permanent Resident",
            color: "#A0CBE8"
        },
        {
            variable: "Temporary Visitor",
            name: "Temporary Visitor",
            color: "#F28E2B"
        },
        {
            variable: "Undocumented Immigrant",
            name: "Undocumented Immigrant",
            color: "#FFBE7D",
        },
        {
            variable: "Unknown",
            name: "Unknown",
            color: "#59A14E"
        },
    ],
    lengthService: [ 
        { 
            variable : "new_individuals_total" ,
            name: "Total New Individuals",
            color: "#4E79A7"
        },{ 
            variable : "new_individuals_served_0_12" ,
            name: "Ages 0 - 12",
            color: "#F28E2B"
        },
        { 
            variable : "new_individuals_served_13_17" ,
            name: "Ages 13 - 17",
            color: "#E15759"
        },
        { 
            variable : "new_individuals_served_18_24" ,
            name: "Ages 18 - 24",
            color: "#76B7B2"
        },
        { 
            variable : "new_individuals_served_25" ,
            name: "Ages 25+",
            color: "#59A14E"
        },
        
    ], 
    exitReason: [
        {
            variable : "rs_law_enforcement" ,
            name: "Law Enforcement",
            color: "#4E79A7"
        },
        { 
            variable : "rs_jj" ,
            name: "Juvenile Justice",
            color: "#A0CBE8"
        },
        { 
            variable : "rs_dfps",
            name: "DFPS",
            color: "#F28E2B"
        }, 
        {
            variable : "rs_csey" ,
            name: "CSEY Advocate",
            color: "#FFBE7D"
        },
        { 
            variable : "rs_self" ,
            name: "Self, Peer-Referral, Walk-in",
            color: "#59A14E"
        },
        { 
            variable : "rs_school" ,
            name: "School",
            color: "#8CD17D"
        },
        { 
            variable : "rs_cbc" ,
            name: "Community Based Care (CBC) contractor",
            color: "#B6992D"
        }, 
        {
            variable : "rs_guardian" ,
            name: "Caretaker, Legal guardian",
            color: "#499894"
        },
        {
            variable : "rs_cc" ,
            name: "Care coordinator, CC staffing",
            color: "#86BCB6"
        },
        {
            variable : "rs_medical" ,
            name: "Medical provider",
            color: "#E15759"
        },
        { 
            variable : "rs_youth_shelter" ,
            name: "Youth shelter, SOP",
            color: "#FF9D9A"
        }, 
        {
            variable : "rs_adult_shelter" ,
            name: "Adult shelter, Homeless coalition",
            color: "#79706E"
        },
        { 
            variable : "rs_social_services" ,
            name: "TLP, ILP, RTC, Other social services",
            color: "#BAB0AC"
        },
        {
            variable : "rs_other" ,
            name: "Other",
            color: "#D37295"
        }
    ],
}