import React, { Fragment, useEffect, useState } from "react";
import Button from "../Button/Button";

import "./Hero.scss";

export default function Hero(props) {
    const [bodyContentList, setBodyContentList] = useState([]);
    useEffect(() => {
        setBodyContentList(props.body?.split("\n")?.filter((str) => !!str.trim()));
    }, [props.body]);

    const onClickHeaderBtn = () => {
        window.open(props.buttonLink || "", "_target");
    };

    return (
        <div className="public-site-hero-container">
            <div className="public-site-hero-wrapper site-header">
                {props.title}
                <div className="public-site-hero-body site-header-body">
                    <Fragment>
                        {bodyContentList?.map((item, key) => {
                            if (key < bodyContentList.length - 1) {
                                return (
                                    <div key={key}>
                                        { item }
                                    </div>
                                );
                            }

                            return (<span key={key}>{item}</span>);
                        })}
                    </Fragment>
                    <Button className="btn-black" onClickFn={onClickHeaderBtn}>
                        {props.button}
                    </Button>
                </div>
            </div>
        </div>
    );
}