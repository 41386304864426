import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import { useAppContext } from "../../../../Lib/UserContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countyList } from "../../../../Data/countyList";
import { regionList } from "../../../../Data/regionList";
import { parishList } from "../../../../Data/parishList";
import { laRegionList } from "../../../../Data/laRegionList";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core/styles';
var newRegionList = [...regionList]
newRegionList.splice(0,1);
var newLaRegionList = [...laRegionList]
newLaRegionList.splice(0,1);


const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '20px',
    // width: '400px',
    fontWeight: '300',
    height: '300px',
    overflowY: 'scroll',
  },
  filterWrapper: {
    display: 'flex'
  },
  active: {
    cursor: 'pointer',
    fontWeight: '500 !important',

  },
  inactive: {
    fontWeight: '400 !important',
    cursor: 'pointer'
  },
  checkboxLabel: {
    padding: '0px'
  },
  checkboxes: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  regionCheckboxes: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '50px'

  },
  saveButton: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  }
}));

export default function LocationFilter(props) {
  const classes = useStyles();
  const { stateLocation } = useAppContext();
  const [location, setLocation] = React.useState(true)
  const [countySelections, setCountySelections] = React.useState([]);
  const [regionSelections, setRegionSelections] = React.useState([]);
  let regionsToMap = stateLocation === 'TX' ? newRegionList : newLaRegionList

  const handleCountyLocationChange = () => {
    setLocation(true)
    setRegionSelections(["All regions"]);
  }
  const handleRegionLocationChange = () => {
    setLocation(false)
    setCountySelections(stateLocation === 'TX' ? ["All counties"] : ["All parishes"]);
  }

  const handleCountyChange = (event) => {
    let lo = stateLocation === 'TX' ? 'All counties' : 'All parishes'
    if (event[event.length - 1] === lo) {
      setCountySelections([lo]);
    } else {
      let newEvent = [...event].filter(x => x !== lo)
      setCountySelections(newEvent);
    }
  };

  const handleRegionChange = (event) => {
    var newRegionList = [...regionSelections];
    newRegionList = newRegionList.filter(x => x !== 'All regions')

    if (newRegionList.includes(event.target.value)) {
      newRegionList = newRegionList.filter(x => x !== event.target.value)
      setRegionSelections(newRegionList);
    } else {
      newRegionList.push(event.target.value)
      setRegionSelections(newRegionList);
    }
  };

  const handleAllRegionChange = (e) => {
    if (e.target.checked){
      setRegionSelections(["All regions"]);
    } else {
      setRegionSelections([]);
    }
  }

  const handleSubmit = () => {
    let locationCategory = ''
    if (stateLocation === 'TX') {
      locationCategory = location ? 'County' : 'Region';
    } else {
      locationCategory = location ? 'Parish' : 'Region';
    }

    props.filterCallback({
      name: 'Location',
      filter: 'Screenings',
      selection: locationCategory,
      value: location ? countySelections : regionSelections,
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      if (props.selectedFilters[i].name === 'Location' && props.selectedFilters[i].filter === 'Screenings'){
        console.log('props.selectedFilters[i]', props.selectedFilters[i])
        if (props.selectedFilters[i].selection === 'County' || props.selectedFilters[i].selection === 'Parish'){
          if (props.selectedFilters[i].value[0] === 'All counties' && stateLocation === 'LA'){
            setCountySelections(["All parishes"])
          } else {
            setCountySelections(props.selectedFilters[i].value)
          }
          setRegionSelections(["All regions"])
        } else {
          setCountySelections(stateLocation === 'TX' ? ["All counties"] : ["All parishes"])
          setRegionSelections(props.selectedFilters[i].value)
        }
      }
    }
  }, [stateLocation, props.selectedFilters])
  console.log(countySelections)

  return (
    <div className={classes.container}>
      {location ? (
        <div>
          <div className={classes.filterWrapper}>
            <div>
              <p className={location ? classes.active : classes.inactive} onClick={handleCountyLocationChange}>{stateLocation === 'TX' ? "County" : "Parish"}</p>
              <p className={!location ? classes.active : classes.inactive} onClick={handleRegionLocationChange}>Region</p>
            </div>
            <div className={classes.checkboxes}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={stateLocation === 'TX' ? countyList : parishList}
                //disableCloseOnSelect
                onChange={(event, value) => handleCountyChange(value)}
                value={countySelections}
                getOptionLabel={(option) => option}
                renderOption={(option) => (
                  <React.Fragment>
                    <BlackCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={countySelections.includes(option)}
                    />
                    {option}
                  </React.Fragment>
                )}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />

            </div>
          </div>
          <div className={classes.saveButton}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save
                </Button>
          </div>
        </div>
      ) :
        (
          <div>
            <div className={classes.filterWrapper}>
              <div>
                <p className={location ? classes.active : classes.inactive} onClick={handleCountyLocationChange}>{stateLocation === 'TX' ? "County" : "Parish"}</p>
                <p className={!location ? classes.active : classes.inactive} onClick={handleRegionLocationChange}>Region</p>
              </div>
              <div className={classes.checkboxes}>
                <FormControlLabel className="checkboxLabel"
                  control={
                    <BlackCheckbox
                      checked={regionSelections.includes("All regions")}
                      onChange={handleAllRegionChange}
                      name="checkedA" />
                  }
                  label="All regions"
                />

                <div className={classes.regionCheckboxes}>
                  {regionsToMap?.map((region, key) => {
                    return (
                      <FormControlLabel key={key} className="checkboxLabel"
                        control={
                          <BlackCheckbox
                            checked={regionSelections.includes(region)}
                            onChange={e => handleRegionChange(e, region)}
                            name="checkedA"
                            value={region} />
                        }
                        label={region}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={classes.saveButton}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Save
                  </Button>
            </div>
          </div>
        )}
    </div>
  )
}