export const ContentfulFields = {
    geographicTrends: {
        ctaBody: "",
        ctaButton: "",
        ctaButtonLink: "",
        ctaTitle: "",
        header: "",
        headerBody: "",
        headerButton: "",
        headerMedia: "",
        introBodyColumns: "",
        introBodySingle: "",
        introTitle: "",
        mapBody: "",
        mapTitle: "",
    },
    dataExplorer: {
        header: "",
        headerBody: "",
        headerButton: "",
        headerButtonLink: "",
        selectionText: "",
        selection: "",
        chartTitle: "",
        chartBody: ""
    },
    policiesLegislation: {
        introTitle: '',
    },
    partnerships: {
        header: '',
    },
    map: {
        mapName: "",
        mapTitle: "",
        mapInsight: "",
        mapSource: "",
        mapPageAssociated: ""
    },
    chart: {
        chartTitle: "",
        chartInsight: "",
        chartName: "",
        chartSource: "",
        chartPageAssociated: "",
        chartCategory: "",
        chartSelectionCategory: "",
        chartId: ""
    },
    footer: {
        footerText: ""
    }
};