import { countyData } from "../../../Data/countyJson";
import { parishData } from "../../../Data/parishJson";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
var imbData = JSON.parse(JSON.stringify(countyData));
let imbParishData = JSON.parse(JSON.stringify(parishData));

export function ImbLayer(map, location, data, stateLocations) {
    if (data.counties) {
        var stateLocation = stateLocations === 'TX' ? imbData : imbParishData;
        if (data.counties.length !== 0){
            if (Object.keys(data.counties[0])[0] === "county"){
                for (let i = 0; i < stateLocation.features.length; i++) {
                    var counter = 0;
                    for (let j = 0; j < data.counties.length; j++) {
                        if (
                            stateLocation.features[i].properties.COUNTY ===
                (data.counties[j].county)
                ) {
                    stateLocation.features[i].properties.SCREENINGS = Number(
                    data.counties[j].total
                );
                counter += 1;
                }
            }
            if (counter === 0){
                stateLocation.features[i].properties.SCREENINGS = 0;
                }
            }
        } 
        } else {
            for (let i = 0; i < stateLocation.features.length; i++) {
                stateLocation.features[i].properties.SCREENINGS = 0;
            }
        }
    let calculatedData = stateLocation;
    map.on("load", function () {
        map.addSource("county", {
        type: "geojson",
        data: calculatedData,
        });
        map.addLayer({
        id: "county",
        type: "line",
        source: "county",
        layout: {
            "line-join": "round",
            "line-cap": "round",
        },
        paint: {
            "line-color": "#28ADE3",
            "line-width": 1,
        },
        });

        map.addLayer({
        id: "county-fills",
        type: "fill",
        source: "county",
        layout: {
            visibility: "visible",
        },
        paint: {
            "fill-color": {
            property: "SCREENINGS",
            stops: [
                [1, "#e9f6fc"],
                [5, "#93d6f1"],
                [10, "#28ade3"],
                [15, "#186788"],
                [20, "#0c3344"],
                [25, "#000000"],
            ],
            },
            "fill-outline-color": "#28ADE3",
            "fill-opacity": 0.9,
        },
        });
    });

    let hoveredStateId = null;
    let popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        });

        map.on("mousemove", "county-fills", function (e) {
        if (hoveredStateId !== e.features[0].id) {
            if (e.features.length > 0) {
            if (hoveredStateId) {
                map.setFeatureState(
                { source: "county", id: hoveredStateId },
                { hover: false }
                );
            }
            hoveredStateId = e.features[0].id;
            map.setFeatureState(
                { source: "county", id: hoveredStateId },
                { hover: true }
            );
            }
            let description = `
            <h3><strong>${e.features[0].properties.COUNTY}</strong></h3> 
            <p>Businesses: <span style="font-weight: 300 !important">${
                e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }</span></p>
                <p>Percent: <span style="font-weight: 300 !important">${(((e.features[0].properties.SCREENINGS / data.countiesTotal[0].sum)*100)) ? 
                    (((e.features[0].properties.SCREENINGS / data.countiesTotal[0].sum)*100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): '0'
                    }%</span></p>`
        
            popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
        }
        });

        map.on("mouseleave", "county-fills", function () {
        if (hoveredStateId) {
            map.setFeatureState(
            { source: "county", id: hoveredStateId },
            { hover: false }
            );
        }
        hoveredStateId = null;
        popup.remove();
        });
    }

}