// TB TODO: finish adding each 4 quarters for years 2015 - 2022(up to Q2 for 2022). Each year should have same color for their 4 quarters.

export const hotlineVariables = {
  hotlineCases: [
    {
      variable: "total",
      color: "#4E79A7",
      name: "Total Situations",
    },
  ],
  time: [
    {
      variable: "total",
      color: "#4E79A7",
      name: "National Hotline Situations"
    }
  ],
  // time: [
  //   {
  //     quarter: "Q1",
  //     name: "2015",
  //     color: "#4E79A7",
  //   },
  //   {
  //     quarter: "Q2",
  //     name: "2015",
  //     color: "#4E79A7",
  //   },
  //   {
  //     quarter: "Q3",
  //     name: "2015",
  //     color: "#4E79A7",
  //   },
  //   {
  //     quarter: "Q4",
  //     name: "2015",
  //     color: "#4E79A7",
  //   },
  //   {
  //     quarter: "Q1",
  //     name: "2016",
  //     color: "#E49343",
  //   },
  //   {
  //     quarter: "Q2",
  //     name: "2016",
  //     color: "#E49343",
  //   },
  //   {
  //     quarter: "Q3",
  //     name: "2016",
  //     color: "#E49343",
  //   },
  //   {
  //     quarter: "Q4",
  //     name: "2016",
  //     color: "#E49343",
  //   },
  //   {
  //     quarter: "Q1",
  //     name: "2017",
  //     color: "#5878A3",
  //   },
  //   {
  //     quarter: "Q2",
  //     name: "2017",
  //     color: "#5878A3",
  //   },
  //   {
  //     quarter: "Q3",
  //     name: "2017",
  //     color: "#5878A3",
  //   },
  //   {
  //     quarter: "Q4",
  //     name: "2017",
  //     color: "#5878A3",
  //   },
  //   {
  //     quarter: "Q1",
  //     name: "2018",
  //     color: "#6B9F57",
  //   },
  //   {
  //     quarter: "Q2",
  //     name: "2018",
  //     color: "#6B9F57",
  //   },
  //   {
  //     quarter: "Q3",
  //     name: "2018",
  //     color: "#6B9F57",
  //   },
  //   {
  //     quarter: "Q4",
  //     name: "2018",
  //     color: "#6B9F57",
  //   },
  //   {
  //     quarter: "Q1",
  //     name: "2019",
  //     color: "#85B5B1",
  //   },
  //   {
  //     quarter: "Q2",
  //     name: "2019",
  //     color: "#85B5B1",
  //   },
  //   {
  //     quarter: "Q3",
  //     name: "2019",
  //     color: "#85B5B1",
  //   },
  //   {
  //     quarter: "Q4",
  //     name: "2019",
  //     color: "#85B5B1",
  //   },
  //   {
  //     quarter: "Q1",
  //     name: "2020",
  //     color: "#E15759",
  //   },
  //   {
  //     quarter: "Q2",
  //     name: "2020",
  //     color: "#E15759",
  //   },
  //   {
  //     quarter: "Q3",
  //     name: "2020",
  //     color: "#E15759",
  //   },
  //   {
  //     quarter: "Q4",
  //     name: "2020",
  //     color: "#E15759",
  //   },
  //   {
  //     quarter: "Q1",
  //     name: "2021",
  //     color: "#E28743",
  //   },
  //   {
  //     quarter: "Q2",
  //     name: "2021",
  //     color: "#E28743",
  //   },
  //   {
  //     quarter: "Q3",
  //     name: "2021",
  //     color: "#E28743",
  //   },
  //   {
  //     quarter: "Q4",
  //     name: "2021",
  //     color: "#E28743",
  //   },
  //   {
  //     quarter: "Q1",
  //     name: "2022",
  //     color: "#C728EA",
  //   },
  //   {
  //     quarter: "Q2",
  //     name: "2022",
  //     color: "#C728EA",
  //   },
  //   {
  //     quarter: "Q3",
  //     name: "2022",
  //     color: "#C728EA",
  //   },
  //   {
  //     quarter: "Q4",
  //     name: "2022",
  //     color: "#C728EA",
  //   },
  // ],
};
