import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../../Lib/UserContext';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import { withStyles } from '@material-ui/core/styles';


const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    fontWeight: '300',
  },
  checkboxLabel: {
    padding: '0px'
  },
  checkboxes: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  saveButton: {
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  }
}));

export default function ImbLocationFilter(props) {
  const classes = useStyles();
  const { stateLocation } = useAppContext()
  const [viewSelection, setViewSelection] = useState('')

  const handleChange = (newSelection) => {
    setViewSelection(newSelection)
  }

  const handleSubmit = () => {
    props.filterCallback({
      name: 'Location ',
      filter: 'Businesses of Interest',
      value: [viewSelection],
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++) {
      if (props.selectedFilters[i].name === 'Location ' && props.selectedFilters[i].filter === 'Businesses of Interest') {
        setViewSelection(props.selectedFilters[i].value[0])
      }
    }
  }, [props.selectedFilters])
  return (
    <div className={classes.container}>
      {[`${stateLocation === 'TX' ? 'County' : 'Parish'}`, 'Zip Code'].map(view => {
        return (
          <FormControlLabel key={view} className="checkboxLabel"
            control={
              <BlackCheckbox
                checked={viewSelection === view}
                onChange={() => handleChange(view)}
                name="checkedA"
                value={view} />
            }
            label={view}
          />
        )
      })}

      <div className={classes.saveButton}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
      </div>
    </div>
  )
}