// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

export function FederalCasesLayer(map, filters, data, stateLocation, mapsIndex, filterCallback) {
    // console.log(data)
    let locationFilter = filters.filter(f => f.name === 'Location ' && f.filter === 'Federal Cases')[0]
    // 'State Prosecuted', 'District Prosecuted' or 'Trafficking Locations'

    // let initialLoSelection = locationFilter.value[0]
    
    // #299
    // reset state clicked view if location filter is changed
    // if (initialLoSelection !== 'State Prosecuted') { 
    //     setFederalStateClicked(null)
    // }

    const loadMapView = (locationSelection, stateClickedData) => {
        let mapDataIndex = mapsIndex['Federal Cases']['Location '][locationSelection]

        if (stateClickedData) {
            // const bounds = [ // from website, NY
            //     [-74.04728, 40.68392], // [west, south]
            //     [-73.91058, 40.87764]  // [east, north]
            // ]; 
            // "BOUNDS": [
            //     [],
            //     []
            // ],

            // console.log('stateClickedData: ', stateClickedData)
            if (stateClickedData?.stateJsonObj?.properties?.BOUNDS) {
                map.setMaxBounds(JSON.parse(stateClickedData?.stateJsonObj?.properties?.BOUNDS))
                map.setZoom(1)
            }
        }

        if (data[mapDataIndex.dataVar] &&
            data[mapDataIndex.dataVar].length > 0) {
            let mapJson = JSON.parse(JSON.stringify(mapDataIndex.mapJson))

            mapJson.features.forEach(f => {
                f.properties.SCREENINGS = 0

                data[mapDataIndex.dataVar].forEach(d => {
                    // National counties needs to check/match 2 properties(county and state)
                    if (f.properties[mapDataIndex?.mapLoc2Var]) {
                        if (f.properties[mapDataIndex.mapLocVar].toLowerCase() === d[mapDataIndex.sourceVar].toLowerCase() && f.properties[mapDataIndex.mapLoc2Var]?.toLowerCase() === d[mapDataIndex?.source2Var]?.toLowerCase()) {
                            f.properties.SCREENINGS = Number(d.total) ?? 0

                            if (mapDataIndex.hoverInfoBoxVars) {
                                mapDataIndex.hoverInfoBoxVars.forEach(infoVar =>
                                    f.properties[infoVar] = Number(d[infoVar]) ?? 0)
                            }
                        }
                    } else if (f.properties[mapDataIndex?.mapLocVar]?.toLowerCase() === d[mapDataIndex?.sourceVar]?.toLowerCase()) {
                        f.properties.SCREENINGS = Number(d.total) ?? 0

                        if (mapDataIndex.hoverInfoBoxVars) {
                            mapDataIndex.hoverInfoBoxVars.forEach(infoVar =>
                                f.properties[infoVar] = Number(d[infoVar]) ?? 0)
                        }
                    }
                })
            })

            let calculatedData = mapJson;
            map.on("load", function () {
                map.addSource("loc1", {
                    type: "geojson",
                    data: calculatedData,
                });
                map.addLayer({
                    id: "loc1",
                    type: "line",
                    source: "loc1",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round",

                    },
                    paint: {
                        "line-color": mapDataIndex.fillOutlineColor,
                        "line-width": 1,
                    },
                });

                map.addLayer({
                    id: "loc1-fills",
                    type: "fill",
                    source: "loc1",
                    layout: {
                        visibility: "visible",
                    },
                    paint: {
                        "fill-color": locationSelection === 'District Prosecuted' ?
                            mapDataIndex.mapFillColors
                            :
                            {
                            property: mapDataIndex.fillColorProp,
                            stops: mapDataIndex.mapFillColors
                        },
                        "fill-outline-color": mapDataIndex.fillOutlineColor, // todo: Not working
                        "fill-opacity": mapDataIndex.mapFillOpacity,
                    },
                });

                if (mapDataIndex.loc2MapOutlines) { // state outlines
                    // #299 
                    let allStatesJsonCopy = JSON.parse(JSON.stringify(mapDataIndex.loc2MapOutlines))
                    
                    let getStateOutline = stateClickedData &&
                        { ...mapDataIndex.loc2MapOutlines }.features.filter(obj => obj.properties.NAME === stateClickedData?.stateJsonObj?.properties?.NAME
                    )
                    allStatesJsonCopy.features = getStateOutline

                    let statesToOutline = stateClickedData ? allStatesJsonCopy
                        : mapDataIndex.loc2MapOutlines;

                    map.addSource("loc2", {
                        type: "geojson",
                        data: statesToOutline,
                    });
                    map.addLayer({
                        id: "loc2",
                        type: "line",
                        source: "loc2",
                        layout: {
                            "line-join": "round",
                            "line-cap": "round",

                        },
                        paint: {
                            "line-color": "#186788",
                            "line-width": 2,
                        },
                    });
                }
            });

            let hoveredStateId = null;
            let popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
            });

            map.on("mousemove", "loc1-fills", function (e) {
                let uniqueMapIdProp = e.features[0].properties.GEO_ID // e.features[0].id
                if (hoveredStateId !== uniqueMapIdProp) {
                    if (e.features.length > 0) {
                        if (hoveredStateId) {
                            map.setFeatureState(
                                { source: "loc1", id: hoveredStateId },
                                { hover: false }
                            );
                        }
                        hoveredStateId = uniqueMapIdProp;
                        map.setFeatureState(
                            { source: "loc1", id: hoveredStateId },
                            { hover: true }
                        );
                    }

                    let hoverText;
                    if (locationSelection === 'State Prosecuted') {
                        hoverText = `<div style='width: 300px'>
                    <h3><strong>${e.features[0].properties[mapDataIndex.hoverTitleVar]}</strong><h3/>
                    <p>Cases Prosecuted: ${e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    <span style='display: block !important'>Minor Sex Trafficking: ${e.features[0].properties?.minorsextraf?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span>
                    <span style='display: block !important'>Adult Sex Trafficking: ${e.features[0].properties?.adultsextraf?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span>
                    <span style='display: block !important'>Labor Trafficking: ${e.features[0].properties?.labor?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span>
                    <span style='display: block !important'>Both Sex and Labor Trafficking: ${e.features[0].properties?.both?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</span>
                    </div>
                    `
                    } else if (locationSelection === 'District Prosecuted') {
                        hoverText = `
                        <h3 style="font-weight: 500 !important">District: ${e.features[0].properties[mapDataIndex.mapLocVar]}</h3>
                        <h3>Number of Cases: ${e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                        `
                    } else if (locationSelection === 'Trafficking Locations') {
                        hoverText = `
                        <h3><strong>${e.features[0].properties[mapDataIndex.mapLocVar]}</strong></h3> 
                        <p>Locations: <span style="font-weight: 300 !important">${e.features[0].properties.SCREENINGS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                        ${mapDataIndex?.noPercent ? '' : `<p>Percent: <span style="font-weight: 300 !important">${(((e.features[0].properties.SCREENINGS / data[mapDataIndex.totalDataVar][0].count) * 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</span></p>`}
                        `
                    }

                    let description = hoverText
                    popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
                }
            });

            map.on("mouseleave", "loc1-fills", function () {
                if (hoveredStateId) {
                    map.setFeatureState(
                        { source: "loc1", id: hoveredStateId },
                        { hover: false }
                    );
                }
                hoveredStateId = null;
                popup.remove();
            });
        }
    }


   
    if (locationFilter.value[0] === 'State Prosecuted' && data?.stateClickedData) {
        // #299
        loadMapView('Trafficking Locations', data.stateClickedData)
    } else {
        // Default, Keep!
        loadMapView(locationFilter.value[0], undefined)
    } 



    // #299
    // // Location > "State prosecuted", State click, replace state map with state zoomed in trafficking locations map
    if (locationFilter.value[0] === 'State Prosecuted' && !data.stateClickedData) {
        map.on('mouseenter', 'loc1-fills', () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'loc1-fills', () => {
            map.getCanvas().style.cursor = '';
        });

        map.on('click', 'loc1-fills', (e) => { // state clicked
            filterCallback({
                name: 'Location ',
                filter: 'Federal Cases',
                stateLocation: stateLocation,
                value: locationFilter.value,
                stateClickedData: e.features[0]
            })
        });
    }
}