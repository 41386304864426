export const servicesdata = {
    //Advocacy
    advocacyTotalServed: [ 
        { 
            variable : "new_individuals_total" ,
            name: "New Individuals",
            color: "#4E79A7"
        },
        { 
            variable : "total_youth_served" ,
            name: "Total Individuals",
            color: "#A0CBE8"
        } 
    ], 
    advocacyAgeServed: [ 
        { 
            variable : "new_individuals_total" ,
            name: "Total New Individuals",
            color: "#4E79A7"
        }, { 
            variable : "new_individuals_served_0_17" ,
            name: "Ages 0 - 17",
            color: "#F28E2B"
        },
        { 
            variable : "new_individuals_served_18_24" ,
            name: "Ages 18 - 24",
            color: "#E15759"
        },
        { 
            variable : "new_individuals_served_25" ,
            name: "Ages 25+",
            color: "#76B7B2"
        },
        
    ], 
    advocacyReferrals: [ 
        {
            variable: "rs_diverson",
            name: "Adult (> 21y/o) Pre-arrest or Diversion Prog",
            color: "#4E79A7"
        },
        {
            variable: "rs_cac",
            name: "CAC, CASA, DAO or Judiciary",
            color: "#A0CBE8"
        },
        {
            variable: "rs_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#F28E2B"
        },
        {
            variable: "rs_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#FFBE7D"
        }, 
        { 
            variable : "rs_dfps",
            name: "DFPS",
            color: "#59A14E"
        }, 
        {
            variable: "rs_shelter_dropin",
            name: "Emergency youth shelter or Drop-in center",
            color: "#8CD17D"
        },
        {
            variable: "rs_medical",
            name: "Hospital, Medical, or Mental health provider",
            color: "#B6992D"
        },
        {
            variable: "rs_jj",
            name: "Juvenile Justice",
            color: "#499894"
        },
        {
            variable : "rs_law_enforcement" ,
            name: "Law Enforcement",
            color: "#86BCB6"
        },    
        {
            variable : "rs_out_of_cc" ,
            name: "Out of catchment Care Coordinator, Advocate",
            color: "#E15759"
        },
        {
            variable : "rs_parent" ,
            name: "Parent, Caregiver, Legal guardian, or Family",
            color: "#FF9D9A"
        },
        { 
            variable : "rs_self_friend" ,
            name: "Self-referral or Friend",
            color: "#79706E"
        },
        {
            variable : "rs_other" ,
            name: "Other",
            color: "#BAB0AC"
        }
    ],
    advocacyReferralSources: [ 
        {
            variable: "rs_diverson",
            name: "Adult (> 21y/o) Pre-arrest or Diversion Prog",
            color: "#4E79A7"
        },
        {
            variable: "rs_cac",
            name: "CAC, CASA, DAO or Judiciary",
            color: "#A0CBE8"
        },
        {
            variable: "rs_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#F28E2B"
        },
        {
            variable: "rs_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#FFBE7D"
        },
        {
            variable: "rs_dfps",
            name: "DFPS",
            color: "#59A14E"
        },
        {
            variable: "rs_shelter_dropin",
            name: "Emergency youth shelter or Drop-in center",
            color: "#8CD17D"
        },
        {
            variable: "rs_medical",
            name: "Hospital, Medical, or Mental health provider",
            color: "#B6992D"
        },
        {
            variable: "rs_jj",
            name: "Juvenile Justice",
            color: "#499894"
        },
        {
            variable: "rs_law_enforcement",
            name: "Law Enforcement",
            color: "#86BCB6"
        },
        {
            variable: "rs_out_of_cc",
            name: "Out of catchment Care Coordinator, Advocate",
            color: "#E15759"
        },
        {
            variable: "rs_parent",
            name: "Parent, Caregiver, Legal guardian, or Family",
            color: "#FF9D9A"
        },
        {
            variable: "rs_self_friend",
            name: "Self-referral or Friend",
            color: "#79706E"
        },
        {
            variable: "rs_other",
            name: "Other",
            color: "#BAB0AC"
        }
    ],
    advocacyEngagement : [ 
        {
            variable:  "es_youth_not_engaged",
            color: "#4E79A7",
            name: "Youth Unwilling to Stay Engaged"
        },{
            variable:  "youth_completed_program",
            color: "#F28E2B",
            name: "Youth successfully completed program/no longer needed services"
        },{ 
            variable : "es_youth_moved",
            name: "Youth Moved and Referred",
            color: "#E15759"
        }, 
        { 
            variable : "es_youth_moved_not_referred" ,
            name: "Youth Moved and Not Referred",
            color: "#76B7B2"
        }, 
        {
            variable : "es_youth_awol" ,
            name: "Youth AWOL",
            color: "#59A14E"
        },{
            variable : "es_youth_aged_out" ,
            name: "Youth Aged Out",
            color: "#EDC949"
        },
        { 
            variable : "es_temp_services" ,
            name: "Temporary Services",
            color: "#B07AA2"
        },
        { 
            variable : "es_guardian_withdrawl" ,
            name: "Guardian Withdraws/Services Declined",
            color: "#FF9DA7"
        },
        { 
            variable : "es_other" ,
            name: "Other",
            color: "#9C755F"
        },
        { 
            variable : "es_unknown" ,
            name: "Unknown",
            color: "#BAB0AC"
        },
    ],
    advocacyCrisis: [
        {
            variable: "crisis_recoveries",
            color: "#4E79A7",
            name: "Response to Crisis Recoveries"
        }
    ], 
    // Drop In
    dropinTotalServed: [
        { 
            variable : "new_individuals_total" ,
            name: "New Individuals",
            color: "#4E79A7"
        },
        { 
            variable : "total_youth_served" ,
            name: "Total Individuals",
            color: "#A0CBE8"
        },
    ], 
    dropinAgeServed: [ 
        { 
            variable : "new_individuals_total" ,
            name: "Total New Individuals",
            color: "#4E79A7"
        },{ 
            variable : "new_individuals_served_0_12" ,
            name: "Ages 0 - 12",
            color: "#F28E2B"
        },
        { 
            variable : "new_individuals_served_13_17" ,
            name: "Ages 13 - 17",
            color: "#E15759"
        },
        { 
            variable : "new_individuals_served_18_24" ,
            name: "Ages 18 - 24",
            color: "#76B7B2"
        },
        { 
            variable : "new_individuals_served_25" ,
            name: "Ages 25+",
            color: "#59A14E"
        },
        
    ], 
    dropinReferrals: [
        {
            variable: "rs_adult_shelter",
            name: "Adult shelter, Homeless coalition",
            color: "#4E79A7"
        },
        {
            variable: "rs_cc",
            name: "Care coordinator or CC staffing",
            color: "#A0CBE8"
        },
        {
            variable: "rs_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#F28E2B"
        }, 
        {
            variable: "rs_csey",
            name: "CSEY Advocates",
            color: "#FFBE7D"
        },
        {
            variable: "rs_dfps",
            name: "DFPS",
            color: "#59A14E"
        }, 
        {
            variable: "rs_jj",
            name: "Juvenile Justice",
            color: "#8CD17D"
        },
        {
            variable : "rs_law_enforcement" ,
            name: "Law Enforcement",
            color: "#B6992D"
        },
        {
            variable: "rs_medical",
            name: "Medical provider",
            color: "#499894"
        },
        {
            variable: "rs_guardian",
            name: "Parent, Caregiver or Legal guardian",
            color: "#86BCB6"
        },
        { 
            variable : "rs_self" ,
            name: "Self-referral or Walk-ins",
            color: "#E15759"
        },
        { 
            variable : "rs_school" ,
            name: "School",
            color: "#FF9D9A"
        },
        {
            variable: "rs_social_services",
            name: "TLP, ILP, RTC, Other social services",
            color: "#79706E"
        },
        { 
            variable : "rs_youth_shelter" ,
            name: "Youth shelter, SOP",
            color: "#BAB0AC"
        },
        {
            variable : "rs_other" ,
            name: "Other",
            color: "#D37295"
        }
    ],
    dropinReferralsArray: [
        {
            variable: "rs_adult_shelter",
            name: "Adult shelter, Homeless coalition",
            color: "#4E79A7"
        },
        {
            variable: "rs_cc",
            name: "Care coordinator or CC staffing",
            color: "#A0CBE8"
        },
        {
            variable: "rs_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#F28E2B"
        },
        {
            variable: "rs_csey",
            name: "CSEY Advocates",
            color: "#FFBE7D"
        },
        {
            variable: "rs_dfps",
            name: "DFPS",
            color: "#59A14E"
        },
        {
            variable: "rs_jj",
            name: "Juvenile Justice",
            color: "#8CD17D"
        },
        {
            variable: "rs_law_enforcement",
            name: "Law Enforcement",
            color: "#B6992D"
        },
        {
            variable: "rs_medical",
            name: "Medical provider",
            color: "#499894"
        },
        {
            variable: "rs_guardian",
            name: "Parent, Caregiver or Legal guardian",
            color: "#86BCB6"
        },
        {
            variable: "rs_self",
            name: "Self-referral or Walk-ins",
            color: "#E15759"
        },
        {
            variable: "rs_school",
            name: "School",
            color: "#FF9D9A"
        },
        {
            variable: "rs_social_services",
            name: "TLP, ILP, RTC, Other social services",
            color: "#79706E"
        },
        {
            variable: "rs_youth_shelter",
            name: "Youth shelter, SOP",
            color: "#BAB0AC"
        },
        {
            variable: "rs_other",
            name: "Other",
            color: "#D37295"
        }
    ], 
    dropinConnecting: [
        {
            variable: "connecting_on_site",
            color: "#4E79A7",
            name: "Connecting on Site"
        },{
            variable: "connecting_remote",
            color: "#F28E2B",
            name: "Connecting in Remote Locations", 
        },{
            variable: "connecting_both",
            color: "#E15759",
            name: "Both Remote and In-Person", 
        }
    ], 
    dropinConnectingServed: [
        {
            variable: "connecting_total",
            color: "#4E79A7",
            name: "Connecting with Individuals", 
        },{
            variable: "total_youth_served",
            color: "#A0CBE8",
            name: "Total Individuals Served"
        }
    ], 
    dropinPlacement: [
        {
            variable: "safety_placement_youth",
            color: "#4E79A7",
            name: "Individuals Reaching Extended Safety"
        }
    ],
    dropinReferredServed: [
        {
            variable: "safety_placement_youth",
            color: "#4E79A7",
            name: "Individuals Reaching Extended Safety"
        },  
        {
            variable: "new_individuals_total",
            color: "#A0CBE8",
            name: "Total New Individuals"
        }
    ],
   
    // Emergency 
    emergencyIndividualServed: [ 
        { 
            variable : "new_individuals_total" ,
            name: "New Individuals",
            color: "#4E79A7"
        },
        { 
            variable : "total_youth_served" ,
            name: "Total Individuals",
            color: "#A0CBE8"
        },
    ],
    emergencyAgeServed: [
        { 
            variable : "new_individuals_total" ,
            name: "Total New Individuals",
            color: "#4E79A7"
        },{ 
            variable : "new_individuals_served_0_17" ,
            name: "Ages 0 - 17",
            color: "#F28E2B"
        },
        { 
            variable : "new_individuals_served_18_24" ,
            name: "Ages 18 - 24",
            color: "#E15759"
        },
        
    ],
    emergencyReferrals: [
        {
            variable: "rs_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#4E79A7"
        },
        { 
            variable : "rs_cbc" ,
            name: "Community-Based Care (CBC) contractor",
            color: "#A0CBE8"
        }, 
        {
            variable: "rs_csey",
            name: "CSEY Advocates",
            color: "#F28E2B"
        },
        {
            variable: "rs_dfps",
            name: "DFPS",
            color: "#FFBE7D"
        }, 
        {
            variable : "rs_dropin" ,
            name: "Drop-in center",
            color: "#59A14E"
        },
        { 
            variable : "rs_jj" ,
            name: "Juvenile Justice",
            color: "#8CD17D"
        },
        {
            variable: "rs_law_enforcement",
            name: "Law Enforcement",
            color: "#B6992D"
        },
        {
            variable : "rs_hotline" ,
            name: "National Human Trafficking Hotline",
            color: "#499894"
        },
        {
            variable: "rs_parent",
            name: "Parent, Caregiver or Legal Guardian",
            color: "#86BCB6"
        },
        { 
            variable : "rs_self" ,
            name: "Self-referral or Walk-ins",
            color: "#E15759"
        }, 
        {
            variable : "rs_other" ,
            name: "Other",
            color: "#FF9D9A"
        }
    ], 
    emergencyReferralsArray: [
        {
            variable: "rs_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#4E79A7"
        },
        {
            variable: "rs_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#A0CBE8"
        },
        {
            variable: "rs_csey",
            name: "CSEY Advocates",
            color: "#F28E2B"
        },
        {
            variable: "rs_dfps",
            name: "DFPS",
            color: "#FFBE7D"
        },
        {
            variable: "rs_dropin",
            name: "Drop-in center",
            color: "#59A14E"
        },
        {
            variable: "rs_jj",
            name: "Juvenile Justice",
            color: "#8CD17D"
        },
        {
            variable: "rs_law_enforcement",
            name: "Law Enforcement",
            color: "#B6992D"
        },
        {
            variable: "rs_hotline",
            name: "National Human Trafficking Hotline",
            color: "#499894"
        },
        {
            variable: "rs_parent",
            name: "Parent, Caregiver or Legal Guardian",
            color: "#86BCB6"
        },
        {
            variable: "rs_self",
            name: "Self-referral or Walk-ins",
            color: "#E15759"
        },
        {
            variable: "rs_other",
            name: "Other",
            color: "#FF9D9A"
        }
    ], 
    emergencyAdmissions: [
        {
            variable: "admissions_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#4E79A7"
        },
        {
            variable: "admissions_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#A0CBE8"
        }, 
        {
            variable: "admissions_csey",
            name: "CSEY Advocates",
            color: "#F28E2B"
        },
        { 
            variable : "admissions_dfps",
            name: "DFPS",
            color: "#FFBE7D"
        }, 
        {
            variable : "admissions_dropin" ,
            name: "Drop-in Center",
            color: "#59A14E"
        },
        { 
            variable : "admissions_jj" ,
            name: "Juvenile Justice",
            color: "#8CD17D"
        },
        {
            variable: "admissions_le",
            name: "Law Enforcement",
            color: "#B6992D"
        },
        {
            variable : "admissions_hotline" ,
            name: "National Human Trafficking Hotline",
            color: "#499894"
        },
        {
            variable: "admissions_parent",
            name: "Parent, Caregiver or Legal Guardian",
            color: "#86BCB6"
        },
        { 
            variable : "admissions_self" ,
            name: "Self-referral or Walk-ins",
            color: "#E15759"
        }, 
        {
            variable : "admissions_other" ,
            name: "Other",
            color: "#FF9D9A"
        }
    ], 
    emergencyAdmissionsArray: [
        {
            variable: "admissions_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#4E79A7"
        },
        {
            variable: "admissions_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#A0CBE8"
        }, 
        {
            variable: "admissions_csey",
            name: "CSEY Advocates",
            color: "#F28E2B"
        },
        { 
            variable : "admissions_dfps",
            name: "DFPS",
            color: "#FFBE7D"
        }, 
        
        {
            variable : "admissions_dropin" ,
            name: "Drop-in Center",
            color: "#59A14E"
        },
        { 
            variable : "admissions_jj" ,
            name: "Juvenile Justice",
            color: "#8CD17D"
        },
        {
            variable: "admissions_le",
            name: "Law Enforcement",
            color: "#B6992D"
        },
        {
            variable : "admissions_hotline" ,
            name: "National Human Trafficking Hotline",
            color: "#499894"
        },
        {
            variable: "admissions_parent",
            name: "Parent, Caregiver or Legal Guardian",
            color: "#86BCB6"
        },
        { 
            variable : "admissions_self" ,
            name: "Self-referral or Walk-ins",
            color: "#E15759"
        }, 
        {
            variable : "admissions_other" ,
            name: "Other",
            color: "#FF9D9A"
        }
    ], 
    emergencyTotalAdRef: [
        {
            variable: "admissions_total",
            color: "#4E79A7",
            name: "Total Admissions", 
        },
        {
            variable: "rs_totals",
            color: "#A0CBE8",
            name: "Total Referrals", 
        } 
    ], 
    emergencyAvStay: [
        {
            variable: "length_of_stay",
            color: "#4E79A7",
            name: "Average Length of Stay", 
        }
    ], 
    emergencyAvStayProg: [
        {
            variable: "length_of_stay_jonathan",
            color: "#4E79A7",
            name: "Jonathan's Place", 
        }, {
            variable: "length_of_stay_roy",
            color: "#F28E2B",
            name: "Roy Maas", 
        }, {
            variable: "length_of_stay_sjrc",
            color: "#E15759",
            name: "SJRC", 
        },
        {
            variable: "length_of_stay_dallasasap",
            color: "#FFBE7D",
            name: "Dallas ASAP",
        },
        {
            variable: "length_of_stay_hope_rising",
            color: "#59A14E",
            name: "Hope Rising",
        },
        {
            variable: "length_of_stay_mhmr",
            color: "#8CD17D",
            name: "Heart of Texas MHMR",
        }, 
        {
            variable: "length_of_stay_nicole",
            color: "#B6992D",
            name: "TFI Nicole's House",
        },
    ], 
    emergencyDischarge: [
        { 
            variable : "discharge_paid_kinship",
            name: "Paid or unpaid kinship",
            color: "#4E79A7"
        }, 
        { 
            variable : "discharge_foster_home" ,
            name: "Foster Home",
            color: "#A0CBE8"
        }, 
        {
            variable : "discharge_sil_supported_housing" ,
            name: "Supervised Independent Living/Supported housing",
            color: "#F28E2B"
        },
        { 
            variable : "discharge_return_home" ,
            name: "Return to prior bio or foster family home",
            color: "#FFBE7D"
        },
        {
            variable : "discharge_group_care" ,
            name: "Group Care",
            color: "#59A14E"
        },
        {
            variable : "discharge_psychiatric_hospital" ,
            name: "Psychiatric Hospital",
            color: "#8CD17D"
        },
        { 
            variable : "discharge_substance_facility" ,
            name: "Residential substance use treatment facility",
            color: "#B6992D"
        },
        { 
            variable : "discharge_residential_treatment" ,
            name: "Residential treatment center",
            color: "#499894"
        }, 
        {
            variable : "discharge_le" ,
            name: "Law Enforcement custody or juvenile detention",
            color: "#86BCB6"
        },
        {
            variable : "discharge_ran_away" ,
            name: "Missing or other unplanned absence from operation",
            color: "#E15759"
        },
        {
            variable : "discharge_sign_out" ,
            name: "Young adult of majority age signed themselves out",
            color: "#FF9D9A"
        }
    ],
    // Longterm
    ltIndividualsServed: [
        { 
            variable : "new_individuals_total" ,
            name: "New Individuals",
            color: "#4E79A7"
        },
        { 
            variable : "total_youth_served" ,
            name: "Total Individuals",
            color: "#A0CBE8"
        },
    ],
    ltAgeServed: [
        { 
            variable : "new_individuals_total" ,
            name: "Total New Individuals",
            color: "#4E79A7"
        },
        { 
            variable : "new_individuals_served_0_17" ,
            name: "Ages 0 - 17",
            color: "#F28E2B"
        },
        { 
            variable : "new_individuals_served_18_24" ,
            name: "Ages 18 - 24",
            color: "#E15759"
        },
       
    ],
    ltReferrals: [
        {
            variable: "rs_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#4E79A7"
        },
        { 
            variable : "rs_cbc" ,
            name: "Community-Based Care (CBC) contractor",
            color: "#A0CBE8"
        }, 
        {
            variable: "rs_csey",
            name: "CSEY Advocates",
            color: "#F28E2B"
        },
        {
            variable: "rs_dfps",
            name: "DFPS",
            color: "#FFBE7D"
        }, 
        {
            variable : "rs_dropin" ,
            name: "Drop-in Center",
            color: "#59A14E"
        },
        { 
            variable : "rs_jj" ,
            name: "Juvenile Justice",
            color: "#8CD17D"
        },
        // {
        //     variable: "rs_law_enforcement",
        //     name: "Law Enforcement",
        //     color: "#B6992D"
        // },
        {
            variable : "rs_hotline" ,
            name: "National Human Trafficking Hotline",
            color: "#499894"
        },
        {
            variable : "rs_parent" ,
            name: "Parent, Caregiver or Legal Guardian",
            color: "#86BCB6"
        },
        { 
            variable : "rs_self" ,
            name: "Self-referral or Walk-ins",
            color: "#E15759"
        }, 
        {
            variable : "rs_other" ,
            name: "Other",
            color: "#FF9D9A"
        }
    ],
    ltReferralsArray: [
        {
            variable: "rs_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#4E79A7"
        },
        {
            variable: "rs_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#A0CBE8"
        },
        {
            variable: "rs_csey",
            name: "CSEY Advocates",
            color: "#F28E2B"
        },
        {
            variable: "rs_dfps",
            name: "DFPS",
            color: "#FFBE7D"
        },
        {
            variable: "rs_dropin",
            name: "Drop-in Center",
            color: "#59A14E"
        },
        {
            variable: "rs_jj",
            name: "Juvenile Justice",
            color: "#8CD17D"
        },
        // {
        //     variable: "rs_law_enforcement",
        //     name: "Law Enforcement",
        //     color: "#B6992D"
        // },
        {
            variable: "rs_hotline",
            name: "National Human Trafficking Hotline",
            color: "#499894"
        },
        {
            variable: "rs_parent",
            name: "Parent, Caregiver or Legal Guardian",
            color: "#86BCB6"
        },
        {
            variable: "rs_self",
            name: "Self-referral or Walk-ins",
            color: "#E15759"
        },
        {
            variable: "rs_other",
            name: "Other",
            color: "#FF9D9A"
        }
    ],
    ltAdmissions: [
        {
            variable: "admissions_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#4E79A7"
        },
        {
            variable: "admissions_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#F28E2B"
        }, 
        {
            variable: "admissions_csey",
            name: "CSEY Advocates",
            color: "#E15759"
        },
        { 
            variable : "admissions_dfps",
            name: "DFPS",
            color: "#76B7B2"
        }, 
        
        {
            variable : "admissions_dropin" ,
            name: "Drop-in center",
            color: "#59A14E"
        },
        { 
            variable : "admissions_jj" ,
            name: "Juvenile Justice",
            color: "#EDC949"
        },
        {
            variable : "admissions_hotline" ,
            name: "National Human Trafficking Hotline",
            color: "#B07AA2"
        },
        {
            variable : "admissions_parent" ,
            name: "Parent, Caregiver or Legal Guardian",
            color: "#FF9DA7"
        },
        {
            variable : "admissions_other" ,
            name: "Other",
            color: "#9C755F"
        }
    ],
    ltAdmissionsArray: [
        {
            variable: "admissions_cc",
            name: "Care Coordinator or Care Coordination staffing",
            color: "#4E79A7"
        },
        {
            variable: "admissions_cbc",
            name: "Community-Based Care (CBC) contractor",
            color: "#F28E2B"
        },
        {
            variable: "admissions_csey",
            name: "CSEY Advocates",
            color: "#E15759"
        },
        {
            variable: "admissions_dfps",
            name: "DFPS",
            color: "#76B7B2"
        },
        {
            variable: "admissions_dropin",
            name: "Drop-in center",
            color: "#59A14E"
        },
        {
            variable: "admissions_jj",
            name: "Juvenile Justice",
            color: "#EDC949"
        },
        {
            variable: "admissions_hotline",
            name: "National Human Trafficking Hotline",
            color: "#B07AA2"
        },
        {
            variable: "admissions_parent",
            name: "Parent, Caregiver or Legal Guardian",
            color: "#FF9DA7"
        },
        {
            variable: "admissions_other",
            name: "Other",
            color: "#9C755F"
        }
    ],
    ltTotalAdmissionsReferral: [
        {
            variable: "total_admissions",
            color: "#4E79A7",
            name: "Total Admissions", 
        },
        {
            variable: "total_referrals",
            color: "#A0CBE8",
            name: "Total Referrals", 
        } 
    ],
    ltAvLengthStay: [
        {
            variable: "length_of_stay",
            color: "#4E79A7",
            name: "Average Length of Stay", 
        }
    ], 
    ltAvLengthStayProg: [
        {
            variable: "length_of_stay_sjrc",
            color: "#4E79A7",
            name: "SJRC", 
        }, {
            variable: "length_of_stay_elpaso",
            color: "#F28E2B",
            name: "El Paso", 
        }, {
            variable: "length_of_stay_jonathan",
            color: "#E15759",
            name: "Jonathan's Place", 
        }, {
            variable: "length_of_stay_freedom_place",
            color: "#76B7B2",
            name: "Freedom Place", 
        }, {
            variable: "length_of_stay_hope_rising",
            color: "#59A14E",
            name: "Hope Rising", 
        }, {
            variable: "length_of_stay_new_life",
            color: "#EDC949",
            name: "New Life Refuge", 
        }, {
            variable: "length_of_stay_the_refuge",
            color: "#B07AA2",
            name: "The Refuge", 
        }, {
            variable: "length_of_stay_nicole",
            color: "#FF9DA7",
            name: "Nicole's Place", 
        }, {
            variable: "length_of_stay_home_hope",
            color: "#9C755F",
            name: "Home of Hope", 
        }
    ],
    ltDischarge: [
        { 
            variable : "kinship",
            name: "Paid or unpaid kinship",
            color: "#4E79A7"
        }, 
        { 
            variable : "foster_home" ,
            name: "Foster Home",
            color: "#A0CBE8"
        }, 
        {
            variable : "sil_supported_housing" ,
            name: "Supervised Independent Living/Supported housing",
            color: "#F28E2B"
        },
        { 
            variable : "return_home" ,
            name: "Return to prior bio or foster family home",
            color: "#FFBE7D"
        },
        {
            variable : "group_care" ,
            name: "Group Care",
            color: "#59A14E"
        },
        {
            variable : "psychiatric_hospital" ,
            name: "Psychiatric Hospital",
            color: "#8CD17D"
        },
        { 
            variable : "substance_facility" ,
            name: "Residential substance use treatment facility",
            color: "#B6992D"
        },
        { 
            variable : "residential_treatment" ,
            name: "Residential treatment center",
            color: "#499894"
        }, 
        {
            variable : "le" ,
            name: "Law Enforcement custody or juvenile detention",
            color: "#86BCB6"
        },
        {
            variable : "absence" ,
            name: "Missing or other unplanned absence from operation",    
            color: "#E15759"
        },
    ], 
    ltImpactAssessment: [
        { 
            variable : "impact_school",
            name: "Enrolled in School/Employed",
            color: "#4E79A7"
        }, 
        { 
            variable : "impact_safe" ,
            name: "Living in Safe/Stable Environment",
            color: "#F28E2B"
        }, 
        {
            variable : "impact_discharge_plans" ,
            name: "Following through with discharge plans",
            color: "#E15759"
        },
        { 
            variable : "impact_contact" ,
            name: "Maintaining contact with support system",
            color: "#76B7B2"
        },
        {
            variable : "impact_unknown" ,
            name: "Unknown",
            color: "#59A14E"
        }
    ],
    nrdArea: [
        {
            variable: "nrdArea",
            name: "Area",
            color: "#BAB0AC"
        },
    ],
    nrdOrganizations: [
        {
            varible: "nrdOrgs",
            name: "Organization",
            color: "#BAB0AC"
        },
    ]
       
    
}