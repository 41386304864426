import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import "./MobileDisclaimerModal.scss";

export default function MobileDisclaimerModal(props) {
    return (
        <Dialog
            className="public-site-dialog mobile-disclaimer-modal"
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogTitle>
                Mobile Disclaimer
            </DialogTitle>
            <DialogContent>
                We noticed you’re on a mobile device. Our graphs are best viewed on a larger screen size like a tablet or laptop. Thank you for your patience as we continue to optimize our mobile experience.
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={props.handleClose}
                    color="primary"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}