import React, { Fragment, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import debounce from "lodash/debounce";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

import CTABlock from "./CTABlock.js";
import CustomButton from "../Button/Button.js";
import { ENUMS } from "../../../Lib/Enums.js";

import "./NewsletterCTABlock.scss";

export default function NewsletterCTABlock() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: {
            email: "",
            firstName: "",
            lastName: ""
        }
    });
    const [shouldShowSnackbar, setShouldShowSnackbar] = useState(false);

    const textInputProps = {
        className: "text-input"
    };

    const isEmailValid = (email) => {
        // Regex copied from https://www.geeksforgeeks.org/how-to-validate-email-address-using-regexp-in-javascript/
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email.toLowerCase())
            ? true
            : "Email must be formatted correctly";
    };

    const getDescription = () => {
        return (
            <Fragment>
                <div className="small-text accent">
                    Subscribe
                </div>
                <div className="title">
                    Inbox Impact
                </div>
                <div className="description-text">
                    Fight Trafficking with Stories, News & Successes!
                </div>
                <div className="small-text contact">
                    <MailOutlineIcon />
                    hello@alliesagainstslavery.org
                </div>
                <div className="small-text contact">
                    <CallOutlinedIcon />
                    +1 (512) 730-0323
                </div>
                <div className="small-text contact">
                    <FmdGoodOutlinedIcon />
                    PO Box 784284, Austin, TX 78768
                </div>
            </Fragment>
        );
    };

    const getContent = () => {
        return (
            <form
                className="form cta-group"
                onSubmit={handleSubmit(debounceSubmitForm)}
            >
                <TextField
                    error={!!errors.email}
                    InputProps={textInputProps}
                    label="Email*"
                    helperText={errors.email ? errors.email.message : ""}
                    {...register("email", {
                        required: "Please complete this required field",
                        validate: isEmailValid
                    })} />
                <div className="form-input-group">
                    <TextField
                        InputProps={textInputProps}
                        label="First Name"
                        {...register("firstName")} />
                    <TextField
                        InputProps={textInputProps}
                        label="Last Name"
                        {...register("lastName")} />
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={true}
                            defaultChecked
                            size="small"
                            sx={{
                                color: "#FFFFFF",
                                '&.Mui-checked': {
                                    color: "#FFFFFF",
                                }
                            }}
                        />
                    }
                    label={
                        <Fragment>
                            <span>I accept the </span>
                            <a
                                rel="noopener, noreferrer"
                                href={ENUMS.URLS.TERMS}
                                target="_blank"
                            >Terms</a>
                        </Fragment>
                    }
                />
                <CustomButton type="submit">
                    Submit
                </CustomButton>
            </form>
        );
    };

    const generateHubspotEndpoint = () => {
        const { SUBMIT_FORM_ENDPOINT } = ENUMS.HUBSPOT;
        const pathParamKeyToValueMap = {
            portalId: process.env.REACT_APP_PUBLIC_SITE_HUBSPOT_PORTAL_ID,
            formGuid: process.env.REACT_APP_PUBLIC_SITE_HUBSPOT_FORM_ID
        };

        let endpoint = SUBMIT_FORM_ENDPOINT;
        for (const key of Object.keys(pathParamKeyToValueMap)) {
            endpoint = endpoint.replace(`:${key}`, pathParamKeyToValueMap[key]);
        }

        return endpoint;
    };

    const submitForm = async (data) => {
        const endpoint = generateHubspotEndpoint();
        const formBody = {
            fields: [
                {
                    name: "email",
                    value: data.email
                },
                {
                    name: "firstname",
                    value: data.firstName
                },
                {
                    name: "lastname",
                    value: data.lastName
                },
            ],
            legalConsentOptions: {
                consent: {
                    consentToProcess: true, // This is always kept to true
                    text: "Allies needs the contact information you provide to us to send you information about human trafficking, Lighthouse, and our work to protect freedom and dignity. You may unsubscribe from these communications at any time."
                }
            }
        };

        try {
            await axios.post(endpoint, formBody, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            reset();
            setShouldShowSnackbar(true);
        } catch (error) {
            console.log("Error on submitting hubspot form", error);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSubmitForm = useCallback(debounce(submitForm, 1000), []);

    const handleSnackbarClose = () => {
        setShouldShowSnackbar(false);
    };

    return (
        <div>
            <CTABlock
                className="newsletter-cta-block"
                description={getDescription()}
                content={getContent()}
            />
            <Snackbar
                open={shouldShowSnackbar}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                message="Thank you for signing up!"
            />
        </div>
    );
};