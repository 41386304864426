import React, { useEffect } from "react";
import * as d3 from "d3";

export default function HorizontalBarChart(props) {
    const anchor = React.createRef();
    
  useEffect(() => {
    var margin = { top: 0, right: 40, bottom: 90, left: 40, yLabelsWidth: 160 };
    var width = 900;
    var height = 750;

    if (props.report){
      width = 1000;
    }
    // eslint-disable-next-line no-unused-vars
    const colors = d3.scaleOrdinal([
      "#4E79A7", // Blue
      "#A0CBE8", // Lightblue
      "#F28E2B", // Orange
      "#FFBE7D", // Light Orange
      "#59A14E", // Green
      "#8CD17D", // Light Green
      "#B6992D", // Gold
      "#499894", // Teal
      "#86BCB6", // Dark Teal
      "#E15759", // Red
      "#FF9D9A", // Pink
      "#79706E", // Dark Gray
      "#BAB0AC", // Light Gray
      "#D37295", // Pink
    ]);

    var max = 0;
    if (props.data) {
      var data = props.data;
      data.sort((a, b) => {
        if (a.total === b.total) {
          return b[props.value].toLowerCase() > a[props.value].toLowerCase() ? 1 : -1;
        } else {
          return a.total - b.total;
        }
      })

      // if (data.length > 40) {
      //   height = 2250;
      // } else if (data.length > 25) {
      //   height = 1400;
      // } else if (data.length > 11) {
      //   height = 750;
      // } else {
      //   height = 500;
      // }
      if (data.length === 1) {
        height = 170
      } else if (data.length <= 3) {
        height = ((data.length * 60) * 2 )
      } else if (data.length <= 6) {
        height = (data.length * 60) + (data.length * 30) // bar heights + spacing inbetween bars
      } else {
        height = (data.length * 30) + (data.length * 20) // bar heights + spacing inbetween bars
      }

      var totalValue;
      // Some charts show top 10 but the percent needs to be based off full total not just top 10 total
      if (props.percentOfTotal) {
        totalValue = d3.sum(props.percentOfTotal.map((d) => {
          return d.total;
        }))
      } else {
        totalValue = d3.sum(data.map((d) => {
          return d.total;
        }))
      }
  
      var variableName = '';
      if (props.value){
        variableName = props.value
      }
      max = d3.max(data, (d) => +d.total);
  
      const svg = d3.select(anchor.current);
  
      svg.selectAll("svg").remove();
      svg.selectAll(".tooltip").remove();
  
      var barsvg = svg
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${margin.yLabelsWidth}, 20)`);
  
      var tooltip = d3
        .select(anchor.current)
        .append("div")
        .attr("class", "tooltip")
          .style("opacity", 0)
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          // .style("z-index", "-1")
          .style("padding", "5px")
        .style("width", "200px");
      tooltip.append("div").attr("class", "label");
      tooltip.append("div").attr("class", "count");
      tooltip.append("div").attr("class", "percent");
  
      var x = d3
        .scaleLinear()
        .domain([0, max])
        .range([margin.left, width - margin.right - margin.yLabelsWidth])
        //.nice();
  
      var y = d3
        .scaleBand()
        .domain(d3.range(data.length))
        .range([height - margin.bottom, margin.top])
        .padding(0.1);

        var xAxisFormat;
        if (max < 20) {
          xAxisFormat = d3.axisBottom(x).ticks(max);
        } else {
          xAxisFormat = d3.axisBottom(x).tickSizeOuter(0);
        }
  
      var xAxis = (g) =>
        g
          .attr("transform", `translate(0,${height - margin.bottom})`)
          .call(xAxisFormat);
  
      var yAxis = (g) =>
        g.attr("transform", `translate(${margin.left},0)`).call(
          d3
            .axisLeft(y)
            .tickSize(0)
            .tickFormat((i) => data[i][props.value].length > 0 ? data[i][props.value] : 'Unknown')
        ).selectAll(".tick text")
          .call(wrap, margin.yLabelsWidth - 25);
  
      barsvg
        .append("g")
        .selectAll("rect")
        .data(data)
        .join("rect")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .attr("x", (d) => x(0))
        .attr("y", (d, i) => y(i))
        .attr("height", data.length <= 6 ? 60 : 30)
        .attr("width", (d) => x(d.total) - x(0))
        .attr("fill", (d, i) => colors(i));
  
      barsvg.append("g").call(xAxis);
  
      barsvg.append("g").call(yAxis);

      let noPercent = props.noPercent;
  
      function onMouseOver(d) {
        tooltip.style("opacity", 1);
        // tooltip.style("z-index", "9999")
        tooltip.select(".label").text(d[variableName].length > 0 ? d[variableName] : 'Unknown');
        tooltip.select(".count").text("Total: " + d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        var percent = Math.round((d.total / totalValue) * 100);
        if (noPercent){
          tooltip.select(".percent").text("");
        } else {
          tooltip.select(".percent").text("Percent: " + percent + "%");
        }
      }
  
      function onMouseOut() {
        tooltip.style("opacity", 0);
        // tooltip.style("z-index", "-1")
  
      }
  
      function onMouseMove(d) {
        tooltip.style("opacity", 1);
        // tooltip.style("z-index", "9999")
        tooltip
        .style("top", (d3.event.clientY - 110) + "px")
        .style("left", (d3.event.clientX - 100) + "px");
      }
  
      // text label for the x axis
      var xaxisLocation = height - 30

      barsvg
        .append("text")
        .attr(
          "transform",
          "translate(" + ((width - margin.right - margin.yLabelsWidth) / 2) + " ," + xaxisLocation + ")"
        )
        .style("text-anchor", "middle")
        .text(props.xaxis);
  
      // text label for the y axis
        barsvg
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", -140)
          .attr("x", 0 - height / 3)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text(props.yaxis);

        function wrap(text, width) {
          text.each(function () {
            let hasBackSlashes = false // split at backslashes too
            var text = d3.select(this),
              backslashWords = text.text(),
              words = text.text().split(/[ /]+/).reverse(),  // .split(/\s+/)
              word,
              line = [],
              lineNumber = 0,
              lineHeight = 1, // ems
              y = text.attr("y"),
              dy = 0.01, // parseFloat(text.attr("dy")),
              tspan = text
                .text(null)
                .append("tspan")
                .attr("x", 0)
                .attr("y", y)
                .attr("dy", dy + "em");
            if (backslashWords.includes('/')) {
              hasBackSlashes = true
            }
            while ((word = words.pop())) {
              line.push(word);
              tspan.text(line.join(hasBackSlashes ? '/' : ' '));
              if (tspan.node().getComputedTextLength() > width && line.length > 1) {
                line.pop();
                tspan.text(line.join(hasBackSlashes ? '/' : ' '));
                line = [word];
                tspan = text
                  .append("tspan")
                  .attr("x", 0)
                  .attr("y", y)
                  .attr("dy", ++lineNumber * lineHeight + dy + "em")
                  .text(word);
              }
            }
          });
        }
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])


  return (
    <div
      ref={anchor}
      id={props.id}
      className={props.smalltext ? 'horizontal-graph' : 'graph1'}
      style={{ position: "relative", width: '900px' }}
    ></div>
  );
}
