import React, { useEffect, Fragment } from "react";
import { useAppContext } from '../../../../Lib/UserContext'
import { imbdata } from "../../GraphComponents/GraphVariables/BusinessesOfInterestData";
import { imbmockdata } from "./ImbData";
import SingleCard from "../../../../Components/Card";
//import { StackedBarVariableXGraph } from "../../GraphComponents/StackedBarVariableXAxis";
import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";
//import { GroupedBarVariableXGraph } from "../../GraphComponents/GroupedBarVariableXAxis";
import ImbTable from "./Tables/ImbTable";
import ImbHorizontalTable from "./Tables/ImbHorizontalTable";
import ImbTotalsTable from "./Tables/ImbTotalsTable";
import HorizontalBarChart from "../../GraphComponents/HorizontalBarChart";
import CardWithDrawer from "../../../../Components/CardWithDrawer";

// import { data } from "../Screenings/DemographicData";
//import PieGraph from '../../GraphComponents/PieGraph';

export default function BusinessesOfInterest(props) {
  const { stateLocation } = useAppContext()
  // eslint-disable-next-line no-unused-vars
  const [graphData, setGraphData] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [graphStackedData, setGraphStackedData] = React.useState([]);

  useEffect(() => {
    setGraphData(props.data);
    setGraphStackedData(props.data);
  }, [props.data]);

  // const addToReports = (data) => {
  //   props.addToReportPage(data);
  // };

  console.log("data biz by year", props.data.businessesByYear);

  const renderAllCharts = () => {
    return (
      <div>
        <div>
          <SingleCard
            width="100%"
            id="Business Information Businesses by Year"
            title="Business Information  >  Businesses by Year"
            graph={
              <SingleBarGraph
                imbByYear
                data={
                  props.data.businessesByYear
                    ? props.data.businessesByYear
                    : imbmockdata.businessByYear
                  // props.data.businessesByYear
                  // ? props.data.businessesByYear.sort((a, b) => {
                  //     if (a.total === b.total) {
                  //       return a.city > b.city ? -1 : 1;
                  //     }
                  //     return a.total > b.total ? -1 : 1;
                  //   })
                  // : imbmockdata.businessByYear.sort((a, b) => {
                  //     if (a.total === b.total) {
                  //       return a.city > b.city ? -1 : 1;
                  //     }
                  //     return a.total > b.total ? -1 : 1;
                  //   })
                }
                keys={imbdata.businessYear}
                xaxis="Year"
                yaxis="Number of Businesses"
                trendLine={true}
                trendLineLegend="Total Businesses"
                type="cc"
              />
            }
            definitions={[
              [
                "Businesses by Year",
                "This chart shows the total number of suspected IMBs per year, and can only be filtered by Location; other filters are not applicable.",
              ],
            ]}
            table={
              <ImbTable
                data={props.data.businessesByYear}
                keys={imbdata.businessYear}
                id="Business by Year"
                single={true}
              />
            }
            addToReports={props.addToReportPage}
          />
          <SingleCard
            width="100%"
            id="Business Information Businesses by Location"
            title="Business Information  >  Businesses by Location"
            graph={
              <HorizontalBarChart
                // data={
                //   props.data.businessesByLocation
                //     ? props.data.businessesByLocation
                //     : imbmockdata.businessByLocation
                // }
                percentOfTotal={props.data.businessByLocationAll}
                data={
                  props.data.businessesByLocation
                    ? props.data.businessesByLocation.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.city > b.city ? -1 : 1;
                        }
                        return a.total > b.total ? -1 : 1;
                      })
                    : imbmockdata.businessByLocation.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.city > b.city ? -1 : 1;
                        }
                        return a.total > b.total ? -1 : 1;
                      })
                }
                value="city"
                xaxis="Number of Businesses"
                yaxis="Location"
              />
            }
            definitions={[
              [
                "Businesses by Location",
                "This chart shows the top ten locations based on the count of suspected IMBs per city, based on selected filters, as of the most recent quarterly data snapshot. The percent is the number of businesses by location out of the total businesses. All filters apply to this chart.",
              ],
            ]}
            table={
              <ImbHorizontalTable
                data={props.data.businessByLocationAll}
                id="Business by Location"
                reverseOrder={true}
              />
            }
            addToReports={props.addToReportPage}
          />
          <SingleCard
            width="100%"
            id="Business Information Businesses by Location and Population"
            title="Business Information  >  Businesses by Location and Population"
            graph={
              <HorizontalBarChart
                percentOfTotal={props.data.businessesByLocationPopAll}
                data={
                  props.data.businessesByLocationPop
                    ? props.data.businessesByLocationPop.sort((a, b) => {
                        if (a.total === b.total) {
                          return a.city > b.city ? -1 : 1;
                        }
                        return a.total > b.total ? -1 : 1;
                      })
                    : []
                }
                value="city"
                xaxis="Number of Businesses per 100,000 Population"
                yaxis="Location"
              />
            }
            definitions={[
              [
                "Businesses by Location and Population",
                `${stateLocation === 'TX' ? `This chart shows the top ten locations based on the count of suspected IMBs per 100,000 population per city, based on selected filters, as of the most recent quarterly data snapshot. City population data is from the <a href='https://demographics.texas.gov/' target='_blank' rel='noopener noreferrer'>Texas Demographic Center</a>. The percent is the number of businesses by location per 100,000 population out of all screenings per 100,000 population. All filters apply to this chart.` : `This chart shows the top ten locations based on the count of suspected IMBs per 100,000 population per city, based on selected filters, as of the most recent quarterly data snapshot. City population data is from the <a href='https://www.louisiana.gov/demographics-and-geography/' target='_blank' rel='noopener noreferrer'>Louisiana Demographic Center</a>. The percent is the number of businesses by location per 100,000 population out of all screenings per 100,000 population. All filters apply to this chart.`}</p>`,
              ],
            ]}
            table={
              <ImbHorizontalTable
                noTotalRow
                decimalPoint
                data={props.data.businessesByLocationPopAll}
                id="Business by Location and Pop"
                reverseOrder={true}
              />
            }
            addToReports={props.addToReportPage}
          />
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <CardWithDrawer
              data={props.data.totals}
              width="35vw"
              id="Business Information Total Businesses"
              title="Business Information  >  Total Businesses"
              category="OVC Measures"
              value={[
                {
                  data: props.data.totals.locations,
                  text: "Total Locations",
                },
                {
                  data: props.data.totals.businesses,
                  text: "Total Businesses",
                },
              ]}
              definitions={[["", "This chart lists all suspected IMBs by business name and address, based on selected filters, as of the most recent quarterly data snapshot. Total Locations refers to each unique city in which there is one or more suspected IMB."]]}
              table={<ImbTotalsTable title="Total Businesses" data={props.data.orgs} />}
              addToReports={props.addToReportPage}
            />
          </div>
        </div>
      </div>
    );
  }

  const renderSingleChart = () => {
      const charts = renderAllCharts();
      const singleCards = charts.props.children.props.children;

      const index = singleCards.findIndex((card) => card.props.id === props.chartId);
      if (index <= -1) {
          return <Fragment></Fragment>;
      }

      const elementToRender = singleCards[index];
      return <SingleCard
          {...elementToRender.props}
          title={props.chartTitle || props.title}
          description={props.chartInsight || ""}
          shouldUseNewStyles={true}
      />;
  }

  return (
      props.chartId
          ? renderSingleChart()
          : renderAllCharts()
  );
}
