import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import "./InteractivePieChart.css";

const TableHoverDonutChart = (props) => {
  const histogramRef = useRef(null);
  let tableColorArray = ["#4E79A7", "#F28E2B", "#E15759", "#76B7B2", "#59A14E", "#EDC949", "#B07AA2", "#FF9DA7", "#9C755F", "#4E79A7", "#F28E2B", "#E15759", "#76B7B2", "#59A14E", "#EDC948", "#B07AA1", "#FF9DA7", "#9C755F", "#4E79A7", "#F28E2B", "#E15759", "#76B7B2"]
  let donutColorIndex = props.donutColorIndex
  const [currentXaxis, setCurrentXaxis] = useState(props.activeCategory)

  useEffect(() => {
    if (props.data) {
      let getCurrentXaxis = currentXaxis
      if (!currentXaxis) {
        getCurrentXaxis = {
          id: props.data[0].id,
          color: '#4E79A7',
          text: `Concern Level for ${props.data[0].id}`
        }
        setCurrentXaxis(getCurrentXaxis)
      }
      var pieChartData = props.data; // response.children;
      const svg = d3.select(histogramRef.current);
      svg.selectAll("svg").remove();
      svg.selectAll("table").remove();
      svg.selectAll("div").remove();

      // compute total for each state.
      pieChartData.forEach(function (d) {
        var childrenTotalValue = 0;
        d.children.forEach(function (item) {
          childrenTotalValue += Number(item.value);
        });
        return (d.total = childrenTotalValue);
      });

      // function to handle histogram.
      // function histoGram(pCD) {
      //   var hG = {},
      //     hGDim = { t: 60, r: 0, b: 30, l: 10 };
      //   hGDim.w = 550 - hGDim.l - hGDim.r;
      //   hGDim.h = 300 - hGDim.t - hGDim.b;

      //   //create svg for histogram.
      //   var hGsvg = svg
      //     .append("svg")
      //     .attr("width", hGDim.w + hGDim.l + hGDim.r)
      //     .attr("height", hGDim.h + 35 + hGDim.t + hGDim.b)
      //     .attr("class", "barChart")
      //     .append("g")
      //     .attr("transform", "translate(" + hGDim.l + "," + hGDim.t + ")");

      //   // create function for x-axis mapping.
      //   var x = d3
      //     .scaleBand()
      //     .rangeRound([0, hGDim.w], 0.1)
      //     .domain(
      //       pCD.map(function (d) {
      //         return d[0];
      //       })
      //     ); // REMOVED: d3.scaleOrdinal() from the beginning

      //   // Add x-axis labels and ticks to the histogram svg.
      //   hGsvg
      //     .append("g")
      //     .attr("class", "x axis")
      //     .attr("width", 1)
      //     .call(d3.axisBottom(x))
      //     .attr("transform", "translate(0," + hGDim.h + ")")
      //     .selectAll("text")
      //     .call(wrap, x.bandwidth() - 5);

      //   function wrap(text, width) {
      //     text.each(function () {
      //       var text = d3.select(this),
      //         words = text.text().split(/\s+/).reverse(),
      //         word,
      //         line = [],
      //         lineNumber = 0,
      //         lineHeight = 1.1, // ems
      //         y = text.attr("y"),
      //         dy = 0.5, // parseFloat(text.attr("dy")),
      //         tspan = text
      //           .text(null)
      //           .append("tspan")
      //           .attr("x", 0)
      //           .attr("y", y)
      //           .attr("dy", dy + "em");
      //       while ((word = words.pop())) {
      //         line.push(word);
      //         tspan.text(line.join(" "));
      //         if (tspan.node().getComputedTextLength() > width && line.length > 1) {
      //           line.pop();
      //           tspan.text(line.join(" "));
      //           line = [word];
      //           tspan = text
      //             .append("tspan")
      //             .attr("x", 0)
      //             .attr("y", y)
      //             .attr("dy", ++lineNumber * lineHeight + dy + "em")
      //             .text(word);
      //         }
      //       }
      //     });
      //   }

      //   // Get all numbers into one array for y-axis simpler calculations
      //   var allNums = [];
      //   // eslint-disable-next-line no-unused-vars
      //   var getAllNums = pCD.map(function (d) {
      //     // d.map(function (item) {
      //     allNums.push(d[1]);
      //     // })
      //     return true;
      //   });
      //   // Create function for y-axis map.
      //   var y = d3
      //     .scaleLinear()
      //     .domain([0, d3.max(allNums)])
      //     .range([hGDim.h, 0]);

      //   var bars = hGsvg
      //     .selectAll(".bar")
      //     .data(pCD)
      //     .enter()
      //     .append("g")
      //     .attr("class", "bar");

      //   //create the rectangles.
      //   bars
      //     .append("rect")
      //     .attr("x", function (d) {
      //       return x(d[0]);
      //     })
      //     .attr("y", function (d) {
      //       return y(d[1]);
      //     })
      //     .attr("width", x.bandwidth() - 5)
      //     .attr("height", function (d) {
      //       return d.forEach(function (item) {
      //         item[1] = +item[1];
      //         return hGDim.h - y(item[1]);
      //       });
      //     })
      //     .attr("fill", barColor);
      //   // .on("mouseover", mouseover) // mouseover is defined below.(NOT NEEDED ANYMORE?)
      //   // .on("mouseout", mouseout); // mouseout is defined below.(NOT NEEDED ANYMORE?)



      //   //Create the frequency labels above the rectangles.

      //   bars
      //     .append("text")
      //     .text(function (d) {
      //       return d.forEach(function (item) {
      //         return d3.format(",")(item[1]);
      //       });
      //     })
      //     .attr("x", function (d) {
      //       return x(d[0]) + x.bandwidth() / 2;
      //     })
      //     .attr("y", function (d) {
      //       return d.forEach(function (item) {
      //         return y(d[1]) - 5;
      //       });
      //     })
      //     .attr("text-anchor", "middle");



      //   // function mouseover(d) { // for rectangles/bars (NOT NEEDED ANYMORE?)
      //   //   // utility function to be called on mouseover.
      //   //   // filter for selected state.
      //   //   var st = pieChartData.filter(function (s) {
      //   //     return d[0] === d[0]; // Removed: s.State === d[0]
      //   //   })[0],
      //   //     nD = d3.keys(st.freq).map(function (s) {
      //   //       return { type: s, freq: st.freq[s] };
      //   //     });

      //   //   // call update functions of pie-chart and legend.
      //   //   pC.update(nD);
      //   //   leg.update(nD);
      //   // }

      //   // function mouseout(d) {
      //   //   // utility function to be called on mouseout.
      //   //   // reset the pie-chart and legend.
      //   //   pC.update(tF);
      //   //   leg.update(tF);
      //   // }

      //   // create function to update the bars. This will be used by pie-chart.
      //   hG.update = function (nD, color) {
      //     // LEFT OFF!!!******************************************************
      //     // Remove old data from the bars.
      //     svg.selectAll(".bar").remove();

      //     // update the domain of the x-axis map to reflect change in frequencies.
      //     // Get all x-axis text into one array for simpler mapping
      //     var updateAllText = [];
      //     // eslint-disable-next-line no-unused-vars
      //     var getAllText = nD[0].map(function (d) {
      //       return updateAllText.push(d[0]);
      //     });

      //     // Attach only the new text to the x-axis.
      //     // eslint-disable-next-line no-unused-vars
      //     var updateText = hGsvg
      //       .select("g")
      //       .attr("class", "x axis")
      //       .attr("width", 1)
      //       .attr("transform", "translate(0," + hGDim.h + ")")
      //       .call(d3.axisBottom(x.domain(updateAllText)))
      //       .selectAll("text")
      //       .call(wrap, x.bandwidth() - 5);

      //     function wrap(text, width) {
      //       text.each(function () {
      //         var text = d3.select(this),
      //           words = text.text().split(/\s+/).reverse(),
      //           word,
      //           line = [],
      //           lineNumber = 0,
      //           lineHeight = 1.1, // ems
      //           y = text.attr("y"),
      //           dy = 0.5, // parseFloat(text.attr("dy")),
      //           tspan = text
      //             .text(null)
      //             .append("tspan")
      //             .attr("x", 0)
      //             .attr("y", y)
      //             .attr("dy", dy + "em");
      //         while ((word = words.pop())) {
      //           line.push(word);
      //           tspan.text(line.join(" "));
      //           if (tspan.node().getComputedTextLength() > width && line.length > 1) {
      //             line.pop();
      //             tspan.text(line.join(" "));
      //             line = [word];
      //             tspan = text
      //               .append("tspan")
      //               .attr("x", 0)
      //               .attr("y", y)
      //               .attr("dy", ++lineNumber * lineHeight + dy + "em")
      //               .text(word);
      //           }
      //         }
      //       });
      //     }

      //     // ********************************

      //     // Give bars with "0" a tiny bit of height/color
      //     var maximumY = d3.max(nD[0], function (d) {
      //       return d[1];
      //     });
      //     var maximumFixer = 0;
      //     if (maximumY === 0) {
      //       maximumFixer = 1;
      //     } else {
      //       maximumFixer = maximumY;
      //     }
      //     // update the domain of the y-axis map to reflect change in frequencies.
      //     y.domain([-(maximumFixer * 0.02), maximumFixer]);

      //     //var bars = svg.selectAll(".bar").data(nD[0]);

      //     const bars = hGsvg
      //       .selectAll(".bar")
      //       .data(nD[0])
      //       .enter()
      //       .append("g")
      //       .merge(hGsvg)
      //       .attr("class", "bar");

      //     // Transition the y axis spot, height, and color of rectangles.
      //     bars
      //       .append("rect")
      //       .attr("x", function (d) {
      //         return x(d[0]);
      //       })
      //       .attr("y", function (d) {
      //         return y(0);
      //       })
      //       .attr("width", x.bandwidth() - 5)
      //       .attr("height", 0);

      //     bars
      //       .select("rect")
      //       .transition()
      //       .duration(500)
      //       .attr("x", function (d) {
      //         return x(d[0]);
      //       })
      //       .attr("y", function (d) {
      //         return y(d[1]);
      //       })
      //       .attr("width", x.bandwidth() - 5)
      //       .attr("height", function (d) {
      //         d[1] = +d[1];
      //         return hGDim.h - y(d[1]);
      //       })
      //       .attr("fill", color);

      //     // transition the top number labels location and change value.
      //     if (!report) {
      //       bars
      //         .append("text")
      //         .attr("class", "raceagetext")
      //         .text(function (d) {
      //           return d3.format(",")(d[1]);
      //         })
      //         .attr("x", function (d) {
      //           return x(d[0]) + x.bandwidth() / 2;
      //         })
      //         .attr("y", function (d) {
      //           return y(0);
      //         });
      //     }

      //     bars
      //       .select("text")
      //       .transition()
      //       .duration(500)
      //       .text(function (d) {
      //         return d3.format(",")(d[1]);
      //       })
      //       .attr("x", function (d) {
      //         return x(d[0]) + x.bandwidth() / 2;
      //       })
      //       .attr("y", function (d) {
      //         return y(d[1]) - 5;
      //       })
      //       .attr("text-anchor", "middle");
      //   };
      //   return hG;
      // }

      // Creating the tooltip
      var tooltip = d3
        .select(histogramRef.current)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        .style("z-index", "-1")
        .style("padding", "5px");
      tooltip.append("div").attr("class", "title");
      tooltip.append("div").attr("class", "label");
      tooltip.append("div").attr("class", "percent");

      function onMouseOver(d) {
        tooltip.style("opacity", 1)
        tooltip.style("z-index", "9999");

        let label = d.data.type
        let value = d.data.total
        let total = pieChartData.filter(f => f.id === d.data.parent)[0].total

        let percent =
          Math.round((1000 * value) / total) / 10;
        // console.log("hotline d.value", d.value);
        // console.log("hotline d.total", d.total);
        // console.log("hotline total", total);
        tooltip.select(".title").text(label);
        tooltip
          .select(".label")
          .text(
            "Total: " +
            value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        tooltip.select(".percent").text("Percent: " + percent + "%");
      }

      function onMouseOut() {
        tooltip.style("opacity", 0)
          .style("z-index", "-1");
      }

      function onMouseMove(d) {
        tooltip
          .style("opacity", 1)
          .style("z-index", "9999")
          .style("top", d3.event.clientY - 150 + "px")
          .style("left", d3.event.clientX - 150 + "px");
      }

      // function to handle legend.
      function legend(lD) {
        var leg = {};

        // create table for legend.
        var legend = svg
          .append("table")
          .attr("class", "legend")
          .style('order', '1')
          .attr("width", "250")
          .attr("height", "120")
          .style('font-size', '12px');

        var legendHead = legend.append("thead");
        legendHead
          .selectAll("tr")
          .data([
            [
              { value: "" },
              { value: "Category" },
              { value: "# of Screenings" },
              { value: `% of ${props.percentOf ?? 'Total'}` },
            ],
          ])
          .enter()
          .append("tr")
          .selectAll("th")
          .data(function (d) {
            return d;
          })
          .enter()
          .append("th")
          .attr("style", "font-size: 12px; font-weight: bold; vertical-align: bottom;")
          .text(function (d) {
            return d.value;
          });

        // create one row per segment.
        var tr = legend
          .append("tbody")
          .selectAll("tr")
          .data(lD)
          .enter()
          .append("tr");

        // create the first column for each segment.
        tr.append("td")
          .append("svg")
          .attr("width", "16")
          .attr("height", "16")
          .append("rect")
          .attr("width", "16")
          .attr("height", "16")
          .attr("fill", function (d) {
            return tableColorArray[pieChartData.map(obj => obj.id).indexOf(d.type)];
          })
          .on("mouseover", (d => mouseover({
            data: {
              type: d.type,
              total: d.total,
            }
          })));
        // .on("mouseout", mouseout); // (NOT NEEDED ANYMORE?)

        // Utility function to be called on mouseover a pie slice.
        function mouseover(d) {
          // call the update function of pieChart with new data.
          let newData;
          pieChartData.forEach((p) => {
            if (p.id === d.data.type) {
              return newData = p.children.map(c => { return { type: c.id, total: c.value, parent: p.id } })
            }
          })
          let updateCurrentXaxis = {
            id: d.data.type,
            color: tableColorArray[pieChartData.map(obj => obj.id).indexOf(d.data.type)],
            text: `Concern Level for ${d.data.type}`
          }
          pC.update(newData, updateCurrentXaxis)
          setCurrentXaxis(updateCurrentXaxis)
          if (props.updateActiveCategory) {
            props.updateActiveCategory(updateCurrentXaxis)
          }
          // ,
          // tableColorArray[pieChartData.map(obj => obj.id).indexOf(d.data.type)]
        }

        // mouseover({
        //   data: {
        //     type: pieChartData[0].id,
        //     total: pieChartData[0].total,
        //   },
        // });

        // create the second column for each segment.
        tr.append("td").text(function (d) {
          return d.type;
        });

        // create the third column for each segment.
        tr.append("td")
          .attr("class", "legendTotal")
          .style('text-align', 'center')
          .text(function (d) {
            return d3.format(",")(d.total);
          });

        // create the fourth column for each segment.
        tr.append("td")
          .attr("class", "legendPerc")
          .style('text-align', 'center')
          .text(function (d) {
            return getLegend(d, lD);
          });

        // Utility function to be used to update the legend.
        // leg.update = function (nD) { // NOT NEEDED ANYMORE??
        //   // update the data attached to the row elements.
        //   var l = legend.select("tbody").selectAll("tr").data(nD);

        //   // update the frequencies.
        //   l.select(".legendTotal").text(function (d) {
        //     return d3.format(",")(d.freq);
        //   });

        //   // update the percentage column.
        //   l.select(".legendPerc").text(function (d) {
        //     return getLegend(d, nD);
        //   });
        // };

        function getLegend(d, aD) {
          // Utility function to compute percentage.
          return d3.format(",.2%")(
            d.total /
            d3.sum(
              aD.map(function (v) {
                if (v.total === 0) {
                  return 0;
                } else {
                  return v.total;
                }
              })
            )
          );
        }

        return leg;
      }


      // function to handle pieChart.
      function pieChart(pD, xaxis) {
        // console.log('pD', pD)
        var pC = {},
          pieDim = { w: 280, h: 230 };
        pieDim.r = Math.min(pieDim.w, pieDim.h) / 2;

        // create svg for pie chart.
        var piesvg = svg
          .append("svg")
          .attr("class", "pieChart")
          .style('order', '2')
          .attr("width", pieDim.w)
          .attr("height", pieDim.h)
          .append("g")
          .attr(
            "transform",
            "translate(" + pieDim.w / 2 + "," + pieDim.h / 2 + ")"
          );

        // create function to draw the arcs of the pie slices.
        var arc = d3
          .arc()
          .outerRadius(pieDim.r - 10)
          .innerRadius(40); // innerRadius creates the white space in the middle

        // create a function to compute the pie slice angles.
        var pie = d3
          .pie()
          .sort(null)
          .value(function (d) {
            if (d.total === 0) {
              return 0.05;
            } else {
              return d.total;
            }
          });

        // Draw the pie slices.
        piesvg
          .selectAll("path")
          .data(pie(pD))
          .enter()
          .append("path")
          .attr("d", arc)
          .on("mouseover", onMouseOver)
          .on("mouseout", onMouseOut)
          .on("mousemove", onMouseMove)
          .each(function (d) {
            this._current = d;
          })
          .style("fill", function (d) {
            return donutColorIndex.filter(f => f.name === d.data.type)[0].color;
          });

        let xaxisSvg = svg
          .append("div")
          .style('order', '3')
          .style("min-width", `${pieDim.w}px`)
          .style('height', '20px')
          .style('position', 'absolute')
          .style('left', '250px')
          .style('bottom', '-20px');
          // .style('display', 'flex')
          // .style('flex-direction', 'row')
          // .style("justify-content", "flex-end");
        
        xaxisSvg
          .append('div')
          .attr('class', 'xaxisDiv')
          // .style("width", '100%')
          // .style("transform", `translate(${pieDim.w}, ${(pieDim.h / 2 + 10)})`)
          // .style('margin-left', 580 - pieDim.w)
          .style("text-anchor", "middle")
          .style('display', 'flex')
          .style('flex-direction', 'row')
          .style('align-items', 'center')
          .style("justify-content", "center")
          .append("svg")
          .attr("width", "20")
          .attr("height", "20")
          .style('margin-right', 7)
          .append('rect')
          .attr('class', 'xaxisColor')
          .attr("width", "20")
          .attr("height", "20")
          .attr("fill", xaxis.color);
        
        xaxisSvg
          .select('.xaxisDiv')
          .append('span')
          .attr('class', 'xaxisText')
          .style("color", "#2a363d")
          .style('white-space', 'nowrap')
          .style('font-size', '14px')
          .text(xaxis.text);
        

        // create function to update the pie slices.
        pC.update = function (data, xaxis) {
          // console.log('data', data)

          // piesvg
          //   .selectAll("path")
          //   .data(pie(data))
          //   .transition()
          //   .duration(500)
          //   .append("path")
          //   .attr("d", arc)
          //   .each(function (d) {
          //     this._current = d;
          //   })
          //   .style("fill", function (d) {
          //     return donutColorIndex.filter(f => f.name === d.data.type)[0].color;
          //   });
          piesvg
            .selectAll("path")
            .data(pie(data))
            .transition()
            .duration(500)
            .attrTween("d", arcTween)
            // .append("path")
            // .attr("d", arc)
            .each(function (d) {
              this._current = d;
            })
            .style("fill", function (d) {
              return donutColorIndex.filter(f => f.name === d.data.type)[0].color;
            });

          xaxisSvg
            .select(".xaxisColor")
            .attr('fill', xaxis.color);
          
          xaxisSvg
            .select(".xaxisText")
            .text(xaxis.text);
        }
        return pC

        //Utility function to be called on mouseout a pie slice.
        // function mouseout(d) { //(NOT NEEDED ANYMORE?)
        //   // call the update function of histogram with all data.
        //   hG.update(
        //     pieChartData.map(function (v) {
        //       return [v.State, v.total];
        //     }),
        //     barColor
        //   );
        // }
        // Animating the pie-slice requiring a custom function which specifies
        // how the intermediate paths should be drawn.
        // eslint-disable-next-line no-unused-vars
        function arcTween(a) {

          var i = d3.interpolate(this._current, a);
          this._current = i(0);
          return function (t) {
            // console.log(t)
            return arc(i(t));
          };
        }
        // return pC;
      }

      let getPieData = pieChartData.filter(f => f.id === getCurrentXaxis.id)[0] ?? pieChartData[0]

      var tP = getPieData.children.map(c => {
        return {
          type: c.id,
          total: c.value,
          parent: getPieData.id
        };
      });

      // calculate total frequency by segment for all state.
      var tF = pieChartData.map(d => d.id).map(function (d) {
        const findObj = pieChartData.find((e) => e.id === d);
        return {
          type: d,
          total: findObj.total,
        };
      });

      // calculate total frequency by state for all segment.
      // var sF = pieChartData.map(function (d) {
      //   return d.children.map(function (item) {
      //     return [item.id, (item.value = +item.value)];
      //   });
      // });

      // var hG = histoGram(sF), // create the histogram.
      // eslint-disable-next-line no-unused-vars
      var leg = legend(tF), // create the legend
        // eslint-disable-next-line no-unused-vars
        pC = pieChart(tP, getCurrentXaxis); // create the pie-chart
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  return ( // css inline for generating report to work
    <div id={props.id} style={{ paddingBottom: props.report ? 0 : 40 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div
          className='histogramRef tableHoverDonutChart'
          ref={histogramRef}
          style={{ position: 'relative', display: 'flex', alignItems: 'center', overflow: 'visible'}}
        ></div>
        <div style={{ width: 'fit-content', padding: 5, borderRadius: 5, border: '4px solid white', borderColor: currentXaxis?.color}}>
          {props.donutColorIndex.map((dci, i) => {
            return (
              <div key={i} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div style={{ width: 30, height: 10, backgroundColor: dci.color, marginRight: 5 }} />
                <span style={{ color: '#2a363d', fontSize: '14px' }}>{dci.name}</span>
              </div>
            )
          })}
        </div>
      </div>
      {props.report && <><br></br> <br></br></>}
      {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingRight: props.report ? (280/3.5) : (280/2), margin: props.report ? '20px 0px' : 0}}>
        <div style={{ width: 20, height: 20, backgroundColor: currentXaxis.color, marginRight: 10 }} />
        <span style={{ color: '#2a363d' }}>{currentXaxis.text}</span>
      </div> */}
    </div>
  );
}

export default TableHoverDonutChart