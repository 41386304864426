// geojson.io
import { dashboardNationalData } from '../Data/dashboardNationalData'
import { mapsNationalData } from './mapsNationalData'

import { dashboardTXData } from '../Data/dashboardTXData'
import { mapsTXData } from '../Data/mapsTXData'

import { dashboardLAData } from '../Data/dashboardLAData'
import { mapsLAData } from '../Data/mapsLAData'

// user role options (user_role):
// Admin: access to all dashboards of the selected State and "Admin" button
// Standard: access to all dashboards of the selected State except Prevention & Intervention > Care Coordination
// Full Access: access to all dashboards of the selected State
// Custom: Cannot be selected in V1 (2/9/24), future addition

// Items and content that are currently accessable
export const availableDashboards = [
    {
        name: 'National',
        stateLocation: 'National',
        accessLevels: [{ name: 'Admin', var: 'Admin' }, { name: 'Full Access', var: 'Full' }], // 'Custom' is not avail in v1
        defaultPrimaryNav: 'Cases',
        defaultSecondaryNav: 'Federal',
        dashboardDataFile: dashboardNationalData,
        helpPages: ['Submit Feedback', 'Support'],
        mapsIndex: mapsNationalData,
    },
    {
        name: 'Louisiana',
        stateLocation: 'LA',
        accessLevels: [{ name: 'Admin', var: 'Admin' }, { name: 'Standard', var: 'Standard' }, { name: 'Full Access', var: 'Full' }],
        defaultPrimaryNav: 'Prevention',
        defaultSecondaryNav: 'Services',
        dashboardDataFile: dashboardLAData,
        helpPages: ['Submit Feedback', 'Support', 'Data Sources', 'CSE-IT Form'],
        mapsIndex: mapsLAData,
    },
    {
        name: 'Texas',
        stateLocation: 'TX',
        accessLevels: [{ name: 'Admin', var: 'Admin' }, { name: 'Standard', var: 'Standard' }, { name: 'Full Access', var: 'Full' }],
        defaultPrimaryNav: 'Prevention',
        defaultSecondaryNav: 'Services',
        dashboardDataFile: dashboardTXData,
        helpPages: ['Submit Feedback', 'Support', 'Data Sources', 'CSE-IT Form'],
        mapsIndex: mapsTXData,
    },
]