export const federalcasesdata = {
    casesYear: [ 
        { 
            variable: "minorsextraf",
            name: "Minor Sex Trafficking",
            color: "#4E79A7"
        }, 
        {
            variable: "adultsextraf" ,
            name: "Adult Sex Trafficking",
            color: "#A0CBE8"
        },  
        { 
            variable: "labor" ,
            name: "Labor Trafficking",
            color: "#F28E2B"
        },
        { 
            variable: "both" ,
            name: "Both Sex and Labor Trafficking",
            color: "#FFBE7D"
        },  
        // {
        //     variable : "2005" ,
        //     name: "2005",
        //     color: "#59A14E"
        // },  {
        //     variable : "2006" ,
        //     name: "2006",
        //     color: "#8CD17D"
        // },  {
        //     variable : "2007" ,
        //     name: "2007",
        //     color: "#B6992D"
        // },  {
        //     variable : "2008" ,
        //     name: "2008",
        //     color: "#499894"
        // } ,{
        //     variable : "2009" ,
        //     name: "2009",
        //     color: "#86BCB6"
        // }, {
        //     variable : "2010" ,
        //     name: "2010",
        //     color: "#E15759"
        // },{ 
        //     variable : "2011",
        //     name: "2011",
        //     color: "#4E79A7"
        // }, 
        // {
        //     variable : "2012" ,
        //     name: "2012",
        //     color: "#A0CBE8"
        // },  
        // { 
        //     variable : "2013" ,
        //     name: "2013",
        //     color: "#F28E2B"
        // },
        // { 
        //     variable : "2014" ,
        //     name: "2014",
        //     color: "#FFBE7D"
        // },  
        // {
        //     variable : "2015" ,
        //     name: "2015",
        //     color: "#59A14E"
        // },  {
        //     variable : "2016" ,
        //     name: "2016",
        //     color: "#8CD17D"
        // },  {
        //     variable : "2017" ,
        //     name: "2017",
        //     color: "#B6992D"
        // },  {
        //     variable : "2018" ,
        //     name: "2018",
        //     color: "#499894"
        // } ,{
        //     variable : "2019" ,
        //     name: "2019",
        //     color: "#86BCB6"
        // }, {
        //     variable : "2020" ,
        //     name: "2020",
        //     color: "#E15759"
        // }
  
    ],
    periodOfExploitation: [
        {
            variable: "minorsextraf",
            name: "Minor Sex Trafficking",
            color: "#4E79A7"
        },
        {
            variable: "adultsextraf",
            name: "Adult Sex Trafficking",
            color: "#A0CBE8"
        },
        {
            variable: "labor",
            name: "Labor Trafficking",
            color: "#F28E2B"
        },
        {
            variable: "both",
            name: "Both Sex and Labor Trafficking",
            color: "#FFBE7D"
        },  
    ],
    laborTraffType: [
        {
            variable: "Agriculture",
            name: "Agriculture",
            color: "#4E79A7",
        },
        {
            variable: "Arts/Sports/Entertainment",
            name: "Arts/Sports/Entertainment",
            color: "#A0CBE8"
        },
        {
            variable: "Carnivals",
            name: "Carnivals",
            color: "#F28E2B"
        },
        {
            variable: "Commercial Cleaning Services",
            name: "Commercial Cleaning Services",
            color: "#FFBE7D",
        },
        {
            variable: "Construction",
            name: "Construction",
            color: "#59A14E"
        },
        {
            variable: "Domestic Servitude",
            name: "Domestic Servitude",
            color: "#8CD17D"
        },
        {
            variable: "Factories/Manufacturing",
            name: "Factories/Manufacturing",
            color: "#B6992D"
        },
        {
            variable: "Forestry and Logging",
            name: "Forestry and Logging",
            color: "#499894"
        },
        {
            variable: "Healthcare",
            name: "Healthcare",
            color: "#86BCB6"
        },
        {
            variable: "Health and Beauty",
            name: "Health and Beauty",
            color: "#E15759",

        },
        {
            variable: "Hotel and Hospitality",
            name: "Hotel and Hospitality",
            color: "#FF9D9A"
        },
        {
            variable: "Illicit Activities",
            name: "Illicit Activities",
            color: "#FF9DA7"
        },
        {
            variable: "Landscaping",
            name: "Landscaping",
            color: "#79706E",
        },
        {
            variable: "Peddling and Begging",
            name: "Peddling and Begging",
            color: "#BAB0AC"
        },
        {
            variable: "Recreational Facilities",
            name: "Recreational Facilities",
            color: "#D37295"
        },
        {
            variable: "Restaurant and Food Services",
            name: "Restaurant and Food Services",
            color: "#4E79A7"
        },
        {
            variable: "Traveling Sales Crew",
            name: "Traveling Sales Crew",
            color: "#A0CBE8"
        },
        {
            variable: "Other",
            name: "Other",
            color: "#F28E2B"
        },
        {
            variable: "Unknown",
            name: "Unknown",
            color: "#FFBE7D",
        },
    ],
    defendantsVictimsTotal: [ 
        {
            variable: "minorsextraf" ,
            name: "Minor Sex Trafficking",
            color: "#4E79A7"
        },
        {
            variable: "adultsextraf" ,
            name: "Adult Sex Trafficking",
            color: "#A0CBE8"
        },
        {
            variable: "labor" ,
            name: "Labor Trafficking",
            color: "#F28E2B"
        },
        {
            variable: "both" ,
            name: "Both Sex and Labor Trafficking",
            color: "#FFBE7D"
        },
        // {
        //     variable : "victim" ,
        //     name: "Victims",
        //     color: "#59A14E"
        // },
        // {
        //     variable : "defendant" ,
        //     name: "Defendants",
        //     color: "#8CD17D"
        // },
    ],
    defendantsAge: [ 
        {
            variable : "18-24" ,
            name: "18-24",
            color: "#4E79A7"
        },  
        { 
            variable : "25-29" ,
            name: "25-29",
            color: "#F28E2B"
        },
        { 
            variable : "30-39" ,
            name: "30-39",
            color: "#FFBE7D"
        },  
        {
            variable : "40-49" ,
            name: "40-49",
            color: "#E15759"
        },  {
            variable : "50-59" ,
            name: "50-59",
            color: "#76B7B2"
        },  {
            variable : "60-69" ,
            name: "60-69",
            color: "#59A14E"
        },  {
            variable : "70+" ,
            name: "70-79",
            color: "#EDC948"
        },{
            variable : "Unknown",
            name: "Unknown",
            color: "#B07AA1"
        }
    ], 
    defendantRace : [
        { 
            variable : "Asian" ,
            name: "Asian",
            color: "#4E79A7"
        },
        { 
            variable : "Black",
            name: "Black",
            color: "#F28E2B"
        }, 
        {
            variable : "Hispanic" ,
            name: "Hispanic",
            color: "#E15759"
        },  
        { 
            variable : "Indian" ,
            name: "Indian",
            color: "#76B7B2"
        },
        { 
            variable : "Other" ,
            name: "Other",
            color: "#59A14E"
        },
        { 
            variable : "Unknown" ,
            name: "Unknown",
            color: "#EDC948"
        },
        { 
            variable : "White" ,
            name: "White",
            color: "#B07AA1"
        },
    ], 
    defendantGender: [ 
        { 
            variable : "Female" ,
            name: "Female",
            color: "#4E79A7"
        },
        { 
            variable : "Male",
            name: "Male",
            color: "#F28E2B"
        }, 
        {
            variable : "Unknown" ,
            name: "Unknown",
            color: "#E15759"
        }, 
    ], 
    defendantCountry: [ 
        { 
            variable : "total" ,
            name: "Total Individuals",
            color: "#4E79A7"
        },
    ],
    victimAge: [ 
        { 
            variable : "Adult" ,
            name: "Adult",
            color: "#4E79A7"
        },
        { 
            variable : "Minor",
            name: "Minor",
            color: "#F28E2B"
        }, 
        // {
        //     variable : "Unknown" ,
        //     name: "Unknown",
        //     color: "#E15759"
        // }, 
    ], 
    victimGender: [ 
        { 
            variable : "Female" ,
            name: "Female",
            color: "#4E79A7"
        },
        { 
            variable : "Male",
            name: "Male",
            color: "#F28E2B"
        }, 
    ],
}