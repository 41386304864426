import React, { Component } from "react";
import * as d3 from "d3";

export default class BarChart extends Component {
  constructor(props) {
    super(props);
    this.anchor = React.createRef();

    this.state = {
      ready: false,
      root: "",
    };
  }

  componentDidMount() {
    this.setState({
      ready: true,
    });
  }

  componentDidUpdate() {
    // var margin = { top: 0, right: 30, bottom: 90, left: 80 };
    // var width;
    // var height;
    // if (window.innerWidth > 1200 && window.innerWidth < 1440) {
    //   width = 1200;
    //   height = 1000;
    // } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
    //   width = 1200;
    //   height = 1000;
    // } else {
    //   width = 1200;
    //   height = 1000;
    // }

    var margin = { top: 0, right: 40, bottom: 90, left: 50 }; // left: 40
    var width = 1200;
    var height = 1000;

    if (this.props.report) {
      width = 1000;
    }
    var percent;
    if (this.props.percent) {
      percent = true;
    }
    // eslint-disable-next-line no-unused-vars
    const colors = d3.scaleOrdinal([
      "#4E79A7",
      "#F28E2B",
      "#E15759",
      "#76B7B2",
      "#59A14E",
      "#EDC949",
      "#B07AA2",
      "#FF9DA7",
      "#9C755F",
    ]);
    // sort bars based on value
    var max = 0;
    var data = [];
    this.props.data.children.forEach((item) => {
      item.children.forEach((d) => {
        if (Number(d.value) > max) {
          max = Number(d.value);
        }
        data.push(d);
      });
    });
    data.reverse();
    //sort data from high to low
    data.sort((a, b) => a.value - b.value);

    max = d3.max(data, (d) => +d.value);

    const svg = d3.select(this.anchor.current);

    svg.selectAll("svg").remove();
    svg.selectAll(".tooltip").remove();

    var barsvg = svg
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(150, 20)");

    var tooltip = d3
      .select(this.anchor.current)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("background-color", "white")
      .style("color", "black")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("position", "fixed")
      .style("z-index", "-1")
      .style("padding", "5px")
      .style("width", "200px");
    tooltip.append("div").attr("class", "label");
    tooltip.append("div").attr("class", "count");

    var x = d3
      .scaleLinear()
      .domain([0, max])
      //.range([margin.left, width - margin.right])
      .range([margin.left, width - margin.right - 160])
      .nice();

    var y = d3
      .scaleBand()
      .domain(d3.range(data.length))
      .range([height - margin.bottom, margin.top])
      .padding(0.1);

    var xAxis = (g) =>
      g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).tickSizeOuter(0));

    var yAxis = (g) =>
      g.attr("transform", `translate(${margin.left},0)`).call(
        d3
          .axisLeft(y)
          .tickSize(0)
          .tickFormat((i) => data[i].id)
      );

    barsvg
      .append("g")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .on("mouseover", onMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove)
      .attr("x", (d) => x(0))
      .attr("y", (d, i) => y(i))
      .attr("height", y.bandwidth())
      .attr("width", (d) => x(d.value) - x(0))
      .attr("fill", (d) => d.color);
    //.attr("fill", (d, i) => colors(i));

    barsvg.append("g").call(xAxis);

    barsvg.append("g").call(yAxis);

    function onMouseOver(d) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", "9999");

      tooltip.select(".label").text(d.id);
      if (percent) {
        tooltip
          .select(".count")
          .text(
            "Percent: " +
              d.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              "%"
          );
      } else {
        tooltip
          .select(".count")
          .text(
            "Total: " + d.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
      }
    }

    function onMouseOut() {
      tooltip.style("opacity", 0);
      tooltip.style("z-index", "-1");
    }

    function onMouseMove(d) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", "9999");
      tooltip
        .style("top", d3.event.clientY - 75 + "px")
        .style("left", d3.event.clientX - 265 + "px");
    }

    // text label for the x axis
    barsvg
      .append("text")
      .attr(
        "transform",
        "translate(" + width / 2 + " ," + (height + margin.top) + ")"
      )
      .style("text-anchor", "middle")
      .text("Number of Indicators");

    // text label for the y axis
    //   barsvg
    //     .append("text")
    //     .attr("transform", "rotate(-90)")
    //     .attr("y", 0)
    //     .attr("x", 0 - height / 2)
    //     .attr("dy", "1em")
    //     .style("text-anchor", "middle")
    //     .text("Indicators");
  }

  render() {
    return (
      <div>
        {this.props.report ? (
          <div
            ref={this.anchor}
            id={this.props.id}
            className="indicator-graph"
            style={{ position: "relative" }}
          ></div>
        ) : (
          <div
            ref={this.anchor}
            id={this.props.id}
            className="indicator-graph"
            style={{ position: "relative", overflow: "scroll" }}
          ></div>
        )}
      </div>
    );
  }
}
