// eslint-disable-next-line import/no-webpack-loader-syntax
//import mapboxgl from "!mapbox-gl";
import { taHubData } from "../../../Data/taHub";

export function NewsLayer(map, location, data, report) {
   
        // map.on("load", function () {
        //     if (taHubData){
        //         taHubData.map((item) => {
        //           return new mapboxgl.Marker({ color: "#4E79A7", scale: 0.5 })
        //                 .setLngLat([item.coordinates[0], item.coordinates[1]])
        //                 //.setPopup(popup)
        //                 .addTo(map);
                  
        //            }
        //         );
        //     }
        // })
        map.on('load', () => {
            // Add a new source from our GeoJSON data and
            // set the 'cluster' option to true. GL-JS will
            // add the point_count property to your source data.
            map.addSource('newsreports', {
            type: 'geojson',
            data: taHubData,
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
            });
             
            map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'newsreports',
            filter: ['has', 'point_count'],
            paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Orange, 30px circles when point count is between 100 and 750
            //   * Green, 40px circles when point count is greater than or equal to 750
            'circle-color': [
            'step',
            ['get', 'point_count'],
            '#93d6f1',
            100,
            '#28ade3',
            750,
            '#186788'
            ],
            'circle-radius': [
            'step',
            ['get', 'point_count'],
            report ? 5 : 20,
            100,
            report ? 10 : 30,
            750,
            report ? 15 : 40
            ]
            }
            });
             
            map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'newsreports',
            filter: ['has', 'point_count'],
            layout: {
            'text-field': '{point_count}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
            }
            });
             
            map.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'newsreports',
            filter: ['!', ['has', 'point_count']],
            paint: {
            'circle-color': '#4E79A7',
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
            }
            });
             
            // inspect a cluster on click
            map.on('click', 'clusters', (e) => {
            const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
            });
            const clusterId = features[0].properties.cluster_id;
            map.getSource('newsreports').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
            if (err) return;
             
            map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
            });
            }
            );
            });
             
            // When a click event occurs on a feature in
            // the unclustered-point layer, open a popup at
            // the location of the feature, with
            // description HTML from its properties.
            // map.on('click', 'unclustered-point', (e) => {
            // const coordinates = e.features[0].geometry.coordinates.slice();
            // const mag = e.features[0].properties.mag;
            // const tsunami =
            // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';
             
            // // Ensure that if the map is zoomed out such that
            // // multiple copies of the feature are visible, the
            // // popup appears over the copy being pointed to.
            // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            // coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            // }
             
            // new mapboxgl.Popup()
            // .setLngLat(coordinates)
            // .setHTML(
            // `magnitude: ${mag}<br>Was there a tsunami?: ${tsunami}`
            // )
            // .addTo(map);
            // });
             
            map.on('mouseenter', 'clusters', () => {
            map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseleave', 'clusters', () => {
            map.getCanvas().style.cursor = '';
            });
            });

}