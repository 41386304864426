import React, {useEffect} from "react";
import SingleCard from "../../../../Components/Card";
import CardWithDrawer from "../../../../Components/CardWithDrawer";
import { useAppContext } from "../../../../Lib/UserContext";
import OrgList from './Tables/OrgList'
import { StackedBarGraph } from "../../GraphComponents/StackedBarGraph";
import { GroupedBarGraph } from "../../GraphComponents/GroupedBarGraph";
import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";
import {servicesdata} from "../../GraphComponents/GraphVariables/ServicesData"
import CustomMeasuresTable from './Tables/CustomMeasuresTable'
import {servicesmockdata} from "./ServicesData"
import PieGraph from "../../GraphComponents/PieGraph";
import AvStayProgramTable from './Tables/AvStayProgramTable'


export default function LongTermModal(props) {
  const { role } = useAppContext();
  const [customData, setCustomData] = React.useState(servicesmockdata);
  const [orgList, setOrgList] = React.useState([])
  const [uniqueOrgList, setUniqueOrgList] = React.useState([])
 //const [budget, setBudget] = React.useState(0)


  // const addToReports = (data) => {
  //   props.addToReports(data);
  // };

  useEffect(() => {
     if (props.data.customMeasures) {
      setCustomData(props.data.customMeasures)

      var array = props.data.orgList.filter((item) => item.org_type === "Long Term Residential");

      var uniqueArray = [
        ...new Set(
          // eslint-disable-next-line array-callback-return
          props.data.orgList.map((item) => {
            if (item.org_type === "Long Term Residential") {
              return item.organization;
            }
          })
        ),
      ];
      uniqueArray.shift()
      setOrgList(array)
      setUniqueOrgList(uniqueArray)

      // var totalBudget = 0;
      // for (let i = 0; i < props.data.allGrantees.length; i++) {
      //   if (props.data.allGrantees[i].org_type === "Long Term Residential") {
      //     totalBudget += Number(props.data.allGrantees[i].budget.replace(/[^0-9.-]+/g,""));
      //   }
      // }
      // var roundedBudget = Math.round(totalBudget);
  
      // setBudget(roundedBudget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }
  }, [props.data])


  return (
    <div>
       {role === "OOG" || role === "Admin" ? (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <CardWithDrawer
            data={props.orgData}
            width="35vw"
            title="Long Term Residential  >  Total Organizations"
            id="Long Term Residential Total Organizations"
            category="Long Term Measures"
            value={[
              {
                data: orgList.length,
                text: "Total Areas Served",
              },
              {
                data: uniqueOrgList.length,
                text: "Total Unique Organizations",
              },
            ]}
            definitions={[["Long Term Residential", "Residential treatment programs provide survivors with trauma-responsive case management, wraparound services, and 24-hour clinical and behavioral crisis services."], ["Grantee Fiscal Year", "In addition to CSTT grantees (past and current), the list of organizations may include non-grantees, such as organizations screening with CSE-IT who are not funded by the CSTT. Grantees are listed with the fiscal years in which they are or were funded. If the fiscal year field is blank, the organization is a non-grantee. The fiscal year corresponds to funding received between October 1-September 30 (e.g., fiscal year 2021 is from October 1, 2020 through September 30, 2021)."]]}
            table={<OrgList title="Long Term Residential" data={props.data.orgList} />}
            addToReports={props.addToReports}
          />
      
          {/* <CardWithDrawer
            data={customData}
            width="35vw"
            GO={true}
            id="Long Term Residential Governor's Office Funding"
            title="Long Term Residential  >  Governor's Office Funding"
            value={[
              {
                data: budget,
                text: "Total Funding",
              },
            ]}
            addToReports={props.addToReports}
          /> */}
        </div>
      ) : (
        <CardWithDrawer
            data={props.orgData}
            title="Long Term Residential  >  Total Organizations"
            id="Long Term Residential Total Organizations"
            category="Long Term Measures"
            value={[
              {
                data: orgList.length,
                text: "Total Areas Served",
              },
              {
                data: uniqueOrgList.length,
                text: "Total Unique Organizations",
              },
            ]}
            definitions={[["Long Term Residential", "Residential treatment programs provide survivors with trauma-responsive case management, wraparound services, and 24-hour clinical and behavioral crisis services."]]}
            table={<OrgList title="Long Term Residential" data={props.data.orgList} />}
            addToReports={props.addToReports}
          />
      )}
      <SingleCard
        // className={classes.card}
        width="100%"
        title="Long Term Residential  >  Individuals Served"
        id="Long Term Residential Individuals Served"
        type="Grantee Data"
        graph={
          <GroupedBarGraph
            keys={servicesdata.ltIndividualsServed}
            data={customData.ltIndividualsServed}
            xaxis="Time (Q and FY)"
            yaxis="Number of Individuals"
            percentLine={true}
            percentLegend="Percentage of New Individuals"
          />
        }
        table={<CustomMeasuresTable data={customData.ltIndividualsServed}  keys={servicesdata.ltIndividualsServed} id="Individuals Served" totalIndividuals={true}/>}
        definitions={[["", "Total number of individuals/victims served (unduplicated in quarter)."]]}
        addToReports={props.addToReports}
      />

      <SingleCard
        // className={classes.card}
        width="100%"
        title="Long Term Residential  >  New Individuals Served (by Age)"
        id="Long Term Residential New Individuals Served (by Age)"
        type="Grantee Data"
        graph={
          <GroupedBarGraph
            keys={servicesdata.ltAgeServed}
            data={customData.ltAgeServed}
            xaxis="Time (Q and FY)"
            yaxis="Number of Individuals"
            yaxisMaxValue={true}
          />
        }
        table={<CustomMeasuresTable data={customData.ltAgeServed}  keys={servicesdata.ltAgeServed} id="New Individuals Served (by Age)" totalIndividuals={true}/>}
        definitions={[["", "Total number of new individuals/victims served per specified age range."]]}
        addToReports={props.addToReports}
      />

      <SingleCard
          // className={classes.card}
          width="100%"
          title="Long Term Residential  >  Referral Sources"
          id="Long Term Residential Referral Sources"
          type="Grantee Data"
          graph={
            <SingleBarGraph 
              data={customData.ltReferrals} 
              xaxis="Referral Source" 
              yaxis="Number of Referrals" 
              keys={servicesdata.ltReferrals} 
              type="cc"
              smalltext={true}
            />}
          table={<CustomMeasuresTable data={customData.ltReferrals}  keys={servicesdata.ltReferrals} id="Referral Sources" single={true}/>}
        definitions={[
          ["Care Coordinator or Care Coordination staffing", "Number of referrals made by a CCT member who reports that a federal decision was made in a CC staffing or if care coordinator initiates referral directly."],
          ["Community-Based Care (CBC) contractor", "Number of referrals made by CBC staff, even though youth is in DFPS custody. Does not count referrals made by DFPS, even though CBC may be involved."],
          ["CSEY Advocates", "Number of referrals made by a CSEY advocate, regardless of guardianship status."],
          ["DFPS", "Number of referrals actually made by the Department of Family and Protective Services (DFPS). It does not matter what stage of service the youth is in: investigative, family based safety services or conservatorship."],
          ["Drop-in Center", "Number of referrals made by a drop-in center, regardless of guardianship or if the same agency operates both of these programs."],
          ["Juvenile Justice", "Number of referrals received from any county or state juvenile justice agency when under care or supervision of that agency. Does not count referrals from a court only, when not remanded to a JJ agency. Does not matter if youth is on probation status or not."],
          ["Law Enforcement", "Number of youth transported by law enforcement or when a caregiver/guardian is directed by law enforcement to take youth to agency. Counts if adult referral is associated with LE or DAO HT diversion or specialized HT program."],
          ["National Human Trafficking Hotline", "Number of referrals made by the NHTH by means of a “warm handoff” - directly connecting youth or caregiver to emergency shelter by phone call or text message."],
          ["Parent, Caregiver, or Legal guardian", "Number of referrals made by a parent, legal guardian, family or a caregiver that is not a DFPS legal guardian."],
          ["Self-referral or Walk-ins", "Number of referrals made by an individual by their own power and not initiated by any staff of an organization."],
          ["Other", "Number of referrals by agencies or individuals other than those listed above."],
        ]}
          addToReports={props.addToReports}
        />

        <SingleCard
          // className={classes.card}
          width="100%"
          title="Long Term Residential  >  Referrals Over Time"
          id="Long Term Residential Referrals Over Time"
          type="Grantee Data"
          graph={
            <StackedBarGraph 
              data={customData.ltReferralsArray} 
              xaxis="Time (Q and FY)" 
              yaxis="Number of Referrals" 
              keys={servicesdata.ltReferralsArray} 
              trendLine={true}
              trendLineLegend="Total Referrals"
            />}
          table={<CustomMeasuresTable data={customData.ltReferralsArray}  keys={servicesdata.ltReferralsArray} id="Referrals Over Time"/>}
        definitions={[
          ["Care Coordinator or Care Coordination staffing", "Number of referrals made by a CCT member who reports that a federal decision was made in a CC staffing or if care coordinator initiates referral directly."],
          ["Community-Based Care (CBC) contractor", "Number of referrals made by CBC staff, even though youth is in DFPS custody. Does not count referrals made by DFPS, even though CBC may be involved."],
          ["CSEY Advocates", "Number of referrals made by a CSEY advocate, regardless of guardianship status."],
          ["DFPS", "Number of referrals actually made by the Department of Family and Protective Services (DFPS). It does not matter what stage of service the youth is in: investigative, family based safety services or conservatorship."],
          ["Drop-in Center", "Number of referrals made by a drop-in center, regardless of guardianship or if the same agency operates both of these programs."],
          ["Juvenile Justice", "Number of referrals received from any county or state juvenile justice agency when under care or supervision of that agency. Does not count referrals from a court only, when not remanded to a JJ agency. Does not matter if youth is on probation status or not."],
          ["Law Enforcement", "Number of youth transported by law enforcement or when a caregiver/guardian is directed by law enforcement to take youth to agency. Counts if adult referral is associated with LE or DAO HT diversion or specialized HT program."],
          ["National Human Trafficking Hotline", "Number of referrals made by the NHTH by means of a “warm handoff” - directly connecting youth or caregiver to emergency shelter by phone call or text message."],
          ["Parent, Caregiver, or Legal guardian", "Number of referrals made by a parent, legal guardian, family or a caregiver that is not a DFPS legal guardian."],
          ["Self-referral or Walk-ins", "Number of referrals made by an individual by their own power and not initiated by any staff of an organization."],
          ["Other", "Number of referrals by agencies or individuals other than those listed above."],
        ]}
          addToReports={props.addToReports}
        />

        <SingleCard
          // className={classes.card}
          width="100%"
          title="Long Term Residential  >  Admissions by Referral Source"
          id="Long Term Residential Admissions by Referral Source"
          type="Grantee Data"
          graph={
            <SingleBarGraph 
              data={customData.ltAdmissions} 
              xaxis="Referral Source" 
              yaxis="Number of Admissions" 
              keys={servicesdata.ltAdmissions} 
              type="cc"
              smalltext={true}
            />}
          table={<CustomMeasuresTable data={customData.ltAdmissions}  keys={servicesdata.ltAdmissions} id="Admissions by Referral Source" single={true}/>}
        definitions={[["", "The number of successful admissions resulting from each referral source."]]}
          addToReports={props.addToReports}
        />

        <SingleCard
          // className={classes.card}
          width="100%"
          title="Long Term Residential  >  Admissions by Referral Source (over time)"
          id="Long Term Residential Admissions by Referral Source (over time)"
          type="Grantee Data"
          graph={
            <StackedBarGraph 
              data={customData.ltAdmissionsArray} 
              xaxis="Time (Q and FY)" 
              yaxis="Number of Admissions" 
              keys={servicesdata.ltAdmissionsArray} 
              trendLine={true}
              trendLineLegend="Total Admissions"

            />}
          table={<CustomMeasuresTable data={customData.ltAdmissionsArray}  keys={servicesdata.ltAdmissionsArray} id="Admissions by Referral Source (over time)" />}
        definitions={[["", "The number of successful admissions resulting from each referral source over time."]]}
          addToReports={props.addToReports}
        />

        <SingleCard
          // className={classes.card}
          width="100%"
          id="Long Term Residential Total Admissions and Referrals"
          title="Long Term Residential  >  Total Admissions and Referrals"
          type="Grantee Data"
          graph={
            <GroupedBarGraph 
              data={customData.ltTotalAdmissionsReferral} 
              xaxis="Time (Q and FY)" 
              yaxis="Total Admissions and Referrals" 
              keys={servicesdata.ltTotalAdmissionsReferral} 
              percentLine={true}
              percentLegend="Percentage Admitted"
            />}
          table={<CustomMeasuresTable data={customData.ltTotalAdmissionsReferral}  keys={servicesdata.ltTotalAdmissionsReferral} id="Total Admissions and Referrals" />}
        definitions={[["", "The relationship between total referrals and total admissions made to emergency shelters."]]}
          addToReports={props.addToReports}
        />

        <SingleCard
          // className={classes.card}
          width="100%"
          id="Long Term Residential Average Length of Stay"
          title="Long Term Residential  >  Average Length of Stay"
          type="Grantee Data"
          graph={
            <GroupedBarGraph 
              data={customData.ltAvLengthStay} 
              xaxis="Time (Q and FY)" 
              yaxis="Number of Days" 
              keys={servicesdata.ltAvLengthStay} 
              type="cc"
            />}
        table={<CustomMeasuresTable getAverageTotal data={customData.ltAvLengthStay}  keys={servicesdata.ltAvLengthStay} id="Average Length of Stay" />}
        definitions={[["", "The actual average number of days for all youth served one or more days in the quarter. This includes youth that were discharged and those continuing services into the following reporting period."]]}
          addToReports={props.addToReports}
        />

        <SingleCard
          // className={classes.card}
          width="100%"
          id="Long Term Residential Average Length of Stay by Program"
          title="Long Term Residential  >  Average Length of Stay by Program"
          type="Grantee Data"
          graph={
            <GroupedBarGraph 
              data={customData.ltAvLengthStayProg} 
              xaxis="Time (Q and FY)" 
              yaxis="Number of Days" 
              yaxisMaxValue={true}
              keys={servicesdata.ltAvLengthStayProg} 
            />}
            table={<AvStayProgramTable data={customData.ltAvLengthStayProg}  keys={servicesdata.ltAvLengthStayProg} id="Average Length of Stay by Program" />}
            definitions={[["", "The average number of days each youth stayed in services for all youth served during the quarter by each individual emergency shelter program. This includes youth that were discharged and those continuing services into the following reporting period."]]}
          addToReports={props.addToReports}
        />

      <SingleCard
          // className={classes.card}
          width="100%"
          title="Long Term Residential  >  Planned/Unplanned Discharges by Type"
          id="Long Term Residential Planned/Unplanned Discharges by Type"
          type="Grantee Data"
          graph={
            <StackedBarGraph 
              data={customData.ltDischarge} 
              xaxis="Time (Q and FY)" 
              yaxis="Number of individuals at time of discharge" 
              keys={servicesdata.ltDischarge} 
              trendLine={true}
              trendLineLegend="Total Individuals" 
            />}
          table={<CustomMeasuresTable data={customData.ltDischarge}  keys={servicesdata.ltDischarge} id="Planned/Unplanned Discharges by Type" />}
        definitions={[
          ["Paid or unpaid kinship", "Number of youth whose family or protective friends approved discharge in advance or retrospectively by parent or guardian."],
          ["Foster Home", "Number of youth who exited emergency shelter services and entered the foster care system."],
          ["Supervised Independent Living/Supported Housing", "Number of youth who exited emergency shelter services and entered supervised independent living or supported housing programs."],
          ["Return to prior bio or foster family home", "Number of youth who exited emergency shelter services and returned to their prior bio or foster family home."],
          ["Group care", "Number of youth who exited emergency shelter services and entered group care services."],
          ["Psychiatric Hospital", "Number of youth who exited emergency shelter services and were admitted to a psychiatric hospital."],
          ["Residence substance use treatment facility", "Number of youth who exited emergency shelter services and entered a residence substance use treatment facility."],
          ["Residential treatment center", "Number of youth who exited emergency shelter services and entered a residential treatment center."],
          ["Law enforcement custody or juvenile detention", "Number of youth who exited emergency shelter services and entered law enforcement custody or juvenile detention."],
          ["Missing or other unplanned absence from operation", "Number of youth who went missing with unplanned absences from emergency shelter services and whose whereabouts are generally unknown."],
          ["Young adults of majority age signed themselves out", "Number of youth who reached the age of 22 and aged out of emergency shelter eligibility."],
        ]}
          addToReports={props.addToReports}
        />

      <SingleCard
          // className={classes.card}
          width="100%"
          title="Long Term Residential  >  Impact Assessment"
          id="Long Term Residential Impact Assessment"
          type="Grantee Data"
          graph={
            <PieGraph 
              data={customData.ltImpactAssessmentPie} 
              width={400}
              height={350}
              innerRadius={0}
              outerRadius={150}
              value="Long Term Residential"
              // xaxis="Time" 
              // yaxis="Number of Admissions" 
              keys={servicesdata.ltImpactAssessment} 
            />}
        table={<CustomMeasuresTable single data={customData.ltImpactAssessmentPie}  keys={servicesdata.ltImpactAssessment} id="Impact Assessment" />}
        definitions={[["","Status of individuals 90 days after discharge."]]}
          addToReports={props.addToReports}
        />
        <SingleCard
          // className={classes.card}
          width="100%"
          title="Long Term Residential  >  Impact Assessment (over time)"
          id="Long Term Residential Impact Assessment (over time)"
          type="Grantee Data"
          graph={
            <StackedBarGraph 
              data={customData.ltImpactAssessment} 
              xaxis="Time (Q and FY)" 
              yaxis="Number of Individuals" 
              keys={servicesdata.ltImpactAssessment} 
              trendLine={true}
              trendLineLegend="Total Individuals"
            />}
          table={<CustomMeasuresTable data={customData.ltImpactAssessment}  keys={servicesdata.ltImpactAssessment} id="Impact Assessment (over time)" />}
        definitions={[["", "Status of individuals 90 days after discharge, over time."]]}
          addToReports={props.addToReports}
        />
          
    </div>
  );
}
