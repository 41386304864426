export const tdcjdata = {
    inmateByYear: [ 
        { 
            variable : "offenseYear" ,
            name: "Offense Year",
            color: "#4E79A7"
        },
        { 
            variable : "sentenceYear",
            name: "Sentence Year",
            color: "#A0CBE8"
        },  
    ],
    lengthSentence: [ 
        { 
            variable : "0-4" ,
            name: "0-4",
            color: "#4E79A7"
        },
        { 
            variable : "5-9",
            name: "5-9",
            color: "#A0CBE8"
        }, 
        {
            variable : "10-19" ,
            name: "10-19",
            color: "#F28E2B"
        },  
        { 
            variable : "20-29" ,
            name: "20-29",
            color: "#FFBE7D"
        },
        { 
            variable : "30-39" ,
            name: "30-39",
            color: "#59A14E"
        },  
        {
            variable : "40-49" ,
            name: "40-49",
            color: "#8CD17D"
        },  {
            variable : "50-59" ,
            name: "50-59",
            color: "#B6992D"
        },  {
            variable : "60-69" ,
            name: "60-69",
            color: "#499894"
        },  {
            variable : "70+" ,
            name: "70+",
            color: "#86BCB6"
        } ,{
            variable : "Life/Capital Life" ,
            name: "Life/Capital Life",
            color: "#E15759"
        }
    ], 
    timeBetweenOffenseSentence: [
        {
            variable: "Less than 1",
            name: "Less than 1",
            color: "#4E79A7"
        },
        {
            variable: "1",
            name: "1",
            color: "#A0CBE8"
        },
        {
            variable: "2",
            name: "2",
            color: "#F28E2B"
        },
        {
            variable: "3",
            name: "3",
            color: "#FFBE7D"
        },
        {
            variable: "4",
            name: "4",
            color: "#59A14E"
        },
        {
            variable: "5",
            name: "5",
            color: "#8CD17D"
        },
        {
            variable: "6-10",
            name: "6-10",
            color: "#B6992D"
        },
        {
            variable: "More than 10",
            name: "More than 10",
            color: "#499894"
        },
        // {
        //     variable: "20+",
        //     name: "20+",
        //     color: "#"
        // }, // E15759
        {
            variable: "No Sentence Date",
            name: "No Sentence Date",
            color: "#86BCB6"
        }
    ],
    offenseByChapterOverTime: [
        // Stacked bars include the six offenses subgroups
        //     (20A Trafficking of Persons, 21 Sexual Offenses, 22 Assaultive Offenses, 25 Offenses Against the Family, 33 Computer Crimes, 43 Public Indecency, Other Chapters / Offenses). 

        {
            variable: "20A",
            name: "20A Trafficking of Persons",
            color: "#4E79A7"
        },
        {
            variable: "20",
            name: "20 Kidnapping, Unlawful Restraint and Smuggling",
            color: "#A0CBE8"
        },
        {
            variable: "21",
            name: "21 Sexual Offenses",
            color: "#F28E2B"
        },
        {
            variable: "22",
            name: "22 Assaultive Offenses",
            color: "#FFBE7D"
        },
        {
            variable: "25",
            name: "25 Offenses Against the Family",
            color: "#59A14E"
        },
        {
            variable: "33",
            name: "33 Computer Crimes",
            color: "#8CD17D"
        }, 
        {
            variable: "43",
            name: "43 Public Indecency",
            color: "#B6992D"
        },
        // { // client might add later
        //     variable: "Other Chapters/Offenses",
        //     name: "Other Chapters/Offenses",
        //     color: "#499894"
        // },
    ],
    inmatesAge : [
        { 
            variable : "<18" ,
            name: "0-17",
            color: "#4E79A7"
        },
        {
            variable : "18-24" ,
            name: "18-24",
            color: "#F28E2B"
        },  
        { 
            variable : "25-29" ,
            name: "25-29",
            color: "#FFBE7D"
        },
        { 
            variable : "30-39" ,
            name: "30-39",
            color: "#59A14E"
        },  
        {
            variable : "40-49" ,
            name: "40-49",
            color: "#8CD17D"
        },  {
            variable : "50-59" ,
            name: "50-59",
            color: "#B6992D"
        },  {
            variable : "60-69" ,
            name: "60-69",
            color: "#499894"
        },  {
            variable : "70+" ,
            name: "70+",
            color: "#86BCB6"
        } 
        // ,{
        //     variable : "Unknown" ,
        //     name: "Unknown",
        //     color: "#E15759"
        // },
    ], 
    inmatesRace: [ 
        { 
            variable : "Asian" ,
            name: "Asian",
            color: "#4E79A7"
        },
        { 
            variable : "Black",
            name: "Black",
            color: "#F28E2B"
        }, 
        {
            variable : "Hispanic" ,
            name: "Hispanic",
            color: "#E15759"
        },  
        { 
            variable : "Indian" ,
            name: "Indian",
            color: "#76B7B2"
        },
        { 
            variable : "Other" ,
            name: "Other",
            color: "#59A14E"
        },
        { 
            variable : "Unknown" ,
            name: "Unknown",
            color: "#EDC948"
        },
        { 
            variable : "White" ,
            name: "White",
            color: "#B07AA1"
        },
    ], 
    inmatesGender: [ 
        { 
            variable : "Female" ,
            name: "Female",
            color: "#4E79A7"
        },
        { 
            variable : "Male",
            name: "Male",
            color: "#F28E2B"
        }, 
        // {
        //     variable : "Unknown" ,
        //     name: "Unknown",
        //     color: "#E15759"
        // },  
    ],
    
}