export const dashboardTXData = { // The order in here is important for the order of the side nav!
    Prevention: {
        Services: {
            primary: "Prevention & Intervention",
            secondary: "Services",
            mapFilters: ["Time ", "Location "],
            chartFilters: ["Time", "Location"]
        },
        // ServicesLA: {
        //     primary: "Prevention & Intervention",
        //     secondary: "Services",
        //     mapFilters: ["Location "],
        //     chartFilters: ["Time", "Location", "Organization", "Age", "Trafficking Type"]
        // },
        Coordination: {
            primary: "Prevention & Intervention",
            secondary: "Care Coordination",
            mapFilters: [],
            chartFilters: ["Time", "Location"]
        },
        Screenings: {
            primary: "Prevention & Intervention",
            secondary: "Screenings",
            mapFilters: ["Time", "Screenings", "Organizations"],
            chartFilters: ["Time", "Location", "Screenings", "Organizations", "Indicators", "Ages"]
        },
        // TraffickingLocations: {
        //     primary: "Prevention & Intervention",
        //     secondary: "Locations",
        //     mapFilters: ["Time", "Location Type", "Trafficking Type", "Ages", "Organizations"],
        //     chartFilters: ["Time", "Location", "Trafficking Type", "Ages", "Organizations"]
        // },
        // Hotline: {
        //     primary: "Prevention & Intervention",
        //     secondary: "Hotline",
        //     filters: ["Time", "Location", "Screenings"]
        // },
        // Education: {
        //     primary: "Prevention & Intervention",
        //     secondary: "Prevention Education",
        //     filters: ["Time", "Location", "Screenings"]
        // }
    },
    Cases: {
        Federal: {
            primary: "Incidents & Cases",
            secondary: "Federal Cases",
            mapFilters: ["Year Case Filed", "Location ", "Trafficking Type"],
            chartFilters: ["Year Case Filed", "Location", "Trafficking Type"],
            defaultChartLoSelection: 'State',
            defaultChartLoValue: ["All states"],
            defaultStateValue: ['All states'],
            defaultDistrictValue: [],
            defaultCountyValue: [],
            // defaultChartLoSelection: 'County',
            // defaultChartLoValue: ["All counties"],
            // defaultStateValue: ['Texas']
        },
        PublicSafety: {
            primary: "Incidents & Cases",
            secondary: "Law Enforcement",
            mapFilters: ["Arrest Year", "Offenses"],
            //mapFilters: ["Agency Type", "Offenses"],
            chartFilters: ["Arrest Year", "Location", "Offenses"]
        },
        Demand: {
            primary: "Incidents & Cases",
            secondary: "Demand",
            mapFilters: ["Ad Sites"],
            chartFilters: ["Ad Sites"]
        },
        TDCJ: {
            primary: "Incidents & Cases",
            secondary: "Texas Department of Criminal Justice",
            mapFilters: ["Offenses"],
            chartFilters: ["Location", "Offenses"]
        },
        // Businesses: {
        //     primary: "Incidents & Cases",
        //     secondary: "Businesses of Interest",
        //     filters: ["Time", "Location", "Screenings"]
        // },
        News: {
            primary: "Incidents & Cases",
            secondary: "News",
            mapFilters: [],
            chartFilters: []
        },
        Hotline: {
            primary: "Incidents & Cases",
            secondary: "Hotline",
            mapFilters: ["Time", "Situations"],
            chartFilters: ["Location", "Time", "Situations"]
        },
        IMB: {
            primary: "Incidents & Cases",
            secondary: "Businesses of Interest",
            mapFilters: ["Year", "Location "],
            chartFilters: ["IMB Analysis", "Location"]
        }
    },
    // Risk: {
    //     Community: {
    //         primary: "Risk & Resiliency",
    //         secondary: "Community Risk",
    //         mapFilters: [],
    //         chartFilters: []
    //     },
    // },
}