import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countyList } from "../../../../Data/countyList";
import { ccOrgList } from "../../../../Data/ccOrgList";
import { regionList } from "../../../../Data/regionList";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
var newRegionList = [...regionList]
newRegionList.splice(0,1);

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      padding: '20px',
      // width: '400px',
      fontWeight: '300',
      height: '300px',
      overflowY: 'scroll',
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer',
      fontWeight: '500 !important',
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      position: 'fixed',
      bottom: '10px',
      right: '10px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function LocationsFilter(props) {
  const classes = useStyles();
  const [location, setLocation] = React.useState("County")
  const [countySelections, setCountySelections] = React.useState([]);
  const [orgSelections, setOrgSelections] = React.useState([]);
  const [regionSelections, setRegionSelections] = React.useState([]);
  // const [inputOrgValue, setOrgValue] = React.useState('');
  // const [inputCountyValue, setCountyValue] = React.useState('');
  // const [inputRegionValue, setRegionValue] = React.useState('');

  const handleCountyLocationChange = () => {
    setLocation("County")
  }

  const handleRegionLocationChange = () => {
    setLocation("Region")
  }

  const handleOrgLocationChange = () => {
    setLocation("Organization")
  }

  const handleCountyChange = (event) => {
      if (event[event.length-1] === 'All counties'){
        setCountySelections(['All counties']);
      } else {
        const index = event.indexOf('All counties');
        if (index > -1) {
          event.splice(index, 1); // 2nd parameter means remove one item only
        }
        setCountySelections(event);
      }
  };

  const handleOrgChange = (event) => {
    if (event[event.length-1] === 'All organizations'){
      setOrgSelections(['All organizations']);
    } else {
      const index = event.indexOf('All organizations');
      if (index > -1) {
        event.splice(index, 1); // 2nd parameter means remove one item only
      }
      setOrgSelections(event);
    }
  };

  const handleRegionChange = (event) => {
    console.log(event.target.value)
    var newRegionList = [...regionSelections];
    const index = newRegionList.indexOf('All regions');
    if (index > -1) {
      newRegionList.splice(index, 1); // 2nd parameter means remove one item only
    }
    if (newRegionList.includes(event.target.value)){
      const index = newRegionList.indexOf(event.target.value);
      if (index > -1) {
        newRegionList.splice(index, 1); // 2nd parameter means remove one item only
      }
      setRegionSelections(newRegionList);
    } else {
      newRegionList.push(event.target.value)
      setRegionSelections(newRegionList);
    }
};

const handleAllRegionChange = (e) => {
  if (e.target.checked){
    setRegionSelections(["All regions"]);
  } else {
    setRegionSelections([]);
  }
}


  const handleSubmit = () => {
    var locationValue;
    if (location === "County"){
      locationValue = countySelections;
    } else if (location === "Region"){
      locationValue = regionSelections;
    } else {
      locationValue = orgSelections;
    }
    props.filterCallback({
      name: 'Location',
      filter: 'Care Coordination',
      selection: location,
      value: locationValue,
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      if (props.selectedFilters[i].name === 'Location' && props.selectedFilters[i].filter === 'Care Coordination'){
        if (props.selectedFilters[i].selection === 'County'){
          setCountySelections(props.selectedFilters[i].value)
          setOrgSelections(["All organizations"])
          setRegionSelections(["All regions"])
        } else if (props.selectedFilters[i].selection === 'Region') {
            setCountySelections(["All counties"])
            setLocation("Region")
            setRegionSelections(props.selectedFilters[i].value)
            setOrgSelections(["All organizations"])
        } else {
          setCountySelections(["All counties"])
          setLocation("Organization")
          setRegionSelections(["All regions"])
          setOrgSelections(props.selectedFilters[i].value)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
        <div className={classes.container}>
           <div className={classes.filterWrapper}>
              <div>
                  <p className={location === "County" ? classes.active : classes.inactive} onClick={handleCountyLocationChange}>County</p>
                  <p className={location === "Region" ? classes.active : classes.inactive} onClick={handleRegionLocationChange}>Region</p>
                  <p className={location === "Organization" ? classes.active : classes.inactive} onClick={handleOrgLocationChange}>Organization</p>
                </div>
                {location === "County" ? (
                  <div>
                    <div className={classes.checkboxes}> 
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={countyList}
                      //disableCloseOnSelect
                      onChange={(event, value) => handleCountyChange(value)}
                      value={countySelections}
                      getOptionLabel={(option) => option}
                      renderOption={(option) => (
                        <React.Fragment>
                          <BlackCheckbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={countySelections.includes(option)}
                          />
                          {option}
                        </React.Fragment>
                      )}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  
                  </div>
                    
               
                <div className={classes.saveButton}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                    </Button>
                </div>
              </div> 
            ) : null }

                {location === "Region" ? (
                    <div> 
                    <div className={classes.checkboxes}>
                    <FormControlLabel className="checkboxLabel"
                        control={ 
                          <BlackCheckbox 
                            checked={regionSelections.includes("All regions")} 
                            onChange={handleAllRegionChange}
                            name="checkedA" />
                          }
                        label="All regions"
                      />
                    {newRegionList.map((region, key) => {
                    return (
                      <FormControlLabel key={key}className="checkboxLabel"
                        control={ 
                          <BlackCheckbox 
                            checked={regionSelections.includes(region)} 
                            onChange={e => handleRegionChange(e, region)}
                            name="checkedA" 
                            value={region}/>
                          }
                        label={region}
                      />
                    )
                  })}
                </div>
                    
               
                <div className={classes.saveButton}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                    </Button>
                </div>
              </div> 
            ) : null }
             
             {location === "Organization" ? (
                    <div>
                    <div className={classes.checkboxes}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={ccOrgList}
                      //disableCloseOnSelect
                      onChange={(event, value) => handleOrgChange(value)}
                      value={orgSelections}
                      getOptionLabel={(option) => option}
                      renderOption={(option) => (
                        <React.Fragment>
                          <BlackCheckbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={orgSelections.includes(option)}
                          />
                          {option}
                        </React.Fragment>
                      )}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                </div>
                    
               
                <div className={classes.saveButton}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Save
                    </Button>
                </div>
              </div> 
            ) : null }

            </div>
        </div>
    )
}