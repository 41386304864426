import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../Assets/Images/lighthouse-login-logo.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import Button from "@material-ui/core/Button";
import { Auth, API } from "aws-amplify";
import { useAppContext } from "../../Lib/UserContext";
import BackgroundImage from "../../Assets/Images/bg.jpeg";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  loginWrapper: {
    border: "1px solid black",
    padding: "20px",
    background: "white",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  checkboxWrapper: {
    display: "flex",
    alignItems: "center",
  },
  primaryButton: {
    backgroundColor: "#28ADE3 !important",
    borderRadius: "3px",
    color: "white",
  },
  forgotPassword: {
    color: "#28ADE3",
    cursor: "pointer",
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const { userHasAuthenticated, setRole, setEmail, setStateLocation } = useAppContext();
  const [checked, setCheckbox] = React.useState(false);
  const handleChange = (event) => {
    var newValue = { ...userData };
    newValue[event.target.name] = event.target.value;
    setUserData(newValue);
  };
  const handleCheckboxChange = (event) => {
    setCheckbox(event.target.checked);
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (userData.password !== userData.confirmpassword) {
      alert("Passwords do not match.");
      setLoading(false);
      return;
    }
    if (new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})').test(userData.password) === false) {
      alert("The password must contain at least 8 characters and at least: 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character (!@#$%^&*?,._), and must not contain repeating or consecutive characters and/or use part of your name or email.");
      setLoading(false);
      return;
    }
    if (new RegExp(/(\w)\1{3,}/g).test(userData.password)) { // If password contains more than 3 repeating chars(e.g., aaaa)
      alert('The password may not contain repeating characters.')
      setLoading(false);
      return;
    }
    const testSequential = (password) => {
      // If both loops fail to find more than 3 sequential characters, the function returns true, otherwise it returns false.
      // (e.g., 1234abcd)
      // Check for sequential numerical characters
      for (var i in password)
        if (+password[+i + 1] === +password[i] + 1 &&
          +password[+i + 2] === +password[i] + 2 &&
          +password[+i + 3] === +password[i] + 3) return false;
      // Check for sequential alphabetical characters
      for (var k in password)
        if (String.fromCharCode(password.charCodeAt(k) + 1) === password[+k + 1] &&
          String.fromCharCode(password.charCodeAt(k) + 2) === password[+k + 2] &&
          String.fromCharCode(password.charCodeAt(k) + 3) === password[+k + 3]) return false;
      return true;
    }
    let noSequentials = testSequential(userData.password)
    if (!noSequentials) { // sequential chars
      alert('The password may not contain consecutive characters.')
      setLoading(false);
      return;
    }
    const passwordContainsEmail = (email, password) => { // 3
      for (let i = 0; (i + 3) < email.length; i++)
        // if password contains 3 sequential chars from their email, return false, else true
        if (password.indexOf(email.substring(i, i + 3)) !== -1)
        return false;
      return true;
    }
    let containsEmailChars = passwordContainsEmail(userData.email.toLowerCase(), userData.password.toLowerCase())
    if (!containsEmailChars) {
      alert('The password may not contain part of your email')
      setLoading(false);
      return;
    }
    Auth.signIn(userData.email.toLowerCase(), userData.temppassword)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(user, userData.password).then((user) => {
            API.post("lighthouse-data-initiative", "getuserrole", {
              body: {
                email: userData.email.toLowerCase(),
              },
            })
              .then((response) => {
                setRole(response[0].role);
                localStorage.setItem("role", response[0].role);
                localStorage.setItem("stateLocation", response[0].state_location);
                setStateLocation(response[0].state_location)
                userHasAuthenticated(true);
                history.push("/dashboard");
                setEmail(userData.email);
              })
              .catch((err) => {
                alert("Error retrieving your user data.");
                history.push("/newuser");
              });
          }).catch((err) => {
            console.log(err);
            setLoading(false);
            if (err.code === "UserNotConfirmedException") {
              alert("User is not confirmed.");
            } else if (err.code === "NotAuthorizedException") {
              alert("You entered the incorrect password. Please try again.");
            } else if (err.code === "UserNotFoundException") {
              alert("User not found. Please try again.");
            } else if (err.code === "InvalidParameterException") {
              alert("The password must contain at least 8 characters and at least: 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character (!@#$%^&*?,._), and must not contain repeating or consecutive characters and/or use part of your name or email.")
            } else {
              alert(err.message);
            }
          });
        } else {
          console.log("error");
          setLoading(false);
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.code === "UserNotConfirmedException") {
          alert("User is not confirmed.");
        } else if (err.code === "NotAuthorizedException") {
          alert("You entered the incorrect password. Please try again.");
        } else if (err.code === "UserNotFoundException") {
          alert("User not found. Please try again.");
        } else if (err.code === "InvalidParameterException") {
          alert("The password must contain at least 8 characters and at least: 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character (!@#$%^&*?,._), and must not contain repeating or consecutive characters and/or use part of your name or email.")
        } else {
          alert(err.message);
        }
      });
  };
  return (
    <div className={classes.root}>
      <div className={classes.loginWrapper}>
        <img src={logo} alt="Lighthouse Data Initiative" width="150px" />
        <p>Sign in to your account</p>
        <TextField
          className={classes.margin}
          id="new-email-textfield"
          name="email"
          label="Email"
          fullWidth
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={classes.margin}
          id="temp-password-textfield"
          name="temppassword"
          fullWidth
          label="Temporary Password"
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={classes.margin}
          id="new-password-textfield"
          name="password"
          type="password"
          fullWidth
          label="Password"
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={classes.margin}
          id="confirm-password-textfield"
          name="confirmpassword"
          fullWidth
          type="password"
          label="Confirm Password"
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.buttonWrapper}>
          <div className={classes.checkboxWrapper}>
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <p>
              I accept the{" "}
              <a
                className={classes.forgotPassword}
                href="https://lighthouse-pdf-images.s3-us-west-2.amazonaws.com/NAI_1513887977_5_Lighthouse+-+End+User+Agreement+(Final).pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                End User Agreement
              </a>
              .
            </p>
          </div>
          <Button
            className={`${classes.primaryButton} btnHover`}
            onClick={handleSubmit}
            disabled={!checked}
          >
            {loading ? "Loading.." : "Sign Up"}
          </Button>
        </div>
      </div>
    </div>
  );
}




