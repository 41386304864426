export const screeningsgraphdata = {
  //color: "#BAB0AC" //THIS COLOR IS IN THE LIST LAURA SENT BUT NOT CURRENTLY IN THE BROWSER CHART
  // Screening information
  allScreenings: {
    'TX': [
      {
        variable: 'wcc',
        name: 'WCCC',
        color: '#4E79A7',
      },
      {
        variable: 'tjjd',
        name: 'TJJD',
        color: '#F28E2B',
      },
      {
        variable: 'dfps',
        name: 'DFPS',
        color: '#E15759',
      },
      {
        variable: 'lighthouse',
        name: 'Lighthouse',
        color: '#76B7B2',
      },
    ],
    'LA': [
      {
        variable: 'wcc',
        name: 'WCCC',
        color: '#4E79A7',
      },
      {
        variable: 'lighthouse',
        name: 'Lighthouse',
        color: '#F28E2B',
      },
    ]
  },
  screeningsIndividuals: [
    {
      name: "Individuals Screened",
      variable: "distinct",
      color: "#4E79A7",
    }, {
      name: "Screenings",
      variable: "screenings",
      color: "#A0CBE8",
    }
  ],
  screeningconcern: [
    {
      variable: 'Clear Concern',
      name: 'Clear Concern',
      color: '#E15759',
    },
    {
      variable: 'Possible Concern',
      name: 'Possible Concern',
      color: '#EDC948',
    },
    {
      variable: 'No Concern',
      name: 'No Concern',
      color: '#59A14E'
    },
    // {
    //   variable: 'Unknown',
    //   name: 'Unknown',
    //   color: ''
    // }
  ],

  //Age
  ageData: [
    {
      variable: "0",
      name: "0",
      color: "#4E79A7"
    },
    {
      variable: "1",
      name: "1",
      color: "#F28E2B"
    },
    {
      variable: "2",
      name: "2",
      color: "#E15759"
    },
    {
      variable: "3",
      name: "3",
      color: "#76B7B2"
    },
    {
      variable: "4",
      name: "4",
      color: "#59A14E"
    },
    {
      variable: "5",
      name: "5",
      color: "#EDC948"
    },
    {
      variable: "6",
      name: "6",
      color: "#B07AA1"
    },
    {
      variable: "7",
      name: "7",
      color: "#FF9DA7"
    },
    {
      variable: "8",
      name: "8",
      color: "#9C755F"
    },
    {
      variable: "9",
      name: "9",
      color: "#4E79A7"
    },
    {
      variable: "10",
      name: "10",
      color: "#F28E2B"
    },
    {
      variable: "11",
      name: "11",
      color: "#E15759"
    },
    {
      variable: "12",
      name: "12",
      color: "#76B7B2"
    },
    {
      variable: "13",
      name: "13",
      color: "#59A14E"
    },
    {
      variable: "14",
      name: "14",
      color: "#EDC948"
    },
    {
      variable: "15",
      name: "15",
      color: "#B07AA1"
    },
    {
      variable: "16",
      name: "16",
      color: "#FF9DA7"
    },
    {
      variable: "17",
      name: "17",
      color: "#9C755F"
    },
    {
      variable: "18",
      name: "18",
      color: "#4E79A7"
    },
    {
      variable: "19",
      name: "19",
      color: "#F28E2B"
    },
    {
      variable: "20",
      name: "20",
      color: "#E15759"
    },
    {
      variable: "21",
      name: "21",
      color: "#76B7B2"
    },
    {
      variable: "22",
      name: "22",
      color: "#59A14E"
    },
    {
      variable: "23",
      name: "23",
      color: "#EDC948"
    },
    {
      variable: "24",
      name: "24",
      color: "#B07AA1"
    },
  ],
  //AgeAll 25+
  ageGroupData: [
    {
      variable: "25-29",
      name: "25-29",
      color: "#4E79A7"
    },
    {
      variable: "30-39",
      name: "30-39",
      color: "#F28E2B"
    },
    {
      variable: "40-49",
      name: "40-49",
      color: "#E15759"
    },
    {
      variable: "50-59",
      name: "50-59",
      color: "#76B7B2"
    },
    {
      variable: "60-69",
      name: "60-69",
      color: "#59A14E"
    },
    {
      variable: "70-79",
      name: "70-79",
      color: "#EDC948"
    },
    {
      variable: "80+",
      name: "80-89",
      color: "#B07AA1"
    },
    {
      variable: "Unknown",
      name: "Unknown",
      color: "#FF9DA7"
    },
  ],
  // Race
  raceData: [
    {
      variable: "African American or Black",
      name: "African American or Black",
      color: "#4E79A7",
    },
    {
      variable: "American Indian or Alaska Native",
      name: "American Indian or Alaska Native",
      color: "#F28E2B",
    },
    {
      variable: "Asian",
      name: "Asian",
      color: "#E15759",
    },
    {
      variable: "Middle Eastern or North African",
      name: "Middle Eastern or North African",
      color: "#76B7B2",
    },
    {
      variable: "Multiracial",
      name: "Multiracial",
      color: "#59A14E",
    },
    {
      variable: "Native Hawaiian or Other Pacific Islander",
      name: "Native Hawaiian or Other Pacific Islander",
      color: "#EDC948",
    },
    {
      variable: "Other",
      name: "Other",
      color: "#B07AA1",
    },
    {
      variable: "Unknown",
      name: "Unknown",
      color: "#FF9DA7",
    },
    {
      variable: "White or Caucasian",
      name: "White or Caucasian",
      color: "#9C755F", // 
    },
  ],
  // Ethnicity
  ethnicityData: [
    {
      variable: "Hispanic or Latino",
      name: "Hispanic or Latino",
      color: "#4E79A7",
    },
    {
      variable: "Not Hispanic or Latino",
      name: "Not Hispanic or Latino",
      color: "#F28E2B",
    },
    {
      variable: "Unknown",
      name: "Unknown",
      color: "#E15759",
    },
  ],
  //Gender
  genderData: [
    {
      variable: "Female",
      name: "Female",
      color: "#4E79A7",
    },
    {
      variable: "Male",
      name: "Male",
      color: "#76B7B2",
    },
    {
      variable: "Other",
      name: "Other",
      color: "#F28E2B",
    },
    {
      variable: "Unknown",
      name: "Unknown",
      color: "#E15759"
    },
  ],
  educationConcern: [
    { color: '#4E79A7', variable: 'College degree', name: 'College/advanced degree'},
    { color: '#F28E2B', variable: 'Assoc degree', name: "Associate's degree" },
    { color: '#E15759', variable: 'Vocation training', name: 'Trade/technical/vocational training' },
    { color: '#76B7B2', variable: 'Some college', name: 'Some college credit/no degree' },
    { color: '#59A14E', variable: 'Diploma/GED', name: 'High school diploma/GED' },
    { color: '#EDC949', variable: 'Some HS', name: 'Some high school/ no diploma' },
    { color: '#B07AA2', variable: '12', name: '12th Grade' },
    { color: '#FF9DA7', variable: '11', name: '11th Grade' },
    { color: '#9C755F', variable: '10', name: '10th Grade' },
    { color: '#4E79A7', variable: '9', name: '9th Grade' },
    { color: '#F28E2B', variable: '8', name: '8th Grade' },
    { color: '#E15759', variable: '7', name: '7th Grade' },
    { color: '#76B7B2', variable: '6', name: '6th Grade' },
    { color: '#59A14E', variable: '5', name: '5th Grade' },
    { color: '#EDC948', variable: '4', name: '4th Grade' },
    { color: '#B07AA1', variable: '3', name: '3rd Grade' },
    { color: '#FF9DA7', variable: '2', name: '2nd grade' },
    { color: '#9C755F', variable: '1', name: '1st Grade' },
    { color: '#4E79A7', variable: 'K', name: 'Kindergarten' },
    { color: '#F28E2B', variable: 'Pre-K', name: 'Pre-kindergarten' }],
  darkConcernColors: [
    {
      variable: 'Clear Concern',
      name: 'Clear Concern',
      color: '#7f170d',
    },
    {
      variable: 'Possible Concern',
      name: 'Possible Concern',
      color: '#f2a93c',
    },
    {
      variable: 'No Concern',
      name: 'No Concern',
      color: '#377e23'
    },
  ],
}