import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const AgeBarGraphV2 = (props) => {
  const ref = useRef(null);
  //const cache = useRef(props.data.raceData);

  useEffect(() => {
    var margin = { top: 30, right: 0, bottom: 30, left: 80 };
    var width;
    if (window.innerWidth < 959) {
      width = 330;
    } else {
      width = 900;
    }
    if (props.report) {
      width = 550;
    }
    // width = width - margin.left - margin.right;
    var height = 500 - margin.top - margin.bottom;

    const colors = d3.scaleOrdinal([
        "#4E79A7",
        "#F28E2B",
        "#E15759",
        "#76B7B2",
        "#59A14E",
        "#EDC949",
        "#B07AA2",
        "#FF9DA7",
        "#9C755F"
    ]);

    var data = props.data;

    var max = d3.max(data, (d) => +d.value);

    let svg = d3.select(ref.current);
    svg.selectAll("svg").remove();

    var barsvg = svg
      .append("svg")
      .attr("width", width)
      .attr("height", 500)
      .append("g")
      .attr("transform", "translate(" + 0 + "," + 20 + ")");

    var tooltip = d3
      .select(".dem-graph")
      .append("div")
      .attr("class", "tooltip")
        .style("opacity", 0)
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        .style("z-index", "-1")
        .style("padding", "5px");;
    tooltip.append("div").attr("class", "count");
    tooltip.append("div").attr("class", "label");
    tooltip.append("div").attr("class", "percent");

    // set the ranges
    var x = d3
      .scaleBand()
      .domain(d3.range(0, data.length))
      .range([margin.left, width - margin.right])
      .padding(0.1);
    var y = d3
      .scaleLinear()
      .domain([0, max])
      .nice()
      .range([height - margin.bottom, margin.top]);

    var xAxis = (g) =>
      g.attr("transform", `translate(0,${height - margin.bottom})`).call(
        d3
          .axisBottom(x)
          .tickFormat((i) => data[i].age)
          .tickSizeOuter(0)
      );
    var yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).ticks(null, data.y))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .append("text")
            .attr("x", -margin.left)
            .attr("y", 10)
            // .attr("fill", )
            .attr("text-anchor", "start")
            .text(data.y)
        );

    barsvg
      .append("g")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .on("mouseover", onMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove)
      .attr("x", (d, i) => x(i))
      .attr("y", (d) => y(d.value))
      .attr("height", (d) => y(0) - y(d.value))
      .attr("width", x.bandwidth())
      .attr("fill", (d, i) => colors(i));

    function onMouseOver(d) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", 9999);
      tooltip.select(".count").text("Age: " + d.age);
      var total = d3.sum(
        data.map((d) => {
          return d.value;
        })
      );
      var percent = Math.round((d.value / total) * 100);
      tooltip.select(".label").text("Total: " + d.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      tooltip.select(".percent").text("Percent: " + percent + "%");
    }

    function onMouseOut() {
      tooltip.style("opacity", 0);
      tooltip.style("z-index", '-1');

    }

    function onMouseMove(d) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", 9999);
      tooltip
      .style("top", (d3.event.clientY - 150) + "px")
      .style("left", (d3.event.clientX - 200) + "px");
    }

    // x axis labels
    barsvg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis)
      .selectAll(".tick text")
      .call(wrap, x.bandwidth())

    barsvg.append("g").call(yAxis);

    // text label for the x axis
    barsvg
      .append("text")
      .attr("transform", "translate(" + width / 2 + " ," + (height + 10) + ")")
      .style("text-anchor", "middle")
      .text("Age");

    // text label for the y axis
    barsvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Number of Screenings");

    // wrap x axis labels function
    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = 0.5, // parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width && line.length > 1) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }
  });

  return <div ref={ref} id={props.id} className="dem-graph"></div>;
};

export default AgeBarGraphV2;
