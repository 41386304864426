export const demanddata = {
    providerAge: [ 
        { 
            variable : "18-24" ,
            name: "18-24",
            color: "#4E79A7"
        },
        { 
            variable : "25-29",
            name: "25-29",
            color: "#F28E2B"
        }, 
        {
            variable : "30-39" ,
            name: "30-39",
            color: "#E15759"
        },  
        { 
            variable : "40-49" ,
            name: "40-49",
            color: "#76B7B2"
        },
        { 
            variable : "50-59" ,
            name: "50-59",
            color: "#59A14E"
        },  
        {
            variable : "60+" ,
            name: "60+",
            color: "#EDC949"
        }, 
        {
            variable : "Unknown" ,
            name: "Unknown",
            color: "#B07AA1"
        }
    ],
}