import { allStatesJson } from '../Data/allStatesJson'
import { nationalCountiesJson } from '../Data/nationalCountiesJson'
// import { nationalDistrictsJson } from './nationalDistrictsJson'

export const mapsNationalData = {
    mapZoom: 4,
    reportMapZoom: 3,
    //mapBoxCenter: [-100.901, 39.0586],
    mapBoxCenter: [-98.5795, 39.8282], 
    //mapBoxCenterReport: [-107.901, 41.0586],// Update?
    mapBoxCenterReport: [-98.5795, 39.8282],
    //mapMaxBounds: [[125.155536, 5.714165], [-53.157750, 73.253393]],
    //mapMaxBoundsReport: [[110.155536, 0.714165], [-53.157750, 73.253393]],
    //reportMapWidth: '60vw',
    'Federal Cases': { // info based on location filter
        'Location ': {
            'State Prosecuted': {
                dataVar: 'nationalStateProsecuted',
                sourceVar: 'state_prosecuted',
                source2Var: 'state_prosecuted',
                totalDataVar: 'nationalTotal',
                mapJson: allStatesJson,
                mapLocVar: 'STATELOCATION',
                mapLoc2Var: 'STATELOCATION',
                hoverTitleVar: 'NAME',
                mapFillOpacity: 0.9,
                mapFillColors: [
                    [1, "#e9f6fc"],
                    [20, "#93d6f1"],
                    [40, "#28ade3"],
                    [50, "#186788"],
                    [60, "#0c3344"],
                    [100, "#000000"],
                ],
                fillColorProp: 'SCREENINGS',
                fillOutlineColor: '#28ADE3',
                mapInfoBox: {
                    title: 'Federal Cases Prosecuted',
                    info: [
                        {
                            label: 'Total Cases:',
                            dataVar: 'nationalTotal',
                            sourceVar: 'count',
                            style: { display: 'block', margin: '1em 0em' },
                        },
                        {
                            label: 'Minor Sex Trafficking:',
                            dataVar: 'nationalStateProsecuted',
                            sourceVar: 'minorsextraf',
                            calcType: 'addEachObj',
                            style: { display: 'block' },
                        },
                        {
                            label: 'Adult Sex Trafficking:',
                            dataVar: 'nationalStateProsecuted',
                            sourceVar: 'adultsextraf',
                            calcType: 'addEachObj',
                            style: { display: 'block' },
                        },
                        {
                            label: 'Labor Trafficking:',
                            dataVar: 'nationalStateProsecuted',
                            sourceVar: 'labor',
                            calcType: 'addEachObj',
                            style: { display: 'block' },
                        },
                        {
                            label: 'Both Sex and Labor Trafficking:',
                            dataVar: 'nationalStateProsecuted',
                            sourceVar: 'both',
                            calcType: 'addEachObj',
                            style: { display: 'block' },
                        },
                    ]
                },
                hoverInfoBoxVars: [
                    'minorsextraf', 'adultsextraf', 'labor', 'both'
                ]
            },


            // 'District Prosecuted': {
            //     dataVar: 'districtsTotals',
            //     sourceVar: 'district_prosecuted',
            //     // totalDataVar: '',
            //     mapLocVar: 'DISTRICT',
            //     // mapLoc2Var: 'CIRCUIT',
            //     mapJson: nationalDistrictsJson,
            //     mapFillOpacity: 0.9,
            //     mapFillColors:
            //         ['interpolate-hcl', ['linear'],
            //             ['get', 'CIRCUIT'],
            //             0, '#4E79A7',
            //             1, '#A0CBE8',
            //             2, "#F28E2B",
            //             3, "#FFBE7D",
            //             4, "#59A14E",
            //             5, "#8CD17D",
            //             6, "#B6992D",
            //             7, "#499894",
            //             8, "#86BCB6",
            //             9, "#E15759",
            //             10, "#4E79A7",
            //             11, "#A0CBE8",
            //         ],
            //     fillColorProp: 'CIRCUIT',
            //     fillOutlineColor: '#000',
            // },


            'Trafficking Locations': {
                dataVar: 'nationalTraffLocations',
                sourceVar: 'county',
                source2Var: 'statelocation',
                totalDataVar: 'nationalTraffLoTotal',
                mapJson: nationalCountiesJson,
                mapLocVar: 'NAME',
                mapLoc2Var: 'STATELOCATION',
                noPercent: true,
                mapFillOpacity: 0.9,
                mapFillColors: [
                // They want a better color distinction between 0 and 1
                    [0, "#e9f6fc"],
                    [1, "#a2dbf2"],
                    [2, "#63c9f2"],
                    [5, "#28ade3"],
                    [10, "#186788"],
                    [20, "#0c3344"],
                    [30, "#000000"],
                    
                    // [1, "#e9f6fc"],
                    // [2, "#93d6f1"],
                    // [5, "#28ade3"],
                    // [10, "#186788"],
                    // [20, "#0c3344"],
                    // [30, "#000000"],
                ],
                fillColorProp: 'SCREENINGS',
                fillOutlineColor: '#28ADE3',
                loc2MapOutlines: allStatesJson, // show state outlineslines while also showing the county data/lines
            }
        },
        mapInfo: 
            <div>
                <table className="data-table">
                <tr>
                    <th>Data Source</th>
                    <th>Date</th>
                </tr>
                <tr>
                    <td>Federal Human Trafficking Case Data</td>
                    <td>2000-present</td>
                </tr>
                </table>
                <p>
                Federal Human Trafficking Case data has been collected by Allies Against Slavery in partnership with Dr. Vanessa Bouché. Data is based on federal human trafficking cases prosecuted nationally, and includes information about case level variables, trafficking locations, defendant and victim characteristics, and criminal methods.
                <br/><br/>
                The State Prosecuted view shows the distribution prosecution data across the United States. If a case has been transferred to another state or territory, the case falls under the location where sentencing occurred. The map card and hovers show this prosecution data broken down by trafficking type.
                {/* todo: district = v2 */}
                {/* <br/><br/>
                The District Prosecuted view is the district in which the case was prosecuted. If a case has been transferred to another district, the case falls under the district where sentencing occurred. To view the number of cases prosecuted by district based on a specific trafficking type, select an option from the Trafficking Type filter. */}
                <br/><br/>
                The Trafficking Locations view shows the number of trafficking locations by county or parish. A trafficking location refers to specific businesses, addresses, cities and counties/parishes where victims were recruited, transported, obtained, retained, harbored and exploited. This view does not include all cases, since some case documents do not specify where in the state trafficking occurred. Those cases are coded with "Unlisted" trafficking locations. To view the distribution of trafficking locations based on a specific trafficking type, select an option from the Trafficking Type filter.
              </p>
            </div>,
    },
}