export const nationalDistrictList = [
    {
        district: 'All districts',
        state: 'All districts',
        stateLocation: 'All districts',
    },
    {
        district: 'Northern District of Alabama',
        state: 'Alabama',
        stateLocation: 'AL',
    },
    {
        district: 'Middle District of Alabama',
        state: 'Alabama',
        stateLocation: 'AL',
    },
    {
        district: 'Southern District of Alabama',
        state: 'Alabama',
        stateLocation: 'AL',
    },
    {
        district: 'District of Alaska',
        state: 'Alaska',
        stateLocation: 'AK',
    },
    {
        district: 'District of Arizona',
        state: 'Arizona',
        stateLocation: 'AR',
    },
    {
        district: 'Eastern District of Arkansas',
        state: 'Arkansas',
        stateLocation: 'AR',
    },
    {
        district: 'Western District of Arkansas',
        state: 'Arkansas',
        stateLocation: 'AR',
    },
    {
        district: 'Northern District of California',
        state: 'California',
        stateLocation: 'CA',
    },
    {
        district: 'Eastern District of California',
        state: 'California',
        stateLocation: 'CA',
    },
    {
        district: 'Central  District of California',
        state: 'California',
        stateLocation: 'CA',
    },
    {
        district: 'Southern District of California',
        state: 'California',
        stateLocation: 'CA',
    },
    {
        district: 'District of Colorado',
        state: 'Colorado',
        stateLocation: 'CO',
    },
    {
        district: 'District of Connecticut',
        state: 'Connecticut',
        stateLocation: 'CT',
    },
    {
        district: 'District of Delaware',
        state: 'Delaware',
        stateLocation: 'DE',
    },
    {
        district: 'District of Columbia',
        state: 'Columbia',
        stateLocation: 'DC'
    },
    {
        district: 'Northern District of Florida',
        state: 'Florida',
        stateLocation: 'FL',
    },
    {
        district: 'Middle District of Florida',
        state: 'Florida',
        stateLocation: 'FL',
    },
    {
        district: 'Southern District of Florida',
        state: 'Florida',
        stateLocation: 'FL',
    },
    {
        district: 'Northern District of Georgia',
        state: 'Georgia',
        stateLocation: 'GA',
    },
    {
        district: 'Middle District of Georgia',
        state: 'Georgia',
        stateLocation: 'GA',
    },
    {
        district: 'Southern District of Georgia',
        state: 'Georgia',
        stateLocation: 'GA',
    },
    {
        district: 'District of Guam',
        state: 'Guam',
        stateLocation: 'GU',
    },
    {
        district: 'District of Hawaii',
        state: 'Hawaii',
        stateLocation: 'HI',
    },
    {
        district: 'District of Idaho',
        state: 'Idaho',
        stateLocation: 'ID'
    },
    {
        district: 'Northern District of Illinois',
        state: 'Illinois',
        stateLocation: 'IL'
    },
    {
        district: 'Central District of Illinois',
        state: 'Illinois',
        stateLocation: 'IL'
    },
    {
        district: 'Southern District of Illinois',
        state: 'Illinois',
        stateLocation: 'IL'
    },
    {
        district: 'Northern District of Indiana',
        state: 'Indiana',
        stateLocation: 'IN',
    },
    {
        district: 'Southern District of Indiana',
        state: 'Indiana',
        stateLocation: 'IN',
    },
    {
        district: 'Northern District of Iowa',
        state: 'Iowa',
        stateLocation: 'IA'
    },
    {
        district: 'Southern District of Iowa',
        state: 'Iowa',
        stateLocation: 'IA'
    },
    {
        district: 'District of Kansas',
        state: 'Kansas',
        stateLocation: 'KS',
    },
    {
        district: 'Eastern District of Kentucky',
        state: 'Kentucky',
        stateLocation: 'KY',
    },
    {
        district: 'Western District of Kentucky',
        state: 'Kentucky',
        stateLocation: 'KY',
    },
    {
        district: 'Western District of Louisiana',
        state: 'Louisiana',
        stateLocation: 'LA',
    },
    {
        district: 'Middle District of Louisiana',
        state: 'Louisiana',
        stateLocation: 'LA',
    },
    {
        district: 'Eastern District of Louisiana',
        state: 'Louisiana',
        stateLocation: 'LA',
    },
    {
        district: 'District of Maine',
        state: 'Maine',
        stateLocation: 'ME',
    },
    {
        district: 'District of Maryland',
        state: 'Maryland',
        stateLocation: 'MD'
    },
    {
        district: 'District of Massachussetts',
        state: 'Massachussetts',
        stateLocation: 'MA'
    },
    {
        district: 'Eastern District of Michigan',
        state: 'Michigan',
        stateLocation: 'MI'
    },
    {
        district: 'Western District of Michigan',
        state: 'Michigan',
        stateLocation: 'MI'
    },
    {
        district: 'District of Minnesota',
        state: 'Minnesota',
        stateLocation: 'MN'
    },
    {
        district: 'Northern District of Mississippi',
        state: 'Mississippi',
        stateLocation: 'MS'
    },
    {
        district: 'Southern District of Mississippi',
        state: 'Mississippi',
        stateLocation: 'MS'
    },
    {
        district: 'Eastern District of Missouri',
        state: 'Missouri',
        stateLocation: 'MO'
    },
    {
        district: 'Western District of Missouri',
        state: 'Missouri',
        stateLocation: 'MO'
    },
    {
        district: 'District of Montana',
        state: 'Montana',
        stateLocation: 'MT'
    },
    {
        district: 'District of Nebraska',
        state: 'Nebraska',
        stateLocation: 'NE'
    },
    {
        district: 'District of Nevada',
        state: 'Nevada',
        stateLocation: 'NV'
    },
    {
        district: 'District of New Hampshire',
        state: 'New Hampshire',
        stateLocation: 'NH'
    },
    {
        district: 'District of New Jersey',
        state: 'New Jersey',
        stateLocation: 'NJ'
    },
    {
        district: 'District of New Mexico',
        state: 'New Mexico',
        stateLocation: 'NM'
    },
    {
        district: 'Northern District of New York',
        state: 'New York',
        stateLocation: 'NY'
    },
    {
        district: 'Southern District of New York',
        state: 'New York',
        stateLocation: 'NY'
    },
    {
        district: 'Eastern District of New York',
        state: 'New York',
        stateLocation: 'NY'
    },
    {
        district: 'Western District of New York',
        state: 'New York',
        stateLocation: 'NY'
    },

    {
        district: 'Eastern District of North Carolina',
        state: 'North Carolina',
        stateLocation: 'NC'
    },
    {
        district: 'Middle District of North Carolina',
        state: 'North Carolina',
        stateLocation: 'NC'
    },
    {
        district: 'Western District of North Carolina',
        state: 'North Carolina',
        stateLocation: 'NC'
    },
    {
        district: 'District of North Dakota',
        state: 'North Dakota',
        stateLocation: 'ND'
    },
    {
        district: 'District of the Northern Mariana Islands',
        state: 'Northern Mariana Islands',
        stateLocation: 'MP'
    },
    {
        district: 'Northern District of Ohio',
        state: 'Ohio',
        stateLocation: 'OH'
    },
    {
        district: 'Southern District of Ohio',
        state: 'Ohio',
        stateLocation: 'OH'
    },
    {
        district: 'Northern District of Oklahoma',
        state: 'Oklahoma',
        stateLocation: 'OK'
    },
    {
        district: 'Eastern District of Oklahoma',
        state: 'Oklahoma',
        stateLocation: 'OK'
    },
    {
        district: 'Western District of Oklahoma',
        state: 'Oklahoma',
        stateLocation: 'OK'
    },
    {
        district: 'District of Oregon',
        state: 'Oregon',
        stateLocation: 'OR'
    },
    {
        district: 'Eastern District of Pennsylvania',
        state: 'Pennsylvania',
        stateLocation: 'PA'
    },
    {
        district: 'Middle District of Pennsylvania',
        state: 'Pennsylvania',
        stateLocation: 'PA'
    },
    {
        district: 'Western District of Pennsylvania',
        state: 'Pennsylvania',
        stateLocation: 'PA'
    },
    {
        district: 'District of Puerto Rico',
        state: 'Puerto Rico',
        stateLocation: 'PR'
    },
    {
        district: 'District of Rhode Island',
        state: 'Rhode Island',
        stateLocation: 'RI'
    },
    {
        district: 'District of South Carolina',
        state: 'South Carolina',
        stateLocation: 'SC'
    },
    {
        district: 'District of South Dakota',
        state: 'South Dakota',
        stateLocation: 'SD'
    },
    {
        district: 'Eastern District of Tennessee',
        state: 'Tennessee',
        stateLocation: 'TN'
    },
    {
        district: 'Middle District of Tennessee',
        state: 'Tennessee',
        stateLocation: 'TN'
    },
    {
        district: 'Western District of Tennessee',
        state: 'Tennessee',
        stateLocation: 'TN'
    },
    {
        district: 'Western District of Texas',
        state: 'Texas',
        stateLocation: 'TX'
    },
    {
        district: 'Northern District of Texas',
        state: 'Texas',
        stateLocation: 'TX'
    },
    {
        district: 'Southern District of Texas',
        state: 'Texas',
        stateLocation: 'TX'
    },
    {
        district: 'Eastern District of Texas',
        state: 'Texas',
        stateLocation: 'TX'
    },
    {
        district: 'District of Utah',
        state: 'Utah',
        stateLocation: 'UT'
    },
    {
        district: 'District of Vermont',
        state: 'Vermont',
        stateLocation: 'VT'
    },
    {
        district: 'District of the Virgin Islands',
        state: 'Virgin Islands',
        stateLocation: 'VI'
    },
    {
        district: 'Eastern District of Virginia',
        state: 'Virginia',
        stateLocation: 'VA'
    },
    {
        district: 'Western District of Virginia',
        state: 'Virginia',
        stateLocation: 'VA'
    },
    {
        district: 'Eastern District of Washington',
        state: 'Washington',
        stateLocation: 'WA' 
    },
    {
        district: 'Western District of Washington',
        state: 'Washington',
        stateLocation: 'WA' 
    },
    {
        district: 'Northern District of West Virginia',
        state: 'West Virginia',
        stateLocation: 'WV'
    },
    {
        district: 'Southern District of West Virginia',
        state: 'West Virginia',
        stateLocation: 'WV'
    },
    {
        district: 'Eastern District of Wisconsin',
        state: 'Wisconsin',
        stateLocation: 'WI'
    },
    {
        district: 'Western District of Wisconsin',
        state: 'Wisconsin',
        stateLocation: 'WI'
    },
    {
        district: 'District of Wyoming',
        state: 'Wyoming',
        stateLocation: 'WY'
    },
    {
        district: 'Out of State Prosecutions',
        state: 'Out of State Prosecutions'
    }
]