import { createClient } from "contentful";
import pick from "lodash/pick";

import { ENUMS } from "../Enums";
import { ContentfulFields } from "../ContentfulFields";

let client;
if (process.env.REACT_APP_PUBLIC_SITE_CONTENTFUL_API_KEY) {
    client = createClient({
        space: process.env.REACT_APP_PUBLIC_SITE_CONTENTFUL_SPACE_ID,
        environment: process.env.REACT_APP_PUBLIC_SITE_CONTENTFUL_ENV_ID,
        accessToken: process.env.REACT_APP_PUBLIC_SITE_CONTENTFUL_API_KEY
    });
}

// const _getContentTypeFromRouteName = (routeName) => {
//     return routeName.substring(1).replace(/-[a-z]/g, (c) => c[1].toUpperCase());
// };

const _getEntries = async (contentType) => {
    const response = await client.getEntries({
        content_type: contentType,
        select: "fields"
    });

    return response.items.map((item) => {
        const entryItem = pick(item.fields, Object.keys(ContentfulFields[contentType]))
        entryItem.contentType = contentType;
        return entryItem;
    });
};

const getFooterContent = async () => {
    try {
        return (await _getEntries("footer"))[0];
    } catch (error) {
        console.log("getFooterContent error", error);
    }
};

const getChartContent = () => {
    try {
        return _getEntries("chart");
    } catch (error) {
        console.log("getChartsContent error", error);
    }
};

const getMapsContent = () => {
    try {
        return _getEntries("map");
    } catch (error) {
        console.log("getMapsContent error", error);
    }
};

const getSiteContent = async (routeName) => {
    // if (!Object.values(ENUMS.ROUTES).includes(routeName)) {
    // return
// }
    let currentPageInfo;
    Object.keys(ENUMS.ROUTES).forEach(page => {
        if (routeName === ENUMS.ROUTES[page].URL) {
            currentPageInfo = ENUMS.ROUTES[page]
        }
    })
    
// Object.values(ENUMS.ROUTES).includes(routeName)
    if (!currentPageInfo) {
        return;
    }

    try {
        // const contentType = _getContentTypeFromRouteName(routeName)
        const contentType = currentPageInfo.CID;
        const siteContent = (await _getEntries(contentType))[0];
        if (siteContent.headerMedia) {
            const { title, file } = siteContent.headerMedia?.[0]?.fields;
            siteContent.headerMedia = {
                url: file?.url || "",
                contentType: file?.contentType,
                title
            };
        }

        return siteContent;
    } catch (error) {
        console.log("getSiteContent error", error);
    }
};

const useContentful = () => {
    return {
        getFooterContent,
        getSiteContent,
        getMapsContent,
        getChartContent
    };
};

export default useContentful;