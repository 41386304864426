import React, {useRef, useEffect} from "react";
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ArrowDown from "../../../../Assets/Images/arrow-down.svg";
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ColorCheckbox = withStyles({
    root: {
      color: props => props.color,
      padding: '0px',
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

export default function ServiceLaLegend(props) {
    // eslint-disable-next-line no-unused-vars
    const [allKeys, setAllKeys] = React.useState([
        "Behavioral Health",
        "CAC",
        "DCFS",
        "District Attorney's Office",
        "Drop-In Center",
        "DV/SA Services",
        "Emergency Shelter",
        "FINS",
        "Homelessness Program",
        "HT Program, Non-Residential",
        "HT Program, Residential",
        "Immigrant Services",
        "Juvenile Justice",
        "Law Enforcement",
        "Legal Assistance",
        "Long Term Residential",
        "Medical",
        "School",
        "Taskforce",
        "Therapy/Counseling",
        "Transitional Housing",
        "Victim Advocacy",
        "Other",
        "National Referral Directory"
    ]);

    const [keys, setKeys] = React.useState([
        "Behavioral Health",
        "CAC",
        "DCFS",
        "District Attorney's Office",
        "Drop-In Center",
        "DV/SA Services",
        "Emergency Shelter",
        "FINS",
        "Homelessness Program",
        "HT Program, Non-Residential",
        "HT Program, Residential",
        "Immigrant Services",
        "Juvenile Justice",
        "Law Enforcement",
        "Legal Assistance",
        "Long Term Residential",
        "Medical",
        "School",
        "Taskforce",
        "Therapy/Counseling",
        "Transitional Housing",
        "Victim Advocacy",
        "Other",
        "National Referral Directory"
    ]);

    const colors = [
        "#4E79A7",
        "#092384",
        "#F28E2B",
        "#FFBE7D",
        "#59A14E",
        "#8CD17D",
        "#B6992D",
        "#499894",
        "#86BCB6",
        "#E15759",
        "#FF9D9A",
        "#79706E",
        "#BAB0AC",
        "#D37295",
        "#FABFD2",
        "#B07AA1",
        "#D4A6C8",
        "#9D7660",
        "#D7B5A6",
        "#000000",
        "#A0CBE8",
        "#FF9D9A",
        "#79706E",
        "#a9d1ce",
    ];
    const buttonRef = useRef(null)
    const popperRef = useRef(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    // eslint-disable-next-line no-unused-vars
    const [popperRefHeight, setPopperRefHeight] = React.useState(100)

    const handleLegendClick = (e, key) => {
        if (keys.includes(key)) {
            setKeys(keys.filter((_key) => _key !== key));
            props.handleLaLegendClick(keys.filter((_key) => _key !== key))
        } else {
            var newArray = [...keys];
            newArray.push(key);
            setKeys(newArray);
            props.handleLaLegendClick(newArray)
        }
    }

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

    useEffect(() => {
        if (props.report) {
            setAnchorEl(buttonRef.current)
            if (popperRef.current && popperRef.current.clientHeight) {

                setPopperRefHeight(popperRef.current.clientHeight)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.report, popperRef.current])

    return (
        <div
            className={!props.report ? 'mapLegendWrapper' : 'reportMapLegendWrapper'}
            style={props.report && { height: `350px` }}
        >
            <Button ref={buttonRef} aria-describedby={id} variant="contained" color="default" type="button" onClick={handleClick} className="filterButton legendButton">
            Legend {
                props.shouldUseNewStyles
                    ? <ArrowDropDownIcon />
                    : <img src={ArrowDown} alt="Arrow Down" />
            }
        </Button>
            <Popper
                ref={popperRef}
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'top-start'}
                transition
                disablePortal // (the children will stay within their parent DOM hierarchy for pdf's to capture it)
                modifiers={props.report && {
                    flip: {
                        enabled: false,
                    },
                }}
            >
            {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Paper>
                <div className="maplegend">
                    <p style={{ margin: 0 }}>Services</p>
                    {allKeys.map((item, key) => {
                        return (
                            <div key={key} className="field" style={{ display: "flex", alignItems: "center"}}>
                            { keys.includes(item) ? <ColorCheckbox checked={true} color={colors[key]} onClick = {(e) => handleLegendClick(e, item)} />
                            : <ColorCheckbox color={colors[key]} checked={false} style={{ padding: '0px'}} onClick = {(e) => handleLegendClick(e, item)} />}
                            <label
                                htmlFor={key}
                                style={{fontSize: "12px", marginLeft: '5px' }}>
                                {item}
                            </label>
                            </div>
                        );
                        })}
                    </div>
                </Paper>
            </Fade>
            )}
        </Popper>
    </div>
    )
}

