import React from "react";

import Layout from "./Layout";

export default function StatePolicy() {
// Contentful id: policiesLegislation
  return (
    <Layout>
      <div>
      </div>
    </Layout>
  );
}
