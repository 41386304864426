/* eslint-disable array-callback-return */
import { parishData } from "../../../Data/parishJson";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { laRegionData } from "../../../Data/laRegionJson";
//import { laRegionData } from '../../../Data/newLARegions'

export function ServicesLaLayer(map, location, data, legend, report) {

    var orgArrayList = [
        { name: "Behavioral Health", color: "#4E79A7"},
        { name: "CAC", color: "#092384"},
        { name: "DCFS", color: "#F28E2B"},
        { name: "District Attorney's Office", color: "#FFBE7D"},
        { name: "Drop-In Center", color: "#59A14E"},
        { name: "DV/SA Services", color: "#8CD17D"},
        { name: "Emergency Shelter", color: "#B6992D"},
        { name: "FINS", color: "#499894"},
        { name: "Homelessness Program", color: "#86BCB6"},
        { name: "HT Program, Non-Residential", color: "#E15759"},
        { name: "HT Program, Residential", color: "#FF9D9A"},
        { name: "Immigrant Services", color: "#79706E"},
        { name: "Juvenile Justice", color: "#BAB0AC"},
        { name: "Law Enforcement", color: "#D37295"},
        { name: "Legal Assistance", color: "#FABFD2"},
        { name: "Long Term Residential", color: "#B07AA1"},
        { name: "Medical", color: "#D4A6C8"},
        { name: "School", color: "#9D7660"},
        { name: "Taskforce", color: "#D7B5A6"},
        { name: "Therapy/Counseling", color: "#000000"},  
        { name: "Transitional Housing", color: "#FF9D9A"},
        { name: "Victim Advocacy", color: "#A0CBE8"},
      { name: "Other", color: "#79706E" },
      { name: "National Referral Directory", color: "#a9d1ce"},
    ]

  var locationSelection;
    for (let i = 0; i < location.length; i++){
      if (location[i].name === 'Provider Location ' && location[i].filter === 'Services'){
            locationSelection = location[i].value[0];
        }
    }

    // console.log(locationSelection)
   

    if (locationSelection === "State"){
        map.on("load", function () {
            if (data.orgList){
              data.orgList.map((item, key) => {
                  for (var i = 0; i < orgArrayList.length; i ++){
                    if (item.org_type === orgArrayList[i].name && legend.includes(orgArrayList[i].name)) {
                        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                          `<p>${item.organization}</p>
                           <p>Parish: <span style="font-weight: 300 !important">${item.county} </span></p>
                           <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                          );
                          return new mapboxgl.Marker({ color: orgArrayList[i].color, scale: report ? 0.2 : 0.5 })
                          .setLngLat([item.long, item.lat])
                          .setPopup(popup)
                          .addTo(map);
                      } 
                  }
                });
            }
          });
    } else if (locationSelection === "Region") {
        map.on("load", function () {
            map.addSource("region", {
              type: "geojson",
              data: laRegionData, // newLARegions
            });
            map.addLayer({
              id: "region",
              type: "line",
              source: "region",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#28ADE3",
                "line-width": 1,
              },
            });
    
            map.addLayer({
              id: "region-fills",
              type: "fill",
              source: "region",
              layout: {},
              paint: {
                "fill-color": "#28ADE3",
                "fill-opacity": [
                  "case",
                  ["boolean", ["feature-state", "hover"], false],
                  0.5,
                  0,
                ],
              },
            });

            if (data.orgList){
              data.orgList.map((item) => {
                for (var i = 0; i < orgArrayList.length; i ++){
                  if (item.org_type === orgArrayList[i].name && legend.includes(orgArrayList[i].name)) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>Parish: <span style="font-weight: 300 !important">${item.region} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                        return new mapboxgl.Marker({ color: orgArrayList[i].color, scale: report ? 0.2 : 0.5 })
                        .setLngLat([item.long, item.lat])
                        .setPopup(popup)
                        .addTo(map);
                    } 
                }
                });
            }
            var popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
              });
        
              map.on("mousemove", "region-fills", function (e) {
                if (hoveredStateId !== e.features[0].id) {
                  if (e.features.length > 0) {
                    if (hoveredStateId) {
                      map.setFeatureState(
                        { source: "region", id: hoveredStateId },
                        { hover: false }
                      );
                    }
                    hoveredStateId = e.features[0].id;
                    map.setFeatureState(
                      { source: "region", id: hoveredStateId },
                      { hover: true }
                    );
                  }
        
                  // var coordinates = e.features[0].geometry.coordinates[0][0];
                  // if (coordinates.length > 2) {
                  //   for (var i = 2; i < coordinates.length; i--) {
                  //     coordinates.pop();
                  //   }
                  // }
                  var description = e.features[0].properties.region;
                  popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
                }
              });
        
              map.on("mouseleave", "region-fills", function () {
                if (hoveredStateId) {
                  map.setFeatureState(
                    { source: "region", id: hoveredStateId },
                    { hover: false }
                  );
                }
                hoveredStateId = null;
                popup.remove();
              });
            });

    } else {
        var hoveredStateId = null;

        map.on("load", function () {
        map.addSource("county", {
            type: "geojson",
            data: parishData,
        });
        map.addLayer({
            id: "county",
            type: "line",
            source: "county",
            layout: {
            "line-join": "round",
            "line-cap": "round",
            },
            paint: {
            "line-color": "#28ADE3",
            "line-width": 1,
            },
        });

        map.addLayer({
            id: "county-fills",
            type: "fill",
            source: "county",
            layout: {},
            paint: {
            "fill-color": "#28ADE3",
            "fill-opacity": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                0.5,
                0,
            ],
            },
        });

        if (data.orgList){
          data.orgList.map((item) => {
            for (var i = 0; i < orgArrayList.length; i ++){
              if (item.org_type === orgArrayList[i].name  && legend.includes(orgArrayList[i].name)) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>Parish: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                    return new mapboxgl.Marker({ color: orgArrayList[i].color, scale: report ? 0.2 : 0.5 })
                    .setLngLat([item.long, item.lat])
                    .setPopup(popup)
                    .addTo(map);
                } 
            }
            });
        }
        
        });

        var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        });

        map.on("mousemove", "county-fills", function (e) {
        if (hoveredStateId !== e.features[0].id) {
            if (e.features.length > 0) {
            if (hoveredStateId) {
                map.setFeatureState(
                { source: "county", id: hoveredStateId },
                { hover: false }
                );
            }
            hoveredStateId = e.features[0].id;
            map.setFeatureState(
                { source: "county", id: hoveredStateId },
                { hover: true }
            );
            }

            // var coordinates;
            // if (e.features[0].geometry.coordinates.length > 1) {
            // coordinates = e.features[0].geometry.coordinates[0][0][0];
            // } else {
            // coordinates = e.features[0].geometry.coordinates[0][0];
            // }

            // if (coordinates.length > 2) {
            // for (var i = 2; i < coordinates.length; i--) {
            //     coordinates.pop();
            // }
            // }
            var description = e.features[0].properties.COUNTY;
            popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
        }
        });

        map.on("mouseleave", "county-fills", function () {
        if (hoveredStateId) {
            map.setFeatureState(
            { source: "county", id: hoveredStateId },
            { hover: false }
            );
        }
        hoveredStateId = null;
        popup.remove();
        });
    }

}