export const federalcasesmockdata = {
  casesYear: [
    {
      adultsextraf:"0",
      both:"0",
      labor:"0",
      minorsextraf:"0",
      source:"2000",
      value:"0"
    },
  ],  
  defendantsAge: [
      {
        value: 0,
        source: "<10"
      },
    ],
    defendantRace: [
          {
            value: "3199",
            source: "Asian Or Pacific Islander"
          },
        ],
        defendantGender: [
          {
            value: "3199",
            source: "Asian Or Pacific Islander"
          },
        ],
        victimAge: [
          {
            value: "57581",
            source: "Hispanic"
          }
        ],
        victimGender: [
            {
                value: "57581",
                source: "Hispanic"
              }
        ],
        methodInternational : [ {
          enabled: true,
          label: "International",
          source: "international",
          value: 50 }
        ],
        methodInterstate : [ {
          enabled: true,
          label: "Interstate",
          source: "interstate",
          value: 50 }
        ],
    };
    