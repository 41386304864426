//Federal cases
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import "../Filter.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { allStateNames } from "../../../../Data/allStateNames";
import { nationalCountyList } from "../../../../Data/nationalCountyList"
import { nationalDistrictList } from "../../../../Data/nationalDistrictList";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles } from "@material-ui/core/styles";


const BlackCheckbox = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: "black",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: "20px",
    // width: '400px',
    fontWeight: "300",
    height: "300px",
    overflowY: "scroll",
  },
  filterWrapper: {
    display: "flex",
  },
  active: {
    cursor: "pointer",
    fontWeight: "500 !important",
  },
  inactive: {
    fontWeight: "400 !important",
    cursor: "pointer",
  },
  checkboxLabel: {
    padding: "0px",
  },
  checkboxes: {
    marginLeft: "20px",
    display: "flex",
    flexDirection: "column",
  },
  saveButton: {
    position: "fixed",
    bottom: "10px",
    right: "10px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
}));

export default function NationalFCLoFilter(props) {
  const classes = useStyles();
  const [stateList, setStateList] = useState([]) // original
  const districtList = nationalDistrictList // original
  const [updatedDistricts, setUpdatedDistricts] = useState(nationalDistrictList) // list to show
  const countyList = nationalCountyList // original
  const [updatedCounties, setUpdatedCounties] = useState(nationalCountyList) // list to show
  const [tab, setTab] = useState('State')
  // const [location, setLocation] = React.useState("State");
  const [stateSelections, setStateSelections] = React.useState([]);
  const [districtSelections, setDistrictSelections] = React.useState([]);
  const [countySelections, setCountySelections] = React.useState([]);

  const getDistrictListFunc = (states, allStateList) => {
    // Update District list to show
    let allStates = allStateList ?? stateList
    let getDistricts = [{ district: 'All districts', state: 'All districts' }]
    states.forEach(state => {
      allStates.forEach(stateObj => {
        districtList.forEach(districtObj => {
          if (state === stateObj.name && stateObj.name === districtObj.state) {
            getDistricts.push(districtObj)
          }
        })
      })
    })
    getDistricts.push({ district: 'Out of State Prosecutions', state: 'Out of State Prosecutions' })
    return getDistricts
  }

  const getCountyListFunc = (states, allStateList) => {
    // Update County list to show
    let allStates = allStateList ?? stateList
    let getCounties = [{ stateLocation: 'All counties', county: 'All counties' }]
    states.forEach(state => {
      allStates.forEach(stateObj => {
        countyList.forEach(countyObj => {
          if (state === stateObj.name && stateObj.stateLocation === countyObj.stateLocation) {
            getCounties.push(countyObj)
          }
        })
      })
    })
    return getCounties
  }

  useEffect(() => {
    let allStateList = [...allStateNames]
    allStateList.forEach(s => {
      if (s.name === 'National') { // replace 'National' with 'All States'
        s.name = 'All states'
        s.stateLocation = 'All states'
      }
    })
    setStateList(allStateList)

    for (let i = 0; i < props.selectedFilters.length; i++) {
      if (
        props.selectedFilters[i].name === "Location" &&
        props.selectedFilters[i].filter === "Federal Cases"
        // && props.selectedFilters[i].stateLocation === 'National'
      ) {
        let districts = getDistrictListFunc(props.selectedFilters[i].stateValue, allStateList) // TODO: LEFT OFF (NOT WORKING AFTER FILTERS ARE CHANGED)
        let counties = getCountyListFunc(props.selectedFilters[i].stateValue, allStateList)
        setUpdatedDistricts(districts)
        setUpdatedCounties(counties)
        // if (props.selectedFilters[i].selection === "State") {
        setStateSelections(props.selectedFilters[i].stateValue);
        setDistrictSelections(props.selectedFilters[i].districtValue)
        setCountySelections(props.selectedFilters[i].countyValue);
        // }
        // else if (props.selectedFilters[i].selection === "District") {
        //   // setStateSelections(['All states']);
        //   setDistrictSelections(props.selectedFilters[i].value)
        //   setCountySelections(["All Counties"]);
        // } else { // County
        //   // setStateSelections(["All states"]);
        //   setDistrictSelections(['All Districts'])
        //   setCountySelections(props.selectedFilters[i].value);
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleLocationChange = (event) => {
  //   setLocation(event);
  // if (event === 'State') {
  //   setCountySelections(["All Counties"]);
  //   setDistrictSelections(['All Districts'])
  // } else if (event === 'District') {
  //   // setStateSelections(["All States"]);
  //   setCountySelections(["All Counties"]);
  // } else { // County
  //   setStateSelections(["All States"]);
  //   setDistrictSelections(['All Districts'])
  // }
  // };


  const handleStateChange = (event) => {
    if (event[event.length - 1] === 'All states') {
      setStateSelections(['All states']);
      // setLocation('State')
      setUpdatedDistricts(nationalDistrictList)
      setDistrictSelections([])
      setUpdatedCounties(nationalCountyList)
      setCountySelections([])
    } else {
      let newEvent = [...event].filter(x => x !== 'All states')
      setStateSelections(newEvent);

      // Update District list to show
      let districtList = getDistrictListFunc(newEvent)
      // Remove district selections for states that are no longer selected
      let updateSelectedDistricts = districtSelections.filter(ds => districtList.map(d => d.district).includes(ds))

      if (updateSelectedDistricts.length === 0) {
        setDistrictSelections(['All districts'])
      } else {
        setDistrictSelections(updateSelectedDistricts)
      }
      setUpdatedDistricts(districtList)

      // Update County list to show
      let getCountyList = getCountyListFunc(newEvent)

      // Remove county selections for states that are no longer selected
      let updateSelectedCounties = countySelections.filter(cs => getCountyList.map(c => c.county).includes(cs))
      if (updateSelectedCounties.length === 0) {
        setCountySelections(['All counties'])
      } else {
        setCountySelections(updateSelectedCounties)
      }
      setUpdatedCounties(getCountyList)
    }
  };

  const handleDistrictChange = (event) => {
    // setLocation('District')
    if (event[event.length - 1] === ('All districts')) {
      setDistrictSelections(['All districts']);
    } else {
      let newEvent = [...event].filter(x => x !== 'All districts')
      setDistrictSelections(newEvent);
      setCountySelections(['All counties'])
    }
  };

  const handleCountyChange = (event) => {
    // setLocation('County')
    if (event[event.length - 1] === 'All counties') {
      setCountySelections(['All counties']);
    } else {
      let newEvent = [...event].filter(x => x !== 'All counties')
      setCountySelections(newEvent);
      setDistrictSelections(['All districts'])
    }
  };


  const handleSubmit = () => {
    let loSelection;
    let finalArray;

    let getStateLos = []
    stateSelections.forEach(ss => {
      allStateNames.forEach(state => {
        if (ss === state.name) {
          getStateLos.push(state.stateLocation)
        }
      })
    })
    if (stateSelections[0] === 'All states') {
      loSelection = 'State'
      finalArray = stateSelections
    } else if (districtSelections[0] === 'All districts' && countySelections[0] === 'All counties') {
      loSelection = 'State'
      finalArray = getStateLos
    } else if (districtSelections[0] !== 'All districts') {
      loSelection = 'District'
      finalArray = districtSelections
    } else if (countySelections[0] !== 'All counties') {
      loSelection = 'County'
      finalArray = countySelections
    } else if (stateSelections[0] !== 'All states') {
      loSelection = 'State'
      finalArray = getStateLos
    }

    props.filterCallback({
      name: "Location",
      filter: "Federal Cases",
      selection: loSelection,
      // stateLocation: 'National',
      value: finalArray,
      stateValue: stateSelections,
      districtValue: districtSelections,
      countyValue: countySelections
    });
    props.handleClose();
  };



  return (
    <div className={classes.container}>
      <div>
        <div className={classes.filterWrapper}>
          <div>
            <p
              className={tab === "State" ? classes.active : classes.inactive}
              onClick={() => setTab("State")}
            >
              State
              </p>
            <p
              className={tab === "District" ? classes.active : classes.inactive}
              onClick={() => setTab("District")}
            >
              District
              </p>
            <p
              className={tab === "County" ? classes.active : classes.inactive}
              onClick={() => setTab("County")}
            >
              County
              </p>
          </div>

          {tab === 'State' &&
            <div className={classes.checkboxes}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={stateList.map(s => s.name)}
                //disableCloseOnSelect
                onChange={(event, value) => handleStateChange(value)}
                value={stateSelections}
                getOptionLabel={(option) => option}
                renderOption={(option) => (
                  <React.Fragment>
                    <BlackCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={stateSelections.includes(option)}
                    />
                    {option}
                  </React.Fragment>
                )}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </div>
          }
          {tab === 'District' &&
            <div className={classes.checkboxes}>
              <Autocomplete
                disabled={stateSelections[0] === 'All states'}
                multiple
                id="checkboxes-tags-demo"
                options={updatedDistricts.map(d => d.district)}
                //disableCloseOnSelect
                onChange={(event, value) => handleDistrictChange(value)}
                value={districtSelections}
                getOptionLabel={(option) => option}
                renderOption={(option) => (
                  <React.Fragment>
                    <BlackCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={districtSelections.includes(option)}
                    />
                    {option}
                  </React.Fragment>
                )}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </div>
          }

          {tab === 'County' &&
            <div className={classes.checkboxes}>
              <Autocomplete
                disabled={stateSelections[0] === 'All states'}
                multiple
                id="checkboxes-tags-demo"
                options={updatedCounties.map(c => c.county)}
                //disableCloseOnSelect
                onChange={(event, value) => handleCountyChange(value)}
                value={countySelections}
                getOptionLabel={(option) => option}
                renderOption={(option) => (
                  <React.Fragment>
                    <BlackCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={countySelections.includes(option)}
                    />
                    {option}
                  </React.Fragment>
                )}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </div>
          }

          <div className={classes.saveButton}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}