export const dendrogramData = {
  id: "Indicators",
  children: [
    {
      id: "1. Housing and Caregiving",
      children: [
        {
          id: "1a. Runaway",
          value: 0,
          color: "#4E79A7",
        },
        {
          id: "1b. Unstable Housing",
          value: 0,
          color: "#4E79A7",
        },
        {
          id: "1c. Homelessness",
          value: 0,
          color: "#4E79A7",
        },
        {
          id: "1d. Needs Services",
          value: 0,
          color: "#4E79A7",
        },
        {
          id: "1e. Caregiver Supervision",
          value: 0,
          color: "#4E79A7",
        },
        {
          id: "1f. School Attendance",
          value: 0,
          color: "#4E79A7",
        },
        {
          id: "1g. Child Welfare System",
          value: 0,
          color: "#4E79A7",
        },
      ],
    },
    {
      id: "2. Prior Abuse or Trauma",
      children: [
        {
          id: "2a. Sexually Abused",
          value: 0,
          color: "#F28E2B",
        },
        {
          id: "2b. Physically Abused",
          value: 0,
          color: "#F28E2B",
        },

        {
          id: "2c. Emotionally Abused",
          value: 0,
          color: "#F28E2B",
        },
        {
          id: "2d. Witnessed DV",
          value: 0,
          color: "#F28E2B",
        },
      ],
    },
    {
      id: "3. Physical Health and Appearance",
      children: [
        {
          id: "3a. Changed Appearance",
          value: 0,
          color: "#E15759",
        },
        {
          id: "3b. Physical Trauma",
          value: 0,
          color: "#E15759",
        },

        {
          id: "3c. Tattoos",
          value: 0,
          color: "#E15759",
        },
        {
          id: "3d. Pregnancy or STIs",
          value: 0,
          color: "#E15759",
        },
        {
          id: "3e. Sleep Deprived",
          value: 0,
          color: "#E15759",
        },
        {
          id: "3f. Poor Nutrition",
          value: 0,
          color: "#E15759",
        },
        {
          id: "3g. Substance Use",
          value: 0,
          color: "#E15759",
        },
        {
          id: "3h. Change in Substance Use",
          value: 0,
          color: "#E15759",
        },
      ],
    },
    {
      id: "4. Environment and Exposure",
      children: [
        {
          id: "4a. Sexual Activities",
          value: 0,
          color: "#76B7B2",
        },
        {
          id: "4b. Proximity to Exploitation",
          value: 0,
          color: "#76B7B2",
        },

        {
          id: "4c. Language",
          value: 0,
          color: "#76B7B2",
        },
        {
          id: "4d. Associations",
          value: 0,
          color: "#76B7B2",
        },
        {
          id: "4e. Bullied",
          value: 0,
          color: "#76B7B2",
        },
        {
          id: "4f. LE/Juvenile Justice", // Law Enforcement/Juvenile Justice
          value: 0,
          color: "#76B7B2",
        },
        {
          id: "4g. Gangs",
          value: 0,
          color: "#76B7B2",
        },
      ],
    },
    {
      id: "5. Relationships and Personal Belongings",
      children: [
        {
          id: "5a. Unhealthy Relationships",
          value: 0,
          color: "#59A14E",
        },
        {
          id: "5b. Internet Contacts",
          value: 0,
          color: "#59A14E",
        },
        {
          id: "5c. Explicit Photos",
          value: 0,
          color: "#59A14E",
        },
        {
          id: "5d. Access to Money",
          value: 0,
          color: "#59A14E",
        },
        {
          id: "5e. Cell Phones",
          value: 0,
          color: "#59A14E",
        },
        {
          id: "5f. Travel",
          value: 0,
          color: "#59A14E",
        },
      ],
    },
    {
      id: "6. Signs of Current Trauma",
      children: [
        {
          id: "6a. On Edge",
          value: 0,
          color: "#EDC949",
        },
        {
          id: "6b. Danger Cues",
          value: 0,
          color: "#EDC949",
        },

        {
          id: "6c. Self Destructive",
          value: 0,
          color: "#EDC949",
        },
        {
          id: "6d. Cell Phone Distress",
          value: 0,
          color: "#EDC949",
        },
      ],
    },
    {
      id: "7. Coercion",
      children: [
        {
          id: "7a. Controlling Partner",
          value: 0,
          color: "#B07AA2",
        },
        {
          id: "7b. Socially Isolated",
          value: 0,
          color: "#B07AA2",
        },

        {
          id: "7c. Reproductive Coercion",
          value: 0,
          color: "#B07AA2",
        },
        {
          id: "7d. Sleep Control",
          value: 0,
          color: "#B07AA2",
        },
        {
          id: "7e. Threats",
          value: 0,
          color: "#B07AA2",
        },
        {
          id: "7f. Information Gaps",
          value: 0,
          color: "#B07AA2",
        },
      ],
    },
    {
      id: "8. Exploitation",
      children: [
        {
          id: "8a. Exchanging Sex",
          value: 0,
          color: "#FF9DA7",
        },
        {
          id: "8b. Filmed",
          value: 0,
          color: "#FF9DA7",
        },
        {
          id: "8c. History of Exploitation",
          value: 0,
          color: "#FF9DA7",
        },
        {
          id: "8d. Force to Give Money",
          value: 0,
          color: "#FF9DA7",
        },
      ],
    },
  ],
};
