export const ccgraphdata = {
    //Referalls
    totalReferralArray: [ 
        { 
            variable : "rs_carecoordinator_cac" ,
            name: "Care Coordinator/CAC",
            color: "#4E79A7"
        },
        { 
            variable : "rs_dfps",
            name: "DFPS",
            color: "#F28E2B"
        }, 
        {
            variable : "rs_lawenforcement" ,
            name: "Law Enforcement",
            color: "#E15759"
        },  
        { 
            variable : "rs_partner_cst" ,
            name: "CSEY Advocate",
            color: "#76B7B2"
        },
        { 
            variable : "rs_partner_jj" ,
            name: "Juvenile Justice",
            color: "#59A14E"
        },  
        {
            variable : "rs_other" ,
            name: "Other (all others)",
            color: "#EDC949"
        }
    ], 
    totalDfpsCases : [ {
        variable:  "rs_open_dfps",
        color: "#4E79A7",
        name: "Individuals with open DFPS case"
    },{
        variable:  "rs_prev_dfps",
        color: "#A0CBE8",
        name: "Individuals with previous open DFPS case"
    }],
    referrals: [ 
        { 
            variable : "rs_dfps",
            name: "DFPS",
            color: "#4E79A7"
        }, { variable : "rs_cbc" ,
            name: "CBC",
            color: "#A0CBE8"
        }, { variable : "rs_carecoordinator_cac" ,
            name: "CC/CAC",
            color: "#F28E2B"
        }, { variable : "rs_partner_jj" ,
            name: "Juvenile Justice",
            color: "#FFBE7D"
        }, { variable : "rs_partner_cst" ,
            name: "CSEY Advocate",
            color: "#59A14E"
        }, {variable : "rs_lawenforcement" ,
            name: "Law Enforcement",
            color: "#8CD17D"
        }, {
            variable : "rs_districtattorney" ,
            name: "District Attorney",
            color: "#B6992D"
        }, {
            variable : "rs_dropincenter" ,
            name: "Drop-In Center",
            color: "#499894"
        }, {
            variable : "rs_shelter" ,
            name: "Shelter",
            color: "#86BCB6"
        }, {
            variable : "rs_medical" ,
            name: "Medical",
            color: "#E15759"
        }, {
            variable : "rs_other_carecoordinator" ,
            name: "Other CCT Partner",
            color: "#FF9D9A"
        }, {
            variable : "rs_noncct_provider" ,
            name: "Non-CCT Provider",
            color: "#79706E"
        }, {
            variable : "rs_hotline" ,
            name: "Hotline",
            color: "#BAB0AC"
        }, {
            variable : "rs_transfer" ,
            name: "Transfer from Other CC",
            color: "#D37295"
        }, {
            variable : "rs_parent_caregiver_legal_guardian" ,
            name: "Caregiver, Legal Guardian",
            color: "#FABFD2"
        }, {
            variable : "rs_long_term_residential_service_provider" ,
            name: "Long Term Residential",
            color: "#B07AA1"
        }, {
            variable : "rs_other" ,
            name: "Other",
            color: "#D4A6C8"
        }
    ],
    referralSources: [ 
        { 
            variable : "rs_dfps",
            name: "DFPS",
            color: "#4E79A7"
        }, { variable : "rs_cbc" ,
            name: "CBC",
            color: "#A0CBE8"
        }, { variable : "rs_carecoordinator_cac" ,
            name: "CC/CAC",
            color: "#F28E2B"
        }, { variable : "rs_partner_jj" ,
            name: "Juvenile Justice",
            color: "#FFBE7D"
        }, { variable : "rs_partner_cst" ,
            name: "CSEY Advocate",
            color: "#59A14E"
        }, {variable : "rs_lawenforcement" ,
            name: "Law Enforcement",
            color: "#8CD17D"
        }, {
            variable : "rs_districtattorney" ,
            name: "District Attorney",
            color: "#B6992D"
        }, {
            variable : "rs_dropincenter" ,
            name: "Drop-In Center",
            color: "#499894"
        }, {
            variable : "rs_shelter" ,
            name: "Shelter",
            color: "#86BCB6"
        }, {
            variable : "rs_medical" ,
            name: "Medical",
            color: "#E15759"
        }, {
            variable : "rs_other_carecoordinator" ,
            name: "Other CCT Partner",
            color: "#FF9D9A"
        }, {
            variable : "rs_noncct_provider" ,
            name: "Non-CCT Provider",
            color: "#79706E"
        }, {
            variable : "rs_hotline" ,
            name: "Hotline",
            color: "#BAB0AC"
        }, {
            variable : "rs_transfer" ,
            name: "Transfer from Other CC",
            color: "#D37295"
        }, {
            variable : "rs_parent_caregiver_legal_guardian" ,
            name: "Caregiver, Legal Guardian",
            color: "#FABFD2"
        }, {
            variable : "rs_long_term_residential_service_provider" ,
            name: "Long Term Residential",
            color: "#B07AA1"
        }, {
            variable : "rs_other" ,
            name: "Other",
            color: "#D4A6C8"
        }
    ],
    referralsByType : [ {
        variable:  "rs_total_cst",
        color: "#4E79A7",
        name: "CSEY Referrals"
    },{
        variable:  "rs_total_hry",
        color: "#F28E2B",
        name: "HRY Referrals"
    },{
        variable:  "rs_total_noconcern",
        color: "#E15759",
        name: "No Concern/Not Enough Information Referrals"
    }],
    totalInCC: [{
        variable: "irp_newyouth",
        color: "#4E79A7",
        name: "Total in CC"
    }], 
    newAndActiveYouth: [{
        variable: "irp_youthactivecare",
        color: "#4E79A7",
        name: "Individuals In Active CC"
    },{
        variable: "irp_newyouth",
        color: "#A0CBE8",
        name: "Individuals Newly Admitted into CC", 
    }], 
    identifiedAndRecovered: [{
        variable: "irp_identified",
        color: "#4E79A7",
        name: "Identified Presumed Victims"
    },{
        variable: "irp_recovered",
        color: "#F28E2B",
        name: "Recovered Presumed Victims", 
    },{
        variable: "irp_rerecovered",
        color: "#E15759",
        name: "Re-Recovery Events", 
    }],
    identified72: [{
        variable: "irp_parentlegal72",
        color: "#4E79A7",
        name: "Parent/Legal Guardian"
    },{
        variable: "irp_unpaidkinship72",
        color: "#A0CBE8",
        name: "Unpaid Kinship", 
    },{
        variable: "irp_paidrelativefictivekinship72",
        color: "#F28E2B",
        name: "Paid Relative", 
    },{
        variable: "irp_emergencyshelter72",
        color: "#FFBE7D",
        name: "Emergency Shelter"
    },{
        variable: "irp_foster72",
        color: "#59A14E",
        name: "Foster", 
    },{
        variable: "irp_residentialtreatment72",
        color: "#8CD17D",
        name: "Residential Treatment", 
    },{
        variable: "irp_psychiatrichospital72",
        color: "#B6992D",
        name: "Psychiatric Hospital", 
    },{
        variable: "irp_juveniledetention72",
        color: "#499894",
        name: "Juvenile Detention", 
    },{
        variable: "irp_adultjail72",
        color: "#86BCB6",
        name: "Adult Jail", 
    },{
        variable: "irp_adultresidential72",
        color: "#E15759",
        name: "Adult Residential", 
    },{
        variable: "irp_cpsgov72",
        color: "#FF9D9A",
        name: "CPS", 
    },{
        variable: "irp_dropincenter72",
        color: "#79706E",
        name: "Drop-In Center", 
    },{
        variable: "irp_other72",
        color: "#BAB0AC",
        name: "Other", 
    }], 
    identified72Array: [{
        variable: "irp_parentlegal72",
        color: "#4E79A7",
        name: "Parent/Legal Guardian"
    },{
        variable: "irp_unpaidkinship72",
        color: "#A0CBE8",
        name: "Unpaid Kinship", 
    },{
        variable: "irp_paidrelativefictivekinship72",
        color: "#F28E2B",
        name: "Paid Relative", 
    },{
        variable: "irp_emergencyshelter72",
        color: "#FFBE7D",
        name: "Emergency Shelter"
    },{
        variable: "irp_foster72",
        color: "#59A14E",
        name: "Foster", 
    },{
        variable: "irp_residentialtreatment72",
        color: "#8CD17D",
        name: "Residential Treatment", 
    },{
        variable: "irp_psychiatrichospital72",
        color: "#B6992D",
        name: "Psychiatric Hospital", 
    },{
        variable: "irp_juveniledetention72",
        color: "#499894",
        name: "Juvenile Detention", 
    },{
        variable: "irp_adultjail72",
        color: "#86BCB6",
        name: "Adult Jail", 
    },{
        variable: "irp_adultresidential72",
        color: "#E15759",
        name: "Adult Residential", 
    },{
        variable: "irp_cpsgov72",
        color: "#FF9D9A",
        name: "CPS", 
    },{
        variable: "irp_dropincenter72",
        color: "#79706E",
        name: "Drop-In Center", 
    },{
        variable: "irp_other72",
        color: "#BAB0AC",
        name: "Other", 
    }], 
    totalPlacedNotPlaced72: [
        {
            variable: "irp_total72",
            color: "#4E79A7",
            name: "Total Individuals Placed", 
        },{
            variable: "irp_notplaced72",
            color: "#A0CBE8",
            name: "Total Individuals Not Placed", 
        }
    ],
    identified15: [{
        variable: "irp_parentlegal15",
        color: "#4E79A7",
        name: "Parent/Legal Guardian"
    },{
        variable: "irp_unpaidkinship15",
        color: "#A0CBE8",
        name: "Unpaid Kinship", 
    },{
        variable: "irp_paidrelativefictivekinship15",
        color: "#F28E2B",
        name: "Paid Relative", 
    },{
        variable: "irp_emergencyshelter15",
        color: "#FFBE7D",
        name: "Emergency Shelter"
    },{
        variable: "irp_foster15",
        color: "#59A14E",
        name: "Foster", 
    },{
        variable: "irp_residentialtreatment15",
        color: "#8CD17D",
        name: "Residential Treatment", 
    },{
        variable: "irp_psychiatrichospital15",
        color: "#B6992D",
        name: "Psychiatric Hospital", 
    },{
        variable: "irp_juveniledetention15",
        color: "#499894",
        name: "Juvenile Detention", 
    },{
        variable: "irp_adultjail15",
        color: "#86BCB6",
        name: "Adult Jail", 
    },{
        variable: "irp_adultresidential15",
        color: "#E15759",
        name: "Adult Residential", 
    },{
        variable: "irp_cpsgov15",
        color: "#FF9D9A",
        name: "CPS", 
    },{
        variable: "irp_dropincenter15",
        color: "#79706E",
        name: "Drop-In Center", 
    },{
        variable: "irp_other15",
        color: "#BAB0AC",
        name: "Other", 
    }],
    identified15Array: [{
        variable: "irp_parentlegal15",
        color: "#4E79A7",
        name: "Parent/Legal Guardian"
    },{
        variable: "irp_unpaidkinship15",
        color: "#A0CBE8",
        name: "Unpaid Kinship", 
    },{
        variable: "irp_paidrelativefictivekinship15",
        color: "#F28E2B",
        name: "Paid Relative", 
    },{
        variable: "irp_emergencyshelter15",
        color: "#FFBE7D",
        name: "Emergency Shelter"
    },{
        variable: "irp_foster15",
        color: "#59A14E",
        name: "Foster", 
    },{
        variable: "irp_residentialtreatment15",
        color: "#8CD17D",
        name: "Residential Treatment", 
    },{
        variable: "irp_psychiatrichospital15",
        color: "#B6992D",
        name: "Psychiatric Hospital", 
    },{
        variable: "irp_juveniledetention15",
        color: "#499894",
        name: "Juvenile Detention", 
    },{
        variable: "irp_adultjail15",
        color: "#86BCB6",
        name: "Adult Jail", 
    },{
        variable: "irp_adultresidential15",
        color: "#E15759",
        name: "Adult Residential", 
    },{
        variable: "irp_cpsgov15",
        color: "#FF9D9A",
        name: "CPS", 
    },{
        variable: "irp_dropincenter15",
        color: "#79706E",
        name: "Drop-In Center", 
    },{
        variable: "irp_other15",
        color: "#BAB0AC",
        name: "Other", 
    }], 
    totalPlacedNotPlaced15: [ {
        variable: "irp_total15",
        color: "#4E79A7",
        name: "Total Individuals Placed", 
    },{
        variable: "irp_notfollow15",
        color: "#A0CBE8",
        name: "Total Individuals Not Placed", 
    }],
    meetingsHeld: [{
        variable: "msc_rrmeeting",
        color: "#4E79A7",
        name: "Rapid Response Meeting", 
    },{
        variable: "msc_ccmeeting",
        color: "#F28E2B",
        name: "Initial CC Meeting", 
    }, {
        variable: "msc_quartermeetings",
        color: "#E15759",
        name: "Follow-up CC Meeting", 
    }], 
    casesClosed: [{
        variable: "msc_declinedsupport",
        color: "#4E79A7",
        name: "Legal guardian declined all support services", 
    }, {
        variable: "msc_caseclosure",
        color: "#A0CBE8",
        name: "Individual was engaged with one or more services", 
    }], 
    dfps: [{
        variable: "msc_dfpsorfbss",
        color: "#4E79A7",
        name: "Individuals who entered DFPS Conservatorship or FBSS", 
    }], 
    meetingTypes: [{
        variable: "msc_rrtmeetings",
        color: "#4E79A7",
        name: "RR Meetings", 
    }, {
        variable: "msc_cstcasereviews",
        color: "#F28E2B",
        name: "CST case reviews (investigative)", 
    }, {
        variable: "msc_familyfocusedmeetingscaregiver",
        color: "#E15759",
        name: "FFTM", 
    }, {
        variable: "msc_familyfocusedmeetingsyouth",
        color: "#76B7B2",
        name: "FFTM With Youth", 
    }, {
        variable: "msc_familyfocusedmeetingsnoyouth",
        color: "#59A14E",
        name: "FFTM Without Youth", 
    }, {
        variable: "msc_servicestatusmeetings",
        color: "#EDC949",
        name: "Service Status Meetings", 
    }], 
    meetingTypesOverTime: [{
        variable: "msc_rrtmeetings",
        color: "#4E79A7",
        name: "Rapid Response", 
    }, {
        variable: "msc_cstcasereviews",
        color: "#F28E2B",
        name: "CST case reviews (investigative)", 
    }, {
        variable: "msc_familyfocusedmeetingscaregiver",
        color: "#E15759",
        name: "FFTM", 
    }, {
        variable: "msc_familyfocusedmeetingsyouth",
        color: "#76B7B2",
        name: "FFTM With Youth", 
    }, {
        variable: "msc_familyfocusedmeetingsnoyouth",
        color: "#59A14E",
        name: "FFTM Without Youth", 
    }, {
        variable: "msc_servicestatusmeetings",
        color: "#EDC949",
        name: "Service Status", 
    }], 
    meetingTypesTotal: [{
        variable: "msc_rrtmeetings",
        color: "#4E79A7",
        name: "RR Meetings", 
    }, {
        variable: "msc_cstcasereviews",
        color: "#F28E2B",
        name: "CST case reviews (investigative)", 
    }, {
        variable: "msc_familyfocusedmeetingscaregiver",
        color: "#E15759",
        name: "FFTM", 
    }, {
        variable: "msc_familyfocusedmeetingsyouth",
        color: "#76B7B2",
        name: "FFTM With Youth", 
    }, {
        variable: "msc_familyfocusedmeetingsnoyouth",
        color: "#59A14E",
        name: "FFTM Without Youth", 
    }, {
        variable: "msc_servicestatusmeetings",
        color: "#EDC949",
        name: "Service Status Meetings", 
    }], 
    forensicInterviews: [{
        variable: "irp_forensicinterview",
        color: "#4E79A7",
        name: "Number of individuals who participated in a forensic interview", 
    }, {
        variable: "msc_cstcasereviews",
        color: "#A0CBE8",
        name: "Number of CST case reviews", 
    }], 
    totalLocationDays: [{
        variable: "ld_totallocationdays",
        color: "#4E79A7",
        name: "Total Days in Care Coordination", 
    }], 
    locationsDaysType: [{
        variable: "ld_missingdays",
        color: "#E15759",
        name: "Missing Days", 
    }, {
        variable: "ld_detentiondays",
        color: "#EDC948",
        name: "Detention Days", 
    }, {
        variable: "ld_paidday",
        color: "#4E79A7",
        name: "Paid Days", 
    }, {
        variable: "ld_unpaiddays",
        color: "#59A14E",
        name: "Unpaid Days", 
    }],
    locationDaysByType: [{
        variable: "ld_missingdays",
        color: "#E15759",
        name: "Missing Days", 
    }, {
        variable: "ld_detentiondays",
        color: "#EDC948",
        name: "Detention Days", 
    }, {
        variable: "ld_paidday",
        color: "#4E79A7",
        name: "Paid Days", 
    }, {
        variable: "ld_unpaiddays",
        color: "#59A14E",
        name: "Unpaid Days", 
    }],
    placements: [{
        variable: "om_recovered",
        color: "#4E79A7",
        name: "Number of individuals who do not run from placement for at least 72 hours", 
    },{
        variable: "irp_total72",
        color: "#A0CBE8",
        name: "Number of individuals returned or placed within 72 hours", 
    }],
    guardianTypes: [
    {
        variable: "om_guardians",
        color: "#4E79A7",
        name: "Total Guardians",
    },{
        variable: "om_guradian_dfps_fbs",
        color: "#F28E2B",
        name: "DFPS Conservatorship", 
    }, {
        variable: "om_guardian_parentcaregiver",
        color: "#E15759",
        name: "Parent/Caregiver Legal Guardian", 
    }],
}