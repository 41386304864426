/* eslint-disable no-unused-vars */
import React, { useEffect, Fragment } from "react";
import SingleCard from "../../../../Components/Card";
//import { StackedBarGraph } from "../GraphComponents/StackedBarGraph";
import { StackedBarVariableXGraph } from "../../GraphComponents/StackedBarVariableXAxis";

import {latraflocationdata} from "../../GraphComponents/GraphVariables/LATrafLocationData"
import CustomMeasuresTable from '../Services/Tables/CustomMeasuresTable'
//import {laservicesmockdata} from "./LAServicesData"
import HorizontalBarChart from '../../GraphComponents/HorizontalBarChart';

import HorizontalTable from '../Services/Louisiana/Tables/HorizontalTable';


export default function TrafLocations(props) {
 const [customData, setCustomData] = React.useState([]);

  // const addToReports = (data) => {
  //   props.addToReportPage(data);
  // };

  useEffect(() => {
    if (props.data) {
      console.log('props.data', props.data)
      setCustomData(props.data)
    }
  }, [props.data])

  const renderAllCharts = () => {
    return (
      <div>
         <SingleCard
              width="100%"
          id="Trafficking Locations Parish of Trafficking"
          title="Trafficking Locations  >  Parish of Trafficking"
              graph={
              <HorizontalBarChart
                  data={customData.parishTrafficking}
                  value="b_location_of_trafficking"
                  xaxis="Number of Locations"
                  yaxis="Location"
              />
              }
              definitions={[["Parish of Trafficking", "Indicates the parish in which trafficking occurred, including multiple locations, out of state, out of country, and unknown."]]}
          table={<HorizontalTable data={props.data.parishTraffickingTableData} reverseOrder={true} id ='Parish'/>}
              addToReports={props.addToReportPage}
          />
           <SingleCard
              width="100%"
          id="Trafficking Locations Parish of Origin"
          title="Trafficking Locations  >  Parish of Origin"
              graph={
              <HorizontalBarChart
                  data={customData.parishOrigin}
                  value="b_location_of_origin"
                  xaxis="Number of Locations"
                  yaxis="Locations"
              />
              }
              definitions={[["Parish of Origin", "Indicates the parish the victim identifies as their principal location; may include multiple locations, out of state, and unknown."]]}
          table={<HorizontalTable data={props.data.parishOriginTableData} reverseOrder={true} id ='Parish'/>}
              addToReports={props.addToReportPage}
          />
          <SingleCard
            width="100%"
          id="Trafficking Locations Parish of Trafficking Over Time (Top Ten)"
          title="Trafficking Locations  >  Parish of Trafficking Over Time (Top Ten)"
            graph={
              <StackedBarVariableXGraph
                data ={customData.parishTraffickingTime}
                xaxis="Year"
                yaxis="Number of Parishes"
                keys={latraflocationdata.parishTraffickingTime}
              />}
          definitions={[["Parish of Trafficking Over Time (Top Ten)", "Indicates top ten parishes of trafficking. The percent is the number of instances by parish out of all parish of trafficking values. This chart can only be filtered by Age; other filters are not applicable."]]}
            table={<CustomMeasuresTable showTopTenOnly data={customData.parishTraffickingTime}  keys={latraflocationdata.parishTraffickingTime} id="Parish" ordered={true}/>}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            width="100%"
          id="Trafficking Locations Parish of Origin Over Time (Top Ten)"
          title="Trafficking Locations  >  Parish of Origin Over Time (Top Ten)"
            graph={
              <StackedBarVariableXGraph
                data ={customData.parishOriginTime}
                xaxis="Year"
                yaxis="Number of Parishes"
                keys={latraflocationdata.parishOriginTime}
              />}
          table={<CustomMeasuresTable showTopTenOnly data={customData.parishOriginTime}  keys={latraflocationdata.parishOriginTime} ordered={true} id="Parish"/>}
          definitions={[["Parish of Origin Over Time (Top Ten)", "Indicates top ten parishes of origin. The percent is the number of instances by parish out of all parish of origin values. This chart can only be filtered by Age; other filters are not applicable."]]}
            addToReports={props.addToReportPage}
          />

      </div>
    );
  }

  const renderSingleChart = () => {
    const charts = renderAllCharts();
    const singleCards = charts.props.children.props.children;

    const index = singleCards.findIndex((card) => card.props.id === props.chartId);
    if (index <= -1) {
      return <Fragment></Fragment>;
    }

    const elementToRender = singleCards[index];
    return <SingleCard
        {...elementToRender.props}
        title={props.chartTitle || props.title}
        description={props.chartInsight || ""}
        shouldUseNewStyles={true}
    />;
  }

  return (
      props.chartId
        ? renderSingleChart()
        : renderAllCharts()
  );
}
