export const raceagealldata = [
    {
      id: "African American or Black",
      children: [
        // {
        //   id: "0-24",
        //   value: 0,
        // },
        {
          id: "25-29",
          value: 0,
        },
        {
          id: "30-39",
          value: 0,
        },
        {
          id: "40-49",
          value: 0,
        },
        {
          id: "50-59",
          value: 0,
        },
        {
          id: "60-69",
          value: 0,
        },
        {
          id: "70-79",
          value: 0,
        },
        {
          id: "80+",
          value: 0,
        },
      ],
    },
    {
      id: "American Indian or Alaska Native",
      children: [
        // {
        //     id: "0-24",
        //     value: 0,
        //   },
          {
            id: "25-30",
            value: 0,
          },
          {
            id: "31-40",
            value: 0,
          },
          {
            id: "41-50",
            value: 0,
          },
          {
            id: "51-60",
            value: 0,
          },
          {
            id: "61-70",
            value: 0,
          },
          {
            id: "71-80",
            value: 0,
          },
          {
            id: "81-90",
            value: 0,
          },
          {
            id: "91-100",
            value: 0,
          },
      ],
    },
    {
      id: "Asian",
      children: [
        // {
        //   id: "0-24",
        //   value: 0,
        // },
        {
          id: "25-30",
          value: 0,
        },
        {
          id: "31-40",
          value: 0,
        },
        {
          id: "41-50",
          value: 0,
        },
        {
          id: "51-60",
          value: 0,
        },
        {
          id: "61-70",
          value: 0,
        },
        {
          id: "71-80",
          value: 0,
        },
        {
          id: "81-90",
          value: 0,
        },
        {
          id: "91-100",
          value: 0,
        },
      ],
    },
    {
      id: "Middle Eastern or North African",
      children: [
        // {
        //     id: "0-24",
        //     value: 0,
        //   },
          {
            id: "25-30",
            value: 0,
          },
          {
            id: "31-40",
            value: 0,
          },
          {
            id: "41-50",
            value: 0,
          },
          {
            id: "51-60",
            value: 0,
          },
          {
            id: "61-70",
            value: 0,
          },
          {
            id: "71-80",
            value: 0,
          },
          {
            id: "81-90",
            value: 0,
          },
          {
            id: "91-100",
            value: 0,
          },
      ],
    },
    {
      id: "Multiracial",
      children: [
        // {
        //     id: "0-24",
        //     value: 0,
        //   },
          {
            id: "25-30",
            value: 0,
          },
          {
            id: "31-40",
            value: 0,
          },
          {
            id: "41-50",
            value: 0,
          },
          {
            id: "51-60",
            value: 0,
          },
          {
            id: "61-70",
            value: 0,
          },
          {
            id: "71-80",
            value: 0,
          },
          {
            id: "81-90",
            value: 0,
          },
          {
            id: "91-100",
            value: 0,
          },
      ],
    },
    {
      id: "Native Hawaiian or Other Pacific Islander",
      children: [
        // {
        //     id: "0-24",
        //     value: 0,
        //   },
          {
            id: "25-30",
            value: 0,
          },
          {
            id: "31-40",
            value: 0,
          },
          {
            id: "41-50",
            value: 0,
          },
          {
            id: "51-60",
            value: 0,
          },
          {
            id: "61-70",
            value: 0,
          },
          {
            id: "71-80",
            value: 0,
          },
          {
            id: "81-90",
            value: 0,
          },
          {
            id: "91-100",
            value: 0,
          },
      ],
    },
    {
      id: "Other",
      children: [
        // {
        //     id: "0-24",
        //     value: 0,
        //   },
          {
            id: "25-30",
            value: 0,
          },
          {
            id: "31-40",
            value: 0,
          },
          {
            id: "41-50",
            value: 0,
          },
          {
            id: "51-60",
            value: 0,
          },
          {
            id: "61-70",
            value: 0,
          },
          {
            id: "71-80",
            value: 0,
          },
          {
            id: "81-90",
            value: 0,
          },
          {
            id: "91-100",
            value: 0,
          },
      ],
    },
    {
      id: "Unknown",
      children: [
        // {
        //     id: "0-24",
        //     value: 0,
        //   },
          {
            id: "25-30",
            value: 0,
          },
          {
            id: "31-40",
            value: 0,
          },
          {
            id: "41-50",
            value: 0,
          },
          {
            id: "51-60",
            value: 0,
          },
          {
            id: "61-70",
            value: 0,
          },
          {
            id: "71-80",
            value: 0,
          },
          {
            id: "81-90",
            value: 0,
          },
          {
            id: "91-100",
            value: 0,
          },
      ],
    },
    {
      id: "White or Caucasian",
      children: [
        // {
        //     id: "0-24",
        //     value: 0,
        //   },
          {
            id: "25-30",
            value: 0,
          },
          {
            id: "31-40",
            value: 0,
          },
          {
            id: "41-50",
            value: 0,
          },
          {
            id: "51-60",
            value: 0,
          },
          {
            id: "61-70",
            value: 0,
          },
          {
            id: "71-80",
            value: 0,
          },
          {
            id: "81-90",
            value: 0,
          },
          {
            id: "91-100",
            value: 0,
          },
      ],
    },
  ];
  