export const latraflocationdata = {
    parishTraffickingTime: [
            { 
                variable : "Unknown" ,
                name: "Unknown",
                color: "#4E79A7"
            },
            { 
                variable : "Orleans" ,
                name: "Orleans",
                color: "#A0CBE8"
            },
            { 
                variable : "Caddo" ,
                name: "Caddo",
                color: "#F28E2B"
            },
            {
                variable : "East Baton Rouge" ,
                name: "East Baton Rouge",
                color: "#FFBE7D"
            },
            { 
                variable : "Multiple" ,
                name: "Multiple",
                color: "#59A14E"
            },
            { 
                variable : "Jefferson" ,
                name: "Jefferson",
                color: "#B6992D"
            },
            {
                variable : "Out of State" ,
                name: "Out of State",
                color: "#8CD17D"
            },
            {
                variable : "Bossier" ,
                name: "Bossier",
                color: "#499894"
            },
            {
                variable : "St. Tammany" ,
                name: "St. Tammany",
                color: "#86BCB6"
            },
            {
                variable : "Ascension" ,
                name: "Ascension",
                color: "#E15759"
            },
            {
                variable : "Ouachita" ,
                name: "Ouachita",
                color: "#FF9D9A"
            },
            {
                variable : "Lincoln" ,
                name: "Lincoln",
                color: "#79706E"
            },
            {
                variable : "Plaquemines" ,
                name: "Plaquemines",
                color: "#BAB0AC"
            },
            {
                variable : "Rapides" ,
                name: "Rapides",
                color: "#D37295"
            },
            {
                variable : "Livingston" ,
                name: "Livingston",
                color: "#FABFD2"
            },{
                variable: "Calcasieu",
                name: "Calcasieu",
                color: "#B07AA1"
            },
            {
                variable: "Lafayette",
                name: "Lafayette",
                color: "#D4A6C8"
        },
        {
                variable : "Terrebonne" ,
                name: "Terrebonne",
            color: "#edcae3"
            },
        ], 
    parishOriginTime : [
        { 
            variable : "Unknown" ,
            name: "Unknown",
            color: "#4E79A7"
        },
        { 
            variable : "Orleans" ,
            name: "Orleans",
            color: "#A0CBE8"
        },
        { 
            variable : "Caddo" ,
            name: "Caddo",
            color: "#F28E2B"
        },
        {
            variable : "East Baton Rouge" ,
            name: "East Baton Rouge",
            color: "#FFBE7D"
        },
        { 
            variable : "Multiple" ,
            name: "Multiple",
            color: "#59A14E"
        },
        {
            variable : "Out of State" ,
            name: "Out of State",
            color: "#8CD17D"
        },
        { 
            variable : "Jefferson" ,
            name: "Jefferson",
            color: "#B6992D"
        },
        {
            variable : "Bossier" ,
            name: "Bossier",
            color: "#499894"
        },
        {
            variable : "St. Tammany" ,
            name: "St. Tammany",
            color: "#86BCB6"
        },
        {
            variable : "Ouachita" ,
            name: "Ouachita",
            color: "#E15759"
        },
        {
            variable : "Lincoln" ,
            name: "Lincoln",
            color: "#FF9D9A"
        },
        {
            variable : "Plaquemines" ,
            name: "Plaquemines",
            color: "#79706E"
        },
        {
            variable : "Rapides" ,
            name: "Rapides",
            color: "#BAB0AC"
        },
        {
            variable : "Tangipahoa" ,
            name: "Tangipahoa",
            color: "#D37295"
        },
        {
            variable: "Calcasieu",
            name: "Calcasieu",
            color: "#FABFD2"
        },
        {
            variable: "Lafayette",
            name: "Lafayette",
            color: "#B07AA1"
        },
        {
            variable: "Terrebonne",
            name: "Terrebonne",
            color: "#edcae3"
        },
    ], 
}



 
