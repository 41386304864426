import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import { useAppContext } from "../../../../Lib/UserContext";
//import { orgList } from "../../../Data/orgList";
import { withStyles } from '@material-ui/core/styles';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        fontWeight: '300',
        overflowY: 'scroll'
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer'
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function LocationTypeFilter(props) {
  const classes = useStyles();
  const [filterSelections, setFilterSelections] = React.useState([]);
  const { stateLocation } = useAppContext();

  const handleCheckboxChange = (e, item) => {
    setFilterSelections(item);
  }

  const handleSubmit = () => {
    props.filterCallback({
      name: 'Location ',
      filter: 'Services',
      selection: filterSelections,
      value: [filterSelections],
      stateLocation: 'TX'
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      if (props.selectedFilters[i].name === 'Location ' && props.selectedFilters[i].filter === 'Services' &&  props.selectedFilters[i].stateLocation === 'TX'){
        setFilterSelections(props.selectedFilters[i].value)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
        <div className={classes.container}>
          <div className={classes.checkboxes}>
            <FormControlLabel className="checkboxLabel"
              control={ 
                <BlackCheckbox 
                  checked={filterSelections.includes("State")} 
                  onChange={e => handleCheckboxChange(e, "State")} name="checkedA" />
                }
              label={"State"}
            />
            {stateLocation === 'TX' ? (
              <FormControlLabel className="checkboxLabel"
                control={ 
                  <BlackCheckbox 
                    checked={filterSelections.includes("County")} 
                    onChange={e => handleCheckboxChange(e, "County")} name="checkedA" />
                  }
                label={"County"}
              />
              ) : (
              <FormControlLabel className="checkboxLabel"
              control={ 
                <BlackCheckbox 
                  checked={filterSelections.includes("County")} 
                  onChange={e => handleCheckboxChange(e, "County")} name="checkedA" />
                }
              label={"Parish"}
              />
              )}
            <FormControlLabel className="checkboxLabel"
              control={ 
                <BlackCheckbox 
                  checked={filterSelections.includes("Region")} 
                  onChange={e => handleCheckboxChange(e, "Region")} name="checkedA" />
                }
              label={"Region"}
            />
        </div>
       
        <div className={classes.saveButton}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    )
}