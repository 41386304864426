import React, { useEffect } from "react";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function CasesByYearTable(props) {
  const classes = useStyles();
  const [customData, setCustomData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [total1, setTotal1] = React.useState(0);
  const [total2, setTotal2] = React.useState(0);
  const [total3, setTotal3] = React.useState(0);
  const [total4, setTotal4] = React.useState(0);
  const [totalRowCellCount, settotalRowCellCount] = React.useState([]);

  useEffect(() => {
    setCustomData(props.data)
      // Total row needs to have the same amount of columns as all the other rows
    settotalRowCellCount(props.columnVars?.[0].concat(props.columnVars?.[1]) ?? ['1', '2'])
    //let calculateRows = props.columnVars?.[0].concat(props.columnVars?.[1]) ?? ['1', '2'];
    let allTotal = 0
    let allTotal1 = 0
    let allTotal2 = 0
    let allTotal3 = 0
    let allTotal4 = 0

    if (props.differentTotalVars) {
      let diffTotalNums1 = []
      let diffTotalNums2 = []
      let diffTotalNums3 = []
      let diffTotalNums4 = []
      props.data.forEach(dataObj => {
        diffTotalNums1.push(dataObj[props.columnVars[1][0]])
        diffTotalNums2.push(dataObj[props.columnVars[1][1]])
        diffTotalNums3.push(dataObj[props.columnVars[1][2]])
        diffTotalNums4.push(dataObj[props.columnVars[1][3]])
      })
      allTotal1 = d3.sum(diffTotalNums1);
      allTotal2 = d3.sum(diffTotalNums2);
      allTotal3 = d3.sum(diffTotalNums3);
      allTotal4 = d3.sum(diffTotalNums4);
      setTotal1(allTotal1)
      setTotal2(allTotal2)
      setTotal3(allTotal3)
      setTotal4(allTotal4)
    } else {
      allTotal = d3.sum(
        props.data.map((d) => {
          return d.total;
        })
      );
      setTotal(allTotal)
    }
    
    console.log(total1, total2, total3, total4)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, props.data])

  return (
    <TableContainer component={Paper} id={`${props.id}`}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.columnHeaders.map((header, index) => (
              <TableCell key={header} className="table-header" align={index !== 0 ? 'center' : 'inherit'}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {customData.map((item, key) => {
            return (
              <TableRow key={key}>
                <TableCell>{item[props.columnVars[0]]}</TableCell>

                {props.columnVars[1].map(cVar => (
                  <TableCell key={item[cVar]} align="center">{item[cVar]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</TableCell>
                ))}
              </TableRow>
            )
          })}
          <TableRow>
            {totalRowCellCount?.map((item, index) => (
              <TableCell
                key={`${item}${index}`}
                className={index === 0 || index === totalRowCellCount.length - 1 ? "table-header" : ''}
                align={index !== 0 ? "center" : 'inherit'}
              >
              {props.differentTotalVars ? (
                <div className={"table-header"}>
                  {
                    index === 0 ? 'Total' :
                    index === 1 ? total1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 
                    index === 2 ? total2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 
                    index === 3 ? total3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 
                    index === 4 ? total4.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : ``
                  } 
                </div>
                ) : 
                <div className={"table-header"}>
                  {
                    index === 0 ? 'Total' :
                    index === totalRowCellCount.length - 1 ? total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : ``
                  } 
                </div>
              }
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
